import React from "react";
import { Link } from "react-router-dom";
import {
  UncontrolledCollapse,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getNumberTabs,
  getNumberCart,
} from "services/globalRedux/globalReduxActions";
import config from "services/config";
import axios from "axios";
// import PerfectScrollbar from "react-perfect-scrollbar";
// import { currencyIDR } from "services/currency";
import { getDataCartProductDetails } from "../../views/Customers/Products/Redux/customerProductActions";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";

var jwt = require("jwt-simple");
var secret = "HS256 ";
class CustomersNavbar extends React.Component {
  state = {
    alert: null,
    collapseClasses: "",
    collapseOpen: false,
    status: "top",
    count: [],
    dataQuotationGroup: {
      listProducts: [],
    },
    isLoading: false,
    history: false,
    cl:false
  };
  componentDidMount() {
    this.mounted = true;
    console.log(Link)
    this.listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 400) {
        if (this.state.status !== "bottom") {
          this.setState({ status: "bottom" });
        }
      } else {
        if (this.state.status !== "top") {
          this.setState({ status: "top" });
        }
      }
    });
    var data = JSON.parse(localStorage.getItem("cart")) || [];
    var count = [];
    data.map((element) => {
      count.push(element.quantity);
    });
    this.setState({
      count: count,
    });
    // history
    var dataCL = JSON.parse(localStorage.getItem("cl")) || false;
    this.setState({
      cl: dataCL,
    });
    var dataHistory = JSON.parse(localStorage.getItem("history")) || false;
    this.setState({
      history: dataHistory,
    });
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  componentDidUpdate() {
    document.removeEventListener("scroll", this.listener);
  }
  handlelogout = () => {
    try {
      var token_local = jwt.decode(localStorage.getItem("token"), secret);
      axios
        .post(
          config.API_URL + "/auth/logout",
          { user_id: token_local.user_id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(
          (res) => {
            this.deleteAllCookies();
            localStorage.clear();
            window.location.reload(true);
          },
          (err) => {
            if (err.response !== undefined) {
              this.failedAlert(err.response.data.message);
              this.setState({ isLoading: false });
            }
          }
        );
    } catch (e) {
      console.log(e);
    }
  };

  deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
  confirmAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Logout"
          onConfirm={() => this.handlelogout()}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, logout!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          {data}
        </SweetAlert>
      ),
    });
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  hanldeSum(index) {
    var data = JSON.parse(localStorage.getItem("cart")) || [];
    var count = parseInt(data[index].quantity) + 1;
    data[index] = {
      ...data[index],
      quantity: count,
      grand_total: data[index].total_product_price * count,
    };
    localStorage.setItem("cart", JSON.stringify(data));
    var count_component = this.state.count;
    count_component[index] = count;
    this.setState({
      count_component: count_component,
    });
  }

  handleSubstract(index) {
    var data = JSON.parse(localStorage.getItem("cart")) || [];
    if (data[index].quantity >= 0) {
      var count = parseInt(data[index].quantity) - 1;
      data[index] = {
        ...data[index],
        quantity: count,
        grand_total: data[index].total_product_price * count,
      };
      localStorage.setItem("cart", JSON.stringify(data));
      var count_component = this.state.count;
      count_component[index] = count;
      this.setState({
        count_component: count_component,
      });
    }
  }

  handleDelete(index) {
    var data = [];
    data = JSON.parse(localStorage.getItem("cart")) || [];
    data.splice(index, 1);
    localStorage.setItem("cart", JSON.stringify(data));
    this.props.dispatch(getNumberCart(data.length));
  }

  handleSubmit() {
    this.setState({ isLoading: true });
    var data = JSON.parse(localStorage.getItem("cart")) || [];
    this.setState(
      {
        dataQuotationGroup: {
          ...this.state.dataQuotationGroup,
          listProducts: data,
        },
      },
      () => {
        axios
          .post(
            config.API_URL + "/quotations/create_quotation_customers_list",
            this.state.dataQuotationGroup,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: config.TOKEN,
              },
            }
          )
          .then(
            (res) => {
              if (this.mounted) {
                this.successAlert(res.data.message);
                this.setState({ isLoading: false });
              }
            },
            (err) => {
              if (this.mounted) {
                if (err.response !== undefined) {
                  this.failedAlert(err.response.data.message);
                  this.setState({ isLoading: false });
                }
              }
            }
          );
      }
    );
  }
  confirmAlertSendQuotes = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Send Quotations"
          onConfirm={() => this.handleSubmit()}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="success"
          confirmBtnText="Yes, send it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Are you sure sending this quotations?
        </SweetAlert>
      ),
    });
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  handleCustomerLoyalti = () => {
    localStorage.setItem("cl", true);
    this.setState({
      cl: true
    });
  };
  handleToSales = () => {
    localStorage.setItem("cl", false);
    this.setState({
      cl: false
    });
  };
  
  handleHistory = () => {
    localStorage.setItem("history", true);
    this.setState({
      history: true,
    });
  };
  handleToProducts = () => {
    localStorage.setItem("history", false);
    this.setState({
      history: false,
    });
  };
  handleDetails = (data, index) => {
    this.props.dispatch(getDataCartProductDetails(data, index));
  };
  render() {
    // var data = JSON.parse(localStorage.getItem("cart")) || [];
    // const styleCartIsEmpty = {
    //   height: "200px"
    // }
    // const styleCart = {
    //   height: "400px"
    // }
console.log()
    return (
      <>
        <Navbar
          className="navbar-horizontal navbar-main navbar-light bg-white border fixed-top"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <NavbarBrand
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                this.handleToProducts();
              }}
            >
              <img alt="..." src={require("assets/img/brand/ssb_navbar.png")} />
            </NavbarBrand>
            <button
              aria-controls="navbar-collapse"
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navbar-collapse"
              data-toggle="collapse"
              id="navbar-collapse"
              type="button"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse
              className="navbar-custom-collapse"
              navbar
              toggler="#navbar-collapse"
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/admin/dashboard">
                      <img
                        alt="..."
                        src={require("assets/img/brand/ssb_navbar.png")}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button
                      aria-controls="navbar-collapse"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#navbar-collapse"
                      data-toggle="collapse"
                      id="navbar-collapse"
                      type="button"
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="mr-auto" navbar>
                {this.state.history ? (
                  <>
                    <NavItem>
                      <NavLink
                        to="/customers/prespec"
                        tag={Link}
                        onClick={() => this.props.dispatch(getNumberTabs(4))}
                      >
                        <span
                          style={{ fontSize: 12 }}
                          className="nav-link-inner--text font-weight-bold"
                        >
                          CONFIGURATION RECORD
                        </span>
                      </NavLink>
                    </NavItem>
                  </>
                ) :
                
                this.state.cl ? (
                  <>
                      <UncontrolledDropdown inNavbar nav>
                      <DropdownToggle caret nav>
                        <span
                          style={{ fontSize: 24, color: "#00aeef" }}
                          className="nav-link-inner--text font-weight-bold"
                          
                        >
                          Historical
                        </span>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                         
                          to={"/customers/historical-transaction"}
                          tag={Link}
                          // onClick={() => this.props.dispatch(getNumberTabs(1))}
                          >
                          Transactions
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                              
                           to={"/customers/historical-redeem"}
                           tag={Link}
                          // onClick={() => this.props.dispatch(getNumberTabs(5))}
                        >
                          Redeem
                          </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem>
                      <NavLink
                        to="/customers/program"
                        tag={Link}
                        // onClick={() => this.props.dispatch(getNumberTabs(3))}
                      >
                        <span
                          style={{ fontSize: 24, color: "#00aeef" }}
                          className="nav-link-inner--text font-weight-bold"
                        >
                          Program
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink 
                          to="/customers/product" 
                          tag={Link}
                          >
                        <span
                          style={{ fontSize: 24, color: "#00aeef" }}
                          className="nav-link-inner--text font-weight-bold"
                        >
                          Product
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="/customers/message"
                        tag={Link}
                        onClick={() => this.props.dispatch(getNumberTabs(2))}
                      >
                        <span
                          style={{ fontSize: 24,color: "#00aeef" }}
                          className="nav-link-inner--text font-weight-bold"
                        >
                          Inbox
                        </span>
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        to="/customerloyalti/customer-loyalti-dashboard"
                        tag={Link}
                        onClick={() => this.props.dispatch(getNumberTabs(6))}
                      >
                        <span
                          style={{ fontSize: 12 }}
                          className="nav-link-inner--text font-weight-bold"
                        >
                          CUSTOMER LOYALTI
                        </span>
                      </NavLink>
                    </NavItem> */}
                  {/* </>
                )} */}
                  </>
                )
                : (
                  <>
                    <NavItem>
                      <NavLink
                        to="/customers/customer_standard_configuration_products"
                        tag={Link}
                        onClick={() => this.props.dispatch(getNumberTabs(3))}
                      >
                        <span
                          style={{ fontSize: 12 }}
                          className="nav-link-inner--text font-weight-bold"
                        >
                          PRODUCTS
                        </span>
                      </NavLink>
                    </NavItem>
                    <UncontrolledDropdown inNavbar nav>
                      <DropdownToggle caret nav>
                        <span
                          style={{ fontSize: 12 }}
                          className="nav-link-inner--text font-weight-bold"
                        >
                          PORTOFOLIO
                        </span>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          to="/customers/customer-portofolio"
                          tag={Link}
                          onClick={() => this.props.dispatch(getNumberTabs(1))}
                          >
                          Customer Portofolio
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          to="/customers/ssb-portofolio"
                          tag={Link}
                          onClick={() => this.props.dispatch(getNumberTabs(5))}
                        >
                          SSB Portofolio
                          </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem>
                      <NavLink to="/customers/spare-parts" tag={Link}>
                        <span
                          style={{ fontSize: 12 }}
                          className="nav-link-inner--text font-weight-bold"
                        >
                          SPARE PARTS
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="/customers/progress-report"
                        tag={Link}
                        onClick={() => this.props.dispatch(getNumberTabs(2))}
                      >
                        <span
                          style={{ fontSize: 12 }}
                          className="nav-link-inner--text font-weight-bold"
                        >
                          PROGRESS REPORT
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="/customers/customer-loyalti-dashboard"
                        tag={Link}
                        // onClick={() => this.props.dispatch(getNumberTabs(6))}
                        onClick={() => {
                          this.handleCustomerLoyalti();
                        }}
                      >
                        <span
                          style={{ fontSize: 12 }}
                          className="nav-link-inner--text font-weight-bold"
                        >
                          CUSTOMER LOYALTI
                        </span>
                      </NavLink>
                    </NavItem>
                  </>
                )}
              </Nav>
              <hr className="d-lg-none" />
              <Nav className="align-items-lg-center ml-lg-auto" navbar>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    to="/customers/myprofile"
                    tag={Link}
                    id="tooltip601201423"
                  >
                    <i className="ni ni-single-02" />
                    <span className="nav-link-inner--text ml-2 d-lg-none">
                      Account
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip601201423">
                    Account
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    id="tooltip871243015"
                    href="/customers/prespec"
                    onClick={() => {
                      this.handleHistory();
                    }}
                  >
                    <i className="fa fa-history" />
                    <span className="nav-link-inner--text ml-2 d-lg-none">
                      History
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip871243015">
                    History
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="#"
                    onClick={(e) => {
                      this.confirmAlert("Are you sure?");
                    }}
                    id="tooltip366258627"
                  >
                    <i className="ni ni-user-run" />
                    <span className="nav-link-inner--text ml-2 d-lg-none">
                      Logout
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip366258627">
                    Logout
                  </UncontrolledTooltip>
                </NavItem>
                {!this.state.history ? null : (
                  <NavItem>
                    <div>
                      <Button
                        href="/customers/main_products"
                        size="sm"
                        color="primary"
                        id="tooltip366258619"
                        onClick={() => this.handleToProducts()}
                      >
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                      </Button>
                      <UncontrolledTooltip delay={0} target="tooltip366258619">
                        Back to page products
                      </UncontrolledTooltip>
                    </div>
                  </NavItem>
                )}
                {!this.state.cl ? null : (
                  <NavItem>
                    <div>
                      <Button
                        href="/customers/main_products"
                        size="sm"
                        color="primary"
                        id="tooltip366258619"
                        onClick={() => this.handleToSales()}
                      >
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                      </Button>
                      <UncontrolledTooltip delay={0} target="tooltip366258619">
                        Back
                      </UncontrolledTooltip>
                    </div>
                  </NavItem>
                )}
                <NavItem className="d-none d-lg-block ml-lg-4">
                  <Media className="align-items-center">
                    <span
                      style={{ fontSize: 12, cursor: "pointer" }}
                      className="font-weight-bold text-uppercase"
                    >
                      {config.USERNAME}
                    </span>
                  </Media>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
        {this.state.alert}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataNumberCart: state.global.dataNumberCart,
  };
};

export default connect(mapStateToProps)(CustomersNavbar);
