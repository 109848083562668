import React, { PureComponent } from "react";
import {
  Container,
  Row,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import config from "services/config";
import axios from "axios";
import ListCompanySales from "./ListCompanySales";

class SalesDetails extends PureComponent {
  state = {
    listCompanySales:[],
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getListCompanySales();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getListCompanySales = () => {
    // axios.get('https://prod-08.southeastasia.logic.azure.com:443/workflows/e52584b2d80b41e799c1877563462885/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=65H2dZuLetPrBA__uKBUHW2Nwbb243tWuyR1du94oto').then((res) => {
    //     console.log(res);
    // })
    axios
      .post(
        config.API_URL + "/users/company_sales",
        { sales_id: this.props.match.params.usersalesId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listCompanySales: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  handleDetails = () => {
     
  }
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  render() {
    return (
      <>
        <StandardHeader
          name="Sales Details"
          parentName="Sales"
          link="sales"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListCompanySales
                  list={this.state.listCompanySales}
                  info={'Companies'}
                  details={this.handleDetails}
                />
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default SalesDetails;
