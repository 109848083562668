import React from "react";

import {
  Row,
  Col,
  Container,
  Input,
  Card,
  Table
} from "reactstrap";
import background from "assets/img/document/background.png"
// import Title from "components/Breadcumbs/Title";
class Message extends React.Component {

  render() {
    return (
      <>
      <section  className="section section-lg  mt--5 mb-6 ">

        {/* <Container className="bg-primary" > */}
        {/* <div className="h1 text-right mb-4  d-inline-block" style={{color: "#00aeef", fontSize: "26px"}}>Transactions</div> */}
        <Row className="justify-content-center mr-8">
         
        <Col lg="3" md="3" xs="3" className="mt-3">
        <Row className="mb-5 text-center"  style={{color: "#00aeef", fontSize: "26px"}}>
          <Col>New Message</Col>
        </Row>
        <Row className="mb-5 text-center">
          <Col>Inbox</Col>
        </Row>
        <Row className="mt-5 text-center">
          <Col>Sent</Col>
        </Row>
        </Col>
        <Col lg="9" md="9" xs="9" className="mt-3 rounded border border-dark" style={{borderRadius: "18px"}} >
                    <Table
                              className="align-items-center "
                              hover
                              borderless
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col" style={{fontSize: "12px"}}>Date</th>
                                  <th scope="col" style={{fontSize: "12px"}}>Message</th>
                                </tr>
                              </thead>
                            <tbody>
                              <tr>
                                <td>09/09/2022 08:00</td>
                                <td>Ayo! segera tukarkan SSB Point Kamu dengan product unggulan terbaru SSB</td>
                              </tr>
                              <tr>
                                <td>08/09/2022 08:00</td>
                                <td>Selamat! Anda mendapatkan SSB Point sebesar 500.000 dari transaksi dengan no PO</td>
                              </tr>
                              <tr>
                                <td>09/09/2022 08:00</td>
                                <td>Ayo! segera tukarkan SSB Point Kamu dengan product unggulan terbaru SSB</td>
                              </tr>
                              <tr>
                                <td>08/09/2022 08:00</td>
                                <td>Selamat! Anda mendapatkan SSB Point sebesar 500.000 dari transaksi dengan no PO</td>
                              </tr>
                              <tr>
                                <td>09/09/2022 08:00</td>
                                <td>Ayo! segera tukarkan SSB Point Kamu dengan product unggulan terbaru SSB</td>
                              </tr>
                              <tr>
                                <td>08/09/2022 08:00</td>
                                <td>Selamat! Anda mendapatkan SSB Point sebesar 500.000 dari transaksi dengan no PO</td>
                              </tr>
                              <tr>
                                <td>09/09/2022 08:00</td>
                                <td>Ayo! segera tukarkan SSB Point Kamu dengan product unggulan terbaru SSB</td>
                              </tr>
                              <tr>
                                <td>08/09/2022 08:00</td>
                                <td>Selamat! Anda mendapatkan SSB Point sebesar 500.000 dari transaksi dengan no PO</td>
                              </tr>
                              <tr>
                                <td>09/09/2022 08:00</td>
                                <td>Ayo! segera tukarkan SSB Point Kamu dengan product unggulan terbaru SSB</td>
                              </tr>
                              <tr>
                                <td>08/09/2022 08:00</td>
                                <td>Selamat! Anda mendapatkan SSB Point sebesar 500.000 dari transaksi dengan no PO</td>
                              </tr>
                              <tr>
                                <td>09/09/2022 08:00</td>
                                <td>Ayo! segera tukarkan SSB Point Kamu dengan product unggulan terbaru SSB</td>
                              </tr>
                              <tr>
                                <td>08/09/2022 08:00</td>
                                <td>Selamat! Anda mendapatkan SSB Point sebesar 500.000 dari transaksi dengan no PO</td>
                              </tr>
                            </tbody>
                    </Table>
        </Col>
        </Row>
        {/* </Container> */}
      </section>
      </>
    );
  }
}

export default Message;
