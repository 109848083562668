import React, { PureComponent } from "react";
import { Container, Row } from "reactstrap";
import { currencyIDR } from "services/currency";
import config from "services/config";
import axios from "axios";
import {
  Card,
  CardTitle,
  CardText,
  Col,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";
import Breadcumbs from "components/Breadcumbs/Breadcumbs";

class PortofolioDetails extends PureComponent {
  state = {
    listDetailPortofolio: [],

    data: {
      purchase_order: "",
      created_at: "",
      status: "",
      total: "",
      total_items: "",
    },
  };
  componentDidMount = async () => {
    this.mounted = true;
    this.getPortofolioHeader();
    this.getPortofolioDetail();
  };

  componentWillUnmount() {
    this.mounted = false;
  }
  getPortofolioHeader = () => {
    axios
      .post(
        config.API_URL + "/portofolio/view_portofolio_header",
        { portofolio_id: this.props.match.params.portofolioId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              data: {
                purchase_order: res.data.data[0].purchase_order,
                created_at: res.data.data[0].created_at,
                status: res.data.data[0].status,
                total: res.data.data[0].total,
                total_items: res.data.data[0].total_items,
              },
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getPortofolioDetail = () => {
    axios
      .post(
        config.API_URL + "/portofolio//view_portofolio_sub",
        { portofolio_id: this.props.match.params.portofolioId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listDetailPortofolio: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handleClose = () => {
    this.props.history.push("/customers/customer-portofolio");
  };
  render() {
    const dataDetail = this.state.listDetailPortofolio.map((datapo) => {
      return (
        <>
          <Row key={datapo.id}  className="mx-2">
            <Col md={7}sm={12} xs={12}  className="text-left">
              <Label>
                {datapo.product_description}
              </Label>
            </Col>
            <Col md={2}sm={5} xs={12} className="text-right">
              {currencyIDR(datapo.price_unit).replace(/\.00$/, "")}
            </Col>
            <Col md={1}sm={2} xs={12} className="text-right">
              &nbsp;{datapo.qty}&nbsp;
            </Col>
            <Col md={2}sm={5} xs={12} className="text-right">
              {currencyIDR(datapo.total_value).replace(/\.00$/, "")}
            </Col>
          </Row>
        </>
      );
    });
    return (
      <>
        <Container className="bg-secondary mt--6">
          <Breadcumbs
            name="Portofolio Details"
            parentName="Portofolio"
            link="/customers/portofolio"
          />
          <Card>
            <CardHeader >
            {this.state.data.purchase_order === '' ? (
            <>
                  <i class="fas fa-sync fa-spin mr-2"></i>
                    <strong>Loading data ...</strong>
            </>
            ) : (
              <Row className="mx-2 mt-2">
                <Col className="text-left">
                  <CardTitle className="text-dark mb-1">
                    <h1>{this.state.data.purchase_order}</h1>
                  </CardTitle>
                  <CardText className="mt-1">

                    &emsp;<b>{this.state.data.total_items}</b>&nbsp;
                    <small className="text-muted">
                    Items
                    </small>
                  </CardText>

                  <small className="text-muted mt-2">
                    {this.state.data.created_at}
                  </small>
                </Col>
                <Col className="text-right align-center m-auto ">
                  <CardTitle className="text-dark mb-1">
                    <h2>
                      {this.state.data.status === "on progress" ? (
                        <Badge color="primary" pill>
                          {this.state.data.status}
                        </Badge>
                      ) : (
                        <Badge color="success" pill>
                          {this.state.data.status}
                        </Badge>
                      )}
                    </h2>
                  </CardTitle>
                </Col>
              </Row>
            )}
            </CardHeader>
          </Card>
          {this.state.data.purchase_order === '' ? null : (
              <>
          <Card>
            <CardBody className="mt-4">
              <Form>
                <FormGroup>
                  {dataDetail}
                  <br />
                  <hr />
                  <Row className="mx-2 my-1">
                    <Col sm={6} md={8} className="text-center"> <strong>Total</strong></Col>
                    <Col className="text-right" sm={6} md={4}>
                       <strong> 
                         {currencyIDR(this.state.data.total).replace(/\.00$/, "")}
                         {/* {this.state.data.total} */}
                       </strong>
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </CardBody>
              <Row className="justify-content-md-center text-center mt-2 mb-4">
                <Col md={4}>
                  <Button
                    color="danger"
                    block
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleClose()}
                  >
                    Close
                  </Button>
                </Col>
              </Row>
                  </Card>
              </>

            )}
        </Container>
      </>
    );
  }
}

export default PortofolioDetails;
