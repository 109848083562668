import Login from "views/Auth/Login";
import Otp from "views/Auth/LoginOTP";
import LoginAdmin from "views/Auth/LoginAdmin";
import ListPortofolio from "views/Customers/Portofolio/ListPortofolio";
import SsbPortofolio from "views/Customers/Portofolio/SsbPortofolio";
import SsbPortofolioDetail from "views/Customers/Portofolio/PortofolioSsbDetails";
import PortofolioDetails from "views/Customers/Portofolio/PortofolioDetails";
import MainProducts from "views/Customers/Products/MainProducts";
import ProductConfigure from "views/Customers/Products/ProductConfigure/ProductConfigure";
import ListCompanies from "views/Admin/Companies/ListCompanies";
import CreateCompany from "views/Admin/Companies/CreateCompany";
import CompanyDetails from "views/Admin/Companies/CompanyDetails";
import ListCategories from "views/Admin/Categories/ListCategories";
import CreateCategory from "views/Admin/Categories/CreateCategory";
import CategoryDetails from "views/Admin/Categories/CategoryDetails";
import ListProducts from "views/Admin/Products/ListProducts";
import CreateProduct from "views/Admin/Products/CreateProduct";
import ProductDetail from "views/Admin/Products/ProductDetail";
import CustomerListProducts from "views/Customers/Products/CustomerListProducts/CustomerListProducts";
import CustomerProductDetails from "views/Customers/Products/CustomerProductDetails/CustomerProductDetails";
import CustomersStandardConfigurationProducts from "views/Customers/Products/CustomersStandardConfigurationProducts/CustomersStandardConfigurationProducts";
import CustomersStandardConfigurationDetails from "views/Customers/Products/CustomersStandardConfigurationProducts/CustomersStandardConfigurationDetails";
import ListCustomerQuotations from "views/Customers/Quotations/ListCustomerQuotations";
import ListProductsQuotation from "views/Admin/Quotations/ListProductsQuotation";
import QuotationDetails from "views/Admin/Quotations/QuotationDetails";
import CreateNewAccount from "views/Auth/CreateNewAccount";
import CustomerQuotationDetails from "views/Customers/Quotations/CustomerQuotationDetails";
import CartProductDetails from "views/Customers/Carts/CartProductDetails";
import MyProfile from "views/Customers/MyProfile/MyProfile";
import Checkouts from "views/Customers/Checkout/Checkout";
import ListCustomerProductsQuotations from "views/Customers/Quotations/ListCustomerProductsQuotations";
import ListQuotationsAdmin from "views/Admin/Quotations/ListQuotationsAdmin";
import Sales from "views/Admin/Sales/Sales";
import SalesDetails from "views/Admin/Sales/SalesDetails";
import ListTechnicalChecklist from "views/Admin/TechnicalChecklist/ListTechnicalChecklist";
import CreateTechnicalChecklist from "views/Admin/TechnicalChecklist/CreateTechnicalChecklist";
import ConfigureNow from "views/Customers/Products/ConfigureNow/ConfigureNow";
import ConfigureDetails from "views/Customers/Products/ConfigureNow/ConfigureDetails";
import ProgressReport from "views/Customers/ProgressReport/ProgressReport";
import SpareParts from "views/Customers/SpareParts/SpareParts";
import SparePartsDetail from "views/Customers/SpareParts/SparePartsDetail";
import MyProfileAdmin from "views/Admin/MyProfile/MyProfileAdmin";
import CustomerLoyaltiDashboard from "views/CustomerLoyalti/CustomerLoyaltiDashboard";
import CustomerLoyaltiHistoryTransaction from "views/CustomerLoyalti/History/Transaction";
import CustomerLoyaltiHistoryRedeem from "views/CustomerLoyalti/History/Redeem";
import CustomerLoyaltiProgram from "views/CustomerLoyalti/Program/Program";
import CustomerLoyaltiMessage from "views/CustomerLoyalti/Message/Message";
import CustomerLoyaltiProduct from "views/CustomerLoyalti/Product/Product";
// import dashboardBehavior from "views/Admin/Customer/CustomerBehavior";

const routes = [
  //Admin
  // {
  //   path: "/customer-behavior",
  //   name: "Customer",
  //   component: dashboardBehavior,
  //   layout: "/admin",
  //   child: false,
  // },
  {
    path: "/myprofile-admin",
    name: "My Profile",
    miniName: "MP",
    component: MyProfileAdmin,
    layout: "/admin",
    child: false,
  },
  {
    path: "/companies",
    name: "Companies",
    icon: "ni ni-building text-dark",
    component: ListCompanies,
    layout: "/admin",
  },
  {
    path: "/create_company",
    name: "Create Company",
    component: CreateCompany,
    layout: "/admin",
    child: false,
  },
  {
    path: "/company_details/:companyId",
    name: "Company Details",
    component: CompanyDetails,
    layout: "/admin",
    child: false,
  },
  {
    path: "/user_sales",
    name: "User Sales",
    icon: "fa fa-users text-dark",
    component: Sales,
    layout: "/admin"
  },
  {
    path: "/user_sales_details/:usersalesId",
    name: "Sales Details",
    component: SalesDetails,
    layout: "/admin",
    child: false,
  },
  {
    path: "/technical-checklist",
    name: "Technical Checklist",
    icon: "fa fa-check text-dark",
    component: ListTechnicalChecklist,
    layout: "/admin"
  },
  {
    path: "/create-technical-checklist",
    name: "Create Technical Checklist",
    icon: "fa fa-check text-dark",
    component: CreateTechnicalChecklist,
    layout: "/admin",
    child: false,
  },
  {
    collapse: true,
    name: "Catalogue",
    icon: "fa fa-cubes text-dark",
    state: "catalogueCollapse",
    views: [
      {
        path: "/categories",
        name: "Categories",
        miniName: "C",
        component: ListCategories,
        layout: "/admin",
      },
      {
        path: "/create_category",
        name: "Create Category",
        miniName: "C",
        component: CreateCategory,
        layout: "/admin",
        child: false,
      },
      {
        path: "/category_details/:categoryId",
        name: "Category Details",
        miniName: "C",
        component: CategoryDetails,
        layout: "/admin",
        child: false,
      },
      {
        path: "/products",
        name: "Products",
        miniName: "P",
        component: ListProducts,
        layout: "/admin",
      },
      {
        path: "/create_product",
        name: "Create Product",
        miniName: "P",
        component: CreateProduct,
        layout: "/admin",
        child: false,
      },
      {
        path: "/product_details/:productId",
        name: "Product Details",
        miniName: "P",
        component: ProductDetail,
        layout: "/admin",
        child: false,
      },
    ],
  },

  {
    path: "/quotations",
    name: "Preliminary Spec",
    icon: "fa fa-tasks text-dark",
    component: ListQuotationsAdmin,
    layout: "/admin",
  },
  {
    path: "/quotations",
    name: "Configuration Record",
    icon: "fa fa-tasks text-dark",
    component: ListProductsQuotation,
    layout: "/admin",
  },
  {
    path: "/quotation_products/:quotationsId",
    name: "Quotation Product",
    icon: "fa fa-tasks text-dark",
    component: ListProductsQuotation,
    layout: "/admin",
    child: false,
  },
  {
    path: "/quotation_details/:quoteId",
    name: "Quotation Details",
    component: QuotationDetails,
    layout: "/admin",
    child: false,
  },
  
  

  //customers
  {
    path: "/spare-parts",
    name: "Spare Parts",
    miniName: "SP",
    component: SpareParts,
    layout: "/customers",
    child: false,
  },
  {
    path: "/spare-parts-details",
    name: "Spareparts Details",
    miniName: "D",
    component: SparePartsDetail,
    layout: "/customers",
    child: false,
  },
  {
    path: "/progress-report",
    name: "Progress Report",
    miniName: "PR",
    component: ProgressReport,
    layout: "/customers",
    child: false,
  },
  {
    path: "/customer-portofolio",
    name: "Customer Portofolio",
    miniName: "P",
    component: ListPortofolio,
    layout: "/customers",
    child: false,
  },
  {
    path: "/portofolio_details/:portofolioId",
    name: "Portofolio Details",
    miniName: "PD",
    component: PortofolioDetails,
    layout: "/customers",
    child: false,
  },
  {
    path: "/ssb-portofolio",
    name: "SSB Portofolio",
    miniName: "P",
    component: SsbPortofolio,
    layout: "/customers",
    child: false,
  },
  {
    path: "/ssb-portofolio-detail/:pid",
    name: "SSB Detail Portofolio ",
    miniName: "P",
    component: SsbPortofolioDetail,
    layout: "/customers",
    child: false,
  },
  {
    path: "/main_products",
    name: "Main Products",
    miniName: "MP",
    component: MainProducts,
    layout: "/customers",
    child: false,
  },
  {
    path: "/customer_standard_configuration_products",
    name: "Standard Products",
    miniName: "SP",
    component: CustomersStandardConfigurationProducts,
    layout: "/customers",
    child: false,
  },
  {
    path: "/customer_standard_configuration_details",
    name: "Products Details",
    miniName: "PD",
    component: CustomersStandardConfigurationDetails,
    layout: "/customers",
    child: false,
  },
  {
    path: "/product_configure",
    name: "Product Configure",
    miniName: "PC",
    component: ProductConfigure,
    layout: "/customers",
    child: false,
  },
  {
    path: "/customer_list_products",
    name: "Customer List Products",
    miniName: "CLS",
    component: CustomerListProducts,
    layout: "/customers",
    child: false,
  },
  {
    path: "/customer_products_details",
    name: "Customer Products Details",
    miniName: "CPD",
    component: CustomerProductDetails,
    layout: "/customers",
    child: false,
  },
  {
    path: "/quotations",
    name: "Quotations",
    miniName: "Q",
    component: ListCustomerQuotations,
    layout: "/customers",
    child: false,
  },
   {
    path: "/prespec",
    name: "Prespec",
    miniName: "Q",
    component: ListCustomerProductsQuotations,
    layout: "/customers",
    child: false,
  },
  {
    path: "/prespec_details/:companyId/:quoteId",
    name: "Quotation Details",
    miniName: "QD",
    component: CustomerQuotationDetails,
    layout: "/customers",
    child: false,
  },
  {
    path: "/product_quotation_details/:quotationsId",
    name: "Product Quotation Details",
    miniName: "PQ",
    component: ListCustomerProductsQuotations,
    layout: "/customers",
    child: false,
  },
  {
    path: "/cart_product_details",
    name: "Cart Product Details",
    miniName: "CPD",
    component: CartProductDetails,
    layout: "/customers",
    child: false,
  },
  {
    path: "/checkout",
    name: "Checkout",
    miniName: "C",
    component: Checkouts,
    layout: "/customers",
    child: false,
  },
  {
    path: "/myprofile",
    name: "My Profile",
    miniName: "MP",
    component: MyProfile,
    layout: "/customers",
    child: false,
  },

  // configure now
  {
    path: "/configure-now/:productId",
    name: "Configure Now",
    miniName: "CN",
    component: ConfigureNow,
    layout: "/customers",
    child: false,
  },
  {
    path: "/configure-details",
    name: "Configure Details",
    miniName: "CD",
    component: ConfigureDetails,
    layout: "/customers",
    child: false,
  },

  //Customer Loyalti

  {
    path: "/customer-loyalti-dashboard",
    name: "Customer Loyalti Dashboad",
    miniName: "CL",
    component: CustomerLoyaltiDashboard,
    layout: "/customers",
    cl: true,
    child: false,
  },
  {
    path: "/historical-transaction",
    name: "Historical Transaction",
    miniName: "HR",
    component: CustomerLoyaltiHistoryTransaction,
    layout: "/customers",
    cl: true,
    child: false,
  },
  {
    path: "/historical-redeem",
    name: "Historical Redeem",
    miniName: "HR",
    component: CustomerLoyaltiHistoryRedeem,
    layout: "/customers",
    cl: true,
    child: false,
  },
  {
    path: "/program",
    name: "Program",
    miniName: "P",
    component: CustomerLoyaltiProgram,
    layout: "/customers",
    cl: true,
    child: false,
  },
  {
    path: "/product",
    name: "Product",
    miniName: "P",
    component: CustomerLoyaltiProduct,
    layout: "/customers",
    cl: true,
    child: false,
  },
  {
    path: "/message",
    name: "Message",
    miniName: "M",
    component: CustomerLoyaltiMessage,
    layout: "/customers",
    cl: true,
    child: false,
  },

  //auth
  {
    path: "/login",
    name: "Login",
    miniName: "L",
    component: Login,
    layout: "/auth",
    child: false,
  },
  {
    path: "/otp/:Id/:Userid",
    name: "OTP",
    miniName: "L",
    component: Otp,
    layout: "/auth",
    child: false,
  },
  {
    path: "/create_new_account",
    name: "Create New Account",
    miniName: "C",
    component: CreateNewAccount,
    layout: "/auth",
    child: false,
  },
  {
    path: "/loginAdmin",
    name: "Login admin",
    miniName: "LGADM",
    component: LoginAdmin,
    layout: "/auth",
    child: false,
  },
];

export default routes;
