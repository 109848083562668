import Title from "components/Breadcumbs/Title";
import React, { PureComponent } from "react";
import {
  Container,
  Card,
  Table,
  CardHeader,
  Row,
  Col,
  Media,
  Button,
} from "reactstrap";
import { currencyIDR } from "services/currency";
import config from "services/config";
import CardFooter from "reactstrap/lib/CardFooter";
import { connect } from "react-redux";
import { getDataCartProductDetails } from "../Products/Redux/customerProductActions";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
class Checkouts extends PureComponent {
  state = { alert: null, isLoading: false };

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  confirmAlertSendQuotes = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Send Quotations"
          onConfirm={() => this.handleSubmit()}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="success"
          confirmBtnText="Yes, send it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Are you sure sending this peliminary spec?
        </SweetAlert>
      ),
    });
  };

  handleSubmit() {
    this.setState({ isLoading: true });
    var data = JSON.parse(localStorage.getItem("cart")) || [];
    this.setState(
      {
        dataQuotationGroup: {
          ...this.state.dataQuotationGroup,
          listProducts: data,
        },
      },
      () => {
        axios
          .post(
            config.API_URL + "/quotations/create_quotation_customers_list",
            this.state.dataQuotationGroup,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: config.TOKEN,
              },
            }
          )
          .then(
            (res) => {
              if (this.mounted) {
                this.successAlert(res.data.message);
                this.setState({ isLoading: false });
              }
            },
            (err) => {
              if (this.mounted) {
                if (err.response !== undefined) {
                  this.failedAlert(err.response.data.message);
                  this.setState({ isLoading: false });
                }
              }
            }
          );
      }
    );
  }
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
    localStorage.removeItem("cart");
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleDetails = (data, index) => {
    this.props.dispatch(getDataCartProductDetails(data, index));
    this.props.history.push("/customers/cart_product_details");
  };

  handleSubmit() {
    this.setState({ isLoading: true });
    var data = JSON.parse(localStorage.getItem("cart")) || [];
    this.setState(
      {
        dataQuotationGroup: {
          ...this.state.dataQuotationGroup,
          listProducts: data,
        },
      },
      () => {
        axios
          .post(
            config.API_URL + "/quotations/create_quotation_customers_list",
            this.state.dataQuotationGroup,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: config.TOKEN,
              },
            }
          )
          .then(
            (res) => {
              if (this.mounted) {
                this.successAlert(res.data.message);
                this.setState({ isLoading: false });
              }
            },
            (err) => {
              if (this.mounted) {
                if (err.response !== undefined) {
                  this.failedAlert(err.response.data.message);
                  this.setState({ isLoading: false });
                }
              }
            }
          );
      }
    );
  }

  confirmAlertSendQuotes = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Send Configuration"
          onConfirm={() => this.handleSubmit()}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="success"
          confirmBtnText="Yes, send it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Are you sure sending this peliminary spec?
        </SweetAlert>
      ),
    });
  };

  render() {
    var data = JSON.parse(localStorage.getItem("cart")) || [];
    var total = 0;
    return (
      <>
        <Container className="bg-secondary mt--6">
          <Title name="Checkout" />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="6">
                      <h3 className="mb-0">List Preliminary Spec</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-flush"
                  hover
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Price</th>
                      {/* <th scope="col">Tax</th>
                      <th scope="col">Discount</th> */}
                      <th scope="col">Total/Unit</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Sub Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((element, index) => {
                      total = Number(element.grand_total) + total;
                      return (
                        <tr
                          key={index}
                          style={{ cursor: "pointer" }}
                          onDoubleClick={() =>
                            this.handleDetails(element, index)
                          }
                        >
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={
                                    config.BUCKET_URL+
                                    element.image_1
                                  }
                                />
                              </a>
                              <Media>
                                <span className="mb-0 text-sm">
                                  {element.chasis} {element.tank_capacity}{" "}
                                  {element.axle}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>
                            {currencyIDR(element.product_price).replace(
                              /\.00$/,
                              ""
                            )}
                          </td>
                          {/* <td>{element.tax + " %"}</td>
                          <td>{element.discount + " %"}</td> */}
                          <td>
                            {currencyIDR(element.total_product_price).replace(
                              /\.00$/,
                              ""
                            )}
                          </td>
                          <td>{element.quantity}</td>
                          <td>
                            {currencyIDR(element.grand_total).replace(
                              /\.00$/,
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <Row className="align-items-center">
                    <Col className="text-right">
                      <small>Grand Total</small>
                      <h4 className="display-4">
                        {currencyIDR(total).replace(/\.00$/, "")}
                      </h4>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
              <Row className="justify-content-md-center mb-3">
                <Col>
                  <Button
                    className="btn-second text-uppercase"
                    type="button"
                    onClick={() => this.confirmAlertSendQuotes()}
                  >
                    <span id="request_quote" className="btn-inner--text">
                      Submit to Preliminary Spec
                    </span>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

export default connect()(Checkouts);
