export const GET_DATA_CUSTOMER_PRODUCTS= 'GET_DATA_CUSTOMER_PRODUCTS';
export const GET_DATA_CUSTOMER_PRODUCTS_DETAILS= 'GET_DATA_CUSTOMER_PRODUCTS_DETAILS';
export const GET_DATA_PRODUCT_TYPE= 'GET_DATA_PRODUCT_TYPE';
export const GET_DATA_CUSTOMER_QUOTATIONS_DETAILS = 'GET_DATA_CUSTOMER_QUOTATIONS_DETAILS';
export const GET_DATA_CART_PRODUCT_DETAILS = 'GET_DATA_CART_PRODUCT_DETAILS';

export const getDataCustomerProducts = customerProduct => ({
    type: GET_DATA_CUSTOMER_PRODUCTS,
    payload: {customerProduct}
});
export const getDataCustomerProductsDetails = customerProductDetails => ({
    type: GET_DATA_CUSTOMER_PRODUCTS_DETAILS,
    payload: {customerProductDetails}
});
export const getDataProductType = productType => ({
    type: GET_DATA_PRODUCT_TYPE,
    payload: {productType}
});
export const getDataCustomerQuotationsDetails = customerQuotationDetails => ({
    type: GET_DATA_CUSTOMER_QUOTATIONS_DETAILS,
    payload: {customerQuotationDetails}
});
export const getDataCartProductDetails = (cartProductDetails, index) => ({
    type: GET_DATA_CART_PRODUCT_DETAILS,
    payload: {cartProductDetails, indexCart: index}
});