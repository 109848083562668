
import React, { PureComponent } from "react";

import {
    Row,
    Col,
    Container,
    Breadcrumb,
    BreadcrumbItem
  } from "reactstrap";

  import { Link } from "react-router-dom";

class Breadcumbs extends PureComponent {
  render() {
    return (
      <>
        <Container className="mt--2 ml--2">
        <Row className="align-items-center py-4">
            <Col>
              <h6 className="h2 text-black d-inline-block mb-0">
                {this.props.name}
              </h6>{" "}
              <Breadcrumb
                className="d-none d-md-inline-block ml-md-4"
                listClassName="breadcrumb-links breadcrumb-white"
              >
                <BreadcrumbItem>
                   
                    <i className="fas fa-chevron-circle-right text-black" />
                    
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Link className="text-black" to={this.props.link}>
                    {this.props.parentName}
                    </Link>
                </BreadcrumbItem>
                <BreadcrumbItem
                    aria-current="page"
                    className="active text-black"
                >
                    {this.props.name}
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            {/* <Col className="text-right" lg="6" xs="5">
                  <Button
                    className="btn-neutral text-uppercase"
                    color=""
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    size="sm"
                  >
                    Configure
                  </Button>
                </Col> */}
          </Row>
        </Container>
      </>
    );
  }
}

export default Breadcumbs;
