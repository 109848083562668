/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import config from "services/config";
import axios from "axios";
import { Container, Row, Col, Table } from "reactstrap";
import Breadcumbs from "components/Breadcumbs/Breadcumbs";

class PortofolioSsbDetails extends PureComponent {
  state = {
    listPortofolio: [],
    product: "",
    data: {
      product_description: "",
      company: "",
      year: "",
    },
  };
  componentDidMount = async () => {
    this.mounted = true;
    this.getPortofolio();
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  getPortofolio = () => {
    axios
      .post(
        config.API_URL + "/portofolio/view_detail_ssb_portofolio_customer",
        { pid: this.props.match.params.pid },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listPortofolio: res.data.data,
                product: res.data.data[0].product,
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handleClose = () => {
    this.props.history.push("/customers/ssb-portofolio");
  };
  render() {
    const listHistory = this.state.listPortofolio.map((list, index) => {
      return (
        <>
          <tr>
            <td>{list.product_description}</td>
            <td>{list.company_name.toUpperCase()}</td>
            <td>{list.year}</td>
          </tr>
        </>
      );
    });
    return (
      <>
        <Container className="bg-secondary mt--6">
          <Breadcumbs
            name={this.props.match.params.pid}
            parentName="SSB Portofolio"
            link="/customers/ssb-portofolio"
          />
          {this.state.listPortofolio === "" ? (
            <>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <i class="fas fa-sync fa-spin mr-2"></i>
                  <strong>Loading data ...</strong>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className=" mx-1 rounded border border-dark mb-4 pt-3">
                <Table striped responsive>
                  <tr>
                    <th><b>Product</b></th>
                    <th>
                      <b>Company</b>
                    </th>
                    <th>
                      <b>Year</b>
                    </th>
                  </tr>
                  {listHistory}
                </Table>
              </Row>
              <Container>
                <a className="pull-right" 
                style={{ cursor: "pointer" }}
                onClick={() => this.handleClose()}>
                  <b>
                    <i className="fa fa-chevron-left" aria-hidden="true">
                      &emsp;Back
                    </i>
                  </b>
                </a>
              </Container>
            </>
          )}
        </Container>
      </>
    );
  }
}

export default PortofolioSsbDetails;
