import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Button,
  PopoverBody,
  UncontrolledPopover,
  FormGroup,
  Input,
  Form,
  CardHeader,
} from "reactstrap";
import Breadcumbs from "components/Breadcumbs/Breadcumbs";
import config from "services/config";
import { currencyIDR } from "services/currency";
import { connect } from "react-redux";
import { getDataCustomerProductsDetails } from "../Redux/customerProductActions";
// import { getNumberCart } from "services/globalRedux/globalReduxActions";
import axios from "axios";
import Pagination from "react-bootstrap-pagination-logic";
import { styleInputSelect } from "services/styles";
import AsyncSelect from "react-select/async";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";

class CustomerStandardConfigurationProducts extends React.Component {
  state = {
    actionListProduct: {
      page: 1,
      items_per_page: 10,
      order_by_field: "product_id",
      order_by_direction: "DESC",
      category_id: 0,
      product_no: "",
      product_type: "",
      chasis: "",
      axle: "",
      tank_capacity: "",
    },
    actionListCategories: {
      page: 1,
      items_per_page: 10,
      order_by_field: "category_id",
      order_by_direction: "DESC",
      category_name: "",
    },
    listAxle: [],
    listChasis: [],
    listCapacity: [],
    typeProduct: "",
    selectedOptionCategories: [],
    listProduct: [],
    total_pages: 0,
    total_items: 0,
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
  };
  componentDidMount() {
    this.mounted = true;
    this.setState(
      {
        actionListProduct: {
          ...this.state.actionListProduct,
          product_type: this.props.dataProductType,
        },
      },
      () => {
        this.getDataListProducts();
      }
    );
// console.log(dataProductType);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getListChasis = async () => {
    try {
      const response = await axios.post(
        config.API_URL + "/customer_list_products/customer_list_chasis",
        { category_id: this.state.actionListProduct.category_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        listChasis: response.data.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  getListAxle = async () => {
    try {
      const response = await axios.post(
        config.API_URL + "/customer_list_products/customer_list_axle",
        {
          category_id: this.state.actionListProduct.category_id,
          chasis: this.state.actionListProduct.chasis,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        listAxle: response.data.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  fetchDataCategories = (inputValue) => {
    let searchTerm = inputValue;

    if (!inputValue || inputValue === " ") {
      searchTerm = "";
    }

    this.setState({
      actionListCategories: {
        ...this.state.actionListCategories,
        category_name: searchTerm,
      },
    });

    const newRequest = axios.post(
      config.API_URL + "/categories/categories_customers",
      this.state.actionListCategories,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );

    if (newRequest) {
      return newRequest.then((response) => {
        const compare = response.data.data.filter((i) =>
          i.category_name.toLowerCase().includes(inputValue.toLowerCase())
        );
        return compare.map((data) => ({
          label: data.category_name,
          value: data.category_id,
        }));
      });
    }
  };
  onSearchChangeCategories = (selectedOptionCategories) => {
    this.setState(
      {
        selectedOptionCategories: selectedOptionCategories,
        typeProduct: selectedOptionCategories.label,
        actionListProduct: {
          ...this.state.actionListProduct,
          category_id: selectedOptionCategories.value,
        },
      },
      () => {
        this.getListChasis();
        this.getListAxle();
        if (this.state.typeProduct === "Water Truck") {
          if (
            this.state.actionListProduct.axle === "6x4" ||
            this.state.actionListProduct.axle === "6x6"
          ) {
            this.setState({
              listCapacity: [
                {
                  value: "20KL",
                  label: "20KL",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "8x4") {
            this.setState({
              listCapacity: [
                {
                  value: "30KL",
                  label: "30KL",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "4x4") {
            this.setState({
              listCapacity: [
                {
                  value: "",
                  label: "",
                },
              ],
            });
          }
        }
        if (this.state.typeProduct === "Fuel Truck") {
          if (
            this.state.actionListProduct.axle === "6x4" ||
            this.state.actionListProduct.axle === "6x6"
          ) {
            this.setState({
              listCapacity: [
                {
                  value: "20KL",
                  label: "20KL",
                },
                {
                  value: "25KL",
                  label: "25KL",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "8x4") {
            this.setState({
              listCapacity: [
                {
                  value: "32KL",
                  label: "32KL",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "4x4") {
            this.setState({
              listCapacity: [
                {
                  value: "",
                  label: "",
                },
              ],
            });
          }
        }
        if (this.state.typeProduct === "Lube Service") {
          if (
            this.state.actionListProduct.axle === "6x4" ||
            this.state.actionListProduct.axle === "6x6"
          ) {
            this.setState({
              listCapacity: [
                {
                  value: "5P2L",
                  label: "Standard SSB",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "8x4") {
            this.setState({
              listCapacity: [
                {
                  value: "",
                  label: "",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "4x4") {
            this.setState({
              listCapacity: [
                {
                  value: "",
                  label: "",
                },
              ],
            });
          }
        }

        if (this.state.typeProduct === "Wash Down") {
          if (
            this.state.actionListProduct.axle === "6x4" ||
            this.state.actionListProduct.axle === "6x6"
          ) {
            this.setState({
              listCapacity: [
                {
                  value: "20KL",
                  label: "20KL",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "8x4") {
            this.setState({
              listCapacity: [
                {
                  value: "30KL",
                  label: "30KL",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "4x4") {
            this.setState({
              listCapacity: [
                {
                  value: "",
                  label: "",
                },
              ],
            });
          }
        }

        if(this.state.typeProduct === 'Crane Truck'){
          if(this.state.actionListProduct.axle === '6x6'){
            this.setState({
              listCapacity: [
                {
                  value: '13.7 MT',
                  label: '13.7 MT'
                },
                {
                  value: '21.9 MT',
                  label: '21.9 MT'
                },
                {
                  value: '29 MT',
                  label: '29 MT'
                },
                {
                  value: '35.1 MT',
                  label: '35.1 MT'
                },
                {
                  value: '46.9 MT',
                  label: '46.9 MT'
                },
              ]
            })
          }
          if(this.state.actionListProduct.axle === '6x4'){
            this.setState({
              listCapacity: [
                {
                  value: '13.7 MT',
                  label: '13.7 MT'
                },
                {
                  value: '21.9 MT',
                  label: '21.9 MT'
                },
                {
                  value: '29 MT',
                  label: '29 MT'
                },
                {
                  value: '35.1 MT',
                  label: '35.1 MT'
                },
                {
                  value: '46.9 MT',
                  label: '46.9 MT'
                },
              ]
            })
          }
        }

        if (this.state.typeProduct === "Fuel Lube Service") {
          if (
            this.state.actionListProduct.axle === "6x4" ||
            this.state.actionListProduct.axle === "6x6"
          ) {
            this.setState({
              listCapacity: [
                {
                  value: "4KL+5P2L",
                  label: "Standard SSB",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "8x4") {
            this.setState({
              listCapacity: [
                {
                  value: "",
                  label: "",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "4x4") {
            this.setState({
              listCapacity: [
                {
                  value: "",
                  label: "",
                },
              ],
            });
          }
        }

        if(this.state.typeProduct === 'Manhaul Bus'){
          if(this.state.actionListProduct.axle === '4x4'){
            this.setState({
              listCapacity: [
                {
                  value: '33 Seat',
                  label: '33 Seat'
                },
                {
                  value: '35 Seat',
                  label: '35 Seat'
                },
                {
                  value: '40 Seat',
                  label: '40 Seat'
                },
                {
                  value: '51 Seat',
                  label: '51 Seat'
                },
                {
                  value: '55 Seat',
                  label: '55 Seat'
                }
              ]
            })
          }
        }
      }
    );
  };
  getDataListProducts = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL +
          "/customer_list_products/customer_list_standard_products",
        this.state.actionListProduct,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listProduct: res.data.data,
                total_pages: res.data.total_pages,
                total_items: res.data.total_items,
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handletoListProductsDetails = (data) => {
    this.props.dispatch(getDataCustomerProductsDetails(data));
    this.props.history.push(
      "/customers/customer_standard_configuration_details"
    );
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleChangeString = (event) => {
    this.setState({
      actionListProduct: {
        ...this.state.actionListProduct,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeChasis = (event) => {
    this.setState(
      {
        actionListProduct: {
          ...this.state.actionListProduct,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        this.getListAxle();
      }
    );
  };

  handleReset = () => {
    this.setState(
      {
        actionListProduct: {
          ...this.state.actionListProduct,
          page: 1,
          items_per_page: 10,
          order_by_field: "product_id",
          order_by_direction: "DESC",
          product_no: "",
          product_type: "",
          category_id: 0,
          chasis: "",
          axle: "",
          tank_capacity: "",
        },
        selectedOptionCategories: [],
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getDataListProducts();
            }
          );
        }
      }
    );
  };
  handlePagination = (numPage) => {
    this.setState(
      {
        actionListProduct: {
          ...this.state.actionListProduct,
          page: numPage,
        },
      },
      async () => {
        await this.getDataListProducts();
        window.scrollTo({
          top: 10,
          behavior: "smooth",
        });
      }
    );
  };
  handleFilter = () => {
    this.setState(
      {
        actionListProduct: {
          ...this.state.actionListProduct,
          page: 1,
        },
      },
      () => {
        this.getDataListProducts();
      }
    );
  };
  handleToFilterComponent = () => {
    var elmnt = document.getElementById("filter_component");
    elmnt.scrollIntoView({ block: "start", behavior: "smooth" });
  };
  // handleAddToCart = (data_) => {
  //   var data = [];
  //   data = JSON.parse(localStorage.getItem('cart')) || [];
  //   data.push({
  //     ...data_,
  //     quantity: 1
  //   });
  //   localStorage.setItem('cart', JSON.stringify(data));
  //   this.props.dispatch(getNumberCart(data.length))
  // }

  handleChangeAxle = (event) => {
    this.setState(
      {
        actionListProduct: {
          ...this.state.actionListProduct,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        if (this.state.typeProduct === "Water Truck") {
          if (
            this.state.actionListProduct.axle === "6x4" ||
            this.state.actionListProduct.axle === "6x6"
          ) {
            this.setState({
              listCapacity: [
                {
                  value: "20KL",
                  label: "20KL",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "8x4") {
            this.setState({
              listCapacity: [
                {
                  value: "30KL",
                  label: "30KL",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "4x4") {
            this.setState({
              listCapacity: [
                {
                  value: "",
                  label: "",
                },
              ],
            });
          }
        }
        if (this.state.typeProduct === "Fuel Truck") {
          if (
            this.state.actionListProduct.axle === "6x4" ||
            this.state.actionListProduct.axle === "6x6"
          ) {
            this.setState({
              listCapacity: [
                {
                  value: "20KL",
                  label: "20KL",
                },
                {
                  value: "25KL",
                  label: "25KL",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "8x4") {
            this.setState({
              listCapacity: [
                {
                  value: "32KL",
                  label: "32KL",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "4x4") {
            this.setState({
              listCapacity: [
                {
                  value: "",
                  label: "",
                },
              ],
            });
          }
        }
        if (this.state.typeProduct === "Lube Service") {
          if (
            this.state.actionListProduct.axle === "6x4" ||
            this.state.actionListProduct.axle === "6x6"
          ) {
            this.setState({
              listCapacity: [
                {
                  value: "5P2L",
                  label: "Standard SSB",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "8x4") {
            this.setState({
              listCapacity: [
                {
                  value: "",
                  label: "",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "4x4") {
            this.setState({
              listCapacity: [
                {
                  value: "",
                  label: "",
                },
              ],
            });
          }
        }

        if (this.state.typeProduct === "Wash Down") {
          if (
            this.state.actionListProduct.axle === "6x4" ||
            this.state.actionListProduct.axle === "6x6"
          ) {
            this.setState({
              listCapacity: [
                {
                  value: "20KL",
                  label: "20KL",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "8x4") {
            this.setState({
              listCapacity: [
                {
                  value: "30KL",
                  label: "30KL",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "4x4") {
            this.setState({
              listCapacity: [
                {
                  value: "",
                  label: "",
                },
              ],
            });
          }
        }

        if(this.state.typeProduct === 'Crane Truck'){
          if(this.state.actionListProduct.axle === '6x6'){
            this.setState({
              listCapacity: [
                {
                  value: '13.7 MT',
                  label: '13.7 MT'
                },
                {
                  value: '21.9 MT',
                  label: '21.9 MT'
                },
                {
                  value: '29 MT',
                  label: '29 MT'
                },
                {
                  value: '35.1 MT',
                  label: '35.1 MT'
                },
                {
                  value: '46.9 MT',
                  label: '46.9 MT'
                },
              ]
            })
          }
          if(this.state.actionListProduct.axle === '6x4'){
            this.setState({
              listCapacity: [
                {
                  value: '13.7 MT',
                  label: '13.7 MT'
                },
                {
                  value: '21.9 MT',
                  label: '21.9 MT'
                },
                {
                  value: '29 MT',
                  label: '29 MT'
                },
                {
                  value: '35.1 MT',
                  label: '35.1 MT'
                },
                {
                  value: '46.9 MT',
                  label: '46.9 MT'
                },
              ]
            })
          }
        }

        if (this.state.typeProduct === "Fuel Lube Service") {
          if (
            this.state.actionListProduct.axle === "6x4" ||
            this.state.actionListProduct.axle === "6x6"
          ) {
            this.setState({
              listCapacity: [
                {
                  value: "4KL+5P2L",
                  label: "Standard SSB",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "8x4") {
            this.setState({
              listCapacity: [
                {
                  value: "",
                  label: "",
                },
              ],
            });
          }
          if (this.state.actionListProduct.axle === "4x4") {
            this.setState({
              listCapacity: [
                {
                  value: "",
                  label: "",
                },
              ],
            });
          }
        }

        if(this.state.typeProduct === 'Manhaul Bus'){
          if(this.state.actionListProduct.axle === '4x4'){
            this.setState({
              listCapacity: [
                {
                  value: '33 Seat',
                  label: '33 Seat'
                },
                {
                  value: '35 Seat',
                  label: '35 Seat'
                },
                {
                  value: '40 Seat',
                  label: '40 Seat'
                },
                {
                  value: '51 Seat',
                  label: '51 Seat'
                },
                {
                  value: '55 Seat',
                  label: '55 Seat'
                }
              ]
            })
          }
        }
      }
    );
  };

  render() {
    const dataProduct = this.state.listProduct.map((products, index) => {
      return (
        <Card
          className="card-lift--hover shadow border-0"
          key={products.product_id}
        >
          <CardBody>
            <div className="d-flex flex-wrap flex-md-nowrap flex-lg-nowrap flex-xl-nowrap">
              <div className="mr-2">
                <img
                  alt="..."
                  className="rounded"
                  width="360"
                  height="220"
                  style={{ cursor: "pointer", objectFit: 'cover'}}
                  src={config.BUCKET_URL + products.image_1}
                  onClick={() => this.handletoListProductsDetails(products)}
                />
              </div>
              <div className="ml-2 mt-md-0 mt-lg-0 mt-xl-0 mt-sm-2 mt-2">
                <h2>
                  {products.category_name} {products.tank_capacity}{" "}
                  {products.chasis} {products.axle}
                </h2>
                <small className="text-dark">
                  Start from{" "}
                  {currencyIDR(products.product_price).replace(/\.00$/, "")}
                  <span
                    className="text-red pe-auto"
                    id={`tooltip3662586${index}`}
                  >
                    *
                  </span>
                  <UncontrolledTooltip
                    delay={0}
                    target={`tooltip3662586${index}`}
                  >
                    Prices are estimated based on configuration. the actual
                    price may change after the requirement are fixed
                  </UncontrolledTooltip>
                </small>
                <CardText className="description text-dark mb-0">
                  {products.product_description.substring(0, 130) + "..."}
                  <Button
                    color="link"
                    className="px-0"
                    id={"UncontrolledPopover" + index}
                    type="button"
                    size="sm"
                  >
                    Read more
                  </Button>
                </CardText>
                <small className="text-dark">
                  Lead Time {products.lead_time}
                </small>
                <UncontrolledPopover
                  placement="bottom"
                  target={"UncontrolledPopover" + index}
                >
                  <PopoverBody>{products.product_description}</PopoverBody>
                </UncontrolledPopover>
                <div className="d-flex px-0 py-2 mt-2 bd-highlight">
                  <Button
                    className="text-uppercase"
                    size="sm"
                    color="primary"
                    type="button"
                    onClick={() =>
                      window.open(
                        config.BUCKET_URL + products.file_specifications,
                        "_blank"
                      )
                    }
                  >
                    Brochure
                  </Button>
                  <Button
                    className="text-uppercase"
                    size="sm"
                    color="primary"
                    type="button"
                    onClick={() => this.handletoListProductsDetails(products)}
                  >
                    Configure More
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      );
    });
    return (
      <>
        <Container className="bg-secondary mt--6">
          <Breadcumbs
            name="All Products"
            parentName="Products"
            link="/customers/main_products"
          />
          <Row id="on_top"></Row>
          <Row className="flex-wrap-reverse">
            {this.state.listProduct.length === 0 ? (
              <Col lg="8">
                <Card className="card-frame mt-5">
                  <CardBody>
                    <i class="fas fa-sync fa-spin mr-2"></i>
                    <strong>Waiting products ...</strong>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <>
                <Col lg="8">
                  <h4 className="text-dark h4 mb-4">
                    {this.state.total_items} Product Found
                  </h4>

                  {dataProduct}

                  <Row className="justify-content-md-center">
                    <Col>
                      <Pagination
                        current_page={this.state.actionListProduct.page}
                        last_page={this.state.total_pages}
                        position="left"
                        handlePageChange={this.handlePagination}
                      />
                    </Col>
                  </Row>
                </Col>
              </>
            )}
            <Col lg="4" md="12" className="align-items-right">
              <Card
                className="bg-secondary border-0 mt-5"
                style={{ position: "sticky", top: 100 }}
              >
                <CardHeader className="pb-5t">
                  <div className="text-muted text-center mt-1">
                    <h3>Filter Products</h3>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-4 py-lg-4">
                  <Row>
                    <Col className="col-lg-12 col-md-12 col-sm-12 mb-3" xs="12">
                      <label className="form-control-label">
                        Attacment Type
                      </label>
                      <Form role="form">
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          styles={styleInputSelect}
                          value={this.state.selectedOptionCategories}
                          placeholder={<div>Select</div>}
                          loadOptions={this.fetchDataCategories}
                          onChange={(e) => {
                            this.onSearchChangeCategories(e);
                          }}
                        />
                      </Form>
                    </Col>
                    <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Chasis Brand
                          {this.state.listChasis.length === 0 && (
                            <i className="fa fa-circle-o-notch fa-spin fa-fw ml-2" />
                          )}
                        </label>
                        <Input
                          id="example-date-input"
                          type="select"
                          onKeyPress={this.keyPressed}
                          name="chasis"
                          placeholder="Chasis"
                          value={this.state.actionListProduct.chasis}
                          onChange={this.handleChangeChasis}
                        >
                          <option value={""}>Select</option>
                          {this.state.listChasis.map((items, index) => {
                            return (
                              <option key={index} value={items.chasis}>
                                {items.chasis}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Axle Configuration
                          {this.state.listAxle.length === 0 && (
                            <i className="fa fa-circle-o-notch fa-spin fa-fw ml-2" />
                          )}
                        </label>
                        <Input
                          type="select"
                          onKeyPress={this.keyPressed}
                          name="axle"
                          placeholder="Axle"
                          value={this.state.actionListProduct.axle}
                          onChange={this.handleChangeAxle}
                        >
                          <option value={""}>Select</option>
                          {this.state.listAxle.map((items, index) => {
                            return (
                              <option key={index} value={items.axle}>
                                {items.axle}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Capacity
                          {this.state.listCapacity.length === 0 && (
                            <i className="fa fa-circle-o-notch fa-spin fa-fw ml-2" />
                          )}
                        </label>
                        <Input
                          id="example-date-input"
                          type="select"
                          onKeyPress={this.keyPressed}
                          name="tank_capacity"
                          placeholder="Tank Capacity"
                          value={this.state.actionListProduct.tank_capacity}
                          onChange={this.handleChangeString}
                        >
                          <option value={""}>Select</option>
                          {this.state.listCapacity.map((items, index) => {
                            return (
                              <option key={index} value={items.value}>
                                {items.label}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col style={{ marginTop: 13 }}>
                      <Button
                        block
                        color="dark"
                        className="btn-icon text-uppercase"
                        type="button"
                        disabled={this.state.isResetLoading ? true : false}
                        onClick={() => this.handleReset()}
                      >
                        {this.state.isResetLoading ? (
                          <>
                            <span className="btn-inner--text">
                              <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                              Reset
                            </span>
                          </>
                        ) : (
                          <span className="btn-inner--text">Reset</span>
                        )}
                      </Button>
                    </Col>
                    <Col style={{ marginTop: 13 }}>
                      <div className="text-center">
                        <Button
                          block
                          color="primary"
                          className="btn-icon text-uppercase"
                          type="button"
                          disabled={this.state.isFilterLoading ? true : false}
                          onClick={() => this.handleFilter()}
                        >
                          {this.state.isFilterLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Filter
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Filter</span>
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataProductType: state.customer_product.dataProductType,
  };
};
export default connect(mapStateToProps)(CustomerStandardConfigurationProducts);
