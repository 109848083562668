/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input
} from "reactstrap";
import Title from "components/Breadcumbs/Title";
import { connect } from "react-redux";
import config from "services/config";
import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import CardBody from "reactstrap/lib/CardBody";
import { styleInputSelect } from "services/styles";
import axios from "axios";
import AsyncSelect from "react-select/async";
import {validateConfigureNow} from 'services/validate';
import SweetAlert from "react-bootstrap-sweetalert";
// import { initGA, trackingPageGA, eventGA } from '../../services/reactGA';
// import styled from 'styled-components';

class MainProducts extends React.Component {
  state = {
    actionListProduct: {
      page: 1,
      items_per_page: 1,
      order_by_field: "product_id",
      order_by_direction: "DESC",
      category_id: 0,
      product_no: "",
      product_type: "",
      // category_id: 0,
      chasis: "",
      axle: "",
      tank_capacity: "",
    },
    actionListCategories: {
      page: 1,
      items_per_page: 10,
      order_by_field: "category_id",
      order_by_direction: "DESC",
      category_name: "",
    },
    listChasis: [],
    listAxle: [],
    listCapacity: [],
    typeProduct: '',
    product: {},
    selectedOptionCategories: [],
    isLoading: false,
    paramError: {},
  };
  componentDidMount() {
    this.mounted = true;
    // initGA(); // Initialize google analytics
    // trackingPageGA('/'); // = ReactGA.pageview (window.location.pathname);
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  getListChasis = async () => {
    try {
      const response = await axios.post(config.API_URL + '/customer_list_products/customer_list_chasis',{ category_id: this.state.actionListProduct.category_id },{
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      this.setState({
        listChasis: response.data.data
      })
    } catch (err) {
      console.log(err)
    }
  }

  getListAxle = async () => {
    try {
      const response = await axios.post(config.API_URL + '/customer_list_products/customer_list_axle',{ category_id: this.state.actionListProduct.category_id, chasis: this.state.actionListProduct.chasis },{
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      this.setState({
        listAxle: response.data.data
      })
    } catch (err) {
      console.log(err)
    }
  }

  fetchDataCategories = (inputValue) => {
    let searchTerm = inputValue;
    if (!inputValue || inputValue === " ") {
      searchTerm = "";
    }
    this.setState({
      actionListCategories: {
        ...this.state.actionListCategories,
        category_name: searchTerm,
      },
    });
    const newRequest = axios.post(
      config.API_URL + "/categories/categories_customers",
      this.state.actionListCategories,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );

    if (newRequest) {
      return newRequest.then((response) => {
        const compare = response.data.data.filter((i) =>
          i.category_name.toLowerCase().includes(inputValue.toLowerCase())
        );
        return compare.map((data) => ({
          label: data.category_name,
          value: data.category_id,
        }));
      });
    }
  };

  onSearchChangeCategories = (selectedOptionCategories) => {
    this.setState({
      selectedOptionCategories: selectedOptionCategories,
      typeProduct: selectedOptionCategories.label,
      actionListProduct: {
        ...this.state.actionListProduct,
        category_id: selectedOptionCategories.value,
      },
    }, () => {
      this.getListChasis();
      this.getListAxle();
      if(this.state.typeProduct === 'Water Truck'){
        if(this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6'){
          this.setState({
            listCapacity: [
              {
                value: '20KL',
                label: '20KL'
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '8x4'){
          this.setState({
            listCapacity: [
              {
                value: '30KL',
                label: '30KL'
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '4x4'){
          this.setState({
            listCapacity: [
              {
                value: '',
                label: ''
              }
            ]
          })
        }
      }
      if(this.state.typeProduct === 'Fuel Truck'){
        if(this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6'){
          this.setState({
            listCapacity: [
              {
                value: '20KL',
                label: '20KL'
              },
              {
                value: '25KL',
                label: '25KL'
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '8x4'){
          this.setState({
            listCapacity: [
              {
                value: '32KL',
                label: '32KL'
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '4x4'){
          this.setState({
            listCapacity: [
              {
                value: '',
                label: ''
              }
            ]
          })
        }
      }
      if(this.state.typeProduct === 'Lube Service'){
        if(this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6'){
          this.setState({
            listCapacity: [
              {
                value: '5P2L',
                label: 'Standard SSB'
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '8x4'){
          this.setState({
            listCapacity: [
              {
                value: '',
                label: ''
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '4x4'){
          this.setState({
            listCapacity: [
              {
                value: '',
                label: ''
              }
            ]
          })
        }
      }

      if(this.state.typeProduct === 'Wash Down'){
        if(this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6'){
          this.setState({
            listCapacity: [
              {
                value: '20KL',
                label: '20KL'
              },
            ]
          })
        }
        if(this.state.actionListProduct.axle === '8x4'){
          this.setState({
            listCapacity: [
              {
                value: '30KL',
                label: '30KL'
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '4x4'){
          this.setState({
            listCapacity: [
              {
                value: '',
                label: ''
              }
            ]
          })
        }
      }

      if(this.state.typeProduct === 'Crane Truck'){
        if(this.state.actionListProduct.axle === '6x6'){
          this.setState({
            listCapacity: [
              {
                value: '13.7 MT',
                label: '13.7 MT'
              },
              {
                value: '21.9 MT',
                label: '21.9 MT'
              },
              {
                value: '29 MT',
                label: '29 MT'
              },
              {
                value: '35.1 MT',
                label: '35.1 MT'
              },
              {
                value: '46.9 MT',
                label: '46.9 MT'
              },
            ]
          })
        }
        if(this.state.actionListProduct.axle === '6x4'){
          this.setState({
            listCapacity: [
              {
                value: '13.7 MT',
                label: '13.7 MT'
              },
              {
                value: '21.9 MT',
                label: '21.9 MT'
              },
              {
                value: '29 MT',
                label: '29 MT'
              },
              {
                value: '35.1 MT',
                label: '35.1 MT'
              },
              {
                value: '46.9 MT',
                label: '46.9 MT'
              },
            ]
          })
        }
      }
     
      if(this.state.typeProduct === 'Fuel Lube Service'){
        if(this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6'){
          this.setState({
            listCapacity: [
              {
                value: '4KL+5P2L',
                label: 'Standard SSB'
              },
            ]
          })
        }
        if(this.state.actionListProduct.axle === '8x4'){
          this.setState({
            listCapacity: [
              {
                value: '',
                label: ''
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '4x4'){
          this.setState({
            listCapacity: [
              {
                value: '',
                label: ''
              }
            ]
          })
        }
      }

      if(this.state.typeProduct === 'Manhaul Bus'){
        if(this.state.actionListProduct.axle === '4x4'){
          this.setState({
            listCapacity: [
              {
                value: '33 Seat',
                label: '33 Seat'
              },
              {
                value: '35 Seat',
                label: '35 Seat'
              },
              {
                value: '40 Seat',
                label: '40 Seat'
              },
              {
                value: '51 Seat',
                label: '51 Seat'
              },
              {
                value: '55 Seat',
                label: '55 Seat'
              }
            ]
          })
        }
      }
    });
  };

  handleChangeString = (event) => {
    this.setState({
      actionListProduct: {
        ...this.state.actionListProduct,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeChasis = (event) => {
    this.setState({
      actionListProduct: {
        ...this.state.actionListProduct,
        [event.target.name]: event.target.value,
      },
    }, () => {
      this.getListAxle();
    });
  };

  handletoStandardConfiguration = () => {
    this.props.history.push(
      "/customers/customer_standard_configuration_products"
    );
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleReset = () => {
    this.setState(
      {
        actionListProductCatalogue: {
          page: 1,
          items_per_page: 9,
          order_by_field: "category_id",
          order_by_direction: "DESC",
          category_name: "",
        },
      },
      () => {
        this.getDataListProductCatalogue();
      }
    );
  };

  getDataListProducts = () => {
    this.setState({isLoading: true});
    const paramError = validateConfigureNow(this.state.actionListProduct);
    this.setState({paramError});
    if(Object.keys(paramError).length === 0){
      axios
        .post(
          config.API_URL +
          "/customer_list_products/customer_list_standard_products",
          this.state.actionListProduct,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              if(res.data.data.length > 0){
                this.setState(
                  {
                    product: res.data.data[0].product_id,
                  },
                  () => {
                    this.handleToConfigureNowPage(this.state.product)
                    this.setState({ isLoading: false });
                  }
                );
              } else {
                this.failedAlert("No products have been configured");
                this.setState({
                  isLoading: false,
                })
              }
            }
          },
          (err) => {
            this.failedAlert(err.response.data.message);
            this.setState({ isLoading: false });
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      })
    }
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleToConfigureNowPage = (data) => {
    this.props.history.push(
      `/customers/configure-now/${data}`
    );
  }

  handleChangeAxle = (event) => {
    this.setState({
      actionListProduct: {
        ...this.state.actionListProduct,
        [event.target.name]: event.target.value,
      },
    }, () => {
      if(this.state.typeProduct === 'Water Truck'){
        if(this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6'){
          this.setState({
            listCapacity: [
              {
                value: '20KL',
                label: '20KL'
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '8x4'){
          this.setState({
            listCapacity: [
              {
                value: '30KL',
                label: '30KL'
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '4x4'){
          this.setState({
            listCapacity: [
              {
                value: '',
                label: ''
              }
            ]
          })
        }
      }
      if(this.state.typeProduct === 'Fuel Truck'){
        if(this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6'){
          this.setState({
            listCapacity: [
              {
                value: '20KL',
                label: '20KL'
              },
              {
                value: '25KL',
                label: '25KL'
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '8x4'){
          this.setState({
            listCapacity: [
              {
                value: '32KL',
                label: '32KL'
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '4x4'){
          this.setState({
            listCapacity: [
              {
                value: '',
                label: ''
              }
            ]
          })
        }
      }
      if(this.state.typeProduct === 'Lube Service'){
        if(this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6'){
          this.setState({
            listCapacity: [
              {
                value: '5P2L',
                label: 'Standard SSB'
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '8x4'){
          this.setState({
            listCapacity: [
              {
                value: '',
                label: ''
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '4x4'){
          this.setState({
            listCapacity: [
              {
                value: '',
                label: ''
              }
            ]
          })
        }
      }

      if(this.state.typeProduct === 'Wash Down'){
        if(this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6'){
          this.setState({
            listCapacity: [
              {
                value: '20KL',
                label: '20KL'
              },
            ]
          })
        }
        if(this.state.actionListProduct.axle === '8x4'){
          this.setState({
            listCapacity: [
              {
                value: '30KL',
                label: '30KL'
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '4x4'){
          this.setState({
            listCapacity: [
              {
                value: '',
                label: ''
              }
            ]
          })
        }
      }

      if(this.state.typeProduct === 'Crane Truck'){
        if(this.state.actionListProduct.axle === '6x6'){
          this.setState({
            listCapacity: [
              {
                value: '13.7 MT',
                label: '13.7 MT'
              },
              {
                value: '21.9 MT',
                label: '21.9 MT'
              },
              {
                value: '29 MT',
                label: '29 MT'
              },
              {
                value: '35.1 MT',
                label: '35.1 MT'
              },
              {
                value: '46.9 MT',
                label: '46.9 MT'
              },
            ]
          })
        }
        if(this.state.actionListProduct.axle === '6x4'){
          this.setState({
            listCapacity: [
              {
                value: '21.9 MT',
                label: '21.9 MT'
              },
              {
                value: '13.7 MT',
                label: '13.7 MT'
              },
              {
                value: '29 MT',
                label: '29 MT'
              },
              {
                value: '35.1 MT',
                label: '35.1 MT'
              },
              {
                value: '46.9 MT',
                label: '46.9 MT'
              },
            ]
          })
        }
      }
     
      if(this.state.typeProduct === 'Fuel Lube Service'){
        if(this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6'){
          this.setState({
            listCapacity: [
              {
                value: '4KL+5P2L',
                label: 'Standard SSB'
              },
            ]
          })
        }
        if(this.state.actionListProduct.axle === '8x4'){
          this.setState({
            listCapacity: [
              {
                value: '',
                label: ''
              }
            ]
          })
        }
        if(this.state.actionListProduct.axle === '4x4'){
          this.setState({
            listCapacity: [
              {
                value: '',
                label: ''
              }
            ]
          })
        }
      }

      if(this.state.typeProduct === 'Manhaul Bus'){
        if(this.state.actionListProduct.axle === '4x4'){
          this.setState({
            listCapacity: [
              {
                value: '33 Seat',
                label: '33 Seat'
              },
              {
                value: '35 Seat',
                label: '35 Seat'
              },
              {
                value: '40 Seat',
                label: '40 Seat'
              },
              {
                value: '51 Seat',
                label: '51 Seat'
              },
              {
                value: '55 Seat',
                label: '55 Seat'
              }
            ]
          })
        }
      }

    });
  }
  render() {
    return (
      <>
        <Container className="bg-secondary mt--6">
          <Row className="align-items-center">
            <Col lg="8" md="12" xs="12">
              <div className="my-auto mx-auto">
          <Title name='MICROSITE SALES' />
                <h2 className="text-dark font-weight-bold">
                  Hi, {config.USERNAME} ({config.COMPANY_NAME})
                </h2>
                <h2 className="text-dark font-weight-light">
                  Welcome to SSB Microsite Sales. It's great to have you!
                  Thank you for entrusting us.
                </h2>
                <br/>
                <h2 className="text-dark font-weight-light">
                  On this page you can configure your own specification or
                  use our standard products. This provides you with a range of
                  options to suite your needs.
                </h2>
              </div>
            </Col>
            {" "}
            <Col lg="4" md="12" xs="12" className="align-items-right">
              <Card className="bg-secondary border-0 mt-8"
              style={{ position: "sticky", top: 100 }}
              >
                <CardHeader className="pb-5t">
                  <div className="text-muted text-center mt-2 mb-1">
                    <h3>Select Our Product</h3>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-4 py-lg-4">
                  <Row>
                    <Col className="col-lg-12 col-md-12 col-sm-12 mb-3" xs="12">
                      <label className="form-control-label">Attachment Type</label>
                      <Form role="form">
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          styles={styleInputSelect}
                          value={this.state.selectedOptionCategories}
                          placeholder={<div>Select</div>}
                          loadOptions={this.fetchDataCategories}
                          onChange={(e) => {
                            this.onSearchChangeCategories(e);
                          }}
                        />
                      </Form>
                      {this.state.paramError.category_id&&
                            <small className="text-warning">{this.state.paramError.category_id}</small>
                        }
                    </Col>
                    <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Chasis Brand
                          {
                            this.state.listChasis.length === 0 &&  <i className="fa fa-circle-o-notch fa-spin fa-fw ml-2" />
                          }
                        </label>
                        <Input
                          id="example-date-input"
                          type="select"
                          onKeyPress={this.keyPressed}
                          name="chasis"
                          placeholder="Chasis"
                          value={this.state.actionListProduct.chasis}
                          onChange={this.handleChangeChasis}
                          className={this.state.paramError.chasis?"is-invalid":""}
                          disabled={this.state.listChasis.length === 0 ? true:false}
                        >
                          <option value={""}>Select</option>
                          {this.state.listChasis.map((items, index) => {
                            return (
                              <option key={index} value={items.chasis}>{items.chasis}</option>
                            )
                          })
                          }
                        </Input>
                        <div className="invalid-feedback">
                              {this.state.paramError.chasis}
                            </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Axle Configuration
                          {
                            this.state.listAxle.length === 0 &&  <i className="fa fa-circle-o-notch fa-spin fa-fw ml-2" />
                          }
                        </label>
                        <Input
                          type="select"
                          onKeyPress={this.keyPressed}
                          name="axle"
                          placeholder="Axle"
                          value={this.state.actionListProduct.axle}
                          onChange={this.handleChangeAxle}
                          className={this.state.paramError.axle?"is-invalid":""}
                          disabled={this.state.listAxle.length === 0 ? true:false}
                        >
                          <option value={""}>Select</option>
                          {this.state.listAxle.map((items, index) => {
                            return (
                              <option key={index} value={items.axle}>{items.axle}</option>
                            )
                          })
                          }
                        </Input>
                        <div className="invalid-feedback">
                              {this.state.paramError.axle}
                            </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Capacity
                          {
                            this.state.listCapacity.length === 0 &&  <i className="fa fa-circle-o-notch fa-spin fa-fw ml-2" />
                          }
                        </label>
                        <Input
                          id="example-date-input"
                          type="select"
                          onKeyPress={this.keyPressed}
                          name="tank_capacity"
                          placeholder="Tank Capacity"
                          value={this.state.actionListProduct.tank_capacity}
                          onChange={this.handleChangeString}
                          className={this.state.paramError.tank_capacity?"is-invalid":""}
                          disabled={this.state.listCapacity.length === 0 ? true:false}
                        >
                          <option value={""}>Select</option>
                          {this.state.listCapacity.map((items, index) => {
                            return (  
                              <option key={index} value={items.value}>{items.label}</option>
                            )
                          })
                          }
                        </Input>
                        <div className="invalid-feedback">
                              {this.state.paramError.tank_capacity}
                            </div>
                      </FormGroup>
                    </Col>
                    <Col
                      style={{ marginTop: 15 }}
                      className="col-12 col-md-12 col-sm-12"
                      xs="12"
                    >
                      <div className="text-center">
                        <Button
                          block
                          color="primary"
                          className="btn-icon text-uppercase"
                          type="button"
                          disabled={this.state.isLoading ? true : false}
                          onClick={() => this.getDataListProducts()}
                        >
                          {this.state.isLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Configure Now
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Configure Now</span>
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              <Row className="mt-2 mb-5 text-center">
                <Col>
                  <span className="text-muted">
                    Or you can{" "}
                    <a
                  style={{ cursor: "pointer", objectFit: 'cover'}}
                      onClick={() => this.handletoStandardConfiguration()}
                    >
                      <b><u>Browse All Products</u></b>
                              </a>
                  </span>
                </Col>
              </Row>
              </Card>
            </Col>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

export default connect()(MainProducts);
