import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";

import routes from "routes.js";
import CustomerLoyaltiFooter from "components/Footers/CustomerLoyaltiFooter";
import CustomerLoyaltiNavbar from "components/Navbars/CustomerLoyaltiNavbar";
// import ReactGA from "react-ga";

class CustomersLoyalti extends React.Component {
  componentDidMount() {
    if (localStorage.getItem("refresh_admin") === "true") {
      window.location.reload(false);
      localStorage.setItem("refresh_admin", "false");
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
    document.body.classList.add("bg-white");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-white");
  }
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/customerloyalti") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <div className="main-content bg-secondary" ref="mainContent">
          <CustomerLoyaltiNavbar />
          <Switch>
            <div className="bg-secondary" style={{paddingTop: '100px'}}>
              {this.getRoutes(routes)}
            </div>
              <Redirect from="*" to="/customerloyalti/customer-loyalti-dashboard" />
          </Switch>
          <CustomerLoyaltiFooter />
        </div>
      </>
    );
  }
}

export default CustomersLoyalti;
