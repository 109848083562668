import React, { PureComponent } from "react";
import Breadcumbs from "components/Breadcumbs/Breadcumbs";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  UncontrolledCarousel,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
  FormGroup,
  Input,
  CardHeader,
  Table,
} from "reactstrap";
import { connect } from "react-redux";
import { currencyIDR } from "services/currency";
import config from "services/config";
import axios from "axios";
import { countries } from "services/countries";
import { validateQuotations } from "services/validate";
import SweetAlert from "react-bootstrap-sweetalert";
class CustomerQuotationDetails extends PureComponent {
  state = {
    listStandardComponents: [],
    listOptionalComponents: [],
    listOptionalComponentsAdded: [],
    listSpecifications: [],
    countPriceComponents: 0,
    grandTotal: 0,
    data: {
      purchase_timeframe: "",
      country: "",
      area: "",
      notes: "",
      shipping_method: "",
      payment_terms: "",
      freight_terms: "",
      quantity: 1,
      total_product_price: 0,
    },
    isLoading: false,
    isCountryArea: false,
    alert: null,
    paramError: {},
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.additionalComponents = [];
    this.checkAdditionalComponents = [];
    this.PriceComponents = [
        parseInt(this.props.dataCartProductDetails.product_price),
    ];
    this.setState({
      listStandardComponents: this.props.dataCartProductDetails
        .quotation_standard_components,
      listOptionalComponentsAdded: this.props.dataCartProductDetails
        .quotation_optional_components,
      listSpecifications: this.props.dataCartProductDetails
        .quotation_specifications,
      countPriceComponents: parseInt(
        this.props.dataCartProductDetails.product_price
      ),
      grandTotal: this.props.dataCartProductDetails.grand_total,
      data: {
        purchase_timeframe: this.props.dataCartProductDetails
          .purchase_timeframe,
        country: this.props.dataCartProductDetails.country,
        area: this.props.dataCartProductDetails.area,
        notes: this.props.dataCartProductDetails.notes,
        shipping_method: this.props.dataCartProductDetails.shipping_method,
        payment_terms: this.props.dataCartProductDetails.payment_terms,
        freight_terms: this.props.dataCartProductDetails.freight_terms,
        quantity: this.props.dataCartProductDetails.quantity,
        total_product_price: this.props.dataCartProductDetails
          .total_product_price,
      },
    });
    this.getListOptionalComponents();
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChangeShippingMethod = (event) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        if (this.state.data.shipping_method === "Delivery") {
          this.setState({
            isCountryArea: true,
          });
        }
        if (this.state.data.shipping_method === "Ex-work") {
          this.setState({
            isCountryArea: false,
            data: {
              ...this.state.data,
              country: "",
              area: "",
            },
          });
        }
      }
    );
  };

  getListOptionalComponents = () => {
    axios
      .post(
        config.API_URL + "/optional_components/optional_components_customers",
        { category_id: this.props.dataCartProductDetails.category_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listOptionalComponents: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  handleChangeQuantity = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: parseInt(event.target.value),
      },
      grandTotal:
        parseInt(event.target.value) * this.state.data.total_product_price,
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  handleAdditionalComponents = (e, data) => {
    e.preventDefault();
    if (
      this.checkAdditionalComponents.indexOf(data.optional_component_name)===-1
    ) {
      this.checkAdditionalComponents.push(data.optional_component_name);
      this.additionalComponents.push(data);
      this.setState({
        listOptionalComponentsAdded: this.additionalComponents,
      });
      this.PriceComponents.push(parseInt(data.optional_component_price));
      let countPrice = 0;
      for (var i = 0; i < this.PriceComponents.length; i++) {
        countPrice += this.PriceComponents[i];
      }
      this.setState(
        {
          countPriceComponents: countPrice,
        },
        () => {
          var tax = this.props.dataCartProductDetails.tax;
          var total_tax =
            (parseInt(this.state.countPriceComponents) * tax) / 100;
          var discount = this.props.dataCartProductDetails.discount;
          var total_discount =
            (parseInt(this.state.countPriceComponents) * discount) / 100;
          this.setState(
            {
              data: {
                ...this.state.data,
                total_product_price:
                  parseInt(this.state.countPriceComponents) +
                  total_tax -
                  total_discount,
              },
            },
            () => {
              this.setState({
                grandTotal:
                  parseInt(this.state.data.quantity) *
                  this.state.data.total_product_price,
              });
            }
          );
        }
      );
    }
  };
  handleDeleteAdditionalComponents = (data) => {
    this.additionalComponents.splice(data, 1);
    this.checkAdditionalComponents.splice(data, 1);
    this.setState({
      listOptionalComponentsAdded: this.additionalComponents,
    });
    this.PriceComponents.splice(data + 1, 1);
    let countPrice = 0;
    for (var i = 0; i < this.PriceComponents.length; i++) {
      countPrice += this.PriceComponents[i];
    }
    this.setState(
      {
        countPriceComponents: countPrice,
      },
      () => {
        var tax = this.props.dataCartProductDetails.tax;
        var total_tax = (parseInt(this.state.countPriceComponents) * tax) / 100;
        var discount = this.props.dataCartProductDetails.discount;
        var total_discount =
          (parseInt(this.state.countPriceComponents) * discount) / 100;
        this.setState(
          {
            data: {
              ...this.state.data,
              total_product_price:
                parseInt(this.state.countPriceComponents) +
                total_tax -
                total_discount,
            },
          },
          () => {
            this.setState({
              grandTotal:
                parseInt(this.state.data.quantity) *
                this.state.data.total_product_price,
            });
          }
        );
      }
    );
  };
  handleAddToCart = () => {
    const paramError = validateQuotations(this.state.data);
    this.setState({ paramError });
    if (Object.keys(paramError).length === 0) {
      var data_ = {
        ...this.state.data,
        category_id: this.props.dataCartProductDetails.category_id,
        product_id: this.props.dataCartProductDetails.product_id,
        company_id: config.COMPANY_ID,
        user_id: config.USER_ID,
        product_no: this.props.dataCartProductDetails.product_no,
        product_description: this.props.dataCartProductDetails
          .product_description,
        chasis: this.props.dataCartProductDetails.chasis,
        axle: this.props.dataCartProductDetails.axle,
        wheel_base: this.props.dataCartProductDetails.wheel_base,
        tank_capacity: this.props.dataCartProductDetails.tank_capacity,
        roh: this.props.dataCartProductDetails.roh,
        product_price: this.state.countPriceComponents,
        grand_total: this.state.grandTotal,
        tax: this.props.dataCartProductDetails.tax,
        lead_time: this.props.dataCartProductDetails.lead_time,
        discount: this.props.dataCartProductDetails.discount,
        image_1: this.props.dataCartProductDetails.image_1,
        image_2: this.props.dataCartProductDetails.image_2,
        image_3: this.props.dataCartProductDetails.image_3,
        image_4: this.props.dataCartProductDetails.image_4,
        file_specifications: this.props.dataCartProductDetails
          .file_specifications,
        quotation_standard_components: [...this.state.listStandardComponents],
        quotation_optional_components: [
          ...this.state.listOptionalComponentsAdded,
        ],
        quotation_specifications: [...this.state.listSpecifications],
      };
      var data = [];
      data = JSON.parse(localStorage.getItem("cart")) || [];
      data[this.props.indexCart] = data_;
      localStorage.setItem("cart", JSON.stringify(data));
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  render() {
    const items = [
      {
        src:
          config.BUCKET_URL +
          this.props.dataCartProductDetails.image_1,
        altText: "",
        caption: "",
        header: "",
      },
      {
        src:
          config.BUCKET_URL +
          this.props.dataCartProductDetails.image_2,
        altText: "",
        caption: "",
        header: "",
      },
      {
        src:
          config.BUCKET_URL +
          this.props.dataCartProductDetails.image_3,
        altText: "",
        caption: "",
        header: "",
      },
      {
        src:
          config.BUCKET_URL +
          this.props.dataCartProductDetails.image_4,
        altText: "",
        caption: "",
        header: "",
      },
    ];
    return (
      <>
        <Container className="bg-secondary mt--6">
          <Breadcumbs
            name={
              this.props.dataCartProductDetails.chasis +
              " " +
              this.props.dataCartProductDetails.tank_capacity +
              " " +
              this.props.dataCartProductDetails.axle
            }
            parentName="Cart Details"
            link="#"
          />
          <Row className="justify-content-center">
            <Col>
              <Card className="bg-dark text-white border-0">
                <UncontrolledCarousel items={items} />
              </Card>
            </Col>
            <Col className="text-left" lg="4">
              <h2 className="display-3">
                {this.props.dataCartProductDetails.chasis}{" "}
                {this.props.dataCartProductDetails.tank_capacity}{" "}
                {this.props.dataCartProductDetails.axle}
              </h2>
              <h4 className="display-4">
                {currencyIDR(
                  this.state.grandTotal
                ).replace(/\.00$/, "")}
              </h4>
              <p className="lead">
                {this.props.dataCartProductDetails.product_description}
              </p>
              <table>
                <tr>
                  {/* <td>
                    <small>ROH</small>
                  </td>
                  <td className="px-4">
                    <small>Wheel Base</small>
                  </td> */}
                  <td>
                    <small>Lead Time</small>
                  </td>
                </tr>
                <tr>
                  {/* <td>
                    <h3 className="mb-0">
                      {this.props.dataCartProductDetails.roh}
                    </h3>
                  </td>
                  <td className="px-4">
                    {" "}
                    <h3 className="mb-0">
                      {this.props.dataCartProductDetails.wheel_base}
                    </h3>
                  </td> */}
                  <td>
                    {" "}
                    <h3 className="mb-0">
                      {this.props.dataCartProductDetails.lead_time}
                    </h3>
                  </td>
                </tr>
              </table>
              <Row className="mt-4 mb-4">
                <Col>
                  <Button
                    className="btn-second text-uppercase"
                    type="button"
                    onClick={() =>
                      window.open(
                        config.BUCKET_URL+
                          this.props.dataCartProductDetails.file_specifications,
                        "_blank"
                      )
                    }
                  >
                    <span className="btn-inner--text ml-2">Brochure</span>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {this.state.listStandardComponents.length > 0 ||
          this.state.listOptionalComponents.length > 0 ? (
            <h1 className="display-4">Components</h1>
          ) : null}
          <Row>
            <Col>
              {this.state.listStandardComponents.length > 0 ||
              this.state.listOptionalComponents.length > 0 ? (
                <Row>
                  <Col className="col-lg-6 col-md-6 col-sm-12" xs="6">
                    <Card>
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <div className="col">
                            <h3 className="mb-0">Standard Components</h3>
                          </div>
                        </Row>
                      </CardHeader>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.listStandardComponents.map(
                            (element, index) => {
                              return (
                                <tr key={index}>
                                  <th scope="row">
                                    {element.standard_component_name}
                                  </th>
                                  <td>
                                    {element.standard_component_description}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </Card>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-sm-12" xs="6">
                    <Card>
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <div className="col">
                            <h3 className="mb-0">Optional Components</h3>
                          </div>
                          <div className="col text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                caret
                                className="btn-second text-uppercase"
                                size="sm"
                              >
                                Add Components
                              </DropdownToggle>
                              <DropdownMenu>
                                {this.state.listOptionalComponents.length ===
                                0 ? (
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <div className="timeline-block">
                                      <div className="d-flex justify-content-between pt-1">
                                        <div>
                                          <span className="text-sm font-weight-bold">
                                            no components can be added
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </DropdownItem>
                                ) : (
                                  <>
                                    {this.state.listOptionalComponents.map(
                                      (element, index) => {
                                        return (
                                          <DropdownItem
                                            href="#pablo"
                                            onClick={(e) =>
                                              this.handleAdditionalComponents(
                                                e,
                                                element
                                              )
                                            }
                                            key={index}
                                          >
                                            <div className="timeline-block">
                                              <div className="d-flex justify-content-between pt-1">
                                                <div>
                                                  <span className="text-sm font-weight-bold">
                                                    {
                                                      element.optional_component_name
                                                    }
                                                  </span>
                                                </div>
                                                <div className="text-right ml-6">
                                                  <span className="text-sm">
                                                    {currencyIDR(
                                                      element.optional_component_price
                                                    ).replace(/\.00$/, "")}
                                                  </span>
                                                </div>
                                              </div>
                                              <p className="text-sm mt-1 mb-0">
                                                {
                                                  element.optional_component_description
                                                }
                                              </p>
                                            </div>
                                          </DropdownItem>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </Row>
                      </CardHeader>
                      {this.state.listOptionalComponentsAdded.length===0 ? (
                        <Alert color="secondary">
                          <strong>Components not found</strong>!
                        </Alert>
                      ) : (
                        <>
                          <Table
                            className="align-items-center table-flush"
                            responsive
                          >
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                                <th scope="col">Price</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.listOptionalComponentsAdded.map(
                                (element, index) => {
                                  return (
                                    <tr key={index}>
                                      <th scope="row">
                                        {element.optional_component_name}
                                      </th>
                                      <td>
                                        {element.optional_component_description}
                                      </td>
                                      <td>
                                        {currencyIDR(
                                          element.optional_component_price
                                        ).replace(/\.00$/, "")}
                                      </td>
                                      <td>
                                        <a
                                          className="table-action table-action-delete"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.handleDeleteAdditionalComponents(
                                              index
                                            )
                                          }
                                        >
                                          <i className="fas fa-trash" />
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </>
                      )}
                    </Card>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
          <h1 className="display-4">Specs</h1>
          <Row>
            <Col className="col-lg-6 col-md-6 col-sm-12" xs="6">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        {" "}
                        {this.props.dataCartProductDetails.chasis}{" "}
                        {this.props.dataCartProductDetails.tank_capacity}{" "}
                        {this.props.dataCartProductDetails.axle} Specs
                      </h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.listSpecifications.map((element, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{element.specification_name}</th>
                          <td>{element.specification_description}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
          {/* <h1 className="display-4">Request a Quote</h1>
          <h6 className="heading-small text-muted">Amount Information</h6>
          <Row>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Price</span>
                <div className="h1">
                  {currencyIDR(this.state.countPriceComponents).replace(
                    /\.00$/,
                    ""
                  )}
                </div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Tax</span>
                <div className="h1">
                  {this.props.dataCartProductDetails.tax + " %"}
                </div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Discount</span>
                <div className="h1">
                  {this.props.dataCartProductDetails.discount + " %"}
                </div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Total Price</span>
                <div className="h1">
                  {currencyIDR(this.state.data.total_product_price).replace(
                    /\.00$/,
                    ""
                  )}
                </div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Quantity</span>
                <div className="h1">{this.state.data.quantity}</div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Grand Total</span>
                <div className="h1">
                  {currencyIDR(this.state.grandTotal).replace(/\.00$/, "")}
                </div>
              </div>
            </Col>
          </Row>
          <h6 className="heading-small text-muted">Shipping Information</h6>*/}
          <Row>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <FormGroup>
                <label className="form-control-label">Quantity</label>
                <Input
                  id="example-date-input"
                  type="number"
                  name="quantity"
                  onKeyPress={this.keyPressed}
                  value={this.state.data.quantity}
                  onChange={this.handleChangeQuantity}
                  placeholder="Quantity"
                  required
                  className={this.state.paramError.quantity ? "is-invalid" : ""}
                />
                <div className="invalid-feedback">
                  {this.state.paramError.quantity}
                </div>
              </FormGroup>
            </Col>
            {/* <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <FormGroup>
                <label className="form-control-label">Shipping Method</label>
                <Input
                  id="example-date-input"
                  type="select"
                  name="shipping_method"
                  onKeyPress={this.keyPressed}
                  value={this.state.data.shipping_method}
                  onChange={this.handleChangeShippingMethod}
                  placeholder="Shipping Method"
                  required
                  className={
                    this.state.paramError.shipping_method ? "is-invalid" : ""
                  }
                >
                  <option value="">Select</option>
                  <option value="Delivery">Delivery</option>
                  <option value="Ex-work">Ex-work</option>
                </Input>
                <div className="invalid-feedback">
                  {this.state.paramError.shipping_method}
                </div>
              </FormGroup>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <FormGroup>
                <label className="form-control-label">Payment Terms</label>
                <Input
                  id="example-date-input"
                  type="select"
                  name="payment_terms"
                  onKeyPress={this.keyPressed}
                  value={this.state.data.payment_terms}
                  onChange={this.handleChangeString}
                  placeholder="Payment Terms"
                  required
                  className={
                    this.state.paramError.payment_terms ? "is-invalid" : ""
                  }
                >
                  <option value="">Select</option>
                  <option value="Net 30">Net 30</option>
                  <option value="Net 40">Net 40</option>
                  <option value="Net 45">Net 45</option>
                  <option value="Net 60">Net 60</option>
                  <option value="10% DP - 90% Before Delivery">
                    10% DP - 90% Before Delivery
                  </option>
                  <option value="20% DP - 80% Before Delivery">
                    20% DP - 80% Before Delivery
                  </option>
                  <option value="30% DP - 70% Before Delivery">
                    30% DP - 70% Before Delivery
                  </option>
                </Input>
                <div className="invalid-feedback">
                  {this.state.paramError.payment_terms}
                </div>
              </FormGroup>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <FormGroup>
                <label className="form-control-label">Purchase Timeframe</label>
                <Input
                  id="example-date-input"
                  type="select"
                  name="purchase_timeframe"
                  placeholder="Timeframe"
                  onKeyPress={this.keyPressed}
                  value={this.state.data.purchase_timeframe}
                  onChange={this.handleChangeString}
                  required
                  className={
                    this.state.paramError.purchase_timeframe ? "is-invalid" : ""
                  }
                >
                  <option value="">Select</option>
                  <option value="Immediate">Immediate</option>
                  <option value="This Quarter">This Quarter</option>
                  <option value="Next Quarter">Next Quarter</option>
                  <option value="This Year">This Year</option>
                  <option value="Unknown">Unknown</option>
                </Input>
                <div className="invalid-feedback">
                  {this.state.paramError.purchase_timeframe}
                </div>
              </FormGroup>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <FormGroup>
                <label className="form-control-label">Freight Terms</label>
                <Input
                  id="example-date-input"
                  type="select"
                  name="freight_terms"
                  onKeyPress={this.keyPressed}
                  value={this.state.data.freight_terms}
                  onChange={this.handleChangeString}
                  placeholder="Freight Terms"
                  required
                  className={
                    this.state.paramError.freight_terms ? "is-invalid" : ""
                  }
                >
                  <option value="">Select</option>
                  <option value="CFR">CFR</option>
                  <option value="CIF">CIF</option>
                  <option value="CIP">CIP</option>
                  <option value="CPT">CPT</option>
                  <option value="FOB">FOB</option>
                  <option value="DAP">DAP</option>
                  <option value="DAT">DAT</option>
                  <option value="DDP">DDP</option>
                  <option value="DPP">DPP</option>
                  <option value="EXW">EXW</option>
                  <option value="FAS">FAS</option>
                  <option value="FCA">FCA</option>
                </Input>
                <div className="invalid-feedback">
                  {this.state.paramError.freight_terms}
                </div>
              </FormGroup>
            </Col>
            {this.state.isCountryArea ? (
              <>
                <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Country</label>
                    <Input
                      id="example-date-input"
                      type="select"
                      name="country"
                      onKeyPress={this.keyPressed}
                      value={this.state.data.country}
                      onChange={this.handleChangeString}
                      placeholder="Country"
                      required
                      className={
                        this.state.paramError.country ? "is-invalid" : ""
                      }
                    >
                      <option value="">Select</option>
                      {countries.map((element) => {
                        return (
                          <option value={element.name}>{element.name}</option>
                        );
                      })}
                    </Input>
                    <div className="invalid-feedback">
                      {this.state.paramError.country}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                  <FormGroup>
                    <label className="form-control-label">City or Area</label>
                    <Input
                      id="example-date-input"
                      type="text"
                      name="area"
                      onKeyPress={this.keyPressed}
                      value={this.state.data.area}
                      onChange={this.handleChangeString}
                      placeholder="Demand City or Area"
                      required
                      className={this.state.paramError.area ? "is-invalid" : ""}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.area}
                    </div>
                  </FormGroup>
                </Col>
              </>
            ) : null} */}
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label">Notes</label>
                <Input
                  id="example-date-input"
                  type="textarea"
                  name="notes"
                  placeholder="Notes"
                  onKeyPress={this.keyPressed}
                  value={this.state.data.notes}
                  onChange={this.handleChangeString}
                />
              </FormGroup>
            </Col>
          </Row> 
          <Row className="justify-content-md-center mb-3">
            <Col>
              <Button
                className="btn-second text-uppercase"
                type="button"
                onClick={() => this.handleAddToCart()}
              >
                <span id="request_quote" className="btn-inner--text">
                  Save
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataCartProductDetails: state.customer_product.dataCartProductDetails,
    indexCart: state.customer_product.indexCart,
  };
};

export default connect(mapStateToProps)(CustomerQuotationDetails);
