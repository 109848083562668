import React, { PureComponent } from "react";
import Breadcumbs from "components/Breadcumbs/Breadcumbs";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  UncontrolledCarousel,
  FormGroup,
  Input,
  CardHeader,
  Table,
  Alert,
  Badge,
} from "reactstrap";
import { connect } from "react-redux";
import { currencyIDR } from "services/currency";
import config from "services/config";
import axios from "axios";
// import { countries } from "services/countries";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { MyDocument } from "services/PDF/quotation/document";

class CustomerQuotationDetails extends PureComponent {
  state = {
    listQuotationOptionalComponents: [],
    listQuotationStandardComponents: [],
    listQuotationSpecifications: [],
    dataCompany: [],
    dataPreSpec: [],
    items: [],
    ready: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getListStandardComponents();
    this.getListOptionalComponents();
    this.getListSpecifications();
    this.getViewCompany();
    this.getDataPreSpec();
    console.log(this.props.match.params.quoteId);
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  toggle() {
    this.setState(
      (prevState) => ({
        ready: false,
      }),
      () => {
        // THIS IS THE HACK
        setTimeout(() => {
          this.setState({ ready: true });
        }, 1);
      }
    );

    // onClick={() => this.setState({ ready: false })}
  }

  getDataPreSpec = async () => {
    try {
      const response = await axios.post(
        config.API_URL + "/quotations/view_quotation_customers",
        { quote_id: this.props.match.params.quoteId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState(
        {
          dataPreSpec: response.data.data[0],
        },
        () => {
          this.setState({
            items: [
              {
                src: config.BUCKET_URL + this.state.dataPreSpec.image_1,
                altText: "",
                caption: "",
                header: "",
              },
              {
                src: config.BUCKET_URL + this.state.dataPreSpec.image_2,
                altText: "",
                caption: "",
                header: "",
              },
              {
                src: config.BUCKET_URL + this.state.dataPreSpec.image_3,
                altText: "",
                caption: "",
                header: "",
              },
              {
                src: config.BUCKET_URL + this.state.dataPreSpec.image_4,
                altText: "",
                caption: "",
                header: "",
              },
            ],
          });
        }
      );
    } catch (err) {}
  };

  getViewCompany = () => {
    axios
      .post(
        config.API_URL + "/companies/view_company_customers",
        { company_id: this.props.match.params.companyId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              dataCompany: res.data.data[0],
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  getListSpecifications = () => {
    axios
      .post(
        config.API_URL + "/quotations/list_quotation_specifications_customers",
        { quote_id: this.props.match.params.quoteId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listQuotationSpecifications: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListStandardComponents = () => {
    axios
      .post(
        config.API_URL +
          "/quotations/list_quotation_standard_components_customers",
        { quote_id: this.props.match.params.quoteId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listQuotationStandardComponents: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListOptionalComponents = () => {
    axios
      .post(
        config.API_URL +
          "/quotations/list_quotation_optional_components_customers",
        { quote_id: this.props.match.params.quoteId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listQuotationOptionalComponents: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  goBack = (e) => {
    // e.preventDefault();
    window.history.back();
  };
  // handleStatus = (data) => {
  //   if (data === "Pending") {
  //     return "bg-warning";
  //   }
  //   if (data === "In Review") {
  //     return "bg-warning";
  //   }
  //   if (data === "Approved") {
  //     return "bg-success";
  //   }
  //   if (data === "Processed") {
  //     return "bg-success";
  //   }
  //   if (data === "Rejected") {
  //     return "bg-danger";
  //   }
  // };
  render() {
    return (
      <>
        <Container className="bg-secondary mt--6" id="pdfdownload">
          <Breadcumbs
            name="Config Details"
            parentName="List ID"
            link="/customers/prespec"
          />
          <Row className="justify-content-center">
            <Col>
              <Card className="bg-dark text-white border-0">
                <UncontrolledCarousel items={this.state.items} />
              </Card>
              <h3>Attention: {this.state.dataPreSpec.username}</h3>
            </Col>
            <Col className="text-left" lg="4">
              <h2 className="display-3">
                {this.state.dataPreSpec.category_name}{" "}
                {this.state.dataPreSpec.chasis}{" "}
                {this.state.dataPreSpec.tank_capacity}{" "}
                {this.state.dataPreSpec.axle}
              </h2>

              {/* <Badge color="" className="badge-dot mr-4">
                <h2>
                  <i
                    className={this.handleStatus(this.state.dataPreSpec.status)}
                  />
                  <b>{this.state.dataPreSpec.status}</b>
                </h2>
              </Badge> */}
              <h4 className="display-4 d-flex">
                {currencyIDR(this.state.dataPreSpec.grand_total).replace(
                  /\.00$/,
                  ""
                )}
                <span className="text-red pe-auto">*</span>
              </h4>
              <small>{this.state.dataPreSpec.product_description}</small>
              <table>
                <tr>
                  {/* <td>
                    <small>ROH</small>
                  </td>
                  <td className="px-4">
                    <small>Wheel Base</small>
                  </td> */}
                  <td>
                    <small>Lead Time</small>
                  </td>
                </tr>
                <tr>
                  {/* <td>
                    <h3 className="mb-0">
                      {this.state.dataPreSpec.roh}
                    </h3>
                  </td>
                  <td className="px-4">
                    {" "}
                    <h3 className="mb-0">
                      {this.state.dataPreSpec.wheel_base}
                    </h3>
                  </td> */}
                  <td>
                    {" "}
                    <h3 className="mb-0 d-flex">
                      {this.state.dataPreSpec.lead_time}
                      <span className="text-red pe-auto">*</span>
                    </h3>
                  </td>
                </tr>
                <small className="font-italic">
                  Price(s) and leadtime(s) are given as indicative information,
                  subject to further confirmation by SSB Sales Department
                </small>
              </table>
              <Row className="mt-4 mb-4">
                <Col>
                  <div>
                    {this.state.ready && (
                      <PDFDownloadLink
                        document={
                          <MyDocument
                            // data={{...this.state.dataPreSpec, ...this.state.dataCompany, company: this.state.dataCompany,}}
                            data={{
                              ...this.state.dataPreSpec, 
                              ...this.state.dataCompany, 
                              company: this.state.dataCompany,
                            }}
                            optional={ 
                              this.state.listQuotationOptionalComponents 
                            }
                            specifications={ 
                              this.state.listQuotationSpecifications 
                            }
                          />
                        }
                        fileName={
                          this.state.dataPreSpec.category_name + "-" +this.state.dataPreSpec.chasis + "-" +this.state.dataPreSpec.tank_capacity + "-" +this.state.dataPreSpec.axle +
                          ".pdf"
                          // "Configuration Record-" +
                          // this.state.dataPreSpec.quotations_id +
                          // ".pdf"
                        }
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <>
                              <Button
                                className="text-uppercase"
                                color="primary"
                                size="sm"
                                onClick={() => this.toggle()}
                              >
                                <span className="btn-inner--text">
                                  <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                  Download your configuration
                                </span>
                              </Button>
                              {/* <Button
                                className="text-uppercase"
                                color="primary"
                                type="button"
                                onClick={() =>
                                  window.open(
                                    config.BUCKET_URL+
                                      this.state.dataPreSpec
                                        .file_specifications,
                                    "_blank"
                                  )
                                }
                              >
                                <span className="btn-inner--text ml-2">Brochure</span>
                              </Button> */}
                            </>
                          ) : (
                            <>
                              <Button
                                className="text-uppercase"
                                color="primary"
                                size="sm"
                                onClick={() => this.setState({ ready: false })}
                              >
                                Download your configuration
                              </Button>
                              {/* <Button
                                className="text-uppercase"
                                color="primary"
                                type="button"
                                onClick={() =>
                                  window.open(
                                    config.BUCKET_URL+
                                      this.state.dataPreSpec
                                        .file_specifications,
                                    "_blank"
                                  )
                                }
                              >
                                <span className="btn-inner--text ml-2">Brochure</span>
                              </Button> */}
                            </>
                          )
                        }
                      </PDFDownloadLink>
                    )}
                    {!this.state.ready && (
                      <>
                        <Button
                          className="text-uppercase"
                          color="primary"
                          size="sm"
                          onClick={() => this.toggle()}
                        >
                          Generate your configuration
                        </Button>
                        {/* <Button
                          className="text-uppercase"
                          color="primary"
                          type="button"
                          onClick={() =>
                            window.open(
                              config.BUCKET_URL+
                                this.state.dataPreSpec
                                  .file_specifications,
                              "_blank"
                            )
                          }
                        >
                          <span className="btn-inner--text ml-2">Brochure</span>
                        </Button> */}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="col-lg-6 col-md-12 col-sm-12 col-12">
              <h1 className="display-4">Specs</h1>
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        {" "}
                        {this.state.dataPreSpec.chasis}{" "}
                        {this.state.dataPreSpec.tank_capacity}{" "}
                        {this.state.dataPreSpec.axle} Specs
                      </h3>
                    </div>
                  </Row>
                </CardHeader>
                {this.state.listQuotationSpecifications.length === 0 ? (
                  <Alert color="secondary">
                    <strong>Components not found</strong>!
                  </Alert>
                ) : (
                  <>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listQuotationSpecifications.map(
                          (element, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">
                                  {element.quotation_specification_name}
                                </th>
                                <td className="text-wrap">
                                  {element.quotation_specification_description}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </>
                )}
              </Card>
            </Col>
            <Col className="col-lg-6 col-md-12 col-sm-12 col-12">
              <Row>
              {this.state.listQuotationStandardComponents.length > 0 ||
              this.state.listQuotationOptionalComponents.length > 0 ? (
<>
<Col className="col-lg-12 col-md-12 col-sm-12 col-12">
<h1 className="display-4">Components</h1>
                    <Card>
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <div className="col">
                            <h3 className="mb-0">Optional Components</h3>
                          </div>
                        </Row>
                      </CardHeader>
                      {this.state.listQuotationOptionalComponents.length ===
                      0 ? (
                        <Alert color="secondary">
                          <strong>Components not found</strong>!
                        </Alert>
                      ) : (
                        <>
                          <Table
                            className="align-items-center table-flush"
                            responsive
                          >
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                                <th scope="col">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.listQuotationOptionalComponents.map(
                                (element, index) => {
                                  return (
                                    <tr key={index}>
                                      <th scope="row">
                                        {
                                          element.quotation_optional_component_name
                                        }
                                      </th>
                                      <td>
                                        {
                                          element.quotation_optional_component_description
                                        }
                                      </td>
                                      <td>
                                        {currencyIDR(
                                          element.quotation_optional_component_price
                                        ).replace(/\.00$/, "")}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </>
                      )}
                    </Card>
                  </Col>
                  <Col className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <label className="form-control-label">Quantity</label>
                    <FormGroup>
                      <Input
                        id="example-date-input"
                        type="number"
                        name="quantity"
                        disabled
                        value={this.state.dataPreSpec.quantity}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <FormGroup>
                      <label className="form-control-label">Notes</label>
                      <Input
                        id="example-date-input"
                        type="textarea"
                        name="notes"
                        disabled
                        value={this.state.dataPreSpec.notes}
                      />
                    </FormGroup>
                  </Col>
              <Button
                block
                color="danger"
                className="btn-icon text-uppercase mx-3"
                type="button"
                onClick={this.goBack}
              >
                {" "}
                Close
              </Button>
</>
              ) :(
                <>
              <Col className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h1 className="display-4">Quantity</h1>
                    <FormGroup>
                      <Input
                        id="example-date-input"
                        type="number"
                        name="quantity"
                        disabled
                        value={this.state.dataPreSpec.quantity}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <FormGroup>
                      <label className="form-control-label">Notes</label>
                      <Input
                        id="example-date-input"
                        type="textarea"
                        name="notes"
                        disabled
                        value={this.state.dataPreSpec.notes}
                      />
                    </FormGroup>
                  </Col>
              <Button
                block
                color="danger"
                className="btn-icon text-uppercase mx-3"
                type="button"
                onClick={this.goBack}
              >
                {" "}
                Close
              </Button>
              </>
              )}
              </Row>
         
            </Col>
          </Row>
          {/* {this.state.listQuotationStandardComponents.length > 0 ||
          this.state.listQuotationOptionalComponents.length > 0 ? (
            <h1 className="display-4">Components</h1>
          ) : null}
          <Col className="col-lg-6 col-md-12 col-sm-12 col-12">
            {this.state.listQuotationStandardComponents.length > 0 ||
            this.state.listQuotationOptionalComponents.length > 0 ? (
              <Row>
                <Col className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <Card>
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h3 className="mb-0">Standard Components</h3>
                        </div>
                      </Row>
                    </CardHeader>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listQuotationStandardComponents.map(
                          (element, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">
                                  {element.quotation_standard_component_name}
                                </th>
                                <td className="text-wrap">
                                  {
                                    element.quotation_standard_component_description
                                  }
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </Card>
                </Col>
                <Col className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <Card>
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h3 className="mb-0">Optional Components</h3>
                        </div>
                      </Row>
                    </CardHeader>
                    {this.state.listQuotationOptionalComponents.length === 0 ? (
                      <Alert color="secondary">
                        <strong>Components not found</strong>!
                      </Alert>
                    ) : (
                      <>
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Description</th>
                              <th scope="col">Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.listQuotationOptionalComponents.map(
                              (element, index) => {
                                return (
                                  <tr key={index}>
                                    <th scope="row">
                                      {
                                        element.quotation_optional_component_name
                                      }
                                    </th>
                                    <td>
                                      {
                                        element.quotation_optional_component_description
                                      }
                                    </td>
                                    <td>
                                      {currencyIDR(
                                        element.quotation_optional_component_price
                                      ).replace(/\.00$/, "")}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </>
                    )}
                  </Card>
                </Col>
              </Row>
            ) : null}
          </Col> */}
          {/* <h1 className="display-4">Request a Quote Information</h1>
          <h6 className="heading-small text-muted">Amount Information</h6>
          <Row>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Price</span>
                <div className="h1">
                  {currencyIDR(
                    this.state.dataPreSpec.product_price
                  ).replace(/\.00$/, "")}
                </div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Tax</span>
                <div className="h1">
                  {this.state.dataPreSpec.tax + " %"}
                </div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Discount</span>
                <div className="h1">
                  {this.state.dataPreSpec.discount + " %"}
                </div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Total Price</span>
                <div className="h1">
                  {currencyIDR(
                    this.state.dataPreSpec.total_product_price
                  ).replace(/\.00$/, "")}
                </div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Quantity</span>
                <div className="h1">
                  {this.state.dataPreSpec.quantity}
                </div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Grand Total</span>
                <div className="h1">
                  {currencyIDR(
                    this.state.dataPreSpec.grand_total
                  ).replace(/\.00$/, "")}
                </div>
              </div>
            </Col>
          </Row>
          // <h6 className="heading-small text-muted">Shipping Information</h6>*/}
          {/* <Row>
            <Col className="col-lg-6 col-md-12 col-sm-12 col-12">
              <FormGroup>
                <label className="form-control-label">Quantity</label>
                <Input
                  id="example-date-input"
                  type="number"
                  name="quantity"
                  disabled
                  value={this.state.dataPreSpec.quantity}
                />
              </FormGroup>
            </Col> */}
          {/* <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <FormGroup>
                <label className="form-control-label">Shipping Method</label>
                <Input
                  id="example-date-input"
                  disabled
                  type="select"
                  name="shipping_method"
                  value={
                    this.state.dataPreSpec.shipping_method
                  }
                >
                  <option value="">Select</option>
                  <option value="Delivery">Delivery</option>
                  <option value="Ex-work">Ex-work</option>
                </Input>
              </FormGroup>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <FormGroup>
                <label className="form-control-label">Payment Terms</label>
                <Input
                  id="example-date-input"
                  disabled
                  type="select"
                  name="payment_terms"
                  value={this.state.dataPreSpec.payment_terms}
                >
                  <option value="">Select</option>
                  <option value="Net 30">Net 30</option>
                  <option value="Net 40">Net 40</option>
                  <option value="Net 45">Net 45</option>
                  <option value="Net 60">Net 60</option>
                  <option value="10% DP - 90% Before Delivery">
                    10% DP - 90% Before Delivery
                  </option>
                  <option value="20% DP - 80% Before Delivery">
                    20% DP - 80% Before Delivery
                  </option>
                  <option value="30% DP - 70% Before Delivery">
                    30% DP - 70% Before Delivery
                  </option>
                </Input>
              </FormGroup>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <FormGroup>
                <label className="form-control-label">Purchase Timeframe</label>
                <Input
                  id="example-date-input"
                  disabled
                  type="select"
                  name="purchase_timeframe"
                  value={
                    this.state.dataPreSpec.purchase_timeframe
                  }
                >
                  <option value="">Select</option>
                  <option value="Immediate">Immediate</option>
                  <option value="This Quarter">This Quarter</option>
                  <option value="Next Quarter">Next Quarter</option>
                  <option value="This Year">This Year</option>
                  <option value="Unknown">Unknown</option>
                </Input>
              </FormGroup>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <FormGroup>
                <label className="form-control-label">Freight Terms</label>
                <Input
                  id="example-date-input"
                  disabled
                  type="select"
                  name="freight_terms"
                  value={this.state.dataPreSpec.freight_terms}
                >
                  <option value="">Select</option>
                  <option value="CFR">CFR</option>
                  <option value="CIF">CIF</option>
                  <option value="CIP">CIP</option>
                  <option value="CPT">CPT</option>
                  <option value="FOB">FOB</option>
                  <option value="DAP">DAP</option>
                  <option value="DAT">DAT</option>
                  <option value="DDP">DDP</option>
                  <option value="DPP">DPP</option>
                  <option value="EXW">EXW</option>
                  <option value="FAS">FAS</option>
                  <option value="FCA">FCA</option>
                </Input>
              </FormGroup>
            </Col>
            {this.state.isCountryArea ? (
              <>
                <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Country</label>
                    <Input
                      id="example-date-input"
                      disabled
                      type="select"
                      name="country"
                      value={this.state.dataPreSpec.country}
                    >
                      {countries.map((element) => {
                        return (
                          <option value={element.name}>{element.name}</option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                  <FormGroup>
                    <label className="form-control-label">City or Area</label>
                    <Input
                      id="example-date-input"
                      disabled
                      type="text"
                      name="area"
                      value={this.state.dataPreSpec.area}
                    />
                  </FormGroup>
                </Col>
              </>
            ) : null} */}
          {/* </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label">Notes</label>
                <Input
                  id="example-date-input"
                  type="textarea"
                  name="notes"
                  disabled
                  value={this.state.dataPreSpec.notes}
                />
              </FormGroup>
            </Col>
          </Row> */}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataCustomerQuotationsDetails:
      state.customer_product.dataCustomerQuotationsDetails,
    dataCustomerProductsQuotationsDetails:
      state.customer_product.dataCustomerProductsQuotationsDetails,
  };
};

export default connect(mapStateToProps)(CustomerQuotationDetails);
