import React, { PureComponent } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Alert,
  CardBody,
  FormText,
} from "reactstrap";
import config from "services/config";
import axios from "axios";
import Pagination from "react-bootstrap-pagination-logic";
import { connect } from "react-redux";
import { getDataQuotations } from "./Redux/quotationActions";
import SweetAlert from "react-bootstrap-sweetalert";
import StandardHeader from "components/Headers/StandardHeader";
import { styleInputSelect } from "services/styles";
import AsyncSelect from "react-select/async";
import moment from "moment";
class ListQuotationsAdmin extends PureComponent {
  state = {
    actionListQuotations: {
      page: 1,
      items_per_page: 10,
      order_by_field: "created_at",
      order_by_direction: "DESC",
      company_id: "",
    },
    actionListCompanies: {
      page: 1,
      items_per_page: 10,
      order_by_field: "company_id",
      order_by_direction: "DESC",
      company_name: "",
    },
    selectedOptionCompanies: [],
    alert: null,
    listQuotations: [],
    total_pages: 0,
    total_items: 0,
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.getDataListQuotations();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  fetchDataCompanies = (inputValue) => {
    let searchTerm = inputValue;

    if (!inputValue || inputValue === " ") {
      searchTerm = "";
    }

    this.setState({
      actionListCompanies: {
        ...this.state.actionListCompanies,
        company_name: searchTerm,
      },
    });

    const newRequest = axios.post(
      config.API_URL + "/companies/companies",
      this.state.actionListCompanies,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );

    if (newRequest) {
      return newRequest.then((response) => {
        const compare = response.data.data.filter((i) =>
          i.company_name.toLowerCase().includes(inputValue.toLowerCase())
        );
        return compare.map((data) => ({
          label: data.company_name,
          value: data.company_id,
        }));
      });
    }
  };
  onSearchChangeCompanies = (selectedOptionCompanies) => {
    this.setState({
      selectedOptionCompanies: selectedOptionCompanies,
      actionListQuotations: {
        ...this.state.actionListQuotations,
        company_id: selectedOptionCompanies.value,
      },
    });
  };

  handleChangeString = (event) => {
    this.setState({
      actionListQuotations: {
        ...this.state.actionListQuotations,
        [event.target.name]: event.target.value,
      },
    });
  };

  getDataListQuotations = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/quotations/list_quotations",
        this.state.actionListQuotations,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listQuotations: res.data.data,
                total_pages: res.data.total_pages,
                total_items: res.data.total_items,
              },
              () => {
                this.props.dispatch(
                  getDataQuotations(this.state.listQuotations)
                );
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  handleDetails = (data) => {
    this.props.history.push("/admin/quotation_products/" + data.quotations_id);
  };

  handlePagination = (numPage) => {
    this.setState(
      {
        actionListQuotations: {
          ...this.state.actionListQuotations,
          page: numPage,
        },
      },
      () => {
        this.getDataListQuotations();
      }
    );
  };

  handleItemPerpage = (e) => {
    this.setState(
      {
        actionListQuotations: {
          ...this.state.actionListQuotations,
          page: 1,
          items_per_page: parseInt(e.target.value),
        },
      },
      () => {
        this.getDataListQuotations();
      }
    );
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleFilter = () => {
    this.setState(
      {
        actionListQuotations: {
          ...this.state.actionListQuotations,
          page: 1,
        },
      },
      () => {
        this.getDataListQuotations();
      }
    );
  };

  handleReset = () => {
    this.setState(
      {
        actionListQuotations: {
          page: 1,
          items_per_page: 10,
          order_by_field: "created_at",
          order_by_direction: "DESC",
          company_id: "",
        },
        selectedOptionCompanies: [],
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getDataListQuotations();
            }
          );
        }
      }
    );
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  render() {
    let fromItem = null;
    let toItem = null;

    if (this.state.total_pages === this.state.actionListQuotations.page) {
      toItem = this.state.total_items;
    } else {
      toItem =
        this.props.dataQuotations.length * this.state.actionListQuotations.page;
    }
    if (this.state.actionListQuotations.page === 1) {
      fromItem = 1;
    } else if (
      this.state.total_pages === this.state.actionListQuotations.page
    ) {
      fromItem = this.state.total_items - this.props.dataQuotations.length + 1;
    } else {
      fromItem =
        this.props.dataQuotations.length *
          (this.state.actionListQuotations.page - 1) +
        1;
    }

    const availdataQuotations = this.props.dataQuotations.map((quotations) => {
      return (
        <tr
          key={quotations.quotation_id}
          style={{ cursor: "pointer" }}
          onDoubleClick={() => this.handleDetails(quotations)}
        >
          <th scope="row">
            <Media className="align-items-center">
              <Media>
                <span className="mb-0 text-sm">{quotations.quotations_id}</span>
              </Media>
            </Media>
          </th>
          <td>{quotations.username}</td>
          <td>{quotations.company_name}</td>
          <td>{moment(quotations.created_at).format("lll")}</td>
          <td className="text-right">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                role="button"
                size="sm"
                color=""
                onClick={(e) => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => this.handleDetails(quotations)}>
                  Details
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });

    return (
      <>
        <StandardHeader
          name="Configuration Record"
          parentName="Configuration Record"
          link="quotations"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="6">
                      <h3 className="mb-0">Filter Record ID</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <label className="form-control-label">Company</label>
                      <Form role="form">
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          styles={styleInputSelect}
                          value={this.state.selectedOptionCompanies}
                          placeholder={<div>Select Company</div>}
                          loadOptions={this.fetchDataCompanies}
                          onChange={(e) => {
                            this.onSearchChangeCompanies(e);
                          }}
                        />
                      </Form>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col
                      style={{ marginTop: 20 }}
                      className="col-6 col-md-3 col-sm-6"
                      xs="12"
                    >
                      <div className="text-center">
                        <Button
                          block
                          color="success"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isFilterLoading ? true : false}
                          onClick={() => this.handleFilter()}
                        >
                          {this.state.isFilterLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Filter
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Filter</span>
                          )}
                        </Button>
                      </div>
                    </Col>
                    <Col
                      style={{ marginTop: 20 }}
                      className="col-6 col-md-3 col-sm-6"
                      xs="12"
                    >
                      <Button
                        block
                        color="danger"
                        className="btn-icon"
                        type="button"
                        disabled={this.state.isResetLoading ? true : false}
                        onClick={() => this.handleReset()}
                      >
                        {this.state.isResetLoading ? (
                          <>
                            <span className="btn-inner--text">
                              <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                              Reset
                            </span>
                          </>
                        ) : (
                          <span className="btn-inner--text">Reset</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="card-wrapper">
                <Card>
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">List Preliminary Spec ID</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <div>
                    {this.props.dataQuotations.length < 5 ? (
                      <div>
                        {this.props.dataQuotations.length === 0 ? (
                          <Alert color="secondary">
                            <strong>Data not found!</strong> Please check again!
                          </Alert>
                        ) : (
                          <>
                            <Table
                              className="align-items-center table-flush"
                              hover
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Preliminary Spec ID</th>
                                  <th scope="col">Attention</th>
                                  <th scope="col">Company Name</th>
                                  <th scope="col">Created At</th>
                                  <th>ACTIONS</th>
                                </tr>
                              </thead>
                              <tbody>{availdataQuotations}</tbody>
                            </Table>
                          </>
                        )}
                      </div>
                    ) : (
                      <>
                        <Table
                          className="align-items-center table-flush"
                          hover
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Preliminary Spec ID</th>
                              <th scope="col">Attention</th>
                              <th scope="col">Company Name</th>
                              <th scope="col">Created At</th>
                              <th>ACTIONS</th>
                            </tr>
                          </thead>
                          <tbody>{availdataQuotations}</tbody>
                        </Table>
                      </>
                    )}
                  </div>

                  <CardFooter className="py-4">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <Col md="3" sm="6">
                          <Form inline>
                            <FormGroup>
                              <FormText className="mr-sm-2">Show</FormText>

                              <Input
                                className="form-control-sm mr-sm-2"
                                type="select"
                                onChange={this.handleItemPerpage}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </Input>
                              <FormText>
                                entries. Showing rows {fromItem} to {toItem} of{" "}
                                {this.state.total_items}
                              </FormText>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Col>
                      <Col className="text-right" xs="4">
                        <nav aria-label="...">
                          <Pagination
                            current_page={this.state.actionListQuotations.page}
                            last_page={this.state.total_pages}
                            position="right"
                            handlePageChange={this.handlePagination}
                          />
                        </nav>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </div>
            </div>
          </Row>
        </Container>

        {this.state.alert}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataQuotations: state.quotation.dataQuotations,
  };
};

export default connect(mapStateToProps)(ListQuotationsAdmin);
