import React from "react";

import {
  Row,
  Col,
  Container,
  Input,
  Card,
  Table
} from "reactstrap";
import background from "assets/img/document/background.png"
// import Title from "components/Breadcumbs/Title";
class Transaction extends React.Component {

  render() {
    return (
      <>
      <section  className="section section-lg  mt--5 mx-8">

        {/* <Container className="bg-primary" > */}
        <div className="h1 text-right mt-6 mb-4  d-inline-block" style={{color: "#00aeef", fontSize: "26px"}}>Transactions</div>
        <Row className="justify-content-center mx-auto rounded border border-dark">
         

        <Col lg="12" md="12" xs="12" className="mt-3">
                    <Table
                              className="align-items-center "
                              hover
                              borderless
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col" style={{fontSize: "12px"}}>PO Number</th>
                                  <th scope="col" style={{fontSize: "12px"}}>PO Date</th>
                                  <th scope="col" style={{fontSize: "12px"}}>PO Desc</th>
                                  <th scope="col" style={{fontSize: "12px"}}>Quantity</th>
                                  <th scope="col" style={{fontSize: "12px"}}>Price/Unit</th>
                                  <th scope="col" style={{fontSize: "12px"}}>Total Price</th>
                                  <th scope="col" style={{fontSize: "12px"}}>LOB</th>
                                  <th scope="col" style={{fontSize: "12px"}}>Product</th>
                                  <th scope="col" style={{fontSize: "12px"}}>Order Point</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>SSB/PO/2022</td>
                                  <td>28/12/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>2</td>
                                  <td>Rp 2.000.000</td>
                                  <td>Rp 4.000.000</td>
                                  <td>Product</td>
                                  <td>Water Tank</td>
                                  <td>4.000</td>
                                </tr>
                                <tr>
                                  <td>SSB/PO/2022</td>
                                  <td>28/12/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>2</td>
                                  <td>Rp 2.000.000</td>
                                  <td>Rp 4.000.000</td>
                                  <td>Product</td>
                                  <td>Water Tank</td>
                                  <td>4.000</td>
                                </tr>
                                <tr>
                                  <td>SSB/PO/2022</td>
                                  <td>28/12/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>2</td>
                                  <td>Rp 2.000.000</td>
                                  <td>Rp 4.000.000</td>
                                  <td>Product</td>
                                  <td>Water Tank</td>
                                  <td>4.000</td>
                                </tr>
                                <tr>
                                  <td>SSB/PO/2022</td>
                                  <td>28/12/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>2</td>
                                  <td>Rp 2.000.000</td>
                                  <td>Rp 4.000.000</td>
                                  <td>Product</td>
                                  <td>Water Tank</td>
                                  <td>4.000</td>
                                </tr>
                                <tr>
                                  <td>SSB/PO/2022</td>
                                  <td>28/12/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>2</td>
                                  <td>Rp 2.000.000</td>
                                  <td>Rp 4.000.000</td>
                                  <td>Product</td>
                                  <td>Water Tank</td>
                                  <td>4.000</td>
                                </tr>
                                <tr>
                                  <td>SSB/PO/2022</td>
                                  <td>28/12/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>2</td>
                                  <td>Rp 2.000.000</td>
                                  <td>Rp 4.000.000</td>
                                  <td>Product</td>
                                  <td>Water Tank</td>
                                  <td>4.000</td>
                                </tr>
                                <tr>
                                  <td>SSB/PO/2022</td>
                                  <td>28/12/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>2</td>
                                  <td>Rp 2.000.000</td>
                                  <td>Rp 4.000.000</td>
                                  <td>Product</td>
                                  <td>Water Tank</td>
                                  <td>4.000</td>
                                </tr>
                                <tr>
                                  <td>SSB/PO/2022</td>
                                  <td>28/12/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>2</td>
                                  <td>Rp 2.000.000</td>
                                  <td>Rp 4.000.000</td>
                                  <td>Product</td>
                                  <td>Water Tank</td>
                                  <td>4.000</td>
                                </tr>
                                <tr>
                                  <td>SSB/PO/2022</td>
                                  <td>28/12/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>2</td>
                                  <td>Rp 2.000.000</td>
                                  <td>Rp 4.000.000</td>
                                  <td>Product</td>
                                  <td>Water Tank</td>
                                  <td>4.000</td>
                                </tr>
                                <tr>
                                  <td>SSB/PO/2022</td>
                                  <td>28/12/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>2</td>
                                  <td>Rp 2.000.000</td>
                                  <td>Rp 4.000.000</td>
                                  <td>Product</td>
                                  <td>Water Tank</td>
                                  <td>4.000</td>
                                </tr>
                                <tr>
                                  <td>SSB/PO/2022</td>
                                  <td>28/12/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>2</td>
                                  <td>Rp 2.000.000</td>
                                  <td>Rp 4.000.000</td>
                                  <td>Product</td>
                                  <td>Water Tank</td>
                                  <td>4.000</td>
                                </tr>
                               
                              </tbody>
                    </Table>
        </Col>
        </Row>
        {/* </Container> */}
      </section>
      </>
    );
  }
}

export default Transaction;
