import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import Breadcumbs from "components/Breadcumbs/Breadcumbs";
// import config from "services/config";
// import axios from "axios";
import b1 from "assets/img/product/crane_truck.PNG";
import b2 from "assets/img/product/fuel_lube_service.PNG";
import b3 from "assets/img/product/fuel_truck.PNG";
import b4 from "assets/img/product/lube_service.PNG";
import b5 from "assets/img/product/manhaul_bus.PNG";


class Product extends React.Component {

state = {
  listProduct: [],
    
  total_items: 0,
};
componentDidMount() {
  this.setState({
    listProduct: [
      {
      category_name: "MANHAUL BUS",
      image_1: b5
      },
      {
      category_name: "LUBE SERVICE",
      image_1: b4
      },
      {
      category_name: "FUEL TRUCK",
      image_1: b3
      },
      {
      category_name: "FUEL LUBE SERVICE",
      image_1: b2
      },
      {
      category_name: "CRANE_TRUCK",
      image_1: b1
      }
    ]
  })
//   this.mounted = true;
//   this.getDataListProducts();
console.log(this.state.listProduct)
}
// componentWillUnmount() {
//   this.mounted = false;
// }

// handletoDetails = (e) => {
//   this.props.history.push("/customers/ssb-portofolio-detail/" + e);
// };
// getDataListProducts = () => {
//   axios
//     .post(
//       config.API_URL + "/portofolio/view_ssb_portofolio_customer",
//       this.state.total_items,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: config.TOKEN,
//         },
//       }
//     )
//     .then(
//       (res) => {
//         if (this.mounted) {
//           this.setState({
//             listProduct: res.data.data,
//           });
//         }
//       },
//       (err) => {
//         console.log("error: ", err);
//       }
//     );
// };

render() {
  const dataProduct = this.state.listProduct.map((products, index) => {
    return (
      <>
        <Card
          className="card-lift--hover rounded shadow mt-4 mx-4"
          key={index}
          style={{ cursor: "pointer", objectFit: "cover" }}
          onClick={() => this.handletoDetails(products.category_name.toUpperCase())}
        >
          <div className="d-flex flex-wrap flex-md-nowrap flex-lg-nowrap flex-xl-nowrap">
            <div className="flex-item justify-content-center">
              <img
                alt="..."
                className="rounded"
                width="320"
                height="220"
                style={{ cursor: "pointer", objectFit: "cover" }}
                src={products.image_1}
              />

              <h4 className="text-center text-uppercase">
                {products.category_name}
              </h4>
            </div>
          </div>
        </Card>
      </>
    );
  });

  return (
    <>
       <section  className="section section-lg  mx-auto mt--5 mb-6">
        {/* <Breadcumbs
          name="SSB Portofolio"
          parentName="SSB Portofolio"
          link="/customers/ssb-portofolio"
        /> */}
              <div className="h1 mx-8 my-5" style={{color: "#00aeef",fontSize: "28px"}}>Product</div>
          {this.state.listProduct.length === 0 ? (
       
              <Card className="card-frame mt-5">
                <CardBody>
                  <i class="fas fa-sync fa-spin mr-2"></i>
                  <strong>Waiting ssb portofolio ...</strong>
                </CardBody>
              </Card>
          ) : (
          <Row className=" mx-8 rounded border border-dark mb-4 pt-3">
            {dataProduct}
          </Row>
            )}
      </section>
    </>
  );
}
}

export default Product;
