import React, { PureComponent } from "react";

import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input,
} from "reactstrap";

// core components
import StandardHeader from "components/Headers/StandardHeader.js";
import { validateCompany, validateUser } from "services/validate";

//services
import config from "services/config";

//packages
import axios from "axios";

// redux
import { connect } from "react-redux";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import { getDataCompanies } from "./Redux/companyActions";
import ListUsers from "./ListUsers";

class CompanyDetails extends PureComponent {
  state = {
    data: {
      company_name: "",
      company_description: "",
      company_phone: "",
      company_address: "",
      sales_id: "",
      market_share: ""
    },
    actionListCompanies: {
      page: 1,
      items_per_page: 10,
      order_by_field: "company_id",
      order_by_direction: "DESC",
      company_name: "",
    },
    dataUser: {
      username: "",
      password: "",
      email: "",
      role_id: 0,
      is_active: 0
    },
    dataUpdateUser: {
      username: "",
      email: "",
      role_id: 0,
      is_active: 0
    },
    updateUser: false,
    listCompanies: [],
    listUsers: [],
    listUsersSales: [],
    isLoading: false,
    alert: null,
    paramErrorCompany: {},
    paramErrorUser: {},
  };

  componentDidMount = async () => {
    this.mounted = true;
    try {
      const response = await axios.post(config.API_URL + '/companies/view_company',{ company_id: this.props.match.params.companyId },{
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      this.setState({
        data: {
          company_id: response.data.data[0].company_id,
          company_name: response.data.data[0].company_name,
          company_description: response.data.data[0].company_description,
          company_phone: response.data.data[0].company_phone,
          company_address: response.data.data[0].company_address,
          sales_id: response.data.data[0].sales_id,
          market_share: response.data.data[0].market_share
        }
      });
      this.getListUsers();
      this.getListUsersSales();
      this.getDataListCompanies();
    } catch (err){
      console.log(err)
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getListUsers = () => {
    axios
      .post(
        config.API_URL + "/users/users",
        { company_id: this.props.match.params.companyId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listUsers: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  getListUsersSales = () => {
    axios
      .post(
        config.API_URL + "/users/users_sales",
        { role_id: 2 },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listUsersSales: res.data.data,
            }, () => {
              console.log(this.state.listUsersSales)
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  getDataListCompanies = () => {
    axios
      .post(
        config.API_URL + "/companies/companies",
        this.state.actionListCompanies,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listCompanies: res.data.data,
              },
              () => {
                this.props.dispatch(getDataCompanies(this.state.listCompanies));
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  remove = (id) => {
    axios
      .post(
        config.API_URL + "/companies/delete_company",
        { company_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlert();
          this.getDataListCompanies();
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  putDataToAPI = () => {
    this.setState({ isLoading: true });
    const paramErrorCompany = validateCompany(this.state.data);
    this.setState({ paramErrorCompany });
    if (Object.keys(paramErrorCompany).length === 0) {
      axios
        .post(config.API_URL + "/companies/update_company", this.state.data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        })
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(this.state.data.company_name);
              this.setState({ isLoading: false });
            }
          },
          (err) => {
            if (this.mounted) {
              this.failedAlert(this.state.data.company_name);
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };

  putDataUserToAPI = () => {
    this.setState({ isLoading: true });
    const paramErrorUser = validateUser(this.state.dataUpdateUser);
    this.setState({ paramErrorUser });
    if (Object.keys(paramErrorUser).length === 0) {
      axios
        .post(
          config.API_URL + "/users/update_user",
          this.state.dataUpdateUser,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(res.data.message);
              this.setState({ isLoading: false });
              this.getListUsers();
            }
          },
          (err) => {
            if (this.mounted) {
              this.failedAlert(this.state.dataUpdateUser.username);
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };

  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeInt = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: parseInt(event.target.value),
      },
    });
  };

  handleChangeStringUser = (event) => {
    this.setState({
      dataUser: {
        ...this.state.dataUser,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeStringUpdateUser = (event) => {
    this.setState({
      dataUpdateUser: {
        ...this.state.dataUpdateUser,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleSubmit = () => {
    this.putDataToAPI();
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  confirmAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.remove(data.company_id)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.company_name}`} company, You won't be able to revert
          this!
        </SweetAlert>
      ),
    });
  };

  confirmedAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.handletoMainPage()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Company has been deleted.
        </SweetAlert>
      ),
    });
  };

  removeUser = (data) => {
    axios
      .post(
        config.API_URL + "/users/delete_user",
        { user_id: data.user_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlertUser();
          this.getListUsers();
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  confirmAlertUser = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.removeUser(data)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.username}`} user, You won't be able to revert this!
        </SweetAlert>
      ),
    });
  };

  confirmedAlertUser = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          User has been deleted.
        </SweetAlert>
      ),
    });
  };

  handleUpdateUser = (data) => {
    this.setState({
      dataUpdateUser: {
        user_id: data.user_id,
        username: data.username,
        email: data.email,
        company_id: data.company_id,
        role_id: data.role_id,
        is_active: data.is_active
      },
      updateUser: true,
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleSubmitUpdateUser = () => {
    this.putDataUserToAPI();
  };

  handleSubmitUser = () => {
    this.setState(
      {
        isLoading: true,
        dataUser: {
          ...this.state.dataUser,
          company_id: this.props.match.params.companyId,
        },
      },
      () => {
        const paramErrorUser = validateUser(this.state.dataUser);
        this.setState({ paramErrorUser });
        if (Object.keys(paramErrorUser).length === 0) {
          axios
            .post(
              config.API_URL + "/users/create_user",
              this.state.dataUser,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: config.TOKEN,
                },
              }
            )
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data.message);
                  this.setState({
                    isLoading: false,
                    dataUser: {
                      ...this.state.dataUser,
                      username: "",
                      email: "",
                      password: "",
                      role_id: "",
                    },
                  });
                  this.getListUsers();
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(this.state.dataUser.username);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };

  render() {
    return (
      <>
        <StandardHeader
          name="Company Details"
          parentName="Companies"
          link="companies"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Company Details</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Company Name
                          </label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="company_name"
                            placeholder="Company Name"
                            value={this.state.data.company_name}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramErrorCompany.company_name
                                ? "is-invalid"
                                : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramErrorCompany.company_name}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Company Phone
                          </label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="company_phone"
                            placeholder="Company Phone"
                            value={this.state.data.company_phone}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramErrorCompany.company_phone
                                ? "is-invalid"
                                : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramErrorCompany.company_phone}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Sales
                          </label>
                          <Input
                            type="select"
                            onKeyPress={this.keyPressed}
                            name="sales_id"
                            placeholder="Sales"
                            value={this.state.data.sales_id}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramErrorCompany.sales_id
                                ? "is-invalid"
                                : ""
                            }
                          >
                            <option value={''}>Select</option>
                            {this.state.listUsersSales.map(element => {
                              return (
                                <option value={element.user_id}>{element.username}</option>
                              )
                            })}
                          </Input>
                          <div className="invalid-feedback">
                            {this.state.paramErrorCompany.sales_id}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Market Share
                          </label>
                          <Input
                            type="select"
                            onKeyPress={this.keyPressed}
                            name="market_share"
                            placeholder="Dealer"
                            value={this.state.data.market_share}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramErrorCompany.market_share
                                ? "is-invalid"
                                : ""
                            }
                          >
                            <option value={''}>Non Truck Dealer</option>
                            <option value={'iveco'}>Iveco</option>
                            <option value={'volvo'}>Volvo</option>
                            <option value={'mercedes'}>Mercedes</option>
                            <option value={'scania'}>Scania</option>
                          </Input>
                          <div className="invalid-feedback">
                            {this.state.paramErrorCompany.market_share}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Company Address
                          </label>
                          <Input
                            type="textarea"
                            onKeyPress={this.keyPressed}
                            name="company_address"
                            placeholder="Company Address"
                            value={this.state.data.company_address}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramErrorCompany.company_address
                                ? "is-invalid"
                                : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramErrorCompany.company_address}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Company Description <small>optional</small>
                          </label>
                          <Input
                            type="textarea"
                            onKeyPress={this.keyPressed}
                            name="company_description"
                            placeholder="Company Description"
                            value={this.state.data.company_description}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramErrorCompany.company_description
                                ? "is-invalid"
                                : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramErrorCompany.company_description}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                      <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-3 col-sm-6"
                        xs="12"
                      >
                        <Button
                          block
                          color="success"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isLoading ? true : false}
                          onClick={() => this.handleSubmit()}
                        >
                          {this.state.isLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Save
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Save</span>
                          )}
                        </Button>
                      </Col>

                      <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-3 col-sm-6"
                        xs="12"
                      >
                        <Button
                          block
                          color="danger"
                          disabled={this.state.isLoading ? true : false}
                          onClick={() => this.confirmAlert(this.state.data)}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <Row>
            <Col>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.state.updateUser ? "Update User" : "Create User"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Username</label>
                        <Input
                          type="text"
                          onKeyPress={this.keyPressed}
                          name="username"
                          placeholder="Username"
                          value={
                            this.state.updateUser
                              ? this.state.dataUpdateUser.username
                              : this.state.dataUser.username
                          }
                          required
                          className={
                            this.state.paramErrorUser.username
                              ? "is-invalid"
                              : ""
                          }
                          onChange={
                            this.state.updateUser
                              ? this.handleChangeStringUpdateUser
                              : this.handleChangeStringUser
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramErrorUser.username}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Email</label>
                        <Input
                          type="text"
                          onKeyPress={this.keyPressed}
                          name="email"
                          placeholder="Email"
                          value={
                            this.state.updateUser
                              ? this.state.dataUpdateUser.email
                              : this.state.dataUser.email
                          }
                          required
                          className={
                            this.state.paramErrorUser.email ? "is-invalid" : ""
                          }
                          onChange={
                            this.state.updateUser
                              ? this.handleChangeStringUpdateUser
                              : this.handleChangeStringUser
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramErrorUser.email}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Password</label>
                        <Input
                          type="password"
                          onKeyPress={this.keyPressed}
                          name="password"
                          placeholder="Password"
                          value={
                            this.state.updateUser
                              ? this.state.dataUpdateUser.password
                              : this.state.dataUser.password
                          }
                          required
                          className={
                            this.state.paramErrorUser.password
                              ? "is-invalid"
                              : ""
                          }
                          onChange={
                            this.state.updateUser
                              ? this.handleChangeStringUpdateUser
                              : this.handleChangeStringUser
                          }
                          disabled={this.state.updateUser?true:false}
                        />
                        <div className="invalid-feedback">
                          {this.state.paramErrorUser.password}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Role</label>
                        <Input
                          type="select"
                          onKeyPress={this.keyPressed}
                          name="role_id"
                          placeholder="Role Id"
                          value={
                            this.state.updateUser
                              ? this.state.dataUpdateUser.role_id
                              : this.state.dataUser.role_id
                          }
                          required
                          className={
                            this.state.paramErrorUser.role_id
                              ? "is-invalid"
                              : ""
                          }
                          onChange={
                            this.state.updateUser
                              ? this.handleChangeStringUpdateUser
                              : this.handleChangeStringUser
                          }
                        >
                          <option value={0}>Select</option>
                          <option value={2}>Sales</option>
                          <option value={3}>Customer</option>
                        </Input>
                        <div className="invalid-feedback">
                          {this.state.paramErrorUser.role_id}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Is Active</label>
                        <Input
                          type="select"
                          onKeyPress={this.keyPressed}
                          name="is_active"
                          placeholder="Is Active"
                          value={
                            this.state.updateUser
                              ? this.state.dataUpdateUser.is_active
                              : this.state.dataUser.is_active
                          }
                          required
                          onChange={
                            this.state.updateUser
                              ? this.handleChangeStringUpdateUser
                              : this.handleChangeStringUser
                          }
                        >
                          <option value={0}>No</option>
                          <option value={1}>Yes</option>
                        </Input>
                        <div className="invalid-feedback">
                          {this.state.paramErrorUser.is_active}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <>
                      {this.state.updateUser ? (
                        <>
                          <Col
                            style={{ marginTop: 20 }}
                            className="col-6 col-md-3 col-sm-6"
                            xs="12"
                          >
                            <Button
                              block
                              color="success"
                              className="btn-icon"
                              type="button"
                              disabled={this.state.isLoading ? true : false}
                              onClick={() => this.handleSubmitUpdateUser()}
                            >
                              {this.state.isLoading ? (
                                <>
                                  <span className="btn-inner--text">
                                    <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                    Save
                                  </span>
                                </>
                              ) : (
                                <span className="btn-inner--text">Save</span>
                              )}
                            </Button>
                          </Col>
                          <Col
                            style={{ marginTop: 20 }}
                            className="col-6 col-md-3 col-sm-6"
                            xs="12"
                          >
                            <Button
                              block
                              color="second"
                              disabled={this.state.isLoading ? true : false}
                              onClick={() =>
                                this.setState({ updateUser: false })
                              }
                            >
                              Cancel
                            </Button>
                          </Col>
                        </>
                      ) : (
                        <Col
                          style={{ marginTop: 20 }}
                          className="col-6 col-md-3 col-sm-6"
                          xs="12"
                        >
                          <Button
                            block
                            color="success"
                            className="btn-icon"
                            type="button"
                            disabled={this.state.isLoading ? true : false}
                            onClick={() => this.handleSubmitUser()}
                          >
                            {this.state.isLoading ? (
                              <>
                                <span className="btn-inner--text">
                                  <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                  Submit
                                </span>
                              </>
                            ) : (
                              <span className="btn-inner--text">Submit</span>
                            )}
                          </Button>
                        </Col>
                      )}
                    </>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListUsers
                  list={this.state.listUsers}
                  info={this.state.data.company_name}
                  update={this.handleUpdateUser}
                  delete={this.confirmAlertUser}
                />
              </div>
            </div>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataCompanies: state.company.dataCompanies,
  };
};
export default connect(mapStateToProps)(CompanyDetails);
