import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Button,
    CardHeader,
    Form,
    FormGroup,
    Input,
    CardImg,
    UncontrolledPopover,
    PopoverBody
} from "reactstrap";
import Breadcumbs from "components/Breadcumbs/Breadcumbs";
import Axios from "axios";
import config from "services/config";
import { currencyIDR } from "services/currency";
import { styleInputSelect } from "services/styles";
import AsyncSelect from "react-select/async";
import { validateConfigureNow } from 'services/validate';
import SweetAlert from "react-bootstrap-sweetalert";
import Title from "components/Breadcumbs/Title";
import { getDataCustomerProductsDetails } from "../Redux/customerProductActions";
import { connect } from "react-redux";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";

class ConfigureNow extends React.Component {
    state = {
        data: {
            category_name: '',
            tank_capacity: '',
            chasis: '',
            axle: ''
        },
        actionListProduct: {
            page: 1,
            items_per_page: 1,
            order_by_field: "product_id",
            order_by_direction: "DESC",
            category_id: 0,
            product_no: "",
            product_type: "",
            chasis: "",
            axle: "",
            tank_capacity: "",
        },
        similarProduct: {
            page: 1,
            items_per_page: 5,
            order_by_field: "product_id",
            order_by_direction: "DESC",
            category_id: 0,
            product_no: "",
            product_type: "",
            category_id: 0,
            chasis: config.MARKET_SHARE,
            axle: "",
            tank_capacity: "",
        },
        actionListCategories: {
            page: 1,
            items_per_page: 10,
            order_by_field: "category_id",
            order_by_direction: "DESC",
            category_name: "",
        },
        listAxle: [],
        listChasis: [],
        listSimilarProducts: [],
        listCapacity: [],
        typeProduct: '',
        product: {},
        selectedOptionCategories: [],
        isLoading: false,
        paramError: {},
    };
    componentDidMount() {
        this.mounted = true;
        this.getProduct()
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    getListChasis = async () => {
        try {
            const response = await Axios.post(config.API_URL + '/customer_list_products/customer_list_chasis', { category_id: this.state.actionListProduct.category_id }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: config.TOKEN,
                },
            })
            this.setState({
                listChasis: response.data.data
            })
        } catch (err) {
            console.log(err)
        }
    }
    getListAxle = async () => {
        try {
            const response = await Axios.post(config.API_URL + '/customer_list_products/customer_list_axle', { category_id: this.state.actionListProduct.category_id, chasis: this.state.actionListProduct.chasis }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: config.TOKEN,
                },
            })
            this.setState({
                listAxle: response.data.data
            })
        } catch (err) {
            console.log(err)
        }
    }
    fetchDataCategories = (inputValue) => {
        let searchTerm = inputValue;
        if (!inputValue || inputValue === " ") {
            searchTerm = "";
        }
        this.setState({
            actionListCategories: {
                ...this.state.actionListCategories,
                category_name: searchTerm,
            },
        });
        const newRequest = Axios.post(
            config.API_URL + "/categories/categories_customers",
            this.state.actionListCategories,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: config.TOKEN,
                },
            }
        );

        if (newRequest) {
            return newRequest.then((response) => {
                const compare = response.data.data.filter((i) =>
                    i.category_name.toLowerCase().includes(inputValue.toLowerCase())
                );
                return compare.map((data) => ({
                    label: data.category_name,
                    value: data.category_id,
                }));
            });
        }
    };
    onSearchChangeCategories = (selectedOptionCategories) => {
        this.setState({
            selectedOptionCategories: selectedOptionCategories,
            typeProduct: selectedOptionCategories.label,
            actionListProduct: {
                ...this.state.actionListProduct,
                category_id: selectedOptionCategories.value,
            },
        }, () => {
            this.getListChasis();
            this.getListAxle();
            if (this.state.typeProduct === 'Water Truck') {
                if (this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '20KL',
                                label: '20KL'
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '8x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '30KL',
                                label: '30KL'
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '4x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '',
                                label: ''
                            }
                        ]
                    })
                }
            }
            if (this.state.typeProduct === 'Fuel Truck') {
                if (this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '20KL',
                                label: '20KL'
                            },
                            {
                                value: '25KL',
                                label: '25KL'
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '8x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '32KL',
                                label: '32KL'
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '4x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '',
                                label: ''
                            }
                        ]
                    })
                }
            }
            if (this.state.typeProduct === 'Lube Service') {
                if (this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '5P2L',
                                label: 'Standard SSB'
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '8x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '',
                                label: ''
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '4x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '',
                                label: ''
                            }
                        ]
                    })
                }
            }
            if (this.state.typeProduct === 'Wash Down') {
                if (this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '20KL',
                                label: '20KL'
                            },
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '8x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '30KL',
                                label: '30KL'
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '4x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '',
                                label: ''
                            }
                        ]
                    })
                }
            }

            if(this.state.typeProduct === 'Crane Truck'){
                if(this.state.actionListProduct.axle === '6x6'){
                  this.setState({
                    listCapacity: [
                        {
                          value: '13.7 MT',
                          label: '13.7 MT'
                        },
                        {
                          value: '12.9 MT',
                          label: '12.9 MT'
                        },
                        {
                          value: '29 MT',
                          label: '29 MT'
                        },
                        {
                          value: '35.1 MT',
                          label: '35.1 MT'
                        },
                        {
                          value: '46.9 MT',
                          label: '46.9 MT'
                        },
                      ]
                  })
                }
                if(this.state.actionListProduct.axle === '6x4'){
                  this.setState({
                    listCapacity: [
                        {
                          value: '13.7 MT',
                          label: '13.7 MT'
                        },
                        {
                          value: '12.9 MT',
                          label: '12.9 MT'
                        },
                        {
                          value: '29 MT',
                          label: '29 MT'
                        },
                        {
                          value: '35.1 MT',
                          label: '35.1 MT'
                        },
                        {
                          value: '46.9 MT',
                          label: '46.9 MT'
                        },
                      ]
                  })
                }
              }

            if (this.state.typeProduct === 'Fuel Lube Service') {
                if (this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '4KL+5P2L',
                                label: 'Standard SSB'
                            },
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '8x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '',
                                label: ''
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '4x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '',
                                label: ''
                            }
                        ]
                    })
                }
            }

            if(this.state.typeProduct === 'Manhaul Bus'){
                if(this.state.actionListProduct.axle === '4x4'){
                  this.setState({
                    listCapacity: [
                      {
                        value: '33 Seat',
                        label: '33 Seat'
                      },
                      {
                        value: '35 Seat',
                        label: '35 Seat'
                      },
                      {
                        value: '40 Seat',
                        label: '40 Seat'
                      },
                      {
                        value: '51 Seat',
                        label: '51 Seat'
                      },
                      {
                        value: '55 Seat',
                        label: '55 Seat'
                      }
                    ]
                  })
                }
              }
        });
    };
    handleChangeString = (event) => {
        this.setState({
            actionListProduct: {
                ...this.state.actionListProduct,
                [event.target.name]: event.target.value,
            },
        });
    };
    handleChangeChasis = (event) => {
        this.setState({
            actionListProduct: {
                ...this.state.actionListProduct,
                [event.target.name]: event.target.value,
            },
        }, () => {
            this.getListAxle();
        });
    };
    getProduct = () => {
        Axios.post(config.API_URL + '/products/view_product_customer', { product_id: this.props.match.params.productId },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: config.TOKEN,
                },
            }
        ).then((res) => {
            if (this.mounted) {
                this.setState({
                    data: res.data.data[0]
                }, () => {
                    this.setState({
                        similarProduct: {
                            ...this.state.similarProduct,
                            category_id: this.state.data.category_id
                        }
                    }, () => {
                        this.getSimilarProduct();
                    })
                });
            }
        }, err => {
            console.log({ err })
        })
    }

    getSimilarProduct = () => {
        Axios
            .post(
                config.API_URL +
                "/customer_list_products/customer_list_standard_products",
                this.state.similarProduct,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: config.TOKEN,
                    },
                }
            )
            .then(
                (res) => {
                    if (this.mounted) {
                        this.setState(
                            {
                                listSimilarProducts: res.data.data
                            });
                    }
                });
    }
    getDataListProducts = () => {
        this.setState({ isLoading: true });
        const paramError = validateConfigureNow(this.state.actionListProduct);
        this.setState({ paramError });
        if (Object.keys(paramError).length === 0) {
            Axios
                .post(
                    config.API_URL +
                    "/customer_list_products/customer_list_standard_products",
                    this.state.actionListProduct,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: config.TOKEN,
                        },
                    }
                )
                .then(
                    (res) => {
                        if (this.mounted) {
                            if (res.data.data.length > 0) {
                                this.setState(
                                    {
                                        product: res.data.data[0].product_id,
                                    },
                                    () => {
                                        this.handleToConfigureNowPage(this.state.product);
                                        this.setState({ isLoading: false });
                                        window.location.reload(true);
                                    }
                                );
                            } else {
                                this.failedAlert("No products have been configured");
                                this.setState({
                                    isLoading: false,
                                })
                            }
                        }
                    },
                    (err) => {
                        this.failedAlert(err.response.data.message);
                        this.setState({ isLoading: false });
                    }
                );
        } else {
            this.failedAlert("Validation Errors");
            this.setState({
                isLoading: false,
            })
        }
    };

    failedAlert = (data) => {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    style={{ display: "block" }}
                    title="Failed"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="danger"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    {`${data}`}
                </SweetAlert>
            ),
        });
    };

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    };

    handleToConfigureNowPage = (data) => {
        this.props.history.push(
            `/customers/configure-now/${data}`
        );
    }

    handletoListProductsDetails = (data) => {
        this.props.dispatch(getDataCustomerProductsDetails(data));
        this.props.history.push(
            "/customers/configure-details"
        );
    };

    handleChangeAxle = (event) => {
        this.setState({
            actionListProduct: {
                ...this.state.actionListProduct,
                [event.target.name]: event.target.value,
            },
        }, () => {
            if (this.state.typeProduct === 'Water Truck') {
                if (this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '20KL',
                                label: '20KL'
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '8x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '30KL',
                                label: '30KL'
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '4x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '',
                                label: ''
                            }
                        ]
                    })
                }
            }
            if (this.state.typeProduct === 'Fuel Truck') {
                if (this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '20KL',
                                label: '20KL'
                            },
                            {
                                value: '25KL',
                                label: '25KL'
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '8x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '32KL',
                                label: '32KL'
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '4x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '',
                                label: ''
                            }
                        ]
                    })
                }
            }
            if (this.state.typeProduct === 'Lube Service') {
                if (this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '5P2L',
                                label: 'Standard SSB'
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '8x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '',
                                label: ''
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '4x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '',
                                label: ''
                            }
                        ]
                    })
                }
            }

            if (this.state.typeProduct === 'Wash Down') {
                if (this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '20KL',
                                label: '20KL'
                            },
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '8x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '30KL',
                                label: '30KL'
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '4x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '',
                                label: ''
                            }
                        ]
                    })
                }
            }

            if(this.state.typeProduct === 'Crane Truck'){
                if(this.state.actionListProduct.axle === '6x6'){
                  this.setState({
                    listCapacity: [
                        {
                          value: '12.9 MT',
                          label: '12.9 MT'
                        },
                        {
                          value: '13.7 MT',
                          label: '13.7 MT'
                        },
                        {
                          value: '29 MT',
                          label: '29 MT'
                        },
                        {
                          value: '35.1 MT',
                          label: '35.1 MT'
                        },
                        {
                          value: '46.9 MT',
                          label: '46.9 MT'
                        },
                      ]
                  })
                }
                if(this.state.actionListProduct.axle === '6x4'){
                  this.setState({
                    listCapacity: [
                        {
                          value: '12.9 MT',
                          label: '12.9 MT'
                        },
                        {
                          value: '13.7 MT',
                          label: '13.7 MT'
                        },
                        {
                          value: '29 MT',
                          label: '29 MT'
                        },
                        {
                          value: '35.1 MT',
                          label: '35.1 MT'
                        },
                        {
                          value: '46.9 MT',
                          label: '46.9 MT'
                        },
                      ]
                  })
                }
              }

            if (this.state.typeProduct === 'Fuel Lube Service') {
                if (this.state.actionListProduct.axle === '6x4' || this.state.actionListProduct.axle === '6x6') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '4KL+5P2L',
                                label: 'Standard SSB'
                            },
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '8x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '',
                                label: ''
                            }
                        ]
                    })
                }
                if (this.state.actionListProduct.axle === '4x4') {
                    this.setState({
                        listCapacity: [
                            {
                                value: '',
                                label: ''
                            }
                        ]
                    })
                }
            }

            if(this.state.typeProduct === 'Manhaul Bus'){
                if(this.state.actionListProduct.axle === '4x4'){
                  this.setState({
                    listCapacity: [
                      {
                        value: '33 Seat',
                        label: '33 Seat'
                      },
                      {
                        value: '35 Seat',
                        label: '35 Seat'
                      },
                      {
                        value: '40 Seat',
                        label: '40 Seat'
                      },
                      {
                        value: '51 Seat',
                        label: '51 Seat'
                      },
                      {
                        value: '55 Seat',
                        label: '55 Seat'
                      }
                    ]
                  })
                }
              }

        });
    }

    handletoStandardConfiguration = () => {
        this.props.history.push(
            "/customers/customer_standard_configuration_products"
        );
    };

    render() {
        const dataSimilarProduct = this.state.listSimilarProducts.map((products, index) => {
            if (products.product_id !== this.state.data.product_id) {
                return (
                    <Col key={products.product_id} className="col-lg-6 col-md-12 col-sm-12 col-12" >
                        <Card className="card-lift--hover shadow border-0">
                            <CardImg
                                alt="..."
                                src={config.BUCKET_URL + products.image_1}
                                top
                                style={{ cursor: "pointer" }}
                                onClick={() => this.handletoListProductsDetails(products)}
                            />
                            <CardBody>
                                <CardTitle className="h2 mb-0">
                                    {products.category_name} {products.tank_capacity} {products.chasis} {products.axle}
                                </CardTitle>
                                <small className="text-dark">
                                    Start from{" "}
                                    {currencyIDR(products.product_price).replace(/\.00$/, "")}
                                    <span className="text-red pe-auto" id={`tooltip3662586${index}`}>*</span>
                                    <UncontrolledTooltip delay={0} target={`tooltip3662586${index}`}>
                                        Prices are estimated based on configuration. the actual price may change after the requirement are fixed
                                    </UncontrolledTooltip>
                                </small>
                                <CardText className="description text-dark mb-0">
                                    {products.product_description.substring(0, 50) + "..."}
                                    <Button
                                        color="link"
                                        className="px-0"
                                        id={"UncontrolledPopover" + index}
                                        type="button"
                                        size="sm"
                                    >
                                        Read more
                                    </Button>
                                </CardText>
                                <small className="text-dark">
                                    Lead Time{" "}
                                    {products.lead_time}
                                </small>
                                <UncontrolledPopover
                                    placement="bottom"
                                    target={"UncontrolledPopover" + index}
                                >
                                    <PopoverBody>{products.product_description}</PopoverBody>
                                </UncontrolledPopover>
                                <div className="d-flex px-0 py-2 mt-2 bd-highlight">
                                    <Button
                                        className="text-uppercase"
                                        size="sm"
                                        color="primary"
                                        type="button"
                                        onClick={() =>
                                            window.open(
                                                config.BUCKET_URL +
                                                products.file_specifications,
                                                "_blank"
                                            )
                                        }
                                    >
                                        Brochure
                                    </Button>
                                    <Button
                                        className="text-uppercase"
                                        size="sm"
                                        color="primary"
                                        type="button"
                                        onClick={() => this.handletoListProductsDetails(products)}
                                    >
                                        Configure More
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )
            }
        })
        return (
            <>
                <Container className="bg-secondary mt--6">
                    <Breadcumbs
                        name="Configure Now"
                        parentName="Products"
                        link="/customers/main_products"
                    />
                    <Row className="flex-wrap-reverse">
                        <Col lg="8">
                            <Card className="card-lift--hover shadow border-0">
                                <CardBody>
                                    <div className="d-flex flex-wrap flex-md-nowrap flex-lg-nowrap flex-xl-nowrap">
                                        <div>
                                            <img
                                                alt="..."
                                                className="rounded"
                                                width="360"
                                                height="220"
                                                style={{ cursor: "pointer" }}
                                                src={config.BUCKET_URL + this.state.data.image_1}
                                                onClick={() => this.handletoListProductsDetails(this.state.data)}
                                            />
                                        </div>
                                        <div className="ml-2 mt-md-0 mt-lg-0 mt-xl-0 mt-sm-2 mt-2">
                                            <h2>
                                                {this.state.data.category_name} {this.state.data.tank_capacity} {this.state.data.chasis} {this.state.data.axle}
                                            </h2>
                                            <small className="text-dark">
                                                Start from{" "}
                                                {currencyIDR(this.state.data.product_price).replace(/\.00$/, "")}<span className="text-red pe-auto" id={`tooltip366258691`}>*</span>
                                                <UncontrolledTooltip delay={0} target={`tooltip366258691`}>
                                                    Prices are estimated based on configuration. the actual price may change after the requirement are fixed
                                                </UncontrolledTooltip>
                                            </small>
                                            <CardText className="description text-dark mb-0">
                                                {this.state.data.product_description}
                                            </CardText>
                                            <small className="text-dark">
                                                Lead Time{" "}
                                                {this.state.data.lead_time}
                                            </small>

                                        </div>
                                    </div>
                                    <div className="d-flex px-0 py-2 mt-2 bd-highlight">
                                        <Button
                                            className="text-uppercase"
                                            size="sm"
                                            color="primary"
                                            type="button"
                                            onClick={() =>
                                                window.open(
                                                    config.BUCKET_URL +
                                                    this.state.data.file_specifications,
                                                    "_blank"
                                                )
                                            }
                                        >
                                            Brochure
                                        </Button>
                                        <Button
                                            className="text-uppercase"
                                            size="sm"
                                            color="primary"
                                            type="button"
                                            onClick={() => this.handletoListProductsDetails(this.state.data)}
                                        >
                                            Configure More
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                            <Title name="Similar Products" />
                            <Row>
                                {dataSimilarProduct}
                            </Row>
                        </Col>
                        <Col lg="4" md="12" sm="12" className="align-items-right">
                            <Card className="bg-secondary border-0"  style={{ position: "sticky",top:100 }}>
                                <CardHeader className="pb-5t">
                                    <div className="text-muted text-center mt-2 mb-1">
                                        <h3>Select Our Product</h3>
                                    </div>
                                </CardHeader>
                                <CardBody className="px-lg-4 py-lg-4">
                                    <Row>
                                        <Col className="col-lg-12 col-md-12 col-sm-12 mb-3" xs="12">
                                            <label className="form-control-label">Attachment Type</label>
                                            <Form role="form">
                                                <AsyncSelect
                                                    cacheOptions
                                                    defaultOptions
                                                    styles={styleInputSelect}
                                                    value={this.state.selectedOptionCategories}
                                                    placeholder={<div>Select</div>}
                                                    loadOptions={this.fetchDataCategories}
                                                    onChange={(e) => {
                                                        this.onSearchChangeCategories(e);
                                                    }}
                                                />
                                            </Form>
                                            {this.state.paramError.category_id &&
                                                <small className="text-warning">{this.state.paramError.category_id}</small>
                                            }
                                        </Col>
                                        <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                                            <FormGroup>
                                                <label className="form-control-label">Chasis Brand
                                                    {
                                                        this.state.listChasis.length === 0 && <i className="fa fa-circle-o-notch fa-spin fa-fw ml-2" />
                                                    }
                                                </label>
                                                <Input
                                                    id="example-date-input"
                                                    type="select"
                                                    onKeyPress={this.keyPressed}
                                                    name="chasis"
                                                    placeholder="Chasis"
                                                    value={this.state.actionListProduct.chasis}
                                                    onChange={this.handleChangeChasis}
                                                    className={this.state.paramError.chasis ? "is-invalid" : ""}
                                                    disabled={this.state.listChasis.length === 0 ? true : false}
                                                >
                                                    <option value={""}>Select</option>
                                                    {this.state.listChasis.map((items, index) => {
                                                        return (
                                                            <option key={index} value={items.chasis}>{items.chasis}</option>
                                                        )
                                                    })
                                                    }
                                                </Input>
                                                <div className="invalid-feedback">
                                                    {this.state.paramError.chasis}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                                            <FormGroup>
                                                <label className="form-control-label">Axle Configuration
                                                    {
                                                        this.state.listAxle.length === 0 && <i className="fa fa-circle-o-notch fa-spin fa-fw ml-2" />
                                                    }
                                                </label>
                                                <Input
                                                    type="select"
                                                    onKeyPress={this.keyPressed}
                                                    name="axle"
                                                    placeholder="Axle"
                                                    value={this.state.actionListProduct.axle}
                                                    onChange={this.handleChangeAxle}
                                                    className={this.state.paramError.axle ? "is-invalid" : ""}
                                                    disabled={this.state.listAxle.length === 0 ? true : false}
                                                >
                                                    <option value={""}>Select</option>
                                                    {this.state.listAxle.map((items, index) => {
                                                        return (
                                                            <option key={index} value={items.axle}>{items.axle}</option>
                                                        )
                                                    })
                                                    }
                                                </Input>
                                                <div className="invalid-feedback">
                                                    {this.state.paramError.chasis}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                                            <FormGroup>
                                                <label className="form-control-label">Capacity
                                                    {
                                                        this.state.listCapacity.length === 0 && <i className="fa fa-circle-o-notch fa-spin fa-fw ml-2" />
                                                    }
                                                </label>
                                                <Input
                                                    id="example-date-input"
                                                    type="select"
                                                    onKeyPress={this.keyPressed}
                                                    name="tank_capacity"
                                                    placeholder="Tank Capacity"
                                                    value={this.state.actionListProduct.tank_capacity}
                                                    onChange={this.handleChangeString}
                                                    className={this.state.paramError.tank_capacity ? "is-invalid" : ""}
                                                    disabled={this.state.listCapacity.length === 0 ? true : false}
                                                >
                                                    <option value={""}>Select</option>
                                                    {this.state.listCapacity.map((items, index) => {
                                                        return (
                                                            <option key={index} value={items.value}>{items.label}</option>
                                                        )
                                                    })
                                                    }
                                                </Input>
                                                <div className="invalid-feedback">
                                                    {this.state.paramError.tank_capacity}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col
                                            style={{ marginTop: 15 }}
                                            className="col-12 col-md-12 col-sm-12"
                                            xs="12"
                                        >
                                            <div className="text-center">
                                                <Button
                                                    block
                                                    color="primary"
                                                    className="btn-icon text-uppercase"
                                                    type="button"
                                                    disabled={this.state.isLoading ? true : false}
                                                    onClick={() => this.getDataListProducts()}
                                                >
                                                    {this.state.isLoading ? (
                                                        <>
                                                            <span className="btn-inner--text">
                                                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                                                Configure Other Product
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <span className="btn-inner--text">Configure Other Product</span>
                                                    )}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            <Row className="mt-2 mb-3 text-center">
                                <Col>
                                    <span className="text-muted">
                                        Or you can{" "}
                                        <a
                                            href="#"
                                            onClick={() => this.handletoStandardConfiguration()}
                                        >
                                            <u>Browse All Products</u>
                                        </a>
                                    </span>
                                </Col>
                            </Row>
                            </Card>
                        </Col>
                    </Row>

                </Container>
                {this.state.alert}
            </>
        );
    }
}

export default connect()(ConfigureNow);
