import React from "react";

import {
  Row,
  Col,
  Container,
  Input,
  Card,
  Table
} from "reactstrap";
import images from "assets/img/document/background.png"
// import Title from "components/Breadcumbs/Title";


class Program extends React.Component {
  state={
    // img: {require('../../../assets/img/theme/bg-auth.PNG')}
  }
  render() {
    return (
      <>
      <section  className="section section-lg  mt--5 mx-auto">
        {/* <Container className="bg-primary" > */}
        <div className="h1 mx-8 my-5" style={{color: "#00aeef",fontSize: "28px"}}>Program</div>
        <Row className="mx-8 justify-content-center" style={{}}>
        <Col className="mx-auto text-center " md={5}>
          {/* <Card className="my-2 mx-2" width="560" height="315"> */}
          <iframe width="560" height="315" style={{borderRadius: "7px"}} src="https://www.youtube.com/embed/jlVTUS6sCZ8?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          {/* </Card> */}
        </Col>
        {/* <Col md={1}></Col> */}
        <Col className="mx-auto text-center " md={5}>
        <iframe width="560" height="315" style={{borderRadius: "7px"}} src="https://www.youtube.com/embed/b9CGPremlcA?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Col>
        </Row>
        {/* </Container> */}
      </section>
      <section  className="section section-lg  mt-3 mb-4 mx-auto">
        {/* <Container className="bg-primary" > */}
        <div className="h2 mx-8 mb-5" style={{color: "#00aeef", fontSize: "28px"}}>Special Offer</div>
        <Row className="mx-8 my-auto justify-content-center" style={{}}>
        {/* <Col className="text-center" sm={2} md={2} style={{backgroundColor: "#ddddde",borderRadius: "7px", height: "200px"}}>
        <div>
        <img
                  alt="..."
                  className="rounded"
                  width="360"
                  height="220"
                  style={{ cursor: "pointer", objectFit: 'cover'}}
                  src={images}
                  // onClick={() => this.handletoListProductsDetails(products)}
                />
              </div>
          <h2>  Promo 1</h2></Col> */}
        <Col className="h2 text-center">
        <img width="560" height="315" style={{borderRadius: "7px"}} src={require('../../../assets/img/theme/bg-auth.PNG')} 
        onClick={() =>this.previewImage(this.state.image_1)}
        />
        </Col>
        <Col className="h2 text-center">
        <img width="560" height="315" style={{borderRadius: "7px"}} src={require('../../../assets/img/theme/bg-auth-6.jpg')} 
        onClick={() =>this.previewImage(this.state.image_1)}
        />
        </Col>
      
        </Row>
        {/* </Container> */}
      </section>
      </>
    );
  }
}

export default Program;
