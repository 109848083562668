import React from "react";
import classnames from "classnames";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
// import ServiceRecord from "./PortofolioDetails/ServiceRecords";
import Breadcumbs from "components/Breadcumbs/Breadcumbs";
import { OBJModel, DirectionLight } from "react-3d-viewer";
// import SpecificationPortofolio from "./PortofolioDetails/SpecificationPortofolio";

class SparePartsDetail extends React.Component {

  // render() {
    // return (
    //   <>
    //     <Container className="mt--8">
    //         <div className="header-body text-center mb-4">
    //           <Row className="justify-content-center py-8">
    //             <Col className="px-8" lg="8" md="8" xl="8">
    //                 <i class="fas fa-cog fa-spin fa-8x"></i>
    //                 <h1 className="text-dark">This Section Under Development</h1>
    //                 <p className="h3 text-dark">Coming Soon</p>
    //             </Col>
    //           </Row>
    //         </div>
         
    //     </Container>
    //   </>
    // );
    handletoDetails = () => {
      this.props.history.push("/customers/portofolio_details");
    };
    state = {
      tabs: 1,
    };
    toggleNavs = (e, state, index) => {
      e.preventDefault();
      this.setState({
        [state]: index,
      });
    };
    render() {
      return (
        <>
          <Container className="mt--8">
              {/* <div className="header-body text-center mb-4">
                <Row className="justify-content-center py-8">
                  <Col className="px-8" lg="8" md="8" xl="8">
                      <i class="fas fa-cog fa-spin fa-8x"></i>
                      <h1 className="text-dark">This Section Under Development</h1>
                      <p className="h3 text-dark">Coming Soon</p>
                  </Col>
                </Row>
              </div> */}
              
              <Container className="bg-secondary mt--6">
          <Breadcumbs
            name="Portofolio Details"
            parentName="Portofolio"
            link="/customers/portofolio"
          />
          <Row>
            <Col>
              <Card className="bg-dark text-white border-0">
                <OBJModel
                  width="550"
                  src={require("assets/img/model/koenigsegg.obj")}
                  texPath=""
                  position={{ x: 0, y: -100, z: 0 }}
                >
                  <DirectionLight
                    color={0xffffff}
                    position={{ x: 100, y: 200, z: 100 }}
                  />
                  <DirectionLight
                    color={0x333444}
                    position={{ x: -100, y: 200, z: -100 }}
                  />
                </OBJModel>
                <CardFooter>
                  <div>
                    <CardTitle className="h2 text-dark mb-2">
                      Fuel Lube Service
                    </CardTitle>
                    <CardText className="text-dark">
                      This is a wider card with supporting text below as a natural
                      lead-in to additional content. This content is a little bit
                      longer.
                    </CardText>
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col>
          <div className="nav-wrapper mt--3">
            <Nav
              className="nav-fill flex-column flex-md-row"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={this.state.tabs === 1}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.tabs === 1,
                  })}
                  onClick={(e) => this.toggleNavs(e, "tabs", 1)}
                  href="#pablo"
                  role="tab"
                >
                  Specification
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.tabs === 2}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.tabs === 2,
                  })}
                  onClick={(e) => this.toggleNavs(e, "tabs", 2)}
                  href="#pablo"
                  role="tab"
                >
                  Service Record
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <Card className="shadow">
            <CardBody>
              <TabContent activeTab={"tabs" + this.state.tabs}>
                <TabPane tabId="tabs1">
                  {/* <SpecificationPortofolio /> */}
                </TabPane>
                <TabPane tabId="tabs2">
                  {/* <ServiceRecord /> */}
                </TabPane>
               
              </TabContent>
            </CardBody>
          </Card>
            </Col>
          </Row>
        </Container>
          </Container>
        </>
      );
    }
  
}

export default SparePartsDetail;
