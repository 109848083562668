import React, { PureComponent } from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import config from "services/config";
import { validateLogin } from "services/validate";
import axios from "axios";
import AuthHeader from "components/Headers/AuthHeader";
import SweetAlert from "react-bootstrap-sweetalert";
import styled from 'styled-components';
import { initGA, trackingPageGA, eventGA } from '../../services/reactGA';
import background from "assets/img/theme/bg-auth.PNG";
import background2 from "assets/img/theme/bg-auth-5.jpg";
import background3 from "assets/img/theme/bg-auth-6.jpg";
// import styled from "styled-components";
import styleLogin from "../../assets/css/custome/login.css";
const items = [
  {
    background
  },
  {
    background2
  },
  {
    background3
  },
];

let gOtp = Math.floor(1000 + Math.random() * 9000);

var jwt = require("jwt-simple");
var secret = "HS256 ";

const LogoWrapper = styled.div`
    img{
      width: 550px; 
      height: 95px; 
      margin-bottom: -70px;
      margin-left: auto; 
      margin-right: auto; 
      display: block;
      @media (max-width: 991.98px) {
        width: 550px; 
        height: 95px;
        margin-bottom: 20px;
      }
      @media (max-width: 575.98px){
        width: 320px; 
        height: 55px;
        margin-bottom: 20px;
      }
    }
`;

class Login extends PureComponent {
  state = {
    data: {
      email: "",
      password: "",
      otp: "",
    },

    authError: false,
    isLoading: false,
    paramError: {},
    token: null,
    alert: null,
  }
  componentDidMount(){
    initGA(); // Initialize google analytics
    trackingPageGA('/'); // = ReactGA.pageview (window.location.pathname);
    // this.handleChangeOTP = this.handleChangeOTP.bind(this);
    this.handleSubmitOTP = this.handleSubmitOTP.bind(this);
   
  }
  // timer(remaining) {
  //   var m = Math.floor(remaining / 60);
  //   var s = remaining % 60;
  //   m = m < 10 ? "0" + m : m;
  //   s = s < 10 ? "0" + s : s;
  //   document.getElementById("countdown").innerHTML = `Time left: ${m} : ${s}`;
  //   remaining -= 1;
  //   if (remaining >= 0 && timerOn) {
  //     setTimeout(function () {
  //       timer(remaining);
  //     }, 1000);
  //     document.getElementById("resend").innerHTML = `
  //     `;
  //     return;
  //   }
  //   if (!timerOn) {
  //     return;
  //   }
  //   document.getElementById("resend").innerHTML = `Don't receive the code? 
  //   <span class="font-weight-bold text-color cursor" onclick="timer(60)">Resend
  //   </span>`;
  // }
  handleChangeOTP(value1, event) {
    console.log( value1 + event.target.value)
    this.setState({
      data: {
        ...this.state.data, 
        [value1]: event.target.value 
      }
    });
  }

  handleSubmitOTP(event) {

    const data = new FormData(event.target);
    console.log(this.state);
    event.preventDefault();
  }

 
  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
        otp: gOtp
      },
    });
  };
  handleSubmit = () => {
    this.setState({ isLoading: true });
    const paramError = validateLogin(this.state.data);
    this.setState({ paramError });
    if (Object.keys(paramError).length === 0) {
      const headers = {
        "Content-Type": "application/json",
      };
      axios.post(config.API_URL + "/auth/login_customer_otp", this.state.data, headers).then(
        (res) => {
          this.setState({ isLoading: false });
          this.props.history.push("/auth/otp/" + res.data.data.id + '/' + res.data.data.user);
        },
        (err) => {
          this.setState({ authError: true, isLoading: false });
          this.failedAlert(err.response.data.message);
        }
      );
    } else {
      this.setState({ isLoading: false });
    }
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  keyUp = (event) => {
    event.preventDefault();
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      this.handleSubmit();
    }
  };
  // handleToCreateNewAccount = () => {
  //   this.props.history.push('/auth/create_new_account');
  // }
  cl = () => {
    this.props.history.push('/customerloyalti/customer-loyalti-dashboard');
  }

  // // carosale
  // onExiting() {
  //   this.animating = true;
  // }

  // onExited() {
  //   this.animating = false;
  // }

  // next() {
  //   if (this.animating) return;
  //   const nextIndex =
  //     this.state.activeIndex === items.length - 1
  //       ? 0
  //       : this.state.activeIndex + 1;
  //   this.setState({ activeIndex: nextIndex });
  // }

  // previous() {
  //   if (this.animating) return;
  //   const nextIndex =
  //     this.state.activeIndex === 0
  //       ? items.length - 1
  //       : this.state.activeIndex - 1;
  //   this.setState({ activeIndex: nextIndex });
  // }

  // goToIndex(newIndex) {
  //   if (this.animating) return;
  //   this.setState({ activeIndex: newIndex });  
  // }


  render() {
    // const paramError = this.state.paramError;
    // const { activeIndex } = this.state;
    {/* <img id="bgl" src={item.src} alt={item.altText} style={{styleLogin}}/> */}

    // const slides = items.map((item) => {
    //   console.log(item)
    //   return (
    //     // <CarouselItem
    //     //   onExiting={this.onExiting}
    //     //   onExited={this.onExited}
    //     //   key={item.src}
    //     // >
    //     // </CarouselItem>
    //     {item}
    //   );
    // });

    // return (
     
    // );

    const version = process.versions
    console.log("this"+ version)
    return (
      <>
        {/* <div style={{ height: `1000px` }}> */}
        <section>
        {/* <div 
        id="bgl" 
        style={{styleLogin}} 
        className="main-content" ref="mainContent"> */}
        <ul class="cb-slideshow" style={{styleLogin}}>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
       


        <Container className="py-7 login-home" style={{styleLogin}}>
        <LogoWrapper>
          <img
            alt="..."
            src={require("assets/img/brand/ssb_auth_white.png")}
          />
        </LogoWrapper>
        <AuthHeader title="" lead={`We provide one stop solutions for your operational’s needs.
Our expertise and proven result since 1977 has helped us built our reputation.
     `} />
          <Row className="justify-content-center" id="lgn">
            <Col lg="4" md="7">
              <Form role="form">
                <FormGroup>
                  <label className="form-control-label text-white float-left">Email</label>
                  <Input
                    type="email"
                    onKeyDown={this.handleEnter}
                    name="email"
                    placeholder="email"
                    value={this.state.data.email}
                    onChange={this.handleChange}
                    required
                    className={this.state.paramError.email ? "is-invalid" : ""}
                  />
                  <div className="invalid-feedback">
                    {this.state.paramError.email}
                  </div>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label text-white float-left">Password</label>
                  <Input
                    type="password"
                    onKeyDown={this.handleEnter}
                    name="password"
                    placeholder="Password"
                    value={this.state.data.password}
                    onChange={this.handleChange}
                    required
                    className={
                      this.state.paramError.password ? "is-invalid" : ""
                    }
                  />
                  <div className="invalid-feedback">
                    {this.state.paramError.password}
                  </div>
                </FormGroup>

               

                <div style={{ marginTop: 40 }} className="text-center">
                  <Button
                    block
                    className="text-uppercase my-4"
                    color="primary"
                    onClick={this.handleSubmit}
                    disabled={this.state.isLoading ? true : false}
                  >
                    {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Login
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Login</span>
                    )}
                  </Button>
                </div>
                {/* <Button
                 block
                 className="text-uppercase my-4"
                 color="primary"
                 onClick={this.cl}
                >
                Customer Loyalti
                </Button> */}

              </Form>
              <Row className="justify-content-center align-items-center mt-3">
                <Col className="text-center">
                  <a
                    className="text-white pe-auto"
                    href="mailto:customerservice@ptssb.co.id?subject=Request An Account"
                  >
                    <small>Don&prime;t have an account yet? <u>Contact Our Sales</u></small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        {this.state.alert}
        </ul>
      {/* </div> */}
      </section>
      </>
    );
  }
}

export default Login;
