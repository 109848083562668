/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import { Button, FormGroup, Form, Container, Row, Col } from "reactstrap";
import config from "services/config";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
// import styled from "styled-components";
// import { initGA, trackingPageGA, eventGA } from "../../services/reactGA";
var jwt = require("jwt-simple");
var secret = "HS256 ";
var gOtp = Math.floor(1000 + Math.random() * 9000);
// const LogoWrapper = styled.div`
//   img {
//     width: 550px;
//     height: 95px;
//     margin-bottom: -70px;
//     margin-left: auto;
//     margin-right: auto;
//     margin-top: 130px;
//     display: block;
//     @media (max-width: 991.98px) {
//       width: 550px;
//       height: 95px;
//       margin-bottom: 20px;
//     }
//     @media (max-width: 575.98px) {
//       width: 320px;
//       height: 55px;
//       margin-bottom: 20px;
//     }
//   }
//   `;
class LoginOTP extends PureComponent {
  state = {
    listVerification: false,
    setTimer: true,
    setResend: false,
    timerLeft: "",
    matchId: "",
    verified: false,
    timer: {
      m: "",
      s: "",
    },
    resend: {
      id: "",
      otp: "",
      user: "",
      setRes: false,
    },
    data: {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
    },
    datas: {
      combineOTP: "",
      id: "",
      user: "",
    },
    countDown: "",
    authError: false,
    isLoading: false,
    paramError: {},
    token: null,
    alert: null,
  };
  componentDidMount() {
    this.mounted = true;
    if (!this.props.match.params.Id && !this.props.match.params.Userid) {
      this.props.history.push("/auth/login");
    }
    this.handleTimer = this.handleTimer.bind(this);
    // var next;
    var dataRem = JSON.parse(localStorage.getItem("verified")) || false;
    this.setState(
      {
        verified: dataRem,
      },
      () =>
        this.state.verified
          ? (window.onload = () => {
              var sec = parseInt(window.localStorage.getItem("seconds"));
              var min = parseInt(window.localStorage.getItem("minutes"));
              var locRem = parseInt(min * 60) + sec;
              var play;
              if (locRem > 1) {
                play = locRem;
              } else {
                play = 500;
              }
              return this.handleTimer(play);
            })
          : this.handleVerification()
    );
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // BU
  // var setResend = this.state.resend.setRes
  // console.log(setResend)

  //   function startTimer(duration, display) {
  //     var timer = duration, minutes, seconds;
  //     setInterval(function () {
  //         minutes = parseInt(timer / 60, 10)
  //         seconds = parseInt(timer % 60, 10);

  //         minutes = minutes < 10 ? "0" + minutes : minutes;
  //         seconds = seconds < 10 ? "0" + seconds : seconds;
  //         // cek = parseInt(minutes*60)+seconds
  //         if (setResend){
  //           display.textContent = "cekkkk";
  //         }else{
  //           display.textContent = minutes + " : " + seconds;
  //         }

  //         if (--timer < 0) {
  //             timer = duration;
  //         }
  //         window.localStorage.setItem("seconds",seconds)
  //         window.localStorage.setItem("minutes",minutes)
  //         window.localStorage.setItem("integerRM", parseInt(minutes*60)+seconds)
  //       }, 1000);
  // }
  // window.onload = function () {
  //   var sec  = parseInt(window.localStorage.getItem("seconds"))
  //   var min = parseInt(window.localStorage.getItem("minutes"))
  //   var twoMinutes;
  //   if (setResend === true){
  //     twoMinutes = 60 * 2;
  //   } else {
  //     if(min || sec){
  //       twoMinutes = (parseInt(min*60)+sec);
  //     }else{
  //       twoMinutes = 40;
  //     }
  //   }
  //   var display = document.querySelector('#timer');
  //   startTimer(twoMinutes, display);
  // };

  handleVerification = () => {
    axios
      .post(
        config.API_URL + "/auth/login_customer_otp_verification",
        {
          ...this.state.resend,
          id: this.props.match.params.Id,
          user: this.props.match.params.Userid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          var matchId = res.data.data.cek;
          this.setState({ matchId });
          if (matchId === 0) {
            this.props.history.push("/auth/login");
          }
          if (matchId === 1) {
            this.handleTimer(119);
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handleTimer = (e) => {
    if (e === 500) {
      this.setState(
        { setResend: true },
        () => (document.getElementById("timer").innerHTML = "00 : 00")
      );
    } else {
      timer(e);
      function timer(remaining) {
        var m = Math.floor(remaining / 60);
        var s = remaining % 60;
        m = m < 10 ? "0" + m : m;
        s = s < 10 ? "0" + s : s;

        remaining -= 1;
        document.getElementById("timer").innerHTML = m + " : " + s;
        if (remaining >= 0) {
          setTimeout(function () {
            timer(remaining);
            window.localStorage.setItem("verified", true);
            window.localStorage.setItem("minutes", m);
            window.localStorage.setItem("seconds", s);
          }, 1000);
          return;
        }
      }
    }
  };
  // handleRemaining=()=>{
  //    window.onload = function () {
  //         var sec  = parseInt(window.localStorage.getItem("seconds"))
  //         var min = parseInt(window.localStorage.getItem("minutes"))
  //         var play
  //           if(min || sec){
  //             play =  (parseInt(min*60)+sec);
  //           }
  //         // this.handleTimer(play);
  //         console.log(play)
  //         }
  //    }

  // timer = (remaining) => {
  //   var m = Math.floor(remaining / 60);
  //   var s = remaining % 60;
  //   m = m < 10 ? '0' + m : m;
  //   s = s < 10 ? '0' + s : s;
  //   // setState({timerLeft: m + ' : ' + s})
  //   document.getElementById('timer').innerHTML = m + ' : ' + s;
  //   remaining -= 1;
  //   if(remaining >= 0 ) {
  //         setTimeout(function() {
  //             timer(remaining);
  //             // console.log( m + ' : ' + s)
  //             window.localStorage.setItem("minutes",m)
  //             window.localStorage.setItem("seconds",s)
  //       }, 1000);

  //       return
  //       // (
  //   }
  // }

  resend = () => {
    this.setState(
      { isLoading: true, resend: { otp: gOtp, setRes: true } },
      () =>
        axios
          .post(
            config.API_URL + "/auth/login_customer_otp_verification",
            {
              ...this.state.resend,
              id: this.props.match.params.Id,
              user: this.props.match.params.Userid,
              otp: gOtp,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: config.TOKEN,
              },
            }
          )
          .then(
            (res) => {
              this.successAlert(res.data.message);
              if (this.state.setResend) {
                this.handleTimer(119);
              }
            },
            (err) => {
              console.log("error: ", err);
            }
          )
    );
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.handleReset()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Close"
          btnSize=""
        >
          Please check email <br />
          Otp has been sent successfully
        </SweetAlert>
      ),
    });
  };
  handleReset = () => {
    localStorage.removeItem("verified");
    localStorage.removeItem("minutes");
    localStorage.removeItem("seconds");
    window.location.reload();
  };
  handleSubmitOTP = () => {
    this.setState(
      {
        ...this.state,
        isLoading: true,
        datas: {
          ...this.state.datas,
          id: this.props.match.params.Id,
          user: this.props.match.params.Userid,
          combineOTP:
            this.state.data.otp1 +
            this.state.data.otp2 +
            this.state.data.otp3 +
            this.state.data.otp4,
        },
      },
      () => {
        const headers = {
          "Content-Type": "application/json",
        };
        axios
          .post(
            config.API_URL + "/auth/login_customer",
            this.state.datas,
            headers
          )
          .then(
            (res) => {
              localStorage.removeItem("verified");
              localStorage.removeItem("minutes");
              localStorage.removeItem("seconds");
              localStorage.setItem("token", jwt.encode(res.data.data, secret));
              localStorage.setItem("refresh_admin", "true");
              this.setState({ isLoading: false });
              this.props.history.push("/customers/main_products");
            },
            (err) => {
              this.setState({ authError: true, isLoading: false });
              this.failedAlert(err.response.data.message);
            }
          );
      }
    );
  };

  inputfocus = (elmnt) => {
    // console.log(elmnt);
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 4) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };
  handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  // handleChange = (event) => {
  //   this.setState({
  //     data: {
  //       ...this.state.data,
  //       [event.target.name]: event.target.value,
  //     },
  //   });
  // };
  handleChangeOTP = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  failedAlert = (data) => {
    // console.log(data)
    this.setState({
      alert: data,
      // (
      //     {data}

      // <SweetAlert
      //   danger
      //   style={{ display: "block" }}
      //   title="Failed"
      //   onConfirm={() => this.hideAlert()}
      //   onCancel={() => this.hideAlert()}
      //   confirmBtnBsStyle="danger"
      //   confirmBtnText="Ok"
      //   btnSize=""
      // >
      // </SweetAlert>
      // ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  keyUp = (event) => {
    event.preventDefault();
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      this.handleSubmit();
    }
  };
  //  handlePaste(e) {
  //     var clipboardData, pastedData;

  //     // Stop data actually being pasted into div
  //     e.stopPropagation();
  //     e.preventDefault();

  //     // Get pasted data via clipboard API
  //     clipboardData = e.clipboardData || window.clipboardData;
  //     pastedData = clipboardData.getData('Text');

  //     // Do whatever with pasteddata
  //     alert(pastedData);
  //     console.log(pastedData)

  //   }

  // Listen to paste on the document
  // document.addEventListener("paste", function(e) {
  //   // if the target is a text input
  //   if (e.target.type === "text") {
  //    var data = e.clipboardData.getData('Text');
  //    // split clipboard text into single characters
  //    data = data.split('');
  //    // find all other text inputs
  //    [].forEach.call(document.querySelectorAll("input[type=text]"), (node, index) => {
  //       // And set input value to the relative character
  //       node.value = data[index];
  //     });
  //   }
  // });

  render() {
    // const paramError = this.state.paramError;
    return (
      <Container className="my-9">
        <Row className="justify-content-center">
          <Col lg="4" md="7">
            <Form role="form">
              <h1 id="data"></h1>
              <FormGroup className="">
                <p className="h1 text-center">OTP VERIFICATION</p>
              </FormGroup>
              <FormGroup>
                <p className="h3 text-center">
                  Enter the OTP sent on your email
                </p>
              </FormGroup>
              <FormGroup>
                <p className="h3 text-center text-muted">Time left</p>
              </FormGroup>
              <FormGroup>
                {/* <h1> m: {this.state.time.m} s: {this.state.time.s}</h1> */}
                <div
                  id="timer"
                  className="text-center"
                  style={{ fontSize: "32px" }}
                ></div>
                <div
                  id="cek"
                  className="text-center"
                  style={{ fontSize: "32px" }}
                ></div>
              </FormGroup>
              {this.state.setResend ? (
                <>
                  <FormGroup>
                    <div className="text-center">
                      <h1
                        style={{
                          // padding: 2rem;
                          // border: 1px solid #ebebeb;
                          // border-radius: 0.25rem;
                          // margin: 0.5rem;
                          // backgroundColor: "#ececec",
                          // boxShadow: "10px 10px #cfc9c9",
                          fontSize: "2.5rem",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        TIMEOUT <br />
                        OTP HAS EXPIRED
                      </h1>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="text-muted text-center">
                      <u>
                        <a
                          style={{ cursor: "pointer", objectFit: "cover" }}
                          className="font-weight-bold text-color"
                          onClick={this.resend}
                        >
                          Resend
                        </a>
                      </u>
                    </div>
                  </FormGroup>
                </>
              ) : (
                <>
                  <FormGroup className="mb-6">
                    <div className="input-container d-flex flex-row justify-content-center mt-2">
                      <input
                        className="otp-input m-2 text-center form-control form-control-solid rounded"
                        style={{
                          width: "62px",
                          height: "62px",
                          borderWidth: "thick",
                          fontWeight: "900",
                          fontSize: "22px",
                          boxShadow: "0.15s",
                          borderColor: this.state.alert ? "#d9534f" : null,
                        }}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]"
                        name="otp1"
                        autoComplete="one-time-code"
                        maxLength={1}
                        tabIndex="1"
                        value={this.state.data.otp1}
                        onKeyPress={this.keyPressed}
                        onChange={this.handleChangeOTP}
                        // onChange={(e) => this.handleChangeOTP("otp1", e)}
                        onKeyUp={(e) => this.inputfocus(e)}
                      />
                      <input
                        className="otp-input m-2 text-center form-control form-control-solid rounded"
                        style={{
                          width: "62px",
                          height: "62px",
                          borderWidth: "thick",
                          fontWeight: "900",
                          fontSize: "22px",
                          boxShadow: "0.15s",
                          borderColor: this.state.alert ? "#d9534f" : null,
                        }}
                        type="text"
                        pattern="[0-9]"
                        inputMode="numeric"
                        name="otp2"
                        autoComplete="one-time-code"
                        maxLength={1}
                        tabIndex="2"
                        value={this.state.data.otp2}
                        onKeyPress={this.keyPressed}
                        onChange={this.handleChangeOTP}
                        // onChange={(e) => this.handleChangeOTP("otp2", e)}
                        onKeyUp={(e) => this.inputfocus(e)}
                      />
                      <input
                        className="otp-input m-2 text-center form-control form-control-solid rounded"
                        style={{
                          width: "62px",
                          height: "62px",
                          borderWidth: "thick",
                          fontWeight: "900",
                          fontSize: "22px",
                          boxShadow: "0.15s",
                          borderColor: this.state.alert ? "#d9534f" : null,
                        }}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]"
                        name="otp3"
                        autoComplete="one-time-code"
                        maxLength={1}
                        tabIndex="3"
                        value={this.state.data.otp3}
                        onKeyPress={this.keyPressed}
                        onChange={this.handleChangeOTP}
                        // onChange={(e) => this.handleChangeOTP("otp3", e)}
                        onKeyUp={(e) => this.inputfocus(e)}
                      />
                      <input
                        className="otp-input m-2 text-center form-control form-control-solid rounded"
                        style={{
                          width: "62px",
                          height: "62px",
                          borderWidth: "thick",
                          fontWeight: "900",
                          fontSize: "22px",
                          boxShadow: "0.15s",
                          borderColor: this.state.alert ? "#d9534f" : null,
                        }}
                        type="text"
                        pattern="[0-9]"
                        inputMode="numeric"
                        name="otp4"
                        autoComplete="one-time-code"
                        maxLength={1}
                        tabIndex="4"
                        value={this.state.data.otp4}
                        onKeyPress={this.keyPressed}
                        // onChange={(e) => this.handleChangeOTP("otp4", e)}
                        onChange={this.handleChangeOTP}
                        onKeyUp={(e) => this.inputfocus(e)}
                      />
                    </div>
                    <FormGroup>
                      <Button
                        block
                        className="text-uppercase mt-5"
                        color="primary"
                        onClick={this.handleSubmitOTP}
                        disabled={this.state.isLoading ? true : false}
                      >
                        {this.state.isLoading ? (
                          <>
                            <span className="btn-inner--text">
                              <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                              Verify
                            </span>
                          </>
                        ) : (
                          <span id="editableDiv" className="btn-inner--text">
                            Verify
                          </span>
                        )}
                      </Button>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup>
                    <p className="h3 text-center text-muted">
                      Don't receive the code?&nbsp;
                      <u>
                        <a
                          style={{ cursor: "pointer", objectFit: "cover" }}
                          className="font-weight-bold text-color"
                          onClick={this.resend}
                        >
                          Resend
                        </a>
                      </u>
                    </p>
                  </FormGroup>
                </>
              )}
            </Form>
          </Col>
        </Row>
        {this.state.alert}
      </Container>
      // </div>
    );
  }
}

export default LoginOTP;
