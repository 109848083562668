import { 
    GET_DATA_QUOTATIONS
 } from "./quotationActions";

 const initialState = {
    dataQuotations: [],
  };

  export default function quotationReducer(state = initialState, action){
      switch(action.type){
          case GET_DATA_QUOTATIONS:
              return {
                  ...state,
                  dataQuotations: action.payload.quotation
              };
        
        default:
            return state;
      }
  }