import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, CardHeader, Alert, UncontrolledTooltip } from "reactstrap";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const ListCompanySales = (props) => {
  const info = props.info;
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="company_id"
          columns={[
            {
              dataField: "company_name",
              text: "Company Name",
              sort: true,
            },
            {
              dataField: "company_phone",
              text: "Company Phone",
              sort: true,
            },
            {
              dataField: "company_address",
              text: "Company Address",
              sort: true,
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                <h3 className="mb-0">List {info} </h3>
                <p className="text-sm mb-0"> </p>
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>Data not found!</strong> Please check again!
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ListCompanySales;
