import React, { PureComponent } from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import config from "services/config";
import { validateLogin } from "services/validate";
import axios from "axios";
import AuthHeader from "components/Headers/AuthHeader";
import SweetAlert from "react-bootstrap-sweetalert";

var jwt = require("jwt-simple");
var secret = "HS256 ";

class LoginAdmin extends PureComponent {
  state = {
    data: {
      email: "",
      password: "",
    },

    authError: false,
    isLoading: false,
    paramError: {},
    token: null,
    alert: null,
  };
  handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }
  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  handleSubmit = () => {
    this.setState({ isLoading: true });
    const paramError = validateLogin(this.state.data);
    this.setState({ paramError });
    if (Object.keys(paramError).length === 0) {
      const headers = {
        "Content-Type": "application/json",
      };

      axios.post(config.API_URL + "/auth/login_admin", this.state.data, headers).then(
        (res) => {
          localStorage.setItem("token", jwt.encode(res.data.data, secret));
          localStorage.setItem("refresh_admin", "true");
          this.setState({ isLoading: false });
          this.props.history.push("/admin");
        },
        (err) => {
          this.setState({ authError: true, isLoading: false });
          this.failedAlert("Invalid Credentials");
        }
      );
    } else {
      this.setState({ isLoading: false });
    }
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  keyUp = (event) => {
    event.preventDefault();
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      this.handleSubmit();
    }
  };
  render() {
    const paramError = this.state.paramError;
    return (
      <div style={{ height: `1000px` }}>
        <AuthHeader title="ADMIN LOGIN" lead="" />
        <Container className="my-auto pb-5">
          <Row className="justify-content-center">
            <Col lg="4" md="7">
              <Form role="form">
                <FormGroup>
                  <label className="form-control-label text-white">Email</label>
                  <Input
                    type="email"
                    onKeyDown={this.handleEnter}
                    name="email"
                    placeholder="email"
                    value={this.state.data.email}
                    onChange={this.handleChange}
                    required
                    className={this.state.paramError.email ? "is-invalid" : ""}
                  />
                  <div className="invalid-feedback">
                    {this.state.paramError.email}
                  </div>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label text-white">Password</label>
                  <Input
                    type="password"
                    onKeyDown={this.handleEnter}
                    name="password"
                    placeholder="Password"
                    value={this.state.data.password}
                    onChange={this.handleChange}
                    required
                    className={
                      this.state.paramError.password ? "is-invalid" : ""
                    }
                  />
                  <div className="invalid-feedback">
                    {this.state.paramError.password}
                  </div>
                </FormGroup>
                <div style={{ marginTop: 40 }} className="text-center">
                  <Button
                    block
                    className="text-uppercase my-4"
                    color="primary"
                    onClick={this.handleSubmit}
                    disabled={this.state.isLoading ? true : false}
                  >
                    {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Login
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Login</span>
                    )}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
        {this.state.alert}
      </div>
    );
  }
}

export default LoginAdmin;
