import React from "react";

import { Row, Col, Container, Input, Card } from "reactstrap";
import background from "assets/img/program/home.png";
import config from "services/config";
import axios from "axios";

// import Title from "components/Breadcumbs/Title";
class CustomerLoyaltiDashboard extends React.Component {
  state = {
    pointInfo: [],
    vendor: config.COMPANY_ID,
  };
  componentDidMount() {
    this.mounted = true;
    this.getDatapointInfo();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getDatapointInfo = () => {
    axios
      .post(
        config.API_URL + "/customer_loyalti/point_info",
        { id: this.state.vendor },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              pointInfo: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  render() {
    return (
      <>
        {/* <Container className="mt-8 bg-primary"  > */}
        <div
          style={{
            backgroundImage: `url(${background})`,
            height: `900px`,
            width: `100%`,
            backgroundPosition: `center`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `cover`,
          }}
          className="main-content mt--5 mb--8"
          ref="mainContent"
        >
          <section>
            <Row className=" pt-6 pr-8 text-right">
              <Col sm={12}>
                <span className="h2 text-white d-inline-block mb-0 text-right">
                  <i>Hi, PT Cakra Jawara</i>
                </span>
              </Col>
            </Row>
            <Row className=" pt-2 px-8 text-right">
              <Col sm={12}>
                <span className="h2 text-white d-inline-block mb-0 text-right">
                  Platinum Member
                </span>
              </Col>
            </Row>
            <Row className="pt-9 px-3">
              <Col md={4} xs={12}>
                <Row>
                  <Col xs={12} className="px-8 ">
                    <span className="h2 text-white d-inline-block mb-0">
                      Your point
                    </span>
                  </Col>
                  <Col xs={12} className="px-8 ">
                    <div
                      className="d-inline-block mt-4 text-center text-white"
                      style={{
                        border: "2px solid #FFFFFF",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "52px",
                          width: "270px",
                          borderWidth: "medium",
                          borderColor: "white",
                        }}
                      >
                        545.900
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} className="px-8 mt-4">
                    <Row>
                    <Col className="pt-2" md="9">
                    <div className="h2 text-white d-inline-block mb-0">
                      Valid until december 2022
                    </div>
                    </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col md={8} xs={12} className="text-right pt-2">
                <Row>
              <Col xs={12}>
              <div
                  className="text-white d-inline-block mb-0"
                  style={{ fontSize: "82px" }}
                  >
                  Welcome to SSB Rewards
                </div>
                    </Col>
                    <Col xs={12}>
                <span className=" text-right h1 text-white d-inline-block">
                  Our customer loyalty program that gets you exclusive perks for
                  transactions
                </span>
              </Col>
                  </Row>
              </Col>

            </Row>
            {/* <Row className=" px-8">
              <Col className="text-right pt-2" md="9">
                <div
                  className="text-white d-inline-block mb-0"
                  style={{ fontSize: "82px" }}
                >
                  Welcome to SSB Rewards
                </div>
              </Col>
            </Row>
            <Row className=" px-8 pb-9">
              <Col md="6" xl="4" className="text-left">
                <div className="h2 text-white d-inline-block mb-0">
                  Valid until december 2022
                </div>
              </Col>
              <Col className="text-right" md="8">
                <span className="h1 text-white d-inline-block">
                  Our customer loyalty program that gets you exclusive perks for
                  transactions
                </span>
              </Col>
            </Row> */}
          </section>
        </div>

        {/* </Container> */}
      </>
    );
  }
}

export default CustomerLoyaltiDashboard;
