import { 
    GET_DATA_CATEGORIES
 } from "./categoryActions";

 const initialState = {
    dataCategories: [],
  };

  export default function categoryReducer(state = initialState, action){
      switch(action.type){
          case GET_DATA_CATEGORIES:
              return {
                  ...state,
                  dataCategories: action.payload.category
              };
        
        default:
            return state;
      }
  }