import React from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Button,
  PopoverBody,
  UncontrolledPopover,
  FormGroup,
  Input,
  Badge,
} from "reactstrap";
import Breadcumbs from "components/Breadcumbs/Breadcumbs";
import config from "services/config";
import { currencyIDR } from "services/currency";
import { connect } from "react-redux";
import { getDataCustomerProductsDetails } from "../Redux/customerProductActions";
// import { getNumberCart } from "services/globalRedux/globalReduxActions";
import axios from "axios";
import Pagination from "react-bootstrap-pagination-logic";
import Title from "components/Breadcumbs/Title";
class CustomerListProducts extends React.Component {
  state = {
    actionListProduct: {
      page: 1,
      items_per_page: 9,
      order_by_field: "product_id",
      order_by_direction: "DESC",
      category_id: 0,
      product_no: "",
      product_type: "",
      chasis: "",
      axle: "",
      tank_capacity: "",
    },

    listProduct: [],
    total_pages: 0,
    total_items: 0,
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
  };
  componentDidMount() {
    this.mounted = true;
    this.setState(
      {
        actionListProduct: {
          ...this.state.actionListProduct,
          category_id: this.props.dataCustomerProducts.category_id,
        },
      },
      () => {
        this.getDataListProducts();
      }
    );
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getDataListProducts = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/customer_list_products/customer_list_products",
        this.state.actionListProduct,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            console.log(res.data.data);
            this.setState(
              {
                listProduct: res.data.data,
                total_pages: res.data.total_pages,
                total_items: res.data.total_items,
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handletoListProductsDetails = (data) => {
    this.props.dispatch(getDataCustomerProductsDetails(data));
    this.props.history.push("/customers/customer_products_details");
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleChangeString = (event) => {
    this.setState({
      actionListProduct: {
        ...this.state.actionListProduct,
        [event.target.name]: event.target.value,
      },
    });
  };
  handleReset = () => {
    this.setState(
      {
        actionListProduct: {
          ...this.state.actionListProduct,
          page: 1,
          items_per_page: 6,
          order_by_field: "product_id",
          order_by_direction: "DESC",
          product_no: "",
          product_type: "",
          chasis: "",
          axle: "",
          tank_capacity: "",
        },
      },
      () => {
        this.getDataListProducts();
      }
    );
  };
  handlePagination = (numPage) => {
    this.setState(
      {
        actionListProduct: {
          ...this.state.actionListProduct,
          page: numPage,
        },
      },
      () => {
        this.getDataListProducts();
      }
    );
  };
  handleToFilterComponent = () => {
    var elmnt = document.getElementById("filter_component");
    elmnt.scrollIntoView({ block: "start", behavior: "smooth" });
  };
  // handleAddToCart = (data_) => {
  //   var data = [];
  //   data = JSON.parse(localStorage.getItem('cart')) || [];
  //   data.push({
  //     ...data_,
  //     quantity: 1
  //   });
  //   localStorage.setItem('cart', JSON.stringify(data));
  //   this.props.dispatch(getNumberCart(data.length))
  // }
  render() {
    const dataProduct = this.state.listProduct.map((products, index) => {
      return (
        <Col
          key={products.product_id}
          className="col-lg-4 col-md-6 col-sm-12"
          xs="12"
        >
          <Card className="card-lift--hover shadow border-0">
            <CardImg
              alt="..."
              src={config.BUCKET_URL + products.image_1}
              top
              style={{ cursor: "pointer" }}
              onClick={() => this.handletoListProductsDetails(products)}
            />
            <CardBody
            // onClick={() => this.handletoListProducts(products)}
            >
              <CardTitle className="h2 mb-0">
                {products.chasis} {products.tank_capacity} {products.axle}
              </CardTitle>
              <small className="text-muted">
                As low as{" "}
                {currencyIDR(products.product_price).replace(/\.00$/, "")}
              </small>
              <CardText className="description text-dark">
                {products.product_description.substring(0, 50) + "..."}
                <Button
                  color="link"
                  className="px-0"
                  id={"UncontrolledPopover" + index}
                  type="button"
                  size="sm"
                >
                  Read more
                </Button>
              </CardText>
              <div className="mb-2">
                <Badge color="primary" pill>
                  {products.product_type}
                </Badge>
              </div>
              <div>
                {/* <Button
                  className="btn-second text-uppercase"
                  size="sm"
                  type="button"
                  onClick={() => this.handleAddToCart(products)}
                >
                  Add to cart
                </Button> */}
                <Button
                  className="btn-second text-uppercase"
                  size="sm"
                  type="button"
                  onClick={() => this.handletoListProductsDetails(products)}
                >
                  Details
                </Button>
              </div>
              <UncontrolledPopover
                placement="bottom"
                target={"UncontrolledPopover" + index}
              >
                <PopoverBody>{products.product_description}</PopoverBody>
              </UncontrolledPopover>
            </CardBody>
          </Card>
        </Col>
      );
    });
    return (
      <>
        <Container className="bg-secondary mt--6">
          <Breadcumbs
            name={this.props.dataCustomerProducts.category_name}
            parentName="Products"
            link="/customers/main_products"
          />
          <Row className="justify-content-center">
            <Col>
              <Card className="bg-dark text-white border-0">
                <CardImg
                  alt="..."
                  src={
                    config.BUCKET_URL+
                    this.props.dataCustomerProducts.image_1
                  }
                />
              </Card>
            </Col>
            <Col className="text-left" lg="4">
              <h2 className="display-3">
                {this.props.dataCustomerProducts.category_name}
              </h2>
              <p className="lead">
                {this.props.dataCustomerProducts.category_description}
              </p>
            </Col>
          </Row>

          <Title name="Standard Configs" />
          <Row>
            {this.state.isFilterLoading ? (
              <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                <Card className="card-frame">
                  <CardBody>
                    <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                    <strong>Please wait</strong>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <>
                <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                  <FormGroup>
                    <Input
                      id="example-date-input"
                      type="text"
                      onKeyPress={this.keyPressed}
                      name="chasis"
                      placeholder="Chasis"
                      value={this.state.actionListProduct.chasis}
                      onChange={this.handleChangeString}
                    />
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                  <FormGroup>
                    <Input
                      id="example-date-input"
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="axle"
                      placeholder="Axle"
                      value={this.state.actionListProduct.axle}
                      onChange={this.handleChangeString}
                    >
                      <option>Select Axle</option>
                      <option value={"6x4"}>6x4</option>
                      <option value={"6x6"}>6x6</option>
                      <option value={"8x4"}>8x4</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                  <FormGroup>
                    <Input
                      id="example-date-input"
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="tank_capacity"
                      placeholder="Tank Capacity"
                      value={this.state.actionListProduct.tank_capacity}
                      onChange={this.handleChangeString}
                    >
                      <option>Select Tank Capacity</option>
                      <option value={"32KL"}>32KL</option>
                      <option value={"30KL"}>30KL</option>
                      <option value={"20KL"}>20KL</option>
                      <option value={"25KL"}>25KL</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                  <FormGroup>
                    <Input
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="product_type"
                      placeholder="Product Type"
                      value={this.state.actionListProduct.product_type}
                      onChange={this.handleChangeString}
                    >
                      <option value="">Select Type</option>
                      <option value="Transport">Transport</option>
                      <option value="Support Equipment">
                        Support Equipment
                      </option>
                      <option value="Mining">Mining</option>
                      <option value="Material Handling">
                        Material Handling
                      </option>
                      <option value="Logistics">Logistics</option>
                    </Input>
                  </FormGroup>
                </Col>
              </>
            )}
            <Col className="mb-4">
              {this.state.isFilterLoading ? null : (
                <div id="filter_component">
                  <Button
                    onClick={() => this.getDataListProducts()}
                    className="btn-second text-uppercase"
                    type="button"
                  >
                    Filter
                  </Button>
                  <Button
                    onClick={() => this.handleReset()}
                    className="btn-second text-uppercase"
                    type="button"
                  >
                    Reset
                  </Button>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            {this.state.listProduct.length == 0 ? (
              <Col md="4" xs="6">
                <Card className="card-frame">
                  <CardBody>
                    <i className="fa fa-meh-o mr-2" />
                    <strong>Products not found</strong>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              dataProduct
            )}
          </Row>
          <Row className="justify-content-md-center">
            <Col>
              <Pagination
                current_page={this.state.actionListProduct.page}
                last_page={this.state.total_pages}
                position="center"
                handlePageChange={this.handlePagination}
              />
            </Col>
          </Row>
          <a
            href="#"
            style={{
              position: "fixed",
              width: "60px",
              height: "60px",
              bottom: "2px",
              right: "230px",
            }}
          >
            <Button
              onClick={() => this.handleToFilterComponent()}
              style={{borderRadius: 50}}
              className="btn-second text-uppercase"
              type="button"
              size="md"
            >
              Filter
            </Button>
          </a>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataCustomerProducts: state.customer_product.dataCustomerProducts,
  };
};

export default connect(mapStateToProps)(CustomerListProducts);
