import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

// reactstrap components
import { Card, CardHeader, Alert, UncontrolledTooltip } from "reactstrap";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const ListStandardComponents = (props) => {
  const info = props.info;
  const actionsFormatter = (cell, row) => {
    return (
      <>
        <a
          className="table-action"
          id={"tooltip12312"}
          style={{ cursor: "pointer" }}
          onClick={() => props.update(row)}
        >
          <i className="fas fa-user-edit" />
        </a>
        <UncontrolledTooltip delay={0} target={"tooltip12312"}>
          Update
        </UncontrolledTooltip>
        <a
          className="table-action table-action-delete"
          id={"tooltip12313"}
          style={{ cursor: "pointer" }}
          onClick={() => props.delete(row)}
        >
          <i className="fas fa-trash" />
        </a>
        <UncontrolledTooltip delay={0} target={"tooltip12313"}>
          Remove
        </UncontrolledTooltip>
      </>
    );
  };
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="standard_component_id"
          columns={[
            {
              dataField: "standard_component_no",
              text: "No",
              sort: true,
            },
            {
              dataField: "standard_component_name",
              text: "Name",
              sort: true,
            },
            {
              dataField: "standard_component_description",
              text: "Description",
              sort: true,
            },
            {
              text: "Actions",
              formatter: actionsFormatter,
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                <h3 className="mb-0">List Standard Components {info} </h3>
                <p className="text-sm mb-0"> </p>
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>Data not found!</strong> Please check again!
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ListStandardComponents;
