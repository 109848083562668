import React, { PureComponent } from "react";

import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input,
  Form,
  CardImg,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from "reactstrap";

// core components
import StandardHeader from "components/Headers/StandardHeader.js";
import { validateProduct, validateSpecifications } from "services/validate";

//services
import config from "services/config";

//packages
import axios from "axios";

// core components
import { styleInputSelect } from "services/styles";
import AsyncSelect from "react-select/async";
// redux
import { connect } from "react-redux";
import { getDataProducts } from "./Redux/productActions";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import Dropzone from "dropzone";
import ListSpecifications from "./ListSpecifications";
Dropzone.autoDiscover = false;
let imageRef_1;
let imageRef_2;
let imageRef_3;
let imageRef_4;
let fileRef;
class ProductDetails extends PureComponent {
  state = {
    data: {
      category_id: "",
      product_no: "",
      product_description: "",
      chasis: "",
      axle: "",
      tank_capacity: "",
      lead_time: "",
      product_type: "",
      wheel_base: "",
      roh: "",
      product_price: "",
      tax:'0',
      discount:'0',
      image_1: "",
      image_2: "",
      image_3: "",
      image_4: "",
      file_specifications: "",
    },
    actionListCategories: {
      page: 1,
      items_per_page: 10,
      order_by_field: "category_id",
      order_by_direction: "DESC",
      category_name: "",
    },
    actionListProducts: {
        page: 1,
        items_per_page: 10,
        order_by_field: "product_id",
        order_by_direction: "DESC",
        product_no: "",
    },
    dataSpecifications: {
      product_id: 0,
      specification_no: "",
      specification_name: "",
      specification_description: "",
    },
    dataUpdateSpecifications: {
      product_id: 0,
      specification_no: "",
      specification_name: "",
      specification_description: "",
    },
    capacity: [],
    typeProduct: '',
    updateSpecifications: false,
    selectedOptionCategories: [],
    listSpecifications: [],
    listProducts: [],
    isLoading: false,
    alert: null,
    paramError: {},
    paramErrorSpecifications: {},
  };

  componentDidMount = async () => {
    this.mounted = true;
    try {
      const response = await axios.post(config.API_URL + '/products/view_product',{ product_id: this.props.match.params.productId },{
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      this.setState({
        data: {
          product_id: response.data.data[0].product_id,
          category_id: response.data.data[0].category_id,
          product_no: response.data.data[0].product_no,
          product_description: response.data.data[0].product_description,
          chasis: response.data.data[0].chasis,
          axle: response.data.data[0].axle,
          tank_capacity: response.data.data[0].tank_capacity,
          wheel_base: response.data.data[0].wheel_base,
          lead_time: response.data.data[0].lead_time,
          product_type: response.data.data[0].product_type,
          roh: response.data.data[0].roh,
          product_price: response.data.data[0].product_price,
          tax: response.data.data[0].tax,
          discount: response.data.data[0].discount,
          image_1: response.data.data[0].image_1,
          image_2: response.data.data[0].image_2,
          image_3: response.data.data[0].image_3,
          image_4: response.data.data[0].image_4,
          file_specifications: response.data.data[0].file_specifications,
        },
        selectedOptionCategories:{
          label: response.data.data[0].category_name,
          value: response.data.data[0].category_id
        },
        typeProduct: response.data.data[0].category_name,
      },()=>{
        if(this.state.typeProduct === 'Water Truck' || this.state.typeProduct === 'Fuel Truck') {
          this.setState({
            capacity: [
              '32KL','30KL','25KL','20KL'
            ]
          })
        }
        if(this.state.typeProduct === 'Fuel Lube Service' || this.state.typeProduct === 'Lube Service') {
          this.setState({
            capacity:[
              '4KL+5P2L','5P2L'
            ]
          })
        }
        this.getListSpecifications();
        this.getDataListProducts();
        this.createFile(this.state.data);
      });
    } catch (err){
      console.log(err)
    }
    this.currentSingleFile_1 = undefined;
    this.currentSingleFile_2 = undefined;
    this.currentSingleFile_3 = undefined;
    this.currentSingleFile_4 = undefined;
    new Dropzone(document.getElementById("dropzone-single-1"), {
      url: "/",
      autoProcessQueue: false,
      dictDefaultMessage: "Drop image here to upload",
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName(
        "dz-preview-single-1"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-single-1")[0]
        .innerHTML,
      maxFiles: 1,
      acceptedFiles: "image/*",
      init: function () {
        this.on("addedfile", function (file) {
          if (this.currentSingleFile_1) {
            this.removeFile(this.currentSingleFile_1);
          }
          this.currentSingleFile_1 = file;
          imageRef_1 = this.currentSingleFile_1;
          console.log(imageRef_1)
        });
      },
    });
    new Dropzone(document.getElementById("dropzone-single-2"), {
      url: "/",
      autoProcessQueue: false,
    dictDefaultMessage: "Drop image here to upload",
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName(
        "dz-preview-single-2"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-single-2")[0]
        .innerHTML,
      maxFiles: 1,
      acceptedFiles: "image/*",
      init: function () {
        this.on("addedfile", function (file) {
          if (this.currentSingleFile_2) {
            this.removeFile(this.currentSingleFile_2);
          }
          this.currentSingleFile_2 = file;
          imageRef_2 = this.currentSingleFile_2;
        });
      },
    });
    new Dropzone(document.getElementById("dropzone-single-3"), {
      url: "/",
      autoProcessQueue: false,
    dictDefaultMessage: "Drop image here to upload",
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName(
        "dz-preview-single-3"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-single-3")[0]
        .innerHTML,
      maxFiles: 1,
      acceptedFiles: "image/*",
      init: function () {
        this.on("addedfile", function (file) {
          if (this.currentSingleFile_3) {
            this.removeFile(this.currentSingleFile_3);
          }
          this.currentSingleFile_3 = file;
          imageRef_3 = this.currentSingleFile_3;
        });
      },
    });
    new Dropzone(document.getElementById("dropzone-single-4"), {
      url: "/",
      autoProcessQueue: false,
    dictDefaultMessage: "Drop image here to upload",
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName(
        "dz-preview-single-4"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-single-4")[0]
        .innerHTML,
      maxFiles: 1,
      acceptedFiles: "image/*",
      init: function () {
        this.on("addedfile", function (file) {
          if (this.currentSingleFile_4) {
            this.removeFile(this.currentSingleFile_4);
          }
          this.currentSingleFile_4 = file;
          imageRef_4 = this.currentSingleFile_4;
        });
      },
    });
    document.getElementsByClassName("dz-preview-single-1")[0].innerHTML = "";
    document.getElementsByClassName("dz-preview-single-2")[0].innerHTML = "";
    document.getElementsByClassName("dz-preview-single-3")[0].innerHTML = "";
    document.getElementsByClassName("dz-preview-single-4")[0].innerHTML = "";
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  createFile = async (data) => {
    let response_image_1 = await fetch(config.BUCKET_URL + data.image_1);
    let data_image_1 = await response_image_1.blob();
    let metadata_1 = {
      type: "image/*",
    };
    let file_image_1 = new File([data_image_1], "test.jpg", metadata_1);
    imageRef_1 = file_image_1;

    let response_image_2 = await fetch(config.BUCKET_URL + data.image_2);
    let data_image_2 = await response_image_2.blob();
    let metadata_2 = {
      type: "image/*",
    };
    let file_image_2 = new File([data_image_2], "test.jpg", metadata_2);
    imageRef_2 = file_image_2;

    let response_image_3 = await fetch(config.BUCKET_URL + data.image_3);
    let data_image_3 = await response_image_3.blob();
    let metadata_3 = {
      type: "image/*",
    };
    let file_image_3 = new File([data_image_3], "test.jpg", metadata_3);
    imageRef_3 = file_image_3;

    let response_image_4 = await fetch(config.BUCKET_URL + data.image_4);
    let data_image_4 = await response_image_4.blob();
    let metadata_4 = {
      type: "image/*",
    };
    let file_image_4 = new File([data_image_4], "test.jpg", metadata_4);
    imageRef_4 = file_image_4;

    let response_file_specifications = await fetch(config.BUCKET_URL + data.file_specifications);
    let data_file_specifications = await response_file_specifications.blob();
    let metadata_5 = {
      type: "file/*",
    };
    let file_file_specifications = new File([data_file_specifications], "test.pdf", metadata_5);
    fileRef = file_file_specifications;
  };
  getListSpecifications = () => {
    axios
      .post(
        config.API_URL + "/specifications/specifications_on_product",
        { product_id: this.props.match.params.productId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listSpecifications: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getDataListProducts = () => {
    axios
      .post(
        config.API_URL + "/products/products",
        this.state.actionListProducts,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listProducts: res.data.data,
              },
              () => {
                console.log(this.state.listProducts)
                this.props.dispatch(getDataProducts(this.state.listProducts));
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  fetchDataCategories = (inputValue) => {
    let searchTerm = inputValue;

    if (!inputValue || inputValue === " ") {
      searchTerm = "a";
    }

    this.setState({
      actionListCategories: {
        ...this.state.actionListCategories,
        category_name: searchTerm,
      },
    });

    const newRequest = axios.post(
      config.API_URL + "/categories/categories",
      this.state.actionListCategories,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );

    if (newRequest) {
      return newRequest.then((response) => {
        const compare = response.data.data.filter((i) =>
          i.category_name.toLowerCase().includes(inputValue.toLowerCase())
        );
        return compare.map((data) => ({
          label: data.category_name,
          value: data.category_id,
        }));
      });
    }
  };
  onSearchChangeCategories = (selectedOptionCategories) => {
    this.setState({
      selectedOptionCategories: selectedOptionCategories,
      data: {
        ...this.state.data,
        category_id: selectedOptionCategories.value,
      },
    });
  };
  postDataToAPI = () => {
    this.setState(
      {
        isLoading: true,
        data: {
          ...this.state.data,
          image_1: imageRef_1,
          image_2: imageRef_2,
          image_3: imageRef_3,
          image_4: imageRef_4,
          file_specifications: fileRef,
        },
      },
      () => {
        let formData = new FormData();
        formData.append("category_id", this.state.data.category_id);
        formData.append("product_id", this.state.data.product_id);
        formData.append("product_no", this.state.data.product_no);
        formData.append(
          "product_description",
          this.state.data.product_description
        );
        formData.append("chasis", this.state.data.chasis);
        formData.append("axle", this.state.data.axle);
        formData.append("tank_capacity", this.state.data.tank_capacity);
        formData.append("wheel_base", this.state.data.wheel_base);
        formData.append("roh", this.state.data.roh);
        formData.append("lead_time", this.state.data.lead_time);
        formData.append("product_type", this.state.data.product_type);
        formData.append("product_price", this.state.data.product_price);
        formData.append("tax", this.state.data.tax === null ? 0: parseFloat(this.state.data.tax));
        formData.append("discount", this.state.data.discount === null? 0 : parseFloat(this.state.data.discount));
        formData.append("image_1", this.state.data.image_1);
        formData.append("image_2", this.state.data.image_2);
        formData.append("image_3", this.state.data.image_3);
        formData.append("image_4", this.state.data.image_4);
        formData.append(
          "file_specifications",
          this.state.data.file_specifications
        );
        const paramError = validateProduct(this.state.data);
        this.setState({ paramError });
        if (Object.keys(paramError).length === 0) {
          axios
            .post(config.API_URL + "/products/update_product", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: config.TOKEN,
              },
            })
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data.message);
                  this.setState({ isLoading: false });
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(err.response.data.message);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };

  putDataSpecificationToAPI = () => {
    this.setState({ isLoading: true });
    const paramErrorSpecifications = validateSpecifications(
      this.state.dataUpdateSpecifications
    );
    this.setState({ paramErrorSpecifications });
    if (Object.keys(paramErrorSpecifications).length === 0) {
      axios
        .post(
          config.API_URL + "/specifications/update_specification",
          this.state.dataUpdateSpecifications,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(res.data.message);
              this.setState({ isLoading: false });
              this.getListSpecifications();
            }
          },
          (err) => {
            if (this.mounted) {
              this.failedAlert(
                this.state.dataUpdateSpecifications.specification_name
              );
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };
  handleSubmitUpdateSpecifications = () => {
    this.putDataSpecificationToAPI();
  };
  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeInt = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: parseInt(event.target.value),
      },
    });
  };

  handleChangeFile = (event) => {
    const fileUploaded = event.target.files[0];
    fileRef = fileUploaded;
  };
  handleSubmit = () => {
    this.postDataToAPI();
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  remove = (data) => {
    axios
      .post(
        config.API_URL + "/products/delete_product",
        { product_id: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlert();
        },
        (err) => {
          console.log(err);
          if (this.mounted) {
            if (err.response !== undefined) {
              this.failedAlert(err.response.data.message);
            }
          }
        }
      );
  };
  
  removeSpecifications = (data) => {
    axios
      .post(
        config.API_URL + "/specifications/delete_specification",
        { specification_id: data.specification_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlertSpecifications();
          this.getListSpecifications();
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  confirmAlertSpecifications = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.removeSpecifications(data)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.specification_name}`} specification, You won't be able to
          revert this!
        </SweetAlert>
      ),
    });
  };
  confirmAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.remove(data.product_id)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.product_no}`} product, You won't be able to revert
          this!
        </SweetAlert>
      ),
    });
  };
  confirmedAlertSpecifications = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Specification has been deleted.
        </SweetAlert>
      ),
    });
  };
  confirmedAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Product has been deleted.
        </SweetAlert>
      ),
    });
  };

  previewImage = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="secondary"
          confirmBtnText="Close"
          btnSize=""
        >
          <CardImg alt="..." src={config.BUCKET_URL+data} top />
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleUpdateSpecifications = (data) => {
    this.setState({
      dataUpdateSpecifications: {
        product_id: data.product_id,
        specification_id: data.specification_id,
        specification_no: data.specification_no,
        specification_name: data.specification_name,
        specification_description: data.specification_description,
      },
      updateSpecifications: true,
    });
  };

  handleChangeStringUpdateSpecifications = (event) => {
    this.setState({
      dataUpdateSpecifications: {
        ...this.state.dataUpdateSpecifications,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeStringSpecifications = (event) => {
    this.setState({
      dataSpecifications: {
        ...this.state.dataSpecifications,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleSubmitSpecifications = () => {
    this.setState(
      {
        isLoading: true,
        dataSpecifications: {
          ...this.state.dataSpecifications,
          product_id: this.props.match.params.productId,
        },
      },
      () => {
        const paramErrorSpecifications = validateSpecifications(
          this.state.dataSpecifications
        );
        this.setState({ paramErrorSpecifications });
        if (Object.keys(paramErrorSpecifications).length === 0) {
          axios
            .post(
              config.API_URL + "/specifications/create_specification",
              this.state.dataSpecifications,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: config.TOKEN,
                },
              }
            )
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data.message);
                  this.setState({
                    isLoading: false,
                    dataSpecifications: {
                      ...this.state.dataSpecifications,
                      product_id: 0,
                      specification_no: "",
                      specification_name: "",
                      specification_description: "",
                    },
                  });
                  this.getListSpecifications();
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(
                      this.state.dataSpecifications.specification_name
                    );
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };
  render() {
    return (
      <>
        <StandardHeader
          name="Product Details"
          parentName="Products"
          link="products"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Product Details</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <Form role="form">
                          <label className="form-control-label">Category</label>
                          <br />
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            styles={styleInputSelect}
                            value={this.state.selectedOptionCategories}
                            placeholder={<div>Select</div>}
                            loadOptions={this.fetchDataCategories}
                            onChange={(e) => {
                              this.onSearchChangeCategories(e);
                            }}
                          />
                        </Form>
                        {this.state.paramError.category_id&&
                            <small className="text-warning">{this.state.paramError.category_id}</small>
                        }
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Product Type
                          </label>
                          <Input
                            type="select"
                            onKeyPress={this.keyPressed}
                            name="product_type"
                            placeholder="Product Type"
                            value={this.state.data.product_type}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.product_type
                                ? "is-invalid"
                                : ""
                            }
                          >
                            <option value="">Select</option>
                            <option value="Transport">Transport</option>
                            <option value="Support Equipment">Support Equipment</option>
                            <option value="Mining">Mining</option>
                            <option value="Material Handling">Material Handling</option>
                            <option value="Logistics">Logistics</option>
                          </Input>
                          <div className="invalid-feedback">
                            {this.state.paramError.product_type}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Product No
                          </label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="product_no"
                            placeholder="Product No"
                            value={this.state.data.product_no}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.product_no
                                ? "is-invalid"
                                : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.product_no}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Chasis</label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="chasis"
                            placeholder="Chasis"
                            value={this.state.data.chasis}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.chasis ? "is-invalid" : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.chasis}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Axle</label>
                          <Input
                            type="select"
                            onKeyPress={this.keyPressed}
                            name="axle"
                            placeholder="Axle"
                            value={this.state.data.axle}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.axle ? "is-invalid" : ""
                            }
                          >
                            <option value={""}>Select</option>
                            <option value={"6x4"}>6x4</option>
                            <option value={"6x6"}>6x6</option>
                            <option value={"8x4"}>8x4</option>
                          </Input>
                          <div className="invalid-feedback">
                            {this.state.paramError.axle}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Tank Capacity
                          </label>
                          <Input
                            type="select"
                            onKeyPress={this.keyPressed}
                            name="tank_capacity"
                            placeholder="Tank Capacity"
                            value={this.state.data.tank_capacity}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.tank_capacity
                                ? "is-invalid"
                                : ""
                            }
                          >
                           <option value={""}>Select</option>
                            {this.state.capacity.map((element, index) => {
                                return (
                                  <option key={index} value={element}>{element}</option>
                                )
                              })
                            }
                          </Input>
                          <div className="invalid-feedback">
                            {this.state.paramError.tank_capacity}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Wheel Base
                          </label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="wheel_base"
                            placeholder="Wheel Base"
                            value={this.state.data.wheel_base}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.wheel_base
                                ? "is-invalid"
                                : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.wheel_base}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">ROH</label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="roh"
                            placeholder="ROH"
                            value={this.state.data.roh}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.roh ? "is-invalid" : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.roh}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Product Price
                          </label>
                          <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              IDR
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="number"
                            onKeyPress={this.keyPressed}
                            name="product_price"
                            placeholder="Product Price"
                            value={this.state.data.product_price}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.product_price
                                ? "is-invalid"
                                : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.product_price}
                          </div>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            TAX
                          </label>
                          <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              %
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="number"
                            onKeyPress={this.keyPressed}
                            name="tax"
                            placeholder="0"
                            value={this.state.data.tax}
                            onChange={this.handleChangeString}
                            required
                          />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Discount
                          </label>
                          <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              %
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="number"
                            onKeyPress={this.keyPressed}
                            name="discount"
                            placeholder="0"
                            value={this.state.data.discount}
                            onChange={this.handleChangeString}
                            required
                          />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Lead Time</label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="lead_time"
                            placeholder="Lead Time"
                            value={this.state.data.lead_time}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.lead_time ? "is-invalid" : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.lead_time}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Product Description <small>optional</small>
                          </label>
                          <Input
                            type="textarea"
                            name="product_description"
                            placeholder="Product Description"
                            value={this.state.data.product_description}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.product_description
                                ? "is-invalid"
                                : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.product_description}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <h6 className="heading-small text-muted mb-4">Images</h6>
                    <Row>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <label className="form-control-label">Image 1</label>
                        <div
                          className="dropzone dropzone-single mb-3"
                          id="dropzone-single-1"
                        >
                          <div className="fallback">
                            <div className="custom-file">
                              <input
                                className="custom-file-input"
                                id="projectCoverUploads"
                                type="file"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="projectCoverUploads"
                              >
                                Choose file
                              </label>
                            </div>
                          </div>
                          <div className="dz-preview dz-preview-single-1">
                            <div className="dz-preview-cover">
                              <img
                                alt="..."
                                className="dz-preview-img"
                                data-dz-thumbnail=""
                              />
                            </div>
                          </div>
                        </div>
                        {this.state.paramError.image_1&&
                            <small className="text-warning">{this.state.paramError.image_1}</small>
                        }
                         <div style={{ marginTop: 10 }}>
                              <Button
                                outline
                                className="btn-neutral"
                                size="sm"
                                onClick={() =>
                                  this.previewImage(this.state.data.image_1)
                                }
                              >
                                  View Image
                              </Button>
                             
                            </div>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <label className="form-control-label">Image 2</label>
                        <div
                          className="dropzone dropzone-single mb-3"
                          id="dropzone-single-2"
                        >
                          <div className="fallback">
                            <div className="custom-file">
                              <input
                                className="custom-file-input"
                                id="projectCoverUploads"
                                type="file"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="projectCoverUploads"
                              >
                                Choose file
                              </label>
                            </div>
                          </div>
                          <div className="dz-preview dz-preview-single-2">
                            <div className="dz-preview-cover">
                              <img
                                alt="..."
                                className="dz-preview-img"
                                data-dz-thumbnail=""
                              />
                            </div>
                          </div>
                        </div>
                        {this.state.paramError.image_2&&
                            <small className="text-warning">{this.state.paramError.image_2}</small>
                        }
                         <div style={{ marginTop: 10 }}>
                              <Button
                                outline
                                className="btn-neutral"
                                size="sm"
                                onClick={() =>
                                  this.previewImage(this.state.data.image_2)
                                }
                              >
                                  View Image
                              </Button>
                             
                            </div>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <label className="form-control-label">Image 3</label>
                        <div
                          className="dropzone dropzone-single mb-3"
                          id="dropzone-single-3"
                        >
                          <div className="fallback">
                            <div className="custom-file">
                              <input
                                className="custom-file-input"
                                id="projectCoverUploads"
                                type="file"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="projectCoverUploads"
                              >
                                Choose file
                              </label>
                            </div>
                          </div>
                          <div className="dz-preview dz-preview-single-3">
                            <div className="dz-preview-cover">
                              <img
                                alt="..."
                                className="dz-preview-img"
                                data-dz-thumbnail=""
                              />
                            </div>
                          </div>
                        </div>
                        {this.state.paramError.image_3&&
                            <small className="text-warning">{this.state.paramError.image_3}</small>
                        }
                         <div style={{ marginTop: 10 }}>
                              <Button
                                outline
                                className="btn-neutral"
                                size="sm"
                                onClick={() =>
                                  this.previewImage(this.state.data.image_3)
                                }
                              >
                                  View Image
                              </Button>
                             
                            </div>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <label className="form-control-label">Image 4</label>
                        <div
                          className="dropzone dropzone-single mb-3"
                          id="dropzone-single-4"
                        >
                          <div className="fallback">
                            <div className="custom-file">
                              <input
                                className="custom-file-input"
                                id="projectCoverUploads"
                                type="file"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="projectCoverUploads"
                              >
                                Choose file
                              </label>
                            </div>
                          </div>
                          <div className="dz-preview dz-preview-single-4">
                            <div className="dz-preview-cover">
                              <img
                                alt="..."
                                className="dz-preview-img"
                                data-dz-thumbnail=""
                              />
                            </div>
                          </div>
                        </div>
                        {this.state.paramError.image_4&&
                            <small className="text-warning">{this.state.paramError.image_4}</small>
                        }
                         <div style={{ marginTop: 10 }}>
                              <Button
                                outline
                                className="btn-neutral"
                                size="sm"
                                onClick={() =>
                                  this.previewImage(this.state.data.image_4)
                                }
                              >
                                  View Image
                              </Button>
                             
                            </div>
                      </Col>
                    </Row>
                    {" "}
                    <h6 className="heading-small text-muted mb-4">Files</h6>
                    <Row>
                      <Col xs="5">
                        <label className="form-control-label">
                          File Specifications <small>PDF</small>
                        </label>
                        <Form>
                      <div className="custom-file">
                        <input
                          className="custom-file-input"
                          id="customFileLang"
                          lang="en"
                          type="file"
                          onChange={this.handleChangeFile}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFileLang"
                        >
                          Select file
                        </label>
                      </div>
                      {this.state.paramError.file_specifications&&
                            <small className="text-warning">{this.state.paramError.file_specifications}</small>
                        }
                         <div style={{ marginTop: 10 }}>
                              <Button
                                outline
                                className="btn-neutral"
                                size="sm"
                                onClick={()=> window.open(config.BUCKET_URL+this.state.data.file_specifications, "_blank")}
                              >
                                  View File
                              </Button>
                             
                            </div>
                    </Form>
                      </Col>
                    </Row>
                     <Row className="justify-content-md-center">
                      <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-3 col-sm-6"
                        xs="12"
                      >
                        <Button
                          block
                          color="success"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isLoading ? true : false}
                          onClick={() => this.handleSubmit()}
                        >
                          {this.state.isLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Save
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Save</span>
                          )}
                        </Button>
                      </Col>

                      <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-3 col-sm-6"
                        xs="12"
                      >
                        <Button
                          block
                          color="danger"
                          disabled={this.state.isLoading ? true : false}
                          onClick={() => this.confirmAlert(this.state.data)}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <Row>
            <Col>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.state.updateSpecifications
                          ? "Update Specification"
                          : "Create Specification"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">No</label>
                        <Input
                          type="text"
                          onKeyPress={this.keyPressed}
                          name="specification_no"
                          placeholder="No"
                          value={
                            this.state.updateSpecifications
                              ? this.state.dataUpdateSpecifications
                                  .specification_no
                              : this.state.dataSpecifications
                                  .specification_no
                          }
                          required
                          className={
                            this.state.paramErrorSpecifications
                              .specification_no
                              ? "is-invalid"
                              : ""
                          }
                          onChange={
                            this.state.updateSpecifications
                              ? this.handleChangeStringUpdateSpecifications
                              : this.handleChangeStringSpecifications
                          }
                        />
                        <div className="invalid-feedback">
                          {
                            this.state.paramErrorSpecifications
                              .specification_no
                          }
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input
                          type="text"
                          onKeyPress={this.keyPressed}
                          name="specification_name"
                          placeholder="Name"
                          value={
                            this.state.updateSpecifications
                              ? this.state.dataUpdateSpecifications
                                  .specification_name
                              : this.state.dataSpecifications
                                  .specification_name
                          }
                          required
                          className={
                            this.state.paramErrorSpecifications
                              .specification_name
                              ? "is-invalid"
                              : ""
                          }
                          onChange={
                            this.state.updateSpecifications
                              ? this.handleChangeStringUpdateSpecifications
                              : this.handleChangeStringSpecifications
                          }
                        />
                        <div className="invalid-feedback">
                          {
                            this.state.paramErrorSpecifications
                              .specification_name
                          }
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Description
                        </label>
                        <Input
                          type="textarea"
                          name="specification_description"
                          placeholder="Description"
                          value={
                            this.state.updateSpecifications
                              ? this.state.dataUpdateSpecifications
                                  .specification_description
                              : this.state.dataSpecifications
                                  .specification_description
                          }
                          required
                          className={
                            this.state.paramErrorSpecifications
                              .specification_description
                              ? "is-invalid"
                              : ""
                          }
                          onChange={
                            this.state.updateSpecifications
                              ? this.handleChangeStringUpdateSpecifications
                              : this.handleChangeStringSpecifications
                          }
                        />
                        <div className="invalid-feedback">
                          {
                            this.state.paramErrorSpecifications
                              .specification_description
                          }
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <>
                      {this.state.updateSpecifications ? (
                        <>
                          <Col
                            style={{ marginTop: 20 }}
                            className="col-6 col-md-3 col-sm-6"
                            xs="12"
                          >
                            <Button
                              block
                              color="success"
                              className="btn-icon"
                              type="button"
                              disabled={this.state.isLoading ? true : false}
                              onClick={() =>
                                this.handleSubmitUpdateSpecifications()
                              }
                            >
                              {this.state.isLoading ? (
                                <>
                                  <span className="btn-inner--text">
                                    <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                    Save
                                  </span>
                                </>
                              ) : (
                                <span className="btn-inner--text">Save</span>
                              )}
                            </Button>
                          </Col>
                          <Col
                            style={{ marginTop: 20 }}
                            className="col-6 col-md-3 col-sm-6"
                            xs="12"
                          >
                            <Button
                              block
                              color="second"
                              disabled={this.state.isLoading ? true : false}
                              onClick={() =>
                                this.setState({
                                  updateSpecifications: false,
                                })
                              }
                            >
                              Cancel
                            </Button>
                          </Col>
                        </>
                      ) : (
                        <Col
                          style={{ marginTop: 20 }}
                          className="col-6 col-md-3 col-sm-6"
                          xs="12"
                        >
                          <Button
                            block
                            color="success"
                            className="btn-icon"
                            type="button"
                            disabled={this.state.isLoading ? true : false}
                            onClick={() =>
                              this.handleSubmitSpecifications()
                            }
                          >
                            {this.state.isLoading ? (
                              <>
                                <span className="btn-inner--text">
                                  <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                  Submit
                                </span>
                              </>
                            ) : (
                              <span className="btn-inner--text">Submit</span>
                            )}
                          </Button>
                        </Col>
                      )}
                    </>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListSpecifications
                  list={this.state.listSpecifications}
                  info={this.state.data.category_name}
                  update={this.handleUpdateSpecifications}
                  delete={this.confirmAlertSpecifications}
                />
              </div>
            </div>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      dataProducts: state.product.dataProducts,
    };
  };

export default connect(mapStateToProps)(ProductDetails);
