
import React, { PureComponent } from "react";
import {
    Row,
    Col,
    FormGroup,
    Input,
    Button
} from "reactstrap";
import {validateFuelTruck} from 'services/validate';
import SweetAlert from "react-bootstrap-sweetalert";
class FuelTruck extends PureComponent {
    state = {
        data: {
            product: 'Fuel Truck',
            tank_capacity: '',
            truck_brand: '',
            truck_model: '',
            axle: '',
            gvw: '',
            wheelbase: '',
            fuel_pump: '',
            flow_rate: '',
            hose_reel: '',
            hose_id: '',
            length_hose_reel: ''
        },
        dataRemarks: {
            tank_capacity_remarks: '',
            truck_brand_remarks: '',
            truck_model_remarks: '',
            gvw_remarks: '',
            wheelbase_remarks: '',
            fuel_pump_remarks: '',
            flow_rate_remarks: '',
            hose_reel_remarks: '',
            hose_id_remarks: '',
            length_hose_reel_remarks: ''
        },
        optionsTruckModel: [],
        isLoading: false,
        alert: null,
        paramError: {}
    };

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleChangeString = (event) => {
        this.setState({
            data: {
                ...this.state.data,
                [event.target.name]: event.target.value,
            },
        });
    };

    handleChangeStringRemarks = (event) => {
        this.setState({
            dataRemarks: {
                ...this.state.dataRemarks,
                [event.target.name]: event.target.value,
            },
        });
    };

    handleChangeTruckBrand = (event) => {
        this.setState({
            data: {
                ...this.state.data,
                [event.target.name]: event.target.value,
            }
        }, () => {
            if (this.state.data.truck_brand === 'IVECO') {
                this.setState({
                    optionsTruckModel: [
                        "ASTRA HD9",
                        "TRAKKER AD380T38",
                        "TRAKKER AD410T44",
                        "TRAKKER AD440T44",
                        "682"
                    ],
                })
            }
            if (this.state.data.truck_brand === 'VOLVO') {
                this.setState({
                    optionsTruckModel: [
                        "FM370",
                        "FM440",
                        "FMX400",
                        "FMX440",
                        "FMX480",
                        "FMX520",
                        "FH16 550",
                        "FH16 610"
                    ],
                })
            }
            if (this.state.data.truck_brand === 'SCANIA') {
                this.setState({
                    optionsTruckModel: [
                        "P360",
                        "P380",
                        "P410",
                        "P420",
                        "P440"
                    ],
                })
            }
            if (this.state.data.truck_brand === 'MERCEDES') {
                this.setState({
                    optionsTruckModel: [
                        "AXOR3340T",
                        "AXOR4928T",
                        "AXOR4028T",
                        "AXOR2528R",
                        "AXOR2523R/45",
                        "AXOR2523R/57",
                        "AXOR1623R/51",
                        "AXOR1623R/60",
                        "AXOR2528C",
                        "AXOR2528RMC",
                        "AXOR4843K",
                        "AXOR3336K",
                        "AXOR3340S",
                        "ACTROS4036K/39",
                        "ACTROS4144K/51",
                        "ACTROS3344A/45"

                    ],
                })
            }
            if (this.state.data.truck_brand === 'HINO') {
                this.setState({
                    optionsTruckModel: [
                        "DUTRO 130HD",
                        "FM260JD",
                        "FM500",
                        "FM700"
                    ],
                })
            }

        })
    }
    keyPressed(event) {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      }

    handleBuildValue = () => {
        this.setState({
            isLoading: true
        }, () => {
            const paramError = validateFuelTruck(this.state.data);
            this.setState({paramError});
            if(Object.keys(paramError).length === 0){
                var data = Object.entries(this.state.data).map(([key, value]) => ({key,value}));
                var dataRemarks = Object.entries(this.state.dataRemarks).map(([key, value]) => ({key,value}));
        
                for(var i=0; i<=dataRemarks.length -1;i++){
                    data[i+1] = Object.values({...data[i+1], remarks: dataRemarks[i].value})
                }
            }else{
                this.failedAlert("Validation Errors");
                this.setState({
                    isLoading: false,
                })
            }
        })
    }

    failedAlert = (data) => {
        this.setState({
          alert: (
            <SweetAlert
              danger
              style={{ display: "block" }}
              title="Failed"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="danger"
              confirmBtnText="Ok"
              btnSize=""
            >
              {`${data}`}
            </SweetAlert>
          ),
        });
      };

      hideAlert = () => {
        this.setState({
          alert: null,
        });
      };
    render() {
        return (
            <>
                <Row>
                    <Col>
                        <h6 className="heading-small text-muted">Tank Descriptions</h6>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>

                            <label className="form-control-label">Tank Capacity</label>
                            <Input
                                id="example-date-input"
                                type="select"
                                onKeyPress={this.keyPressed}
                                name="tank_capacity"
                                placeholder="Tank Capacity"
                                value={this.state.data.tank_capacity}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.tank_capacity?"is-invalid":""}
                            >
                                <option value={""}>Select</option>
                                <option value={"200L"}>200L</option>
                                <option value={"1000L"}>1000L</option>
                                <option value={"2000L"}>2000L</option>
                                <option value={"5000L"}>5000L</option>
                                <option value={"16KL"}>16KL</option>
                                <option value={"20KL"}>20KL</option>
                                <option value={"24KL"}>24KL</option>
                                <option value={"25KL"}>25KL</option>
                                <option value={"30KL"}>30KL</option>
                                <option value={"32KL"}>32KL</option>
                            </Input>
                            <div className="invalid-feedback">
                              {this.state.paramError.tank_capacity}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="tank_capacity_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.tank_capacity_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h6 className="heading-small text-muted">Truck</h6>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <label className="form-control-label">Truck Brand</label>
                        <FormGroup>
                            <Input
                                id="example-date-input"
                                type="select"
                                onKeyPress={this.keyPressed}
                                name="truck_brand"
                                placeholder="Truck Brand"
                                value={this.state.data.truck_brand}
                                onChange={this.handleChangeTruckBrand}
                                required
                                className={this.state.paramError.truck_brand?"is-invalid":""}
                            >
                                <option value={""}>Select</option>
                                <option value={"IVECO"}>IVECO</option>
                                <option value={"VOLVO"}>VOLVO</option>
                                <option value={"MERCEDES"}>MERCEDES</option>
                                <option value={"SCANIA"}>SCANIA</option>
                                <option value={"HINO"}>HINO</option>
                                <option value={"NISSAN"}>NISSAN</option>
                                <option value={"ISUZU"}>ISUZU</option>
                                <option value={"MITSUBISHI"}>MITSUBISHI</option>
                                <option value={"CATERPILLAR"}>CATERPILLAR</option>
                                <option value={"KOMATSU"}>KOMATSU</option>
                                <option value={"LOADPRO"}>LOADPRO</option>
                            </Input>
                            <div className="invalid-feedback">
                              {this.state.paramError.truck_brand}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="truck_brand_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.truck_brand_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <label className="form-control-label">Truck Model</label>
                        <FormGroup>
                            <Input
                                id="example-date-input"
                                type="select"
                                onKeyPress={this.keyPressed}
                                name="truck_model"
                                placeholder="Truck Brand"
                                value={this.state.data.truck_model}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.truck_model?"is-invalid":""}
                            >
                                <option value={""}>Select</option>
                                {
                                    this.state.optionsTruckModel.map((element, index) => {
                                        return (
                                            <option key={index} value={element}>{element}</option>
                                        )
                                    })
                                }
                            </Input>
                            <div className="invalid-feedback">
                              {this.state.paramError.truck_model}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="truck_model_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.truck_model_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <label className="form-control-label">GVW (Kg)</label>
                        <FormGroup>
                            <Input
                                id="example-date-input"
                                type="select"
                                onKeyPress={this.keyPressed}
                                name="gvw"
                                placeholder="GVW"
                                value={this.state.data.gvw}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.gvw?"is-invalid":""}
                            >
                                <option value={""}>Select</option>
                                <option value={"< 15000"}>{`< 15000`}</option>
                                <option value={"15000 - 26000"}>15000 - 26000</option>
                                <option value={"26000 - 40000"}>26000 - 40000</option>
                                <option value={"> 40000"}>{`> 40000`}</option>
                            </Input>
                            <div className="invalid-feedback">
                              {this.state.paramError.gvw}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="gvw_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.gvw_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <label className="form-control-label">Axle</label>
                        <FormGroup>
                            <Input
                                id="example-date-input"
                                type="select"
                                onKeyPress={this.keyPressed}
                                name="axle"
                                placeholder="Axle"
                                value={this.state.data.axle}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.axle?"is-invalid":""}
                            >
                                <option value={""}>Select</option>
                                <option value={"6x4T"}>6x4T</option>
                                <option value={"6x6T"}>6x6T</option>
                                <option value={"6x4R"}>6x4R</option>
                                <option value={"6x6R"}>6x6R</option>
                                <option value={"8x4R"}>8x4R</option>
                                <option value={"8x6R"}>8x6R</option>
                            </Input>
                            <div className="invalid-feedback">
                              {this.state.paramError.axle}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="axle_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.axle_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <label className="form-control-label">Wheelbase (mm)</label>
                        <FormGroup>
                            <Input
                                id="example-date-input"
                                type="select"
                                onKeyPress={this.keyPressed}
                                name="wheelbase"
                                placeholder="Wheelbase"
                                value={this.state.data.wheelbase}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.wheelbase?"is-invalid":""}
                            >
                                <option value={""}>Select</option>
                                <option value={"2350"}>2350</option>
                                <option value={"2600"}>2600</option>
                                <option value={"2850"}>2850</option>
                                <option value={"3100"}>3100</option>
                                <option value={"3200"}>3200</option>
                                <option value={"3350"}>3350</option>
                                <option value={"3500"}>3500</option>
                                <option value={"3600"}>3600</option>
                                <option value={"3800"}>3800</option>
                                <option value={"4100"}>4100</option>
                                <option value={"4200"}>4200</option>
                                <option value={"4500"}>4500</option>
                                <option value={"4600"}>4600</option>
                                <option value={"4750"}>4750</option>
                                <option value={"5020"}>5020</option>
                                <option value={"5060"}>5060</option>
                                <option value={"5100"}>5100</option>
                                <option value={"6700"}>6700</option>
                                <option value={"4300"}>4300</option>
                                <option value={"4570"}>4570</option>
                                <option value={"4950"}>4950</option>
                            </Input>
                            <div className="invalid-feedback">
                              {this.state.paramError.wheelbase}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="wheelbase_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.wheelbase_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h6 className="heading-small text-muted">Equipments</h6>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <label className="form-control-label">Fuel Pump</label>
                        <FormGroup>
                            <Input
                                id="example-date-input"
                                type="select"
                                onKeyPress={this.keyPressed}
                                name="fuel_pump"
                                placeholder="Fuel Pump"
                                value={this.state.data.fuel_pump}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.fuel_pump?"is-invalid":""}
                            >
                                <option value={""}>Select</option>
                                <option value={"Ebsray V30"}>Ebsray V30</option>
                                <option value={"Corken PZ10"}>Corken PZ10</option>
                                <option value={"Corkent PZ7"}>Corkent PZ7</option>
                            </Input>
                            <div className="invalid-feedback">
                              {this.state.paramError.fuel_pump}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="fuel_pump_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.fuel_pump_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <label className="form-control-label">Flow Rate (Fuel Pump)</label>
                        <FormGroup>
                            <Input
                                id="example-date-input"
                                type="select"
                                onKeyPress={this.keyPressed}
                                name="flow_rate"
                                placeholder="Flow Rate"
                                value={this.state.data.flow_rate}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.flow_rate?"is-invalid":""}
                            >
                                <option value={""}>Select</option>
                                <option value={"150"}>150</option>
                                <option value={"250"}>250</option>
                                <option value={"350"}>350</option>
                                <option value={"450"}>450</option>
                                <option value={"550"}>550</option>
                            </Input>
                            <div className="invalid-feedback">
                              {this.state.paramError.flow_rate}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="flow_rate_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.flow_rate_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <label className="form-control-label">Hose Reel</label>
                        <FormGroup>
                            <Input
                                id="example-date-input"
                                type="select"
                                onKeyPress={this.keyPressed}
                                name="hose_reel"
                                placeholder="Hose Reel"
                                value={this.state.data.hose_reel}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.hose_reel?"is-invalid":""}
                            >
                                <option value={""}>Select</option>
                                <option value={"Hannay"}>Hannay</option>
                                <option value={"Cobra"}>Cobra</option>
                                <option value={"Allemite"}>Allemite</option>
                                <option value={"Samoa"}>Samoa</option>
                            </Input>
                            <div className="invalid-feedback">
                              {this.state.paramError.hose_reel}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="hose_reel_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.hose_reel_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <label className="form-control-label">Hose ID (Hose Reel)</label>
                        <FormGroup>
                            <Input
                                id="example-date-input"
                                type="select"
                                onKeyPress={this.keyPressed}
                                name="hose_id"
                                placeholder="Hose ID"
                                value={this.state.data.hose_id}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.hose_id?"is-invalid":""}
                            >
                                <option value={""}>Select</option>
                                <option value={`1/4"`}>{`1/4"`}</option>
                                <option value={`3/8"`}>{`3/8"`}</option>
                                <option value={`1/2"`}>{`1/2"`}</option>
                                <option value={`3/4"`}>{`3/4"`}</option>
                                <option value={`1"`}>{`1"`}</option>
                                <option value={`1 1/2"`}>{`1 1/2"`}</option>
                                <option value={`2"`}>{`2"`}</option>
                            </Input>
                            <div className="invalid-feedback">
                              {this.state.paramError.hose_id}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="hose_id_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.hose_id_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <label className="form-control-label">Length (Hose Reel)</label>
                        <FormGroup>
                            <Input
                                id="example-date-input"
                                type="select"
                                onKeyPress={this.keyPressed}
                                name="length_hose_reel"
                                placeholder="Length"
                                value={this.state.data.length_hose_reel}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.length_hose_reel?"is-invalid":""}
                            >
                                <option value={""}>Select</option>
                                <option value={"12 m"}>12 m</option>
                                <option value={"15 m"}>15 m</option>
                            </Input>
                            <div className="invalid-feedback">
                              {this.state.paramError.length_hose_reel}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="length_hose_reel_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.length_hose_reel_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-3 col-sm-6"
                        xs="12"
                    >
                        <Button
                            block
                            color="success"
                            className="btn-icon"
                            type="button"
                            disabled={this.state.isLoading ? true : false}
                            onClick={() => this.handleBuildValue()}
                        >
                            {this.state.isLoading ? (
                                <>
                                    <span className="btn-inner--text">
                                        <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Submit
                              </span>
                                </>
                            ) : (
                                <span className="btn-inner--text">Submit</span>
                            )}
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}

export default FuelTruck;
