import React, { PureComponent } from "react";

import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input
} from "reactstrap";

// core components
import StandardHeader from "components/Headers/StandardHeader.js";
import {validateCategory} from 'services/validate';

//services
import config from "services/config";

//packages
import axios from "axios";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import Dropzone from "dropzone";
Dropzone.autoDiscover = false;
let imageRef;
class CreateCategory extends PureComponent {
  state = {
    data: {
        category_name: "",
        category_description: "",
        image_1: ""
    },
    isLoading: false,
    alert: null,
    paramError: {},
  };

  componentDidMount() {
    this.mounted = true;
    this.currentSingleFile = undefined;
    new Dropzone(document.getElementById("dropzone-single"), {
      url: '/',
      autoProcessQueue: false,
      dictDefaultMessage: "Drop image here to upload",
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName(
        "dz-preview-single"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-single")[0]
        .innerHTML,
      maxFiles: 1,
      acceptedFiles: "image/*",
      init: function() {
        this.on("addedfile", function(file) {
          if (this.currentSingleFile) {
            this.removeFile(this.currentSingleFile);
          }
          this.currentSingleFile = file;
          imageRef = this.currentSingleFile;
          console.log(this.currentSingleFile)
        });
      }
    });
    document.getElementsByClassName("dz-preview-single")[0].innerHTML = "";
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  postDataToAPI = () => {
    this.setState({ 
        isLoading: true,
        data: {
            ...this.state.data,
            image_1: imageRef
        }
     }, () =>{
        let formData = new FormData();
        formData.append('image_1', this.state.data.image_1);
        formData.append('category_name', this.state.data.category_name);
        formData.append('category_description', this.state.data.category_description);
         const paramError = validateCategory(this.state.data);
         this.setState({paramError});
         if(Object.keys(paramError).length === 0){
           axios
             .post(config.API_URL + "/categories/create_category", formData, {
               headers: {
                 "Content-Type": "multipart/form-data",
                 "Authorization": config.TOKEN
               },
             })
             .then(
               (res) => {
                 if (this.mounted) {
                   this.successAlert(res.data.message);
                   this.setState({ isLoading: false });
                 }
               },
               (err) => {
                 if (this.mounted) {
                   if (err.response !== undefined) {
                     this.failedAlert(err.response.data.message);
                     this.setState({ isLoading: false });
                   }
                 }
               }
             );
         } else {
           this.failedAlert("Validation Errors");
           this.setState({
             isLoading: false,
           })
         }
     });
  };

  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeInt = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: parseInt(event.target.value),
      },
    });
  };

  handleSubmit = () => {
    this.postDataToAPI();
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  render() {
    return (
      <>
        <StandardHeader name="Create Category" parentName="Categories" link="categories" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Create Category</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Category Name</label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="category_name"
                            placeholder="Category Name"
                            value={this.state.data.category_name}
                            onChange={this.handleChangeString}
                            required
                            className={this.state.paramError.category_name?"is-invalid":""}
                          />
                           <div className="invalid-feedback">
                              {this.state.paramError.category_name}
                            </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Category Description <small>optional</small></label>
                          <Input
                            type="textarea"
                            onKeyPress={this.keyPressed}
                            name="category_description"
                            placeholder="Category Description"
                            value={this.state.data.category_description}
                            onChange={this.handleChangeString}
                            required
                            className={this.state.paramError.category_description?"is-invalid":""}
                          />
                           <div className="invalid-feedback">
                              {this.state.paramError.category_description}
                            </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                    <label className="form-control-label">Image</label>
                      <div
                      className="dropzone dropzone-single mb-3"
                      id="dropzone-single"
                    >
                      <div className="fallback">
                        <div className="custom-file">
                          <input
                            className="custom-file-input"
                            id="projectCoverUploads"
                            type="file"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="projectCoverUploads"
                          >
                            Choose file
                          </label>
                        </div>
                      </div>
                      <div className="dz-preview dz-preview-single">
                        <div className="dz-preview-cover">
                          <img
                            alt="..."
                            className="dz-preview-img"
                            data-dz-thumbnail=""
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.paramError.image_1&&
                            <small className="text-warning">{this.state.paramError.image_1}</small>
                        }
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                      <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-3 col-sm-6"
                        xs="12"
                      >
                        <Button
                          block
                          color="success"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isLoading ? true : false}
                          onClick={() => this.handleSubmit()}
                        >
                          {this.state.isLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Submit
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Submit</span>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

export default CreateCategory;
