/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Input,
  Badge,
  CardTitle,
  Button
} from "reactstrap";
import Breadcumbs from "components/Breadcumbs/Breadcumbs";
import config from "services/config";
import { currencyIDR } from "services/currency";
// import { connect } from "react-redux";
// import { getDataCustomerPortofolio } from "./Redux/customerPortofolioActions";
// import { getNumberCart } from "services/globalRedux/globalReduxActions";
import axios from "axios";
import Pagination from "react-bootstrap-pagination-logic";
// import { styleInputSelect } from "services/styles";
// import AsyncSelect from "react-select/async";
// import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
class ListPortofolio extends PureComponent {
  // class ListPortofolio extends React.Component {
  state = {
    actionListProduct: {
      page: 1,
      items_per_page: 10,
      order_by_field: "created_at",
      order_by_direction: "DESC",
      company_id: config.COMPANY_NAME,
      option_status: "on progress",
      searchpo: "",
      list_year: "",
    },
    showBadge: "",
    actionListCategories: {
      page: 1,
      items_per_page: 10,
      order_by_field: "category_id",
      order_by_direction: "DESC",
      category_name: "",
    },
    optionYear:{
      company: config.COMPANY_NAME,
    },
    listYear: [],
    listDetail: [],
    typeProduct: "",
    selectedOptionCategories: [],
    listProduct: [],
    total_pages: 0,
    total_items: 0,
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
  };
  componentDidMount() {
    this.mounted = true;
    this.getDataListProducts();
    this.getListYear();
    this.getListSubDetail();
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  selectStatus = (e) => {
    this.setState(
      {
        actionListProduct: {
          ...this.state.actionListProduct,
          option_status: e,
          searchpo: "",
          list_year: ""
        },
        listYear: [],
        // showBadge: this.state.total_items,
      },
      () => {
        this.getDataListProducts();
      }
    );
  };
  getDataListProducts = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/portofolio/view_portofolio_customer",
        this.state.actionListProduct,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listProduct: res.data.data,
                total_pages: res.data.total_pages,
                total_items: res.data.total_items,
                showBadge: res.data.total_items,
              },  () => {
                this.getListYear();
                this.getListSubDetail();
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter:false,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListYear = () => {
    axios
      .post(
        config.API_URL + "/portofolio/list_portofolio_year",this.state.actionListProduct,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listYear: res.data.data,
            }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListSubDetail = () => {
    this.setState({
      ...this.state.actionListProduct,
    });
    axios
      .post(
        config.API_URL + "/portofolio/view_portofolio_sub",
        this.state.actionListProduct,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listDetail: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleChangeString = (event) => {
    this.setState(
      {
        actionListProduct: {
          ...this.state.actionListProduct,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        this.getDataListProducts();
      }
    );
  };

  handleSearch = (event) => {
    this.setState(
      {
        actionListProduct: {
          ...this.state.actionListProduct,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        this.getDataListProducts();
      }
    );
  };

  handleReset = (e) => {
    this.setState(
      {
        actionListProduct: {
          ...this.state.actionListProduct,
          searchpo: "",
          list_year:""
        },
        listYear: [],
      },
      () => {
        this.getDataListProducts();
      }
    );
  };

  handletoDetails = (e) => {
    this.props.history.push("/customers/portofolio_details/" + e);
  };

  handlePagination = (numPage) => {
    this.setState(
      {
        actionListProduct: {
          ...this.state.actionListProduct,
          page: numPage,
        },
      },
      async () => {
        await this.getDataListProducts();
        window.scrollTo({
          top: 10,
          behavior: "smooth",
        });
      }
    );
  };
  handleFilter = () => {
    this.setState(
      {
        actionListProduct: {
          ...this.state.actionListProduct,
          page: 1,
        },
      },
      () => {
        this.getDataListProducts();
      }
    );
  };
  handleToFilterComponent = () => {
    var elmnt = document.getElementById("filter_component");
    elmnt.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  render() {
    const dataProduct = this.state.listProduct.map((products, index) => {
      if (products.status !== "on progress" && products.status !== "delivered") {
        return null;
      }
      if (products.status === "") {
        return null;
      }

      if (products.status === "on progress") {
        return (
          <>
            <Card
              className="card-lift--hover rounded shadow mt-3 mx-2"
              key={index}
            >
              <CardBody
                style={{ cursor: "pointer", objectFit: "cover" }}
                onClick={() => this.handletoDetails(products.purchase_order)}
              >
                <Row>
                  {/* <Col lg="4" md="4" sm="12" xs="12" className="px-0 mx-3">
                  <div className="d-flex flex-wrap flex-md-nowrap">
                    <img
                      alt="..."
                      className="rounded"
                      width="360"
                      height="220"
                      style={{ cursor: "pointer", objectFit: "cover" }}
                      src={config.BUCKET_URL + products.image_1}
                      onClick={() => this.handletoDetails(products.quote_id)}
                    />
                  </div>
                </Col> */}
                  <Col lg="8" md="8" sm="12" xs="12" className="px-0 mx-3">
                    <div className="ml-2 mt-md-0 mt-lg-0 mt-xl-0 mt-sm-2 mt-2">
                      <CardTitle className="text-dark my-2">
                        <h3>{products.company_name}</h3>
                        <p>
                          Purchase Order : <b>{products.purchase_order}</b>
                        </p>
                      </CardTitle>
                      <CardText className="description text-dark mb-0">
                        {this.state.listDetail
                          .filter(
                            (poItems) =>
                              poItems.purchase_order === products.purchase_order
                          )
                          .slice(0, 3)
                          .map((items) => (
                            <ul>
                              <li>
                                {items.product_type.substring(0, 100)}
                              </li>
                            </ul>
                          ))}
                      </CardText>
                      <CardText className=" mt-4">
                        <small className="text-muted">
                          {products.created_at}
                        </small>
                      </CardText>
                    </div>
                  </Col>
                  <Col
                    lg="3"
                    md="3"
                    sm="12"
                    xs="12"
                    className="px-0 mx-0 text-right my-auto"
                  >
                    <CardTitle className="text-dark mb-4">
                      <h3>
                        <Badge color="primary" pill>
                          {products.status}
                        </Badge>
                      </h3>
                    </CardTitle>
                    <CardTitle className="text-dark mt-1">
                      Total payment
                    </CardTitle>
                    <CardTitle className="description text-dark mt-1">
                      <b>{currencyIDR(products.total).replace(/\.00$/, "")}</b>
                    </CardTitle>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>
        );
      }

      if (products.status === "delivered") {
        return (
          <>
            <Card
              className="card-lift--hover rounded shadow mt-3 mx-2"
              key={index}
            >
              <CardBody
                style={{ cursor: "pointer", objectFit: "cover" }}
                onClick={() => this.handletoDetails(products.purchase_order)}
              >
                <Row>
                  {/* <Col lg="4" md="4" sm="12" xs="12" className="px-0 mx-3">
                  <div className="d-flex flex-wrap flex-md-nowrap">
                    <img
                      alt="..."
                      className="rounded"
                      width="360"
                      height="220"
                      style={{ cursor: "pointer", objectFit: "cover" }}
                      src={config.BUCKET_URL + products.image_1}
                      onClick={() => this.handletoDetails(products.quote_id)}
                    />
                  </div>
                </Col> */}
                  <Col lg="8" md="8" sm="12" xs="12" className="px-0 mx-3">
                    <div className="ml-2 mt-md-0 mt-lg-0 mt-xl-0 mt-sm-2 mt-2">
                      <CardTitle className="text-dark my-2">
                        <h3>{products.company_name}</h3>
                        <p>
                          Purchase Order : <b>{products.purchase_order}</b>
                        </p>
                      </CardTitle>
                      <CardText className="description text-dark mb-0">
                        {this.state.listDetail
                          .filter(
                            (poItems) =>
                              poItems.purchase_order === products.purchase_order
                          )
                          .slice(0, 3)
                          .map((items) => (
                            <ul>
                              <li>{items.product_type}</li>
                            </ul>
                          ))}
                      </CardText>
                      <CardText className=" mt-4">
                        <small className="text-muted">
                          {products.created_at}
                        </small>
                      </CardText>
                    </div>
                  </Col>
                  <Col
                    lg="3"
                    md="3"
                    sm="12"
                    xs="12"
                    className="px-0 mx-0 text-right my-auto"
                  >
                    <CardTitle className="text-dark mb-4">
                      <h3>
                        <Badge color="success" pill>
                          {/* {products.status} */}Completed
                        </Badge>
                      </h3>
                    </CardTitle>
                    <CardTitle className="text-dark mt-1">
                      Total payment
                    </CardTitle>
                    <CardTitle className="description text-dark mt-1">
                      <b>{currencyIDR(products.total).replace(/\.00$/, "")}</b>
                    </CardTitle>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>
        );
      }
    });

    return (
      <>
        <Container className="bg-secondary mt--6">
          <Breadcumbs
            name="Customer Portofolio"
            parentName="Customer Portofolio"
            link="/customers/customer-portofolio"
          />
          <Row className="flex-wrap-reverse">
            <Col lg="12" md="12" xs="12">
              <Row
                className=" text-center mt-4 mb-3 mx-2"
                // style={{ width: "100%" }}
              >
                <Col md="6" xs="12">
                  {/* <ul class="nav nav-tabs nav-justified mb-3"> */}
                  <ul class="nav  nav-justified mb-3 m-2">
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        style={{ cursor: "pointer", objectFit: "cover" }}
                        onClick={() => this.selectStatus("on progress")}
                      >
                        {/* <b className="text-dark ">{this.state.total_items}</b> */}
                        {this.state.actionListProduct.option_status ===
                        "on progress" ? (
                          <span class="badge bg-info">
                            <b className="text-dark "></b>
                            {this.state.showBadge}
                          </span>
                        ) : (
                          <span class="badge bg-info"></span>
                        )}
                        &emsp;On Progress
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        style={{ cursor: "pointer", objectFit: "cover" }}
                        onClick={() => this.selectStatus("delivered")}
                      >
                        <span class="badge bg-success">
                          {this.state.actionListProduct.option_status ===
                          "delivered"
                            ? this.state.showBadge
                            : null}
                        </span>
                        &emsp;Completed
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        style={{ cursor: "pointer", objectFit: "cover" }}
                        onClick={() => this.selectStatus("")}
                      >
                        <span
                          class="badge"
                          style={{ backgroundColor: "#D1D1D1" }}
                        >
                          {this.state.actionListProduct.option_status === ""
                            ? this.state.showBadge
                            : null}
                        </span>
                        &emsp;All
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col md="3" xs="12" className="text-right">
                  <Input
                    type="search"
                    placeholder="Search product"
                    onKeyPress={this.keyPressed}
                    name="searchpo"
                    value={this.state.actionListProduct.searchpo}
                    onChange={this.handleSearch}
                  />
                </Col>
                <Col md="2" xs="12">
                  {/* <FormGroup> */}
                  {/* <label className="form-control-label">
                          Axle Configuration
                          {this.state.listAxle.length === 0 && (
                            <i className="fa fa-circle-o,-notch fa-spin fa-fw ml-2" />
                          value={this.state.ListYear}
                          )}
                        </label> */}
                  <Input
                    type="select"
                    onKeyPress={this.keyPressed}
                    name="list_year"
                    onChange={this.handleChangeString}
                  >
                    <option value="">Year</option>
                    {this.state.listYear.map((items, index) => {
                      return (
                        <option key={index} value={items.listyears}>
                          {items.listyears}
                        </option>
                      );
                    })}
                  </Input>
                  {/* </FormGroup> */}
                </Col>
                <Col md="1"  sm="12" xs="12" className="text-left">
                  <Button
                  block
                    color="dark"
                    style={{ cursor: "pointer", objectFit: "cover" }}
                    onClick={() => this.handleReset()}
                  >
                    <i class="fa fa-times-circle fa-lg"></i>
                  </Button>
                </Col>
                {/* <Col md={1} xs={2} className="my-auto text-left">
                      <a style={{ cursor: "pointer", objectFit: "cover" }}>
                        <i class="fas fa-filter fa-lg"></i>
                      </a>
                    </Col> */}
              </Row>
              {this.state.total_items === 0 ? (
                <Col lg="12" md="12" xs="12">
                  <Card className="card-frame mt-5">
                    <CardBody>
                      <i class="fa fa-search-minus mr-2"></i>
                      <strong>Data not found ...</strong>
                    </CardBody>
                  </Card>
                </Col>
              ) : (
                <>
                  <Row className="justify-content-center mx-auto rounded border border-dark mb-4">
                    <Col lg="12" md="12" xs="12" className="mt-3">
                      {dataProduct}
                    </Col>
                    {this.state.total_pages < 2 ? null : (
                      <>
                        <Row className="text-center">
                          <Col>
                            <Pagination
                              current_page={this.state.actionListProduct.page}
                              last_page={this.state.total_pages}
                              position="left"
                              handlePageChange={this.handlePagination}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     dataProductType: state.customer_product.dataProductType,
//     dataCustomerPortofolio: state.customerPortofolio.dataCustomerPortofolio,
//   };
// };
// export default connect(mapStateToProps)(ListPortofolio);
export default ListPortofolio;
