import Validator from "validator";

export const validateLogin = (data) => {
  const errors = {};
  if (data.email) {
    if (!Validator.isEmail(data.email)) {
      errors.email = "Invalid email";
    }
  } else if (!data.email) {
    errors.email = "Field is required";
  }

  if (!data.password) {
    errors.password = "Field is required";
  }

  return errors;
};
export const validateCreateNewAccount = (data) => {
  const errors = {};
  if (data.email) {
    if (!Validator.isEmail(data.email)) {
      errors.email = "Invalid email";
    }
  } else if (!data.email) {
    errors.email = "Field is required";
  }
  if (!data.username) {
    errors.username = "Field is required";
  }
  if (!data.password) {
    errors.password = "Field is required";
  }
  if (!data.confirm_password) {
    errors.confirm_password = "Field is required";
  }

  if (data.password !== data.confirm_password) {
    errors.confirm_password = "Not Matching";
  }
  if (!data.company_id) {
    errors.company_id = "Field is required";
  }

  return errors;
};
export const validateCompany = (data) => {
  const errors = {};

  if (!data.company_name) {
    errors.company_name = "Field is required";
  }
  if (!data.company_phone) {
    errors.company_phone = "Field is required";
  }
  if (!data.company_address) {
    errors.company_address = "Field is required";
  }

  if (!data.sales_id) {
    errors.sales_id = "Field is required";
  }

  if (!data.market_share) {
    errors.market_share = "Field is required";
  }

  return errors;
};
export const validateConfigureNow = (data) => {
  const errors = {};
  if (!data.category_id) {
    errors.category_id = "Field is required";
  }
  if (!data.chasis) {
    errors.chasis = "Field is required";
  }
  if (!data.axle) {
    errors.axle = "Field is required";
  }
  if (!data.tank_capacity) {
    errors.tank_capacity = "Field is required";
  }

  return errors;
};
export const validateUser = (data) => {
  const errors = {};
  if (data.email) {
    if (!Validator.isEmail(data.email)) {
      errors.email = "Invalid email";
    }
  } else if (!data.email) {
    errors.email = "Field is required";
  }
  // if (!data.password) {
  //   errors.password = "Field is required";
  // }
  if (!data.username) {
    errors.username = "Field is required";
  }
  if (!data.role_id) {
    errors.role_id = "Field is required";
  }
  return errors;
};
export const validateCategory = (data) => {
  const errors = {};

  if (!data.category_name) {
    errors.category_name = "Field is required";
  }
  if (!data.image_1) {
    errors.image_1 = "Field is required";
  }
  return errors;
};
export const validateStandardComponents = (data) => {
  const errors = {};

  if (!data.category_id) {
    errors.category_id = "Field is required";
  }
  if (!data.standard_component_no) {
    errors.standard_component_no = "Field is required";
  }
  if (!data.standard_component_name) {
    errors.standard_component_name = "Field is required";
  }
  return errors;
};
export const validateSpecifications = (data) => {
  const errors = {};

  if (!data.product_id) {
    errors.product_id = "Field is required";
  }
  if (!data.specification_no) {
    errors.specification_no = "Field is required";
  }
  if (!data.specification_name) {
    errors.specification_name = "Field is required";
  }
  return errors;
};
export const validateOptionalComponents = (data) => {
  const errors = {};

  if (!data.category_id) {
    errors.category_id = "Field is required";
  }
  if (!data.optional_component_no) {
    errors.optional_component_no = "Field is required";
  }
  if (!data.optional_component_name) {
    errors.optional_component_name = "Field is required";
  }
  if (!data.optional_component_price) {
    errors.optional_component_price = "Field is required";
  }
  return errors;
};
export const validateProduct = (data) => {
  const errors = {};

  if (!data.product_no) {
    errors.product_no = "Field is required";
  }
  if (!data.lead_time) {
    errors.lead_time = "Field is required";
  }
  if (!data.product_type) {
    errors.product_type = "Field is required";
  }
  if (!data.category_id) {
    errors.category_id = "Field is required";
  }
  if (!data.chasis) {
    errors.chasis = "Field is required";
  }
  if (!data.axle) {
    errors.axle = "Field is required";
  }
  if (!data.tank_capacity) {
    errors.tank_capacity = "Field is required";
  }
  if (!data.wheel_base) {
    errors.wheel_base = "Field is required";
  }
  if (!data.roh) {
    errors.roh = "Field is required";
  }
  if (!data.product_price) {
    errors.product_price = "Field is required";
  }
  if (!data.image_1) {
    errors.image_1 = "Field is required";
  }
  if (!data.image_2) {
    errors.image_2 = "Field is required";
  }
  if (!data.image_3) {
    errors.image_3 = "Field is required";
  }
  if (!data.image_4) {
    errors.image_4 = "Field is required";
  }
  if (!data.file_specifications) {
    errors.file_specifications = "Field is required";
  }
  return errors;
};
export const validateQuotations = (data) => {
  const errors = {};

  // if (!data.purchase_timeframe) {
  //   errors.purchase_timeframe = "Field is required";
  // }
  // if (!data.shipping_method) {
  //   errors.shipping_method = "Field is required";
  // }
  // if (data.shipping_method === "Delivery") {
  //   if (!data.country) {
  //     errors.country = "Field is required";
  //   }
  //   if (!data.area) {
  //     errors.area = "Field is required";
  //   }
  // }
  // if (!data.payment_terms) {
  //   errors.payment_terms = "Field is required";
  // }
  if (data.quantity < 1) {
    errors.quantity = "Must be more than 0";
  }
  // if (!data.quantity) {
  //   errors.quantity = "Field is required";
  // }
  // if (!data.freight_terms) {
  //   errors.freight_terms = "Field is required";
  // }
  return errors;
};

export const validateProfilCustomer = (data) => {
  const errors = {};
  if (!data.username) {
    errors.password = "Field is required";
  }
  if (!data.old_password) {
    errors.old_password = "Field is required";
  }
  if (!data.new_password) {
    errors.new_password = "Field is required";
  }
  if (!data.confirm_password) {
    errors.confirm_password = "Field is required";
  }
  if (data.confirm_password !== data.new_password) {
    errors.confirm_password = "Confirm password not matching";
  }
  return errors;
};

export const validateProfile = (data) => {
  const errors = {};
  if (!data.username) {
    errors.password = "Field is required";
  }
  if (!data.old_password) {
    errors.old_password = "Field is required";
  }
  if (!data.new_password) {
    errors.new_password = "Field is required";
  }
  if (!data.confirm_password) {
    errors.confirm_password = "Field is required";
  }
  if (data.confirm_password !== data.new_password) {
    errors.confirm_password = "Confirm password not matching";
  }
  return errors;
};

export const validateWaterTruck = (data) => {
  const errors = {};
  if (!data.tank_capacity) {
    errors.tank_capacity = "Field is required";
  }
  if (!data.truck_brand) {
    errors.truck_brand = "Field is required";
  }
  if (!data.truck_model) {
    errors.truck_model = "Field is required";
  }
  if (!data.gvw) {
    errors.gvw = "Field is required";
  }
  if (!data.axle) {
    errors.axle = "Field is required";
  }
  if (!data.wheelbase) {
    errors.wheelbase = "Field is required";
  }
  if (!data.water_pump) {
    errors.water_pump = "Field is required";
  }
  if (!data.water_cannon) {
    errors.water_cannon = "Field is required";
  }
  if (!data.spray_bar) {
    errors.spray_bar = "Field is required";
  }
  if (!data.spray_head) {
    errors.spray_head = "Field is required";
  }
  return errors;
};

export const validateFuelTruck = (data) => {
  const errors = {};
  if (!data.tank_capacity) {
    errors.tank_capacity = "Field is required";
  }
  if (!data.truck_brand) {
    errors.truck_brand = "Field is required";
  }
  if (!data.truck_model) {
    errors.truck_model = "Field is required";
  }
  if (!data.gvw) {
    errors.gvw = "Field is required";
  }
  if (!data.axle) {
    errors.axle = "Field is required";
  }
  if (!data.wheelbase) {
    errors.wheelbase = "Field is required";
  }
  if (!data.fuel_pump) {
    errors.fuel_pump = "Field is required";
  }
  if (!data.flow_rate) {
    errors.flow_rate = "Field is required";
  }
  if (!data.hose_reel) {
    errors.hose_reel = "Field is required";
  }
  if (!data.hose_id) {
    errors.hose_id = "Field is required";
  }
  if (!data.length_hose_reel) {
    errors.length_hose_reel = "Field is required";
  }
  return errors;
};

export const validateWashDownTruck = (data) => {
  const errors = {};
  if (!data.tank_capacity) {
    errors.tank_capacity = "Field is required";
  }
  if (!data.truck_brand) {
    errors.truck_brand = "Field is required";
  }
  if (!data.truck_model) {
    errors.truck_model = "Field is required";
  }
  if (!data.gvw) {
    errors.gvw = "Field is required";
  }
  if (!data.axle) {
    errors.axle = "Field is required";
  }
  if (!data.wheelbase) {
    errors.wheelbase = "Field is required";
  }
  if (!data.water_pump) {
    errors.water_pump = "Field is required";
  }
  if (!data.water_cannon) {
    errors.water_cannon = "Field is required";
  }
  if (!data.spray_head) {
    errors.spray_head = "Field is required";
  }
  if (!data.degreaser) {
    errors.degreaser = "Field is required";
  }
  if (!data.high_pressurer_washer) {
    errors.high_pressurer_washer = "Field is required";
  }
  return errors;
};


export const validateLubeTruck = (data) => {
  const errors = {};
  if (!data.quantity_oil_tanks) {
    errors.quantity_oil_tanks = "Field is required";
  }
  if (!data.quantity_coolant_tanks) {
    errors.quantity_coolant_tanks = "Field is required";
  }
  if (!data.quantity_waste_tanks) {
    errors.quantity_waste_tanks = "Field is required";
  }
  if (!data.quantity_grease_tanks) {
    errors.quantity_grease_tanks = "Field is required";
  }
  if (!data.truck_brand) {
    errors.truck_brand = "Field is required";
  }
  if (!data.truck_model) {
    errors.truck_model = "Field is required";
  }
  if (!data.gvw) {
    errors.gvw = "Field is required";
  }
  if (!data.axle) {
    errors.axle = "Field is required";
  }
  if (!data.wheelbase) {
    errors.wheelbase = "Field is required";
  }
  if (!data.compressor) {
    errors.compressor = "Field is required";
  }
  if (!data.oil_pumps) {
    errors.oil_pumps = "Field is required";
  }
  if (!data.coolant_pumps) {
    errors.coolant_pumps = "Field is required";
  }
  if (!data.waste_pumps) {
    errors.waste_pumps = "Field is required";
  }
  if (!data.grease_pumps) {
    errors.grease_pumps = "Field is required";
  }
  if (!data.oil_filter) {
    errors.oil_filter = "Field is required";
  }
  if (!data.coolant_filter) {
    errors.coolant_filter = "Field is required";
  }
  if (!data.oil_meter) {
    errors.oil_meter = "Field is required";
  }
  if (!data.grease_meter) {
    errors.grease_meter = "Field is required";
  }
  if (!data.coolant_meter) {
    errors.coolant_meter = "Field is required";
  }
  if (!data.hose_reel_oil) {
    errors.hose_reel_oil = "Field is required";
  }
  if (!data.length_hose_reel_oil) {
    errors.length_hose_reel_oil = "Field is required";
  }
  if (!data.hose_reel_coolant) {
    errors.hose_reel_coolant = "Field is required";
  }
  if (!data.length_hose_reel_coolant) {
    errors.length_hose_reel_coolant = "Field is required";
  }
  if (!data.hose_reel_grease) {
    errors.hose_reel_grease = "Field is required";
  }
  if (!data.length_hose_reel_grease) {
    errors.length_hose_reel_grease = "Field is required";
  }
  if (!data.hose_reel_waste) {
    errors.hose_reel_waste = "Field is required";
  } if (!data.hose_reel_coolant) {
    errors.hose_reel_coolant = "Field is required";
  }
  if (!data.length_hose_reel_waste) {
    errors.length_hose_reel_waste = "Field is required";
  }
  return errors;
};

export const validateOHTBody = (data) => {
  const errors = {};
  if (!data.brand) {
    errors.brand = "Field is required";
  }
  if (!data.type) {
    errors.type = "Field is required";
  }
  if (!data.wheelbase) {
    errors.wheelbase = "Field is required";
  }
  if (!data.emow) {
    errors.emow = "Field is required";
  }
  if (!data.tgmow) {
    errors.tgmow = "Field is required";
  }
  if (!data.faxle_e) {
    errors.faxle_e = "Field is required";
  }
  if (!data.raxle_e) {
    errors.raxle_e = "Field is required";
  }
  if (!data.faxle_l) {
    errors.faxle_l = "Field is required";
  }
  if (!data.payload_type) {
    errors.payload_type = "Field is required";
  }
  if (!data.payload_density) {
    errors.payload_density = "Field is required";
  }
  if (!data.t_payload_volume) {
    errors.t_payload_volume = "Field is required";
  }
  if (!data.t_payload_weight) {
    errors.t_payload_weight = "Field is required";
  }
  if (!data.body_type) {
    errors.body_type = "Field is required";
  }
  if (!data.body_heating) {
    errors.body_heating = "Field is required";
  }
  if (!data.travelling_distance) {
    errors.travelling_distance = "Field is required";
  }
  if (!data.loading_unit) {
    errors.loading_unit = "Field is required";
  }
  if (!data.bucket_capacity) {
    errors.bucket_capacity = "Field is required";
  }
  if (!data.production_target_year) {
    errors.production_target_year = "Field is required";
  }
  if (!data.qty) {
    errors.qty = "Field is required";
  }
  if (!data.type) {
    errors.type = "Field is required";
  }
  if (!data.capacity) {
    errors.capacity = "Field is required";
  }
  return errors;
};