import React, { PureComponent } from "react";

import {
  Row,
  Col,
  Container,
  Breadcrumb,
  BreadcrumbItem
} from "reactstrap";

import { Link } from "react-router-dom";
class BreadcumbsStandardConfigurationDetails extends PureComponent {
  render() {
    return (
      <>
        <Container className="px-0">
          <Row className="align-items-center py-4">
            <Col lg="6" xs="7">
              <h6 className="h2 text-black d-inline-block mb-0">
                {this.props.name}
              </h6>{" "}
              <Breadcrumb
                className="d-none d-md-inline-block ml-md-4"
                listClassName="breadcrumb-links breadcrumb-white"
              >
                <BreadcrumbItem>
                  <i className="fas fa-chevron-circle-right text-black" />
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link className="text-black" to={this.props.link}>
                    {this.props.parentName}
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link
                    className="text-black"
                    to={"/customers/" + "customer_standard_configuration_products"}
                  >
                    {this.props.category}
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem
                  aria-current="page"
                  className="active text-black"
                >
                  {this.props.name}
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default BreadcumbsStandardConfigurationDetails;
