import React, { PureComponent } from "react";

import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input,
  CardImg,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from "reactstrap";

// core components
import StandardHeader from "components/Headers/StandardHeader.js";
import {
  validateCategory,
  validateStandardComponents,
  validateOptionalComponents,
} from "services/validate";

//services
import config from "services/config";

//packages
import axios from "axios";
// redux
import { connect } from "react-redux";
import { getDataCategories } from "./Redux/categoryActions";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import Dropzone from "dropzone";
import ListStandardComponents from "./ListStandardComponents";
import ListOptionalComponents from "./ListOptionalComponents";
Dropzone.autoDiscover = false;
let imageRef;
class CategoryDetails extends PureComponent {
  state = {
    data: {
      category_name: "",
      category_description: "",
      image_1: "",
    },
    actionListCategories: {
      page: 1,
      items_per_page: 10,
      order_by_field: "category_id",
      order_by_direction: "DESC",
      category_name: "",
    },
    dataStandardComponents: {
      category_id: 0,
      standard_component_no: "",
      standard_component_name: "",
      standard_component_description: "",
    },
    dataUpdateStandardComponents: {
      category_id: 0,
      standard_component_no: "",
      standard_component_name: "",
      standard_component_description: "",
    },
    updateStandardComponents: false,
    dataOptionalComponents: {
      category_id: 0,
      optional_component_no: "",
      optional_component_name: "",
      optional_component_description: "",
      optional_component_price: "",
    },
    dataUpdateOptionalComponents: {
      category_id: 0,
      optional_component_no: "",
      optional_component_name: "",
      optional_component_description: "",
      optional_component_price: "",
    },
    updateOptionalComponents: false,
    listCategories: [],
    listStandardComponents: [],
    listOptionalComponents: [],
    isLoading: false,
    alert: null,
    paramErrorCategory: {},
    paramErrorStandardComponents: {},
    paramErrorOptionalComponents: {},
  };

  componentDidMount = async() => {
    this.mounted = true;
    try {
      const response = await axios.post(config.API_URL + '/categories/view_category',{ category_id: this.props.match.params.categoryId },{
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      this.setState({
        data: {
          category_id: response.data.data[0].category_id,
          category_name: response.data.data[0].category_name,
          category_description: response.data.data[0].category_description,
          image_1: response.data.data[0].image_1
        }
      });
      this.getListStandardComponents();
      this.getListOptionalComponents();
      this.getDataListCategories();
      this.createFile(response.data.data[0].image_1);
    } catch (err){
      console.log(err)
    }
    this.currentSingleFile = undefined;
    new Dropzone(document.getElementById("dropzone-single"), {
      url: "/",
      autoProcessQueue: false,
      dictDefaultMessage: "Drop image here to upload",
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName(
        "dz-preview-single"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-single")[0]
        .innerHTML,
      maxFiles: 1,
      acceptedFiles: "image/*",
      init: function () {
        this.on("addedfile", function (file) {
          if (this.currentSingleFile) {
            this.removeFile(this.currentSingleFile);
          }
          this.currentSingleFile = file;
          imageRef = this.currentSingleFile;
        });
      },
    });
    document.getElementsByClassName("dz-preview-single")[0].innerHTML = "";
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  createFile = async (image_path) => {
    let response = await fetch(config.BUCKET_URL + image_path);
    let data = await response.blob();
    let metadata = {
      type: "image/*",
    };
    let file = new File([data], "test.jpg", metadata);
    imageRef = file;
  };
  getListStandardComponents = () => {
    axios
      .post(
        config.API_URL + "/standard_components/standard_components_on_category",
        { category_id: this.props.match.params.categoryId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listStandardComponents: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListOptionalComponents = () => {
    axios
      .post(
        config.API_URL + "/optional_components/optional_components_on_category",
        { category_id: this.props.match.params.categoryId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listOptionalComponents: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getDataListCategories = () => {
    axios
      .post(
        config.API_URL + "/categories/categories",
        this.state.actionListCategories,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listCategories: res.data.data,
              },
              () => {
                this.props.dispatch(
                  getDataCategories(this.state.listCategories)
                );
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  putDataToAPI = () => {
    this.setState(
      {
        isLoading: true,
        data: {
          ...this.state.data,
          image_1: imageRef,
        },
      },
      () => {
        let formData = new FormData();
        formData.append("category_id", this.props.match.params.categoryId);
        formData.append("image_1", this.state.data.image_1);
        formData.append("category_name", this.state.data.category_name);
        formData.append(
          "category_description",
          this.state.data.category_description
        );
        const paramErrorCategory = validateCategory(this.state.data);
        this.setState({ paramErrorCategory });
        if (Object.keys(paramErrorCategory).length === 0) {
          axios
            .post(config.API_URL + "/categories/update_category", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: config.TOKEN,
              },
            })
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data.message);
                  this.setState({ isLoading: false });
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(err.response.data.message);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };

  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeInt = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: parseInt(event.target.value),
      },
    });
  };
  remove = (data) => {
    axios
      .post(
        config.API_URL + "/categories/delete_category",
        { category_id: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlert();
          this.handleFilter();
        },
        (err) => {
          console.log(err);
          if (this.mounted) {
            if (err.response !== undefined) {
              this.failedAlert(err.response.data.message);
            }
          }
        }
      );
  };

  confirmAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.remove(data.category_id)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.category_name}`} category, You won't be able to revert
          this!
        </SweetAlert>
      ),
    });
  };

  putDataStandardComponentToAPI = () => {
    this.setState({ isLoading: true });
    const paramErrorStandardComponents = validateStandardComponents(
      this.state.dataUpdateStandardComponents
    );
    this.setState({ paramErrorStandardComponents });
    if (Object.keys(paramErrorStandardComponents).length === 0) {
      axios
        .post(
          config.API_URL + "/standard_components/update_standard_component",
          this.state.dataUpdateStandardComponents,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(res.data.message);
              this.setState({ isLoading: false });
              this.getListStandardComponents();
            }
          },
          (err) => {
            if (this.mounted) {
              this.failedAlert(
                this.state.dataUpdateStandardComponents.standard_component_name
              );
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };

  putDataOptionalComponentToAPI = () => {
    this.setState({ isLoading: true });
    const paramErrorOptionalComponents = validateOptionalComponents(
      this.state.dataUpdateOptionalComponents
    );
    this.setState({ paramErrorOptionalComponents });
    if (Object.keys(paramErrorOptionalComponents).length === 0) {
      axios
        .post(
          config.API_URL + "/optional_components/update_optional_component",
          this.state.dataUpdateOptionalComponents,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(res.data.message);
              this.setState({ isLoading: false });
              this.getListOptionalComponents();
            }
          },
          (err) => {
            if (this.mounted) {
              this.failedAlert(
                this.state.dataUpdateOptionalComponents.optional_component_name
              );
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };

  confirmedAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Category has been deleted.
        </SweetAlert>
      ),
    });
  };

  handleSubmit = () => {
    this.putDataToAPI();
  };

  handleSubmitUpdateStandardComponents = () => {
    this.putDataStandardComponentToAPI();
  };

  handleSubmitUpdateOptionalComponents = () => {
    this.putDataOptionalComponentToAPI();
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  previewImage = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="secondary"
          confirmBtnText="Close"
          btnSize=""
        >
          <CardImg alt="..." src={config.BUCKET_URL + data} top />
        </SweetAlert>
      ),
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  removeStandardComponents = (data) => {
    axios
      .post(
        config.API_URL + "/standard_components/delete_standard_component",
        { standard_component_id: data.standard_component_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlertStandardComponents();
          this.getListStandardComponents();
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  removeOptionalComponents = (data) => {
    axios
      .post(
        config.API_URL + "/optional_components/delete_optional_component",
        { optional_component_id: data.optional_component_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlertOptionalComponents();
          this.getListOptionalComponents();
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  confirmAlertStandardComponents = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.removeStandardComponents(data)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.standard_component_name}`} user, You won't be able to
          revert this!
        </SweetAlert>
      ),
    });
  };
  confirmAlertOptionalComponents = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.removeOptionalComponents(data)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.optional_component_name}`} user, You won't be able to
          revert this!
        </SweetAlert>
      ),
    });
  };

  confirmedAlertStandardComponents = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Standard component has been deleted.
        </SweetAlert>
      ),
    });
  };
  confirmedAlertOptionalComponents = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Optional component has been deleted.
        </SweetAlert>
      ),
    });
  };

  handleUpdateOptionalComponents = (data) => {
    this.setState({
      dataUpdateOptionalComponents: {
        category_id: data.category_id,
        optional_component_id: data.optional_component_id,
        optional_component_no: data.optional_component_no,
        optional_component_name: data.optional_component_name,
        optional_component_description: data.optional_component_description,
        optional_component_price: data.optional_component_price,
      },
      updateOptionalComponents: true,
    });
  };

  handleChangeStringUpdateOptionalComponents = (event) => {
    this.setState({
      dataUpdateOptionalComponents: {
        ...this.state.dataUpdateOptionalComponents,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeStringOptionalComponents = (event) => {
    this.setState({
      dataOptionalComponents: {
        ...this.state.dataOptionalComponents,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleUpdateStandardComponents = (data) => {
    this.setState({
      dataUpdateStandardComponents: {
        category_id: data.category_id,
        standard_component_id: data.standard_component_id,
        standard_component_no: data.standard_component_no,
        standard_component_name: data.standard_component_name,
        standard_component_description: data.standard_component_description,
      },
      updateStandardComponents: true,
    });
  };

  handleChangeStringUpdateStandardComponents = (event) => {
    this.setState({
      dataUpdateStandardComponents: {
        ...this.state.dataUpdateStandardComponents,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeStringStandardComponents = (event) => {
    this.setState({
      dataStandardComponents: {
        ...this.state.dataStandardComponents,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleSubmitStandardComponents = () => {
    this.setState(
      {
        isLoading: true,
        dataStandardComponents: {
          ...this.state.dataStandardComponents,
          category_id: this.props.match.params.categoryId,
        },
      },
      () => {
        const paramErrorStandardComponents = validateStandardComponents(
          this.state.dataStandardComponents
        );
        this.setState({ paramErrorStandardComponents });
        if (Object.keys(paramErrorStandardComponents).length === 0) {
          axios
            .post(
              config.API_URL + "/standard_components/create_standard_component",
              this.state.dataStandardComponents,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: config.TOKEN,
                },
              }
            )
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data.message);
                  this.setState({
                    isLoading: false,
                    dataStandardComponents: {
                      ...this.state.dataStandardComponents,
                      category_id: 0,
                      standard_component_no: "",
                      standard_component_name: "",
                      standard_component_description: "",
                    },
                  });
                  this.getListStandardComponents();
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(
                      this.state.dataStandardComponents.standard_component_name
                    );
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };

  handleSubmitOptionalComponents = () => {
    this.setState(
      {
        isLoading: true,
        dataOptionalComponents: {
          ...this.state.dataOptionalComponents,
          category_id: this.props.match.params.categoryId,
        },
      },
      () => {
        const paramErrorOptionalComponents = validateOptionalComponents(
          this.state.dataOptionalComponents
        );
        this.setState({ paramErrorOptionalComponents });
        if (Object.keys(paramErrorOptionalComponents).length === 0) {
          axios
            .post(
              config.API_URL + "/optional_components/create_optional_component",
              this.state.dataOptionalComponents,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: config.TOKEN,
                },
              }
            )
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data.message);
                  this.setState({
                    isLoading: false,
                    dataOptionalComponents: {
                      ...this.state.dataOptionalComponents,
                      category_id: 0,
                      optional_component_no: "",
                      optional_component_name: "",
                      optional_component_description: "",
                      optional_component_price: "",
                    },
                  });
                  this.getListOptionalComponents();
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(
                      this.state.dataOptionalComponents.optional_component_name
                    );
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };

  render() {
    return (
      <>
        <StandardHeader
          name="Category Details"
          parentName="Categories"
          link="categories"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Category Details</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Category Name
                          </label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="category_name"
                            placeholder="Category Name"
                            value={this.state.data.category_name}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramErrorCategory.category_name
                                ? "is-invalid"
                                : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramErrorCategory.category_name}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Category Description <small>optional</small>
                          </label>
                          <Input
                            type="textarea"
                            onKeyPress={this.keyPressed}
                            name="category_description"
                            placeholder="Category Description"
                            value={this.state.data.category_description}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramErrorCategory.category_description
                                ? "is-invalid"
                                : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramErrorCategory.category_description}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <label className="form-control-label">Image</label>
                        <div
                          className="dropzone dropzone-single mb-3"
                          id="dropzone-single"
                        >
                          <div className="fallback">
                            <div className="custom-file">
                              <input
                                className="custom-file-input"
                                id="projectCoverUploads"
                                type="file"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="projectCoverUploads"
                              >
                                Choose file
                              </label>
                            </div>
                          </div>
                          <div className="dz-preview dz-preview-single">
                            <div className="dz-preview-cover">
                              <img
                                alt="..."
                                className="dz-preview-img"
                                data-dz-thumbnail=""
                              />
                            </div>
                          </div>
                        </div>
                        {this.state.paramErrorCategory.image_1 && (
                          <small className="text-warning">
                            {this.state.paramErrorCategory.image_1}
                          </small>
                        )}
                        <div style={{ marginTop: 10 }}>
                          <Button
                            outline
                            className="btn-neutral"
                            size="sm"
                            onClick={() =>
                              this.previewImage(this.state.data.image_1)
                            }
                          >
                            View Image
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                      <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-3 col-sm-6"
                        xs="12"
                      >
                        <Button
                          block
                          color="success"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isLoading ? true : false}
                          onClick={() => this.handleSubmit()}
                        >
                          {this.state.isLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Save
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Save</span>
                          )}
                        </Button>
                      </Col>

                      <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-3 col-sm-6"
                        xs="12"
                      >
                        <Button
                          block
                          color="danger"
                          disabled={this.state.isLoading ? true : false}
                          onClick={() => this.confirmAlert(this.state.data)}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <Row>
            <Col>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.state.updateStandardComponents
                          ? "Update Standard Component"
                          : "Create Standard Component"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">No</label>
                        <Input
                          type="text"
                          onKeyPress={this.keyPressed}
                          name="standard_component_no"
                          placeholder="No"
                          value={
                            this.state.updateStandardComponents
                              ? this.state.dataUpdateStandardComponents
                                  .standard_component_no
                              : this.state.dataStandardComponents
                                  .standard_component_no
                          }
                          required
                          className={
                            this.state.paramErrorStandardComponents
                              .standard_component_no
                              ? "is-invalid"
                              : ""
                          }
                          onChange={
                            this.state.updateStandardComponents
                              ? this.handleChangeStringUpdateStandardComponents
                              : this.handleChangeStringStandardComponents
                          }
                        />
                        <div className="invalid-feedback">
                          {
                            this.state.paramErrorStandardComponents
                              .standard_component_no
                          }
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input
                          type="text"
                          onKeyPress={this.keyPressed}
                          name="standard_component_name"
                          placeholder="Name"
                          value={
                            this.state.updateStandardComponents
                              ? this.state.dataUpdateStandardComponents
                                  .standard_component_name
                              : this.state.dataStandardComponents
                                  .standard_component_name
                          }
                          required
                          className={
                            this.state.paramErrorStandardComponents
                              .standard_component_name
                              ? "is-invalid"
                              : ""
                          }
                          onChange={
                            this.state.updateStandardComponents
                              ? this.handleChangeStringUpdateStandardComponents
                              : this.handleChangeStringStandardComponents
                          }
                        />
                        <div className="invalid-feedback">
                          {
                            this.state.paramErrorStandardComponents
                              .standard_component_name
                          }
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Description
                        </label>
                        <Input
                          type="textarea"
                          onKeyPress={this.keyPressed}
                          name="standard_component_description"
                          placeholder="Description"
                          value={
                            this.state.updateStandardComponents
                              ? this.state.dataUpdateStandardComponents
                                  .standard_component_description
                              : this.state.dataStandardComponents
                                  .standard_component_description
                          }
                          required
                          className={
                            this.state.paramErrorStandardComponents
                              .standard_component_description
                              ? "is-invalid"
                              : ""
                          }
                          onChange={
                            this.state.updateStandardComponents
                              ? this.handleChangeStringUpdateStandardComponents
                              : this.handleChangeStringStandardComponents
                          }
                        />
                        <div className="invalid-feedback">
                          {
                            this.state.paramErrorStandardComponents
                              .standard_component_description
                          }
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <>
                      {this.state.updateStandardComponents ? (
                        <>
                          <Col
                            style={{ marginTop: 20 }}
                            className="col-6 col-md-3 col-sm-6"
                            xs="12"
                          >
                            <Button
                              block
                              color="success"
                              className="btn-icon"
                              type="button"
                              disabled={this.state.isLoading ? true : false}
                              onClick={() =>
                                this.handleSubmitUpdateStandardComponents()
                              }
                            >
                              {this.state.isLoading ? (
                                <>
                                  <span className="btn-inner--text">
                                    <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                    Save
                                  </span>
                                </>
                              ) : (
                                <span className="btn-inner--text">Save</span>
                              )}
                            </Button>
                          </Col>
                          <Col
                            style={{ marginTop: 20 }}
                            className="col-6 col-md-3 col-sm-6"
                            xs="12"
                          >
                            <Button
                              block
                              color="second"
                              disabled={this.state.isLoading ? true : false}
                              onClick={() =>
                                this.setState({
                                  updateStandardComponents: false,
                                })
                              }
                            >
                              Cancel
                            </Button>
                          </Col>
                        </>
                      ) : (
                        <Col
                          style={{ marginTop: 20 }}
                          className="col-6 col-md-3 col-sm-6"
                          xs="12"
                        >
                          <Button
                            block
                            color="success"
                            className="btn-icon"
                            type="button"
                            disabled={this.state.isLoading ? true : false}
                            onClick={() =>
                              this.handleSubmitStandardComponents()
                            }
                          >
                            {this.state.isLoading ? (
                              <>
                                <span className="btn-inner--text">
                                  <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                  Submit
                                </span>
                              </>
                            ) : (
                              <span className="btn-inner--text">Submit</span>
                            )}
                          </Button>
                        </Col>
                      )}
                    </>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListStandardComponents
                  list={this.state.listStandardComponents}
                  info={this.state.data.category_name}
                  update={this.handleUpdateStandardComponents}
                  delete={this.confirmAlertStandardComponents}
                />
              </div>
            </div>
          </Row>
          <Row>
            <Col>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.state.updateOptionalComponents
                          ? "Update Optional Component"
                          : "Create Optional Component"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">No</label>
                        <Input
                          type="text"
                          onKeyPress={this.keyPressed}
                          name="optional_component_no"
                          placeholder="No"
                          value={
                            this.state.updateOptionalComponents
                              ? this.state.dataUpdateOptionalComponents
                                  .optional_component_no
                              : this.state.dataOptionalComponents
                                  .optional_component_no
                          }
                          required
                          className={
                            this.state.paramErrorOptionalComponents
                              .optional_component_no
                              ? "is-invalid"
                              : ""
                          }
                          onChange={
                            this.state.updateOptionalComponents
                              ? this.handleChangeStringUpdateOptionalComponents
                              : this.handleChangeStringOptionalComponents
                          }
                        />
                        <div className="invalid-feedback">
                          {
                            this.state.paramErrorOptionalComponents
                              .optional_component_no
                          }
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Name</label>
                        <Input
                          type="text"
                          onKeyPress={this.keyPressed}
                          name="optional_component_name"
                          placeholder="Name"
                          value={
                            this.state.updateOptionalComponents
                              ? this.state.dataUpdateOptionalComponents
                                  .optional_component_name
                              : this.state.dataOptionalComponents
                                  .optional_component_name
                          }
                          required
                          className={
                            this.state.paramErrorOptionalComponents
                              .optional_component_name
                              ? "is-invalid"
                              : ""
                          }
                          onChange={
                            this.state.updateOptionalComponents
                              ? this.handleChangeStringUpdateOptionalComponents
                              : this.handleChangeStringOptionalComponents
                          }
                        />
                        <div className="invalid-feedback">
                          {
                            this.state.paramErrorOptionalComponents
                              .optional_component_name
                          }
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Description
                        </label>
                        <Input
                          type="textarea"
                          onKeyPress={this.keyPressed}
                          name="optional_component_description"
                          placeholder="Description"
                          value={
                            this.state.updateOptionalComponents
                              ? this.state.dataUpdateOptionalComponents
                                  .optional_component_description
                              : this.state.dataOptionalComponents
                                  .optional_component_description
                          }
                          required
                          className={
                            this.state.paramErrorOptionalComponents
                              .optional_component_description
                              ? "is-invalid"
                              : ""
                          }
                          onChange={
                            this.state.updateOptionalComponents
                              ? this.handleChangeStringUpdateOptionalComponents
                              : this.handleChangeStringOptionalComponents
                          }
                        />
                        <div className="invalid-feedback">
                          {
                            this.state.paramErrorOptionalComponents
                              .optional_component_description
                          }
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Price</label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              IDR
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="number"
                            onKeyPress={this.keyPressed}
                            name="optional_component_price"
                            placeholder="0.00"
                            value={
                              this.state.updateOptionalComponents
                                ? this.state.dataUpdateOptionalComponents
                                    .optional_component_price
                                : this.state.dataOptionalComponents
                                    .optional_component_price
                            }
                            required
                            className={
                              this.state.paramErrorOptionalComponents
                                .optional_component_price
                                ? "is-invalid"
                                : ""
                            }
                            onChange={
                              this.state.updateOptionalComponents
                                ? this
                                    .handleChangeStringUpdateOptionalComponents
                                : this.handleChangeStringOptionalComponents
                            }
                          />
                          <div className="invalid-feedback">
                            {
                              this.state.paramErrorOptionalComponents
                                .optional_component_price
                            }
                          </div>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <>
                      {this.state.updateOptionalComponents ? (
                        <>
                          <Col
                            style={{ marginTop: 20 }}
                            className="col-6 col-md-3 col-sm-6"
                            xs="12"
                          >
                            <Button
                              block
                              color="success"
                              className="btn-icon"
                              type="button"
                              disabled={this.state.isLoading ? true : false}
                              onClick={() =>
                                this.handleSubmitUpdateOptionalComponents()
                              }
                            >
                              {this.state.isLoading ? (
                                <>
                                  <span className="btn-inner--text">
                                    <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                    Save
                                  </span>
                                </>
                              ) : (
                                <span className="btn-inner--text">Save</span>
                              )}
                            </Button>
                          </Col>
                          <Col
                            style={{ marginTop: 20 }}
                            className="col-6 col-md-3 col-sm-6"
                            xs="12"
                          >
                            <Button
                              block
                              color="second"
                              disabled={this.state.isLoading ? true : false}
                              onClick={() =>
                                this.setState({
                                  updateOptionalComponents: false,
                                })
                              }
                            >
                              Cancel
                            </Button>
                          </Col>
                        </>
                      ) : (
                        <Col
                          style={{ marginTop: 20 }}
                          className="col-6 col-md-3 col-sm-6"
                          xs="12"
                        >
                          <Button
                            block
                            color="success"
                            className="btn-icon"
                            type="button"
                            disabled={this.state.isLoading ? true : false}
                            onClick={() =>
                              this.handleSubmitOptionalComponents()
                            }
                          >
                            {this.state.isLoading ? (
                              <>
                                <span className="btn-inner--text">
                                  <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                  Submit
                                </span>
                              </>
                            ) : (
                              <span className="btn-inner--text">Submit</span>
                            )}
                          </Button>
                        </Col>
                      )}
                    </>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListOptionalComponents
                  list={this.state.listOptionalComponents}
                  info={this.state.data.category_name}
                  update={this.handleUpdateOptionalComponents}
                  delete={this.confirmAlertOptionalComponents}
                />
              </div>
            </div>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataCategories: state.category.dataCategories,
  };
};

export default connect(mapStateToProps)(CategoryDetails);
