
import React, { PureComponent } from "react";

import {
  Row,
  Col,
  Container
} from "reactstrap";

class Title extends PureComponent {
  render() {
    return (
      <>
        <Container className="px-0">
          <Row className="align-items-center py-4">
            <Col>
              <h6 className="h2 text-black d-inline-block mb-0 text-right">
                {this.props.name}
              </h6>{" "}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Title;
