import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";
import config from "services/config";
import { capitalize } from "services/capitalize";
import { currencyIDR } from "services/currency";
import Myriad from "./MyriadProRegular.ttf"

//Register Font 
Font.register(
  {
    family: 'Myriad',
    src: Myriad
  }
)
// Create styles
const styles = StyleSheet.create({
  textTitle: {
    fontSize: 18,
    textAlign: "justify",
    fontFamily: "Myriad",
    color: '#575656'
  },
  textTitle2: {
    fontSize: 18,
    textAlign: "justify",
    margin: 10,
    fontFamily: "Myriad",
    color: '#575656'
  },
  section1: {
    marginTop: 200,
    marginHorizontal: 40,
    padding: 10,
    flexGrow: 1,
  },
  textParagraf1: {
    fontSize: 12,
    textAlign: "justify",
    marginTop: 5,
    fontFamily: "Myriad",
    color: '#575656'
  },
  image1: {
    borderRadius: 15,
    position: "relative",
    display: "block",
    height: "180px",
    width: "250px"
  },
  section2: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
  },
  fillColorVariant1: {
    backgroundColor: '#A9A9A9',
    borderRadius: 2,
    width: 55,
    marginLeft: 7
  },
  fillColorVariant2: {
    backgroundColor: '#A9A9A9',
    borderRadius: 2,
    width: 123,
    marginLeft: 7
  },
  textVariant: {
    fontSize: 12,
    textAlign: "justify",
    marginTop: 5,
    marginLeft: 10,
    color: 'white',
    fontFamily: "Myriad",

  },
  sectionVariant: {
    marginLeft: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  pageBackground: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    display: "block",
    height: "100%",
    width: "100%",
  },
  sectionVariantSpec: {
    marginLeft: 5,
    marginRight: 5,
    width: 350,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItem: ""
  },
  textParagrafSpec: {
    fontSize: 8,
    textAlign: "justify",
    marginTop: 5,
    fontFamily: "Myriad",
    color: '#575656'
  },
  textParagrafSpecDesc: {
    fontSize: 8,
    textAlign: "left",
    marginTop: 5,
    fontFamily: "Myriad",
    color: '#575656'
  },
});

// Create Document Component
export const MyDocument = (props) => {
  return (
    <Document>
      <Page size="A4">
        <Image
          style={styles.pageBackground}
          src={require("assets/img/document/background.jpg")}
        />
        <View style={styles.section1}>
          <Text style={styles.textTitle}>
            {props.data.category_name.toUpperCase()}
          </Text>
          <Text style={styles.textParagraf1}>
            {props.data.product_description}
          </Text>
          <View style={styles.section2}>
            <Image
              style={styles.image1}
              src={{ uri: config.BUCKET_URL + props.data.image_1, method: 'GET', headers: {}, body: '' }}
            />
            <View style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <View style={styles.fillColorVariant1}>
                <Text style={styles.textVariant}>
                  Variant
                </Text>
              </View>
              <View style={styles.sectionVariant}>
                <Text style={styles.textParagraf1}>Capacity :</Text>
                <Text style={styles.textParagraf1}>{" " + props.data.tank_capacity}</Text>
              </View>
              <View style={styles.sectionVariant}>
                <Text style={styles.textParagraf1}>Prime Mover :</Text>
                <Text style={styles.textParagraf1}>{" " + props.data.axle}</Text>
              </View>
              <View style={styles.sectionVariant}>
                <Text style={styles.textParagraf1}>Truck :</Text>
                <Text style={styles.textParagraf1}>{" " + props.data.chasis}</Text>
              </View>
              <View style={styles.fillColorVariant2}>
                <Text style={styles.textVariant}>
                  Optional Component
                </Text>
              </View>
              {props.optional.map(element => {
                return (
                  <View style={styles.sectionVariant}>
                    <Text style={styles.textParagraf1}>{capitalize(element.quotation_optional_component_name)} :</Text>
                    <Text style={styles.textParagraf1}>{currencyIDR(element.quotation_optional_component_price)}</Text>
                  </View>
                )
              })

              }
            </View>
          </View>
          <Text style={styles.textTitle2}>
            TECHNICAL SPECIFICATION
          </Text>
          {
            props.specifications.map(element => {
              return (
                <View style={styles.sectionVariantSpec}>
                  <Text style={styles.textParagrafSpec}>{capitalize(element.quotation_specification_name)} :</Text>
                  <Text style={styles.textParagrafSpecDesc}>{capitalize(element.quotation_specification_description)}</Text>
                </View>
              )
            })
          }
        </View>
      </Page>
    </Document>
  );
};
