import React, { PureComponent } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Alert,
  CardBody,
  FormText,
  Badge,
  UncontrolledPopover,
  PopoverBody
} from "reactstrap";
import { currencyIDR } from "services/currency";
import config from "services/config";
import axios from "axios";
import Pagination from "react-bootstrap-pagination-logic";
import SweetAlert from "react-bootstrap-sweetalert";
import StandardHeader from "components/Headers/StandardHeader";
import moment from "moment";
class ListProductsQuotation extends PureComponent {
  state = {
    actionListQuotations: {
      page: 1,
      items_per_page: 10,
      order_by_field: "created_at",
      order_by_direction: "DESC",
      quotations_id: this.props.match.params.quotationsId,
      status: "",
    },
    ready: false,
    alert: null,
    dataCompany: [],
    listQuotations: [],
    total_pages: 0,
    total_items: 0,
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.getDataListQuotations();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  toggle() {
    this.setState(
      (prevState) => ({
        ready: false,
      }),
      () => {
        setTimeout(() => {
          this.setState({ ready: true });
        }, 1);
      }
    );
  }

  handleChangeString = (event) => {
    this.setState({
      actionListQuotations: {
        ...this.state.actionListQuotations,
        [event.target.name]: event.target.value,
      },
    });
  };

  getDataListQuotations = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/quotations/quotations",
        this.state.actionListQuotations,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listQuotations: res.data.data,
                total_pages: res.data.total_pages,
                total_items: res.data.total_items,
              },
              () => {
                // this.getViewCompany();
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  // getViewCompany = () => {
  //   axios
  //     .post(
  //       config.API_URL + "/companies/view_company",
  //       { company_id: this.state.listQuotations[0].company_id },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         if (this.mounted) {
  //           this.setState({
  //             dataCompany: res.data.data[0],
  //           });
  //         }
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };

  handleDetails = (data) => {
    this.props.history.push("/admin/quotation_details/" + data.quote_id);
  };

  handlePagination = (numPage) => {
    this.setState(
      {
        actionListQuotations: {
          ...this.state.actionListQuotations,
          page: numPage,
        },
      },
      () => {
        this.getDataListQuotations();
      }
    );
  };

  handleItemPerpage = (e) => {
    this.setState(
      {
        actionListQuotations: {
          ...this.state.actionListQuotations,
          page: 1,
          items_per_page: parseInt(e.target.value),
        },
      },
      () => {
        this.getDataListQuotations();
      }
    );
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleFilter = () => {
    this.setState(
      {
        actionListQuotations: {
          ...this.state.actionListQuotations,
          page: 1,
        },
      },
      () => {
        this.getDataListQuotations();
      }
    );
  };

  handleReset = () => {
    this.setState(
      {
        actionListQuotations: {
          page: 1,
          items_per_page: 10,
          order_by_field: "created_at",
          order_by_direction: "DESC",
          quotations_id: this.props.match.params.quotationsId,
          status: "",
        },
        selectedOptionCompanies: [],
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getDataListQuotations();
            }
          );
        }
      }
    );
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  handleStatus = (data) => {
    if (data === "Pending") {
      return "bg-warning";
    }
    if (data === "In Review") {
      return "bg-warning";
    }
    if (data === "Approved") {
      return "bg-success";
    }
    if (data === "Processed") {
      return "bg-success";
    }
    if (data === "Rejected") {
      return "bg-danger";
    }
  };
  render() {
    let fromItem = null;
    let toItem = null;

    if (this.state.total_pages === this.state.actionListQuotations.page) {
      toItem = this.state.total_items;
    } else {
      toItem =
        this.state.listQuotations.length * this.state.actionListQuotations.page;
    }
    if (this.state.actionListQuotations.page === 1) {
      fromItem = 1;
    } else if (
      this.state.total_pages === this.state.actionListQuotations.page
    ) {
      fromItem = this.state.total_items - this.state.listQuotations.length + 1;
    } else {
      fromItem =
        this.state.listQuotations.length *
          (this.state.actionListQuotations.page - 1) +
        1;
    }

    const availdataQuotations = this.state.listQuotations.map((quotations, index) => {
      return (
        <tr
          key={quotations.quotation_id}
          style={{ cursor: "pointer" }}
          onDoubleClick={() => this.handleDetails(quotations)}
        >
          <td>{quotations.quotations_id}</td>
          <th scope="row">
            <Media className="align-items-center">
              <a
                className="avatar rounded-circle mr-3"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <img
                  alt="..."
                  src={config.BUCKET_URL + quotations.image_1}
                />
              </a>
              <Media>
                <span className="mb-0 text-sm">
                {quotations.category_name} {quotations.chasis} {quotations.tank_capacity}{" "}
                  {quotations.axle}
                </span>
              </Media>
            </Media>
          </th>
          <td>{quotations.product_description.substring(0, 30) + "..."}
            <Button
              color="link"
              className="px-0"
              id={"UncontrolledPopover" + index}
              type="button"
              size="sm"
            >
              Read more
                </Button>

            <UncontrolledPopover
              placement="bottom"
              target={"UncontrolledPopover" + index}
            >
              <PopoverBody>{quotations.product_description}</PopoverBody>
            </UncontrolledPopover>
          </td>
          <td>{quotations.username}</td>
          {/* <td>{this.state.dataCompany.company_name}</td>
          <td>{this.state.dataCompany.username}</td> */}
          <td>{currencyIDR(quotations.product_price).replace(/\.00$/, "")}</td>
          {/* <td>{quotations.tax + " %"}</td>
          <td>{quotations.discount + " %"}</td> */}
          <td>
            {currencyIDR(quotations.total_product_price).replace(/\.00$/, "")}
          </td>
          <td>{quotations.quantity}</td>
          <td>{currencyIDR(quotations.grand_total).replace(/\.00$/, "")}</td>
          {/* <td>{quotations.purchase_timeframe}</td> */}
          <td>
            <Badge color="" className="badge-dot mr-4">
              <i className={this.handleStatus(quotations.status)} />
              {quotations.status}
            </Badge>
          </td>
          <td>{moment(quotations.created_at).format("lll")}</td>
          <td className="text-right">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                role="button"
                size="sm"
                color=""
                onClick={(e) => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => this.handleDetails(quotations)}>
                  Details
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });
    return (
      <>
        {/* <StandardHeader
          name="List Preliminary Spec"
          parentName="Preliminary Spec"
          link="quotations"
        /> */}
        <StandardHeader
          name="Configuration Record"
          parentName="Configuration Record"
          link="quotations"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="6">
                      <h3 className="mb-0">Filter Configuration Record</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Status</label>
                        <Input
                          type="select"
                          name="status"
                          placeholder="Status"
                          value={this.state.actionListQuotations.status}
                          onChange={this.handleChangeString}
                        >
                          <option value="">Select</option>
                          <option value="Pending">Pending</option>
                          <option value="In Review">In Review</option>
                          {/* <option value="Approved">Approved</option> */}
                          <option value="Processed">Processed</option>
                          {/* <option value="Rejected">Rejected</option> */}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col
                      style={{ marginTop: 20 }}
                      className="col-6 col-md-3 col-sm-6"
                      xs="12"
                    >
                      <div className="text-center">
                        <Button
                          block
                          color="success"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isFilterLoading ? true : false}
                          onClick={() => this.handleFilter()}
                        >
                          {this.state.isFilterLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Filter
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Filter</span>
                          )}
                        </Button>
                      </div>
                    </Col>
                    <Col
                      style={{ marginTop: 20 }}
                      className="col-6 col-md-3 col-sm-6"
                      xs="12"
                    >
                      <Button
                        block
                        color="danger"
                        className="btn-icon"
                        type="button"
                        disabled={this.state.isResetLoading ? true : false}
                        onClick={() => this.handleReset()}
                      >
                        {this.state.isResetLoading ? (
                          <>
                            <span className="btn-inner--text">
                              <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                              Reset
                            </span>
                          </>
                        ) : (
                          <span className="btn-inner--text">Reset</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Row>
                {/* <Col lg="8" xs="8">
                  <Title
                    name={
                      "PreSpec ID: " + this.props.match.params.quotationsId
                    }
                  />
                </Col>
                <Col className="text-right" lg="4" xs="4">
                  <div>
                    {this.state.ready && (
                      <PDFDownloadLink
                        document={
                          <MyDocumentMultipleProducts
                            data={{
                              products: this.state.listQuotations,
                              company: this.state.dataCompany,
                            }}
                          />
                        }
                        fileName={
                          "PreSpec-" +
                          this.props.match.params.quotationsId +
                          ".pdf"
                        }
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <>
                              <Button
                                className="btn-second text-uppercase"
                                onClick={() => this.toggle()}
                              >
                                <span className="btn-inner--text">
                                  <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                  Generate PDF
                                </span>
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                className="btn-second text-uppercase"
                                onClick={() => this.setState({ ready: false })}
                              >
                                Download PDF
                              </Button>
                            </>
                          )
                        }
                      </PDFDownloadLink>
                    )}
                    {!this.state.ready && (
                      <>
                        <Button
                          className="btn-second text-uppercase"
                          onClick={() => this.toggle()}
                        >
                          Generate PDF
                        </Button>
                      </>
                    )}
                  </div>
                </Col> */}
              </Row>
              <div className="card-wrapper">
                <Card>
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">List Configuration Record</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <div>
                    {this.state.listQuotations.length < 5 ? (
                      <div>
                        {this.state.listQuotations.length === 0 ? (
                          <Alert color="secondary">
                            <strong>Data not found!</strong> Please check again!
                          </Alert>
                        ) : (
                          <>
                            <Table
                              className="align-items-center table-flush"
                              hover
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">ID</th>
                                  <th scope="col">Product</th>
                                  <th scope="col">Description</th>
                                  <th scope="col">Attention</th>
                                  {/* <th scope="col">Company</th>
                                  <th scope="col">Sales</th> */}
                                  <th scope="col">Price</th>
                                  {/* <th scope="col">Tax</th>
                                  <th scope="col">Discount</th> */}
                                  <th scope="col">Total/Unit</th>
                                  <th scopre="col">Quantity</th>
                                  <th scopre="col">Grand Total</th>
                                  {/* <th scope="col">Purchase Timeframe</th> */}
                                  <th scope="col">Status</th>
                                  <th scope="col">Created At</th>
                                  <th>ACTIONS</th>
                                </tr>
                              </thead>
                              <tbody>{availdataQuotations}</tbody>
                            </Table>
                          </>
                        )}
                      </div>
                    ) : (
                      <>
                        <Table
                          className="align-items-center table-flush"
                          hover
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">ID</th>
                              <th scope="col">Product</th>
                              <th scope="col">Description</th>
                              <th scope="col">Attention</th>
                              {/* <th scope="col">Company</th>
                              <th scope="col">Sales</th> */}
                              <th scope="col">Price</th>
                              {/* <th scope="col">Tax</th>
                              <th scope="col">Discount</th> */}
                              <th scope="col">Total/Unit</th>
                              <th scopre="col">Quantity</th>
                              <th scopre="col">Grand Total</th>
                              {/* <th scope="col">Purchase Timeframe</th> */}
                              <th scope="col">Status</th>
                              <th scope="col">Created At</th>
                              <th>ACTIONS</th>
                            </tr>
                          </thead>
                          <tbody>{availdataQuotations}</tbody>
                        </Table>
                      </>
                    )}
                  </div>

                  <CardFooter className="py-4">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <Col md="3" sm="6">
                          <Form inline>
                            <FormGroup>
                              <FormText className="mr-sm-2">Show</FormText>

                              <Input
                                className="form-control-sm mr-sm-2"
                                type="select"
                                onChange={this.handleItemPerpage}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </Input>
                              <FormText>
                                entries. Showing rows {fromItem} to {toItem} of{" "}
                                {this.state.total_items}
                              </FormText>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Col>
                      <Col className="text-right" xs="4">
                        <nav aria-label="...">
                          <Pagination
                            current_page={this.state.actionListQuotations.page}
                            last_page={this.state.total_pages}
                            position="right"
                            handlePageChange={this.handlePagination}
                          />
                        </nav>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </div>
            </div>
          </Row>
        </Container>

        {this.state.alert}
      </>
    );
  }
}

export default ListProductsQuotation;
