import React, { PureComponent } from "react";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input,
  Table,
  UncontrolledCarousel,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { countries } from "services/countries";
//services
import config from "services/config";
//packages
import axios from "axios";
// redux
import { connect } from "react-redux";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import { currencyIDR } from "services/currency";
import Title from "components/Breadcumbs/Title";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { MyDocument } from "services/PDF/quotation/document";
import StandardHeader from "components/Headers/StandardHeader";

class QuotationDetails extends PureComponent {
  state = {
    data: {
      quote_id: "",
    },
    dataChange: {
      status: "Pending",
      lead_time: "",
      product_price: "",
      tax: 0,
      discount: 0,
      total_product_price: "",
      grand_total: "",
      quantity: 0,
    },
    items: [],
    dataCompany: [],
    listQuotationOptionalComponents: [],
    listQuotationStandardComponents: [],
    listQuotationSpecifications: [],
    sumQuotationOptionalComponenets: 0,
    isLoading: false,
    alert: null,
    paramErrorQuotation: {},
    paramErrorUser: {},
    ready: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
    try {
      const response = await axios.post(
        config.API_URL + "/quotations/view_quotation",
        { quote_id: this.props.match.params.quoteId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      const responseQuotationOptionalComponent = await axios.post(
        config.API_URL + "/quotations/list_quotation_optional_components",
        { quote_id: this.props.match.params.quoteId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      const responseQuotationStandardComponent = await axios.post(
        config.API_URL + "/quotations/list_quotation_standard_components",
        { quote_id: this.props.match.params.quoteId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      const responseQuotationSpecifications = await axios.post(
        config.API_URL + "/quotations/list_quotation_specifications",
        { quote_id: this.props.match.params.quoteId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        data: response.data.data[0],
        dataChange: {
          status: response.data.data[0].status,
          lead_time: response.data.data[0].lead_time,
          product_price: response.data.data[0].product_price,
          tax: response.data.data[0].tax,
          discount: response.data.data[0].discount,
          total_product_price: response.data.data[0].total_product_price,
          grand_total: response.data.data[0].grand_total,
          quantity: response.data.data[0].quantity,
        },
        listQuotationOptionalComponents:
          responseQuotationOptionalComponent.data.data,
        listQuotationStandardComponents:
          responseQuotationStandardComponent.data.data,
        listQuotationSpecifications: responseQuotationSpecifications.data.data,
        sumQuotationOptionalComponenets: this.sum(
          responseQuotationOptionalComponent.data.data
        ),
      }, () => {
        this.setState({
          items :[
            {
              src: config.BUCKET_URL + this.state.data.image_1,
              altText: "",
              caption: "",
              header: "",
            },
            {
              src: config.BUCKET_URL + this.state.data.image_2,
              altText: "",
              caption: "",
              header: "",
            },
            {
              src: config.BUCKET_URL + this.state.data.image_3,
              altText: "",
              caption: "",
              header: "",
            },
            {
              src: config.BUCKET_URL + this.state.data.image_4,
              altText: "",
              caption: "",
              header: "",
            },
          ]
        })
        this.getViewCompany()
      });
    } catch (err) {
      console.log(err);
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  toggle() {
    this.setState(
      (prevState) => ({
        ready: false,
      }),
      () => {
        // THIS IS THE HACK
        setTimeout(() => {
          this.setState({ ready: true });
        }, 1);
      }
    );
  }

  sum(arr) {
    return arr.reduce(
      (total, obj) => Number(obj.quotation_optional_component_price) + total,
      0
    );
  }

  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  getViewCompany = () => {
    axios
      .post(
        config.API_URL + "/companies/view_company",
        { company_id: this.state.data.company_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              dataCompany: res.data.data[0],
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  handleSubmit = () => {
    axios
      .post(
        config.API_URL + "/quotations/update_status_quotation",
        {
          quote_id: this.props.match.params.quoteId,
          status: this.state.dataChange.status,
          product_price: this.state.dataChange.product_price,
          tax: this.state.dataChange.tax,
          discount: this.state.dataChange.discount,
          total_product_price: this.state.dataChange.total_product_price,
          quantity: this.state.dataChange.quantity,
          grand_total: this.state.dataChange.grand_total,
          lead_time: this.state.dataChange.lead_time
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.successAlert(res.data.message);
            this.setState({ isLoading: false });
          }
        },
        (err) => {
          if (this.mounted) {
            this.failedAlert(this.props.match.params.quoteId);
            this.setState({ isLoading: false });
          }
        }
      );
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`} Unsuccessfully Updated
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleChangeString = (event) => {
    this.setState({
      dataChange: {
        ...this.state.dataChange,
        [event.target.name]: event.target.value,
      },
    });
  };
  handleChangeQuantity = (event) => {
    this.setState({
      dataChange: {
        ...this.state.dataChange,
        [event.target.name]: parseInt(event.target.value),
        grand_total: parseInt(event.target.value)*this.state.dataChange.total_product_price
      },
    });
  };
  handleChangeProductPriceTaxDiscount = (event) => {
    this.setState({
      dataChange: {
        ...this.state.dataChange,
        [event.target.name]: parseInt(event.target.value)
      },
    }, () => {
      var tax = this.state.dataChange.tax;
      var total_tax = (parseInt(this.state.dataChange.product_price) * tax) / 100;
      var discount = this.state.dataChange.discount;
      var total_discount =
        (parseInt(this.state.dataChange.product_price) * discount) / 100;
      var total_product_price = parseInt(this.state.dataChange.product_price) + total_tax - total_discount
      this.setState({
        dataChange: {
          ...this.state.dataChange,
          total_product_price: total_product_price,
          grand_total: total_product_price * this.state.dataChange.quantity
        }
      })
    });
  };

  render() {
    return (
      <>
        {/* <QuotationDetailsHeader
          name={this.state.data.chasis + " "+this.state.data.tank_capacity+" "+this.state.data.axle}
          parentName="Preliminary Spec"
          childName="List Preliminary Spec"
          link="quotations"
        /> */}
        <StandardHeader
          name={this.state.data.category_name + " " +this.state.data.chasis + " "+this.state.data.tank_capacity+" "+this.state.data.axle}
          parentName="Configuration Record"
          link="quotations"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col>
              <div>
                {this.state.ready && (
                  <PDFDownloadLink
                    document={
                      <MyDocument
                        data={{...this.state.data, ...this.state.dataCompany, company: this.state.dataCompany}}
                        optional={this.state.listQuotationOptionalComponents}
                      />
                    }
                    fileName={"PreSpec-" + this.state.data.quote_id + ".pdf"}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <>
                          <Button
                            className="text-uppercase mb-4"
                            color="primary"
                            onClick={() => this.toggle()}
                          >
                            <span className="btn-inner--text">
                              <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                              Generate PDF
                            </span>
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            className="text-uppercase mb-4"
                            color="primary"
                            onClick={() => this.setState({ ready: false })}
                          >
                            Download PDF
                          </Button>
                        </>
                      )
                    }
                  </PDFDownloadLink>
                )}
                {!this.state.ready && (
                  <>
                    <Button
                      className="text-uppercase mb-4"
                      color="primary"
                      onClick={() => this.toggle()}
                    >
                      Generate PDF
                    </Button>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      {/* <Col xs="8">
                        <h3 className="mb-0">
                            ID: {this.state.data.quote_id}
                        </h3>
                      </Col> */}
                      <Col className="text-right">
                        <h3 className="mb-0">
                          Attention: {this.state.data.username}
                        </h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Status</label>
                          <Input
                            type="select"
                            name="status"
                            placeholder="Status"
                            onKeyPress={this.keyPressed}
                            value={this.state.dataChange.status}
                            onChange={this.handleChangeString}
                          >
                            <option value="">Select</option>
                            <option value="Pending">Pending</option>
                            <option value="In Review">In Review</option>
                            {/* <option value="Approved">Approved</option> */}
                            <option value="Processed">Processed</option>
                            {/* <option value="Rejected">Rejected</option> */}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Lead Time
                          </label>
                          <Input
                            type="text"
                            name="lead_time"
                            placeholder="Lead Time"
                            onKeyPress={this.keyPressed}
                            value={this.state.dataChange.lead_time}
                            onChange={this.handleChangeString}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <h6 className="heading-small text-muted">
                      Amount Information
                    </h6>
                    <Row>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Product Price
                          </label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>IDR</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="number"
                              onKeyPress={this.keyPressed}
                              name="product_price"
                              placeholder="Product Price"
                              value={this.state.dataChange.product_price}
                              onChange={this.handleChangeProductPriceTaxDiscount}
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">TAX</label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="number"
                              onKeyPress={this.keyPressed}
                              name="tax"
                              placeholder="0"
                              value={this.state.dataChange.tax}
                              onChange={this.handleChangeProductPriceTaxDiscount}
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Discount</label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="number"
                              onKeyPress={this.keyPressed}
                              name="discount"
                              placeholder="Discount"
                              value={this.state.dataChange.discount}
                              onChange={this.handleChangeProductPriceTaxDiscount}
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Quantity</label>
                          <Input
                            id="example-date-input"
                            type="number"
                            name="quantity"
                            onKeyPress={this.keyPressed}
                            value={this.state.dataChange.quantity}
                            onChange={this.handleChangeQuantity}
                            placeholder="Quantity"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <div>
                          <span className="h6 surtitle text-muted">
                            Total Price
                          </span>
                          <div className="h1">
                            {currencyIDR(
                              this.state.dataChange.total_product_price
                            ).replace(/\.00$/, "")}
                          </div>
                        </div>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <div>
                          <span className="h6 surtitle text-muted">
                            Grand Total
                          </span>
                          <div className="h1">
                            {currencyIDR(
                              this.state.dataChange.grand_total
                            ).replace(/\.00$/, "")}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                      <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-3 col-sm-6"
                        xs="12"
                      >
                        <Button
                          block
                          color="success"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isLoading ? true : false}
                          onClick={() => this.handleSubmit()}
                        >
                          {this.state.isLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Save
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Save</span>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <Row>
            <Col>
              <Title name="Product Details" />
              <Row className="justify-content-center">
                <Col>
                  <Card className="bg-dark text-white border-0">
                    <UncontrolledCarousel items={this.state.items} />
                  </Card>
                </Col>
                <Col className="text-left" lg="4">
                  <h2 className="display-3">
                    {this.state.data.category_name} {this.state.data.chasis} {this.state.data.tank_capacity}{" "}
                    {this.state.data.axle}
                  </h2>
                  <p className="lead">{this.state.data.product_description}</p>
                  <table>
                    <tr>
                      {/* <td>
                        <small>ROH</small>
                      </td>
                      <td className="px-4">
                        <small>Wheel Base</small>
                      </td> */}
                      <td>
                        <small>Lead Time</small>
                      </td>
                    </tr>
                    <tr>
                      {/* <td>
                        <h3 className="mb-0">{this.state.data.roh}</h3>
                      </td>
                      <td className="px-4">
                        {" "}
                        <h3 className="mb-0">{this.state.data.wheel_base}</h3>
                      </td> */}
                      <td>
                        {" "}
                        <h3 className="mb-0">{this.state.data.lead_time}</h3>
                      </td>
                    </tr>
                  </table>
                  <Row className="mt-4 mb-4">
                    <Col>
                      <Button
                        className="text-uppercase"
                        color="primary"
                        type="button"
                        onClick={() =>
                          window.open(
                            config.BUCKET_URL+
                              this.state.data.file_specifications,
                            "_blank"
                          )
                        }
                      >
                        <span className="btn-inner--text ml-2">Brochure</span>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {this.state.listQuotationStandardComponents.length > 0 ||
              this.state.listQuotationOptionalComponents.length > 0 ? (
                <h1 className="display-4">Components</h1>
              ) : null}
              <Row>
                <Col>
                  {this.state.listQuotationStandardComponents.length > 0 ||
                  this.state.listQuotationOptionalComponents.length > 0 ? (
                    <Row>
                      <Col className="col-lg-6 col-md-6 col-sm-12" xs="6">
                        <Card>
                          <CardHeader className="border-0">
                            <Row className="align-items-center">
                              <div className="col">
                                <h3 className="mb-0">Standard Components</h3>
                              </div>
                            </Row>
                          </CardHeader>
                          <Table
                            className="align-items-center table-flush"
                            responsive
                          >
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.listQuotationStandardComponents.map(
                                (element, index) => {
                                  return (
                                    <tr key={index}>
                                      <th scope="row">
                                        {
                                          element.quotation_standard_component_name
                                        }
                                      </th>
                                      <td>
                                        {
                                          element.quotation_standard_component_description
                                        }
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </Card>
                      </Col>
                      <Col className="col-lg-6 col-md-6 col-sm-12" xs="6">
                        <Card>
                          <CardHeader className="border-0">
                            <Row className="align-items-center">
                              <div className="col">
                                <h3 className="mb-0">Optional Components</h3>
                              </div>
                            </Row>
                          </CardHeader>
                          {this.state.listQuotationOptionalComponents.length ===
                          0 ? (
                            <Alert color="secondary">
                              <strong>Components not found</strong>!
                            </Alert>
                          ) : (
                            <>
                              <Table
                                className="align-items-center table-flush"
                                responsive
                              >
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.listQuotationOptionalComponents.map(
                                    (element, index) => {
                                      return (
                                        <tr key={index}>
                                          <th scope="row">
                                            {
                                              element.quotation_optional_component_name
                                            }
                                          </th>
                                          <td>
                                            {
                                              element.quotation_optional_component_description
                                            }
                                          </td>
                                          <td>
                                            {currencyIDR(
                                              element.quotation_optional_component_price
                                            ).replace(/\.00$/, "")}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </Table>
                            </>
                          )}
                        </Card>
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              </Row>
              <h1 className="display-4">Specs</h1>
              <Row>
                <Col className="col-lg-6 col-md-6 col-sm-12" xs="6">
                  <Card>
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h3 className="mb-0">
                            {" "}
                            {this.state.data.chasis}{" "}
                            {this.state.data.tank_capacity}{" "}
                            {this.state.data.axle} Specs
                          </h3>
                        </div>
                      </Row>
                    </CardHeader>
                    {this.state.listQuotationSpecifications.length === 0 ? (
                      <Alert color="secondary">
                        <strong>Components not found</strong>!
                      </Alert>
                    ) : (
                      <>
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.listQuotationSpecifications.map(
                              (element, index) => {
                                return (
                                  <tr key={index}>
                                    <th scope="row">
                                      {element.quotation_specification_name}
                                    </th>
                                    <td>
                                      {
                                        element.quotation_specification_description
                                      }
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </>
                    )}
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <h1 className="display-4">Request a Quote Information</h1>
          <h6 className="heading-small text-muted">Amount Information</h6>
          <Row>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Price</span>
                <div className="h1">
                  {currencyIDR(this.state.data.product_price).replace(
                    /\.00$/,
                    ""
                  )}
                </div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Tax</span>
                <div className="h1">{this.state.data.tax + " %"}</div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Discount</span>
                <div className="h1">{this.state.data.discount + " %"}</div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Total Price</span>
                <div className="h1">
                  {currencyIDR(this.state.data.total_product_price).replace(
                    /\.00$/,
                    ""
                  )}
                </div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Quantity</span>
                <div className="h1">{this.state.data.quantity}</div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <div>
                <span className="h6 surtitle text-muted">Grand Total</span>
                <div className="h1">
                  {currencyIDR(this.state.data.grand_total).replace(
                    /\.00$/,
                    ""
                  )}
                </div>
              </div>
            </Col>
          </Row> */}
          {/* <h6 className="heading-small text-muted">Shipping Information</h6> */}
          <Row>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <FormGroup>
                <label className="form-control-label">Quantity</label>
                <Input
                  id="example-date-input"
                  type="number"
                  name="quantity"
                  disabled
                  value={this.state.data.quantity}
                />
              </FormGroup>
            </Col>
            {/* <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <FormGroup>
                <label className="form-control-label">Shipping Method</label>
                <Input
                  id="example-date-input"
                  disabled
                  type="select"
                  name="shipping_method"
                  value={this.state.data.shipping_method}
                >
                  <option value="">Select</option>
                  <option value="Delivery">Delivery</option>
                  <option value="Ex-work">Ex-work</option>
                </Input>
              </FormGroup>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <FormGroup>
                <label className="form-control-label">Payment Terms</label>
                <Input
                  id="example-date-input"
                  disabled
                  type="select"
                  name="payment_terms"
                  value={this.state.data.payment_terms}
                >
                  <option value="">Select</option>
                  <option value="Net 30">Net 30</option>
                  <option value="Net 40">Net 40</option>
                  <option value="Net 45">Net 45</option>
                  <option value="Net 60">Net 60</option>
                  <option value="10% DP - 90% Before Delivery">
                    10% DP - 90% Before Delivery
                  </option>
                  <option value="20% DP - 80% Before Delivery">
                    20% DP - 80% Before Delivery
                  </option>
                  <option value="30% DP - 70% Before Delivery">
                    30% DP - 70% Before Delivery
                  </option>
                </Input>
              </FormGroup>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <FormGroup>
                <label className="form-control-label">Purchase Timeframe</label>
                <Input
                  id="example-date-input"
                  disabled
                  type="select"
                  name="purchase_timeframe"
                  value={this.state.data.purchase_timeframe}
                >
                  <option value="">Select</option>
                  <option value="Immediate">Immediate</option>
                  <option value="This Quarter">This Quarter</option>
                  <option value="Next Quarter">Next Quarter</option>
                  <option value="This Year">This Year</option>
                  <option value="Unknown">Unknown</option>
                </Input>
              </FormGroup>
            </Col>
            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
              <FormGroup>
                <label className="form-control-label">Freight Terms</label>
                <Input
                  id="example-date-input"
                  disabled
                  type="select"
                  name="freight_terms"
                  value={this.state.data.freight_terms}
                >
                  <option value="">Select</option>
                  <option value="CFR">CFR</option>
                  <option value="CIF">CIF</option>
                  <option value="CIP">CIP</option>
                  <option value="CPT">CPT</option>
                  <option value="FOB">FOB</option>
                  <option value="DAP">DAP</option>
                  <option value="DAT">DAT</option>
                  <option value="DDP">DDP</option>
                  <option value="DPP">DPP</option>
                  <option value="EXW">EXW</option>
                  <option value="FAS">FAS</option>
                  <option value="FCA">FCA</option>
                </Input>
              </FormGroup>
            </Col>
            {this.state.isCountryArea ? (
              <>
                <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Country</label>
                    <Input
                      id="example-date-input"
                      disabled
                      type="select"
                      name="country"
                      value={this.state.data.country}
                    >
                      {countries.map((element) => {
                        return (
                          <option value={element.name}>{element.name}</option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                  <FormGroup>
                    <label className="form-control-label">City or Area</label>
                    <Input
                      id="example-date-input"
                      disabled
                      type="text"
                      name="area"
                      value={this.state.data.area}
                    />
                  </FormGroup>
                </Col>
              </>
            ) : null} */}
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label">Notes</label>
                <Input
                  id="example-date-input"
                  type="textarea"
                  name="notes"
                  disabled
                  value={this.state.data.notes}
                />
              </FormGroup>
            </Col>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataQuotations: state.quotation.dataQuotations,
  };
};
export default connect(mapStateToProps)(QuotationDetails);
