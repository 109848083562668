import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import routes from "routes.js";
// import {
//   Carousel,
//   CarouselItem,
//   CarouselControl,
//   CarouselIndicators,
//   CarouselCaption,
// } from "reactstrap";

// import background from "assets/img/theme/bg-auth.PNG";
// import background2 from "assets/img/theme/bg-auth-5.jpg";
// import background3 from "assets/img/theme/bg-auth-6.jpg";
// // import styled from "styled-components";
// import styleLogin from "../assets/css/custome/login.css";
// const LogoWrapper = styled.div`
//   img {
//     width: 550px;
//     height: 95px;
//     margin-bottom: -70px;
//     margin-left: auto;
//     margin-right: auto;
//     margin-top: 130px;
//     display: block;
//     @media (max-width: 991.98px) {
//       width: 550px;
//       height: 95px;
//       margin-bottom: 20px;
//     }
//     @media (max-width: 575.98px) {
//       width: 320px;
//       height: 55px;
//       margin-bottom: 20px;
//     }
//   }
// `;

class Auth extends React.Component {

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // this.refs.mainContent.scrollTop = 0;
    document.body.classList.add("bg-secondary");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-secondary");
  }
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      // this.refs.mainContent.scrollTop = 0;
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
   
    return (
      <>
      <AuthNavbar/>
       <Switch>
                {/* <div style={{marginTop: '40px'}}> */}
                
                {/* <div style={{ marginTop: "40px"}}> */}
                {/* <div style={{ marginTop: "40px", position: "fixed" }}> */}
                  {this.getRoutes(routes)}
                {/* </div> */}
                <Redirect from="*" to="/auth/login" />
              </Switch>
      <AuthFooter/>
      </>
    );
  }
}

export default Auth;
