import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";

import routes from "routes.js";
import CustomersNavbar from "components/Navbars/CustomersNavbar";
import CustomersFooter from "components/Footers/CustomersFooter";
// import ReactGA from "react-ga";

class Customers extends React.Component {
  componentDidMount() {
    if (localStorage.getItem("refresh_admin") === "true") {
      window.location.reload(false);
      localStorage.setItem("refresh_admin", "false");
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
    document.body.classList.add("bg-white");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-white");
  }
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/customers") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            />
            );
          } else {
            return null;
          }
        });
      };
    render() {
      return (
      <>
      {/* {Header} */}
<CustomersNavbar/>
        <div className="main-content bg-secondary" ref="mainContent">
          <Switch>
            <div className="bg-secondary" style={{marginTop: '150px'}}>
              {this.getRoutes(routes)}
            </div>
              <Redirect from="*" to="/customers/main_products" />
          </Switch>
<CustomersFooter/>
          {/* {showFooter} */}
        </div>
      </>
    );
  }
}

export default Customers;
