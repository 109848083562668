import React, { PureComponent } from "react";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    CardBody,
    Button,
    FormGroup,
    Input,
    Table,
    CardFooter,
    Form,
    FormText,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader";
import moment from "moment";
import Pagination from "react-bootstrap-pagination-logic";

class ListTechnicalChecklist extends PureComponent {
    state = {
        actionFilter: {
            page: 1,
            items_per_page: 10,
            order_by_field: "created_at",
            order_by_direction: "DESC",
            product: ''
        },
        total_pages: 0,
        total_items: 0,
    };

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }


    keyPressed(event) {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    }

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    };

    handletoAdd = () => {
        this.props.history.push("/admin/create-technical-checklist");
    };

    handleChangeString = (event) => {
        this.setState({
            actionFilter: {
                ...this.state.actionFilter,
                [event.target.name]: event.target.value,
            },
        });
    };

    handleItemPerpage = (e) => {
        this.setState(
            {
                actionFilter: {
                    ...this.state.actionFilter,
                    page: 1,
                    items_per_page: parseInt(e.target.value),
                },
            },
            () => {
                // this.getDataListCompanies();
            }
        );
    };

    handlePagination = (numPage) => {
        this.setState(
            {
                actionFilter: {
                    ...this.state.actionFilter,
                    page: numPage,
                },
            },
            () => {
                // this.getDataListCompanies();
            }
        );
    };


    render() {
        let fromItem = null;
        let toItem = null;
        return (
            <>
                <StandardHeader
                    name="Technical Checklist"
                    parentName="Technical Checklist"
                    link="technical-checklist"
                />
                <Container className="mt--6" fluid>
                    <Row className="mb-4">
                        <Col>
                            <Button
                                color="success"
                                type="button"
                                className="btn-icon"
                                onClick={() => this.handletoAdd()}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="ni ni-fat-add" />
                                </span>
                                Create Technical Checklist
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <div className="col">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="6">
                                            <h3 className="mb-0">Filter Technical Checklist</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                                            <FormGroup>
                                                <label className="form-control-label">
                                                    Product
                                                </label>
                                                <Input
                                                    type="select"
                                                    onKeyPress={this.keyPressed}
                                                    name="product"
                                                    placeholder="Product"
                                                    value={this.state.actionFilter.product}
                                                    onChange={this.handleChangeString}
                                                >
                                                    <option value={""}>Select</option>
                                                    <option value={"Water Truck"}>Water Truck</option>
                                                    <option value={"Fuel Truck"}>Fuel Truck</option>
                                                    <option value={"Washdown Truck"}>Washdown Truck</option>
                                                    <option value={"Lube Truck"}>Lube Truck</option>
                                                    <option value={"OHT Body"}>OHT Body</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">List Checklists</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <Table
                                    className="align-items-center table-flush"
                                    hover
                                    responsive
                                >
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Created At</th>
                                            <th scope="col">Updated At</th>
                                            <th>ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Fuel Truck</td>
                                            <td>{moment().format('lll')}</td>
                                            <td>{moment().format('lll')}</td>
                                            <td className="text-right">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className="btn-icon-only text-light"
                                                        role="button"
                                                        size="sm"
                                                        color=""
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        <i className="fas fa-ellipsis-v" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                        <DropdownItem onClick={(e) => e.preventDefault()}>
                                                            Details
                </DropdownItem>
                                                        <DropdownItem onClick={(e) => e.preventDefault()}>
                                                            Remove
                </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <CardFooter className="py-4">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <Col md="3" sm="6">
                                                <Form inline>
                                                    <FormGroup>
                                                        <FormText className="mr-sm-2">Show</FormText>

                                                        <Input
                                                            className="form-control-sm mr-sm-2"
                                                            type="select"
                                                            onChange={this.handleItemPerpage}
                                                        >
                                                            <option value={10}>10</option>
                                                            <option value={25}>25</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </Input>
                                                        <FormText>
                                                            entries. Showing rows {fromItem} to {toItem} of{" "}
                                                            {this.state.total_items}
                                                        </FormText>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <nav aria-label="...">
                                                <Pagination
                                                    current_page={1}
                                                    last_page={1}
                                                    position="right"
                                                    handlePageChange={this.handlePagination}
                                                />
                                            </nav>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default ListTechnicalChecklist;
