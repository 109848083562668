import React, { PureComponent } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  Alert,
  CardBody,
  FormText,
  CardImg,
} from "reactstrap";
//services
import config from "services/config";

//packages
import axios from "axios";
import Pagination from "react-bootstrap-pagination-logic";

//redux
import { connect } from "react-redux";
import { getDataProducts } from "./Redux/productActions";
import { currencyIDR } from "services/currency";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import StandardHeader from "components/Headers/StandardHeader";
class ListProducts extends PureComponent {
  state = {
    actionListProducts: {
      page: 1,
      items_per_page: 10,
      order_by_field: "product_id",
      order_by_direction: "DESC",
      product_no: "",
    },

    alert: null,
    listProducts: [],
    total_pages: 0,
    total_items: 0,
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.getDataListProducts();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChangeString = (event) => {
    this.setState({
      actionListProducts: {
        ...this.state.actionListProducts,
        [event.target.name]: event.target.value,
      },
    });
  };

  getDataListProducts = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/products/products",
        this.state.actionListProducts,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listProducts: res.data.data,
                total_pages: res.data.total_pages,
                total_items: res.data.total_items,
              },
              () => {
                this.props.dispatch(getDataProducts(this.state.listProducts));
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  handleDetails = (data) => {
    this.props.dispatch(getDataProducts(data));
    this.props.history.push("/admin/product_details/"+data.product_id);
  };

  handlePagination = (numPage) => {
    this.setState(
      {
        actionListProducts: {
          ...this.state.actionListProducts,
          page: numPage,
        },
      },
      () => {
        this.getDataListProducts();
      }
    );
  };

  handleItemPerpage = (e) => {
    this.setState(
      {
        actionListProducts: {
          ...this.state.actionListProducts,
          page: 1,
          items_per_page: parseInt(e.target.value),
        },
      },
      () => {
        this.getDataListProducts();
      }
    );
  };

  handletoAdd = () => {
    this.props.history.push("/admin/create_product");
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  confirmAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.remove(data.product_id)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.product_no}`} product, You won't be able to revert
          this!
        </SweetAlert>
      ),
    });
  };

  confirmedAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Product has been deleted.
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleFilter = () => {
    this.setState(
      {
        actionListProducts: {
          ...this.state.actionListProducts,
          page: 1,
        },
      },
      () => {
        this.getDataListProducts();
      }
    );
  };

  handleReset = () => {
    this.setState(
      {
        actionListProducts: {
          page: 1,
          items_per_page: 10,
          order_by_field: "product_id",
          order_by_direction: "DESC",
          product_no: "",
        },
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getDataListProducts();
            }
          );
        }
      }
    );
  };

  remove = (data) => {
    axios
      .post(
        config.API_URL + "/products/delete_product",
        { product_id: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlert();
          this.handleFilter();
        },
        (err) => {
          console.log(err);
          if (this.mounted) {
            if (err.response !== undefined) {
              this.failedAlert(err.response.data.message);
            }
          }
        }
      );
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  previewImage = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="secondary"
          confirmBtnText="Close"
          btnSize=""
        >
          <CardImg alt="..." src={config.BUCKET_URL + data} top />
        </SweetAlert>
      ),
    });
  };

  render() {
    let fromItem = null;
    let toItem = null;

    if (this.state.total_pages === this.state.actionListProducts.page) {
      toItem = this.state.total_items;
    } else {
      toItem =
        this.props.dataProducts.length * this.state.actionListProducts.page;
    }
    if (this.state.actionListProducts.page === 1) {
      fromItem = 1;
    } else if (this.state.total_pages === this.state.actionListProducts.page) {
      fromItem = this.state.total_items - this.props.dataProducts.length + 1;
    } else {
      fromItem =
        this.props.dataProducts.length *
          (this.state.actionListProducts.page - 1) +
        1;
    }

    const availDataProducts = this.props.dataProducts.map((products) => {
      return (
        <tr
          key={products.product_id}
          style={{ cursor: "pointer" }}
          onDoubleClick={() => this.handleDetails(products)}
        >
          <td>{products.product_no}</td>
          <td>{products.chasis}</td>
          <td>{products.axle}</td>
          <td>{products.tank_capacity}</td>
          <td>{products.wheel_base}</td>
          <td>{products.roh}</td>
          <td>{currencyIDR(products.product_price).replace(/\.00$/, "")}</td>
          <td>{products.category_name}</td>
          <td>
            <div className="avatar-group">
              <a
                className="avatar avatar-sm rounded-circle"
                onClick={() => this.previewImage(products.image_1)}
              >
                <img
                  alt="..."
                  src={config.BUCKET_URL + products.image_1}
                />
              </a>
              <a
                className="avatar avatar-sm rounded-circle"
                onClick={() => this.previewImage(products.image_2)}
              >
                <img
                  alt="..."
                  src={config.BUCKET_URL + products.image_2}
                />
              </a>
              <a
                className="avatar avatar-sm rounded-circle"
                onClick={() => this.previewImage(products.image_3)}
              >
                <img
                  alt="..."
                  src={config.BUCKET_URL + products.image_3}
                />
              </a>
              <a
              src="ss"
                className="avatar avatar-sm rounded-circle"
                onClick={() => this.previewImage(products.image_4)}
              >
                <img
                  alt="..."
                  src={config.BUCKET_URL + products.image_4}
                />
              </a>
            </div>
          </td>
          <td >
            <span 
            className="btn-inner--icon mr-1"
            onClick={() => this.confirmAlert(products)}
            >
                  <i className="ni ni-fat-remove fa-3x"/>
            </span>
          </td>
        </tr>
      );
    });

    return (
      <>
        <StandardHeader name="Products" parentName="Products" link="products" />
        <Container className="mt--6" fluid>
          <Row className="mb-4">
            <Col>
              <Button
                color="success"
                type="button"
                className="btn-icon"
                onClick={() => this.handletoAdd()}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                Create Product
              </Button>
            </Col>
          </Row>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="6">
                      <h3 className="mb-0">Filter Products</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Product No</label>
                        <Input
                          id="example-date-input"
                          type="text"
                          onKeyPress={this.keyPressed}
                          name="product_no"
                          placeholder="Product No"
                          value={this.state.actionListProducts.product_no}
                          onChange={this.handleChangeString}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col
                      style={{ marginTop: 20 }}
                      className="col-6 col-md-3 col-sm-6"
                      xs="12"
                    >
                      <div className="text-center">
                        <Button
                          block
                          color="success"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isFilterLoading ? true : false}
                          onClick={() => this.handleFilter()}
                        >
                          {this.state.isFilterLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Filter
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Filter</span>
                          )}
                        </Button>
                      </div>
                    </Col>
                    <Col
                      style={{ marginTop: 20 }}
                      className="col-6 col-md-3 col-sm-6"
                      xs="12"
                    >
                      <Button
                        block
                        color="danger"
                        className="btn-icon"
                        type="button"
                        disabled={this.state.isResetLoading ? true : false}
                        onClick={() => this.handleReset()}
                      >
                        {this.state.isResetLoading ? (
                          <>
                            <span className="btn-inner--text">
                              <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                              Reset
                            </span>
                          </>
                        ) : (
                          <span className="btn-inner--text">Reset</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="card-wrapper">
                <Card>
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">List Products</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <div>
                    {this.props.dataProducts.length < 5 ? (
                      <div style={{ height: 323 }}>
                        {this.props.dataProducts.length === 0 ? (
                          <Alert color="secondary">
                            <strong>Data not found!</strong> Please check again!
                          </Alert>
                        ) : (
                          <>
                            <Table
                              className="align-items-center table-flush"
                              hover
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Product No</th>
                                  <th scope="col">Chasis</th>
                                  <th scope="col">Axle</th>
                                  <th scope="col">Capacity</th>
                                  <th scope="col">Wheel Base</th>
                                  <th scope="col">Roh</th>
                                  <th scope="col">Product Price</th>
                                  <th scope="col">Category</th>
                                  <th scope="col">Images</th>
                                  <th>ACTIONS</th>
                                </tr>
                              </thead>
                              <tbody>{availDataProducts}</tbody>
                            </Table>
                          </>
                        )}
                      </div>
                    ) : (
                      <>
                        <Table
                          className="align-items-center table-flush"
                          hover
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Product No</th>
                              <th scope="col">Chasis</th>
                              <th scope="col">Axle</th>
                              <th scope="col">Capacity</th>
                              <th scope="col">Wheel Base</th>
                              <th scope="col">Roh</th>
                              <th scope="col">Product Price</th>
                              <th scope="col">Category</th>
                              <th scope="col">Images</th>
                              <th>ACTIONS</th>
                            </tr>
                          </thead>
                          <tbody>{availDataProducts}</tbody>
                        </Table>
                      </>
                    )}
                  </div>

                  <CardFooter className="py-4">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <Col md="3" sm="6">
                          <Form inline>
                            <FormGroup>
                              <FormText className="mr-sm-2">Show</FormText>

                              <Input
                                className="form-control-sm mr-sm-2"
                                type="select"
                                onChange={this.handleItemPerpage}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </Input>
                              <FormText>
                                entries. Showing rows {fromItem} to {toItem} of{" "}
                                {this.state.total_items}
                              </FormText>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Col>
                      <Col className="text-right" xs="4">
                        <nav aria-label="...">
                          <Pagination
                            current_page={this.state.actionListProducts.page}
                            last_page={this.state.total_pages}
                            position="right"
                            handlePageChange={this.handlePagination}
                          />
                        </nav>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </div>
            </div>
          </Row>
        </Container>

        {this.state.alert}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataProducts: state.product.dataProducts,
  };
};

export default connect(mapStateToProps)(ListProducts);
