import React from "react";

import {
  Row,
  Col,
  Container,
} from "reactstrap";

class ProgressReport extends React.Component {

  render() {
    return (
      <>
        <Container className="mt--8">
            <div className="header-body text-center mb-4">
              <Row className="justify-content-center py-8">
                <Col className="px-8" lg="8" md="8" xl="8">
                    <i class="fas fa-cog fa-spin fa-8x"></i>
                    <h1 className="text-dark">This Section Under Development</h1>
                    <p className="h3 text-dark">Coming Soon</p>
                </Col>
              </Row>
            </div>
         
        </Container>
      </>
    );
  }
}

export default ProgressReport;
