import {
  GET_DATA_CUSTOMER_PRODUCTS,
  GET_DATA_CUSTOMER_PRODUCTS_DETAILS,
  GET_DATA_PRODUCT_TYPE,
  GET_DATA_CUSTOMER_QUOTATIONS_DETAILS,
  GET_DATA_CART_PRODUCT_DETAILS
} from "./customerProductActions";

const initialState = {
  dataCustomerProducts: [],
  dataCustomerProductsDetails: [],
  dataCustomerQuotationsDetails: [],
  dataCartProductDetails: [],
  indexCart: [],
  dataProductType: "",
};

export default function customerProductReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DATA_CUSTOMER_PRODUCTS:
      return {
        ...state,
        dataCustomerProducts: action.payload.customerProduct,
      };

    case GET_DATA_CUSTOMER_PRODUCTS_DETAILS:
      return {
        ...state,
        dataCustomerProductsDetails: action.payload.customerProductDetails,
      };

    case GET_DATA_CUSTOMER_QUOTATIONS_DETAILS:
      return {
        ...state,
        dataCustomerQuotationsDetails: action.payload.customerQuotationDetails,
      };

    case GET_DATA_PRODUCT_TYPE:
      return {
        ...state,
        dataProductType: action.payload.productType,
      };
    case GET_DATA_CART_PRODUCT_DETAILS:
      return {
        ...state,
        dataCartProductDetails: action.payload.cartProductDetails,
        indexCart: action.payload.indexCart
      };

    default:
      return state;
  }
}
