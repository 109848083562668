import React from "react";
import {
  Badge,
  Card,
  CardFooter,
  Media,
  Alert,
  Form,
  FormGroup,
  FormText,
  Input,
  Table,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
} from "reactstrap";
import Pagination from "react-bootstrap-pagination-logic";
import config from "services/config";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import Title from "components/Breadcumbs/Title";
class ListCustomerQuotations extends React.Component {
  state = {
    actionListQuotations: {
      page: 1,
      items_per_page: 10,
      order_by_field: "created_at",
      order_by_direction: "DESC",
      company_id: "",
    },
    listQuotations: [],
    total_pages: 0,
    total_items: 0,
  };
  componentDidMount() {
    this.mounted = true;
    this.getDataListQuotations();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getDataListQuotations = () => {
    axios
      .post(
        config.API_URL + "/quotations/list_quotations_customers",
        { ...this.state.actionListQuotations, company_id: config.COMPANY_ID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listQuotations: res.data.data,
              total_pages: res.data.total_pages,
              total_items: res.data.total_items,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handlePagination = (numPage) => {
    this.setState(
      {
        actionListQuotations: {
          ...this.state.actionListQuotations,
          page: numPage,
        },
      },
      () => {
        this.getDataListQuotations();
      }
    );
  };

  handleItemPerpage = (e) => {
    this.setState(
      {
        actionListQuotations: {
          ...this.state.actionListQuotations,
          page: 1,
          items_per_page: parseInt(e.target.value),
        },
      },
      () => {
        this.getDataListQuotations();
      }
    );
  };

  handleDetails = (data) => {
    this.props.history.push("/customers/product_quotation_details/" + data.quotations_id);
  };

  handleStatus = (data) => {
    if (data == "Pending") {
      return "bg-warning";
    }
    if (data == "In Review") {
      return "bg-warning";
    }
    if (data == "Approved") {
      return "bg-success";
    }
    if (data == "Processed") {
      return "bg-success";
    }
    if (data == "Rejected") {
      return "bg-danger";
    }
  };
  render() {
    let fromItem = null;
    let toItem = null;

    if (this.state.total_pages === this.state.actionListQuotations.page) {
      toItem = this.state.total_items;
    } else {
      toItem =
        this.state.listQuotations.length * this.state.actionListQuotations.page;
    }
    if (this.state.actionListQuotations.page === 1) {
      fromItem = 1;
    } else if (
      this.state.total_pages === this.state.actionListQuotations.page
    ) {
      fromItem = this.state.total_items - this.state.listQuotations.length + 1;
    } else {
      fromItem =
        this.state.listQuotations.length *
          (this.state.actionListQuotations.page - 1) +
        1;
    }
    const availDataQuotations = this.state.listQuotations.map((quotations) => {
      return (
        <tr
          key={quotations.quote_id}
          style={{ cursor: "pointer" }}
          onDoubleClick={() => this.handleDetails(quotations)}
        >
          <th scope="row">
            <Media className="align-items-center">
              <Media>
                <span className="mb-0 text-sm">{quotations.quotations_id}</span>
              </Media>
            </Media>
          </th>
          <td>{quotations.username}</td>
          <td>{moment(quotations.created_at).format("lll")}</td>
        </tr>
      );
    });

    return (
      <>
        <Container className="bg-secondary mt--6">
          <Title name="Preliminary Spec" />
          <Row>
            <Col>
              <div className="card-wrapper">
                <Card>
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="6">
                        <h3 className="mb-0">List PreSpec ID</h3>
                      </Col>
                      <Col className="text-right" xs="6">
                        <Button
                          className="btn-second text-uppercase"
                          color=""
                          onClick={() => this.getDataListQuotations()}
                          size="sm"
                        >
                          Refresh
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <div>
                    {this.state.listQuotations.length < 5 ? (
                      <div>
                        {this.state.listQuotations.length === 0 ? (
                          <Alert color="white">
                            <strong>Data not found!</strong> Please check again!
                          </Alert>
                        ) : (
                          <>
                            <Table
                              className="align-items-center table-flush"
                              hover
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Preliminary Spec ID</th>
                                  <th scope="col">Attention</th>
                                  <th scope="col">Created At</th>
                                </tr>
                              </thead>
                              <tbody>{availDataQuotations}</tbody>
                            </Table>
                          </>
                        )}
                      </div>
                    ) : (
                      <>
                        <Table
                          className="align-items-center table-flush"
                          hover
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Preliminary Spec ID</th>
                              <th scope="col">Attention</th>
                              <th scope="col">Created At</th>
                            </tr>
                          </thead>
                          <tbody>{availDataQuotations}</tbody>
                        </Table>
                      </>
                    )}
                  </div>
                  <CardFooter className="py-4">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <Col md="3" sm="6">
                          <Form inline>
                            <FormGroup>
                              <FormText className="mr-sm-2">Show</FormText>

                              <Input
                                className="form-control-sm mr-sm-2"
                                type="select"
                                onChange={this.handleItemPerpage}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </Input>
                              <FormText>
                                entries. Showing rows {fromItem} to {toItem} of{" "}
                                {this.state.total_items}
                              </FormText>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Col>
                      <Col className="text-right" xs="4">
                        <nav aria-label="...">
                          <Pagination
                            current_page={this.state.actionListQuotations.page}
                            last_page={this.state.total_pages}
                            position="right"
                            handlePageChange={this.handlePagination}
                          />
                        </nav>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default connect()(ListCustomerQuotations);
