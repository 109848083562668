import React from "react";

import {
  Container,
  Row,
  Col
} from "reactstrap";
import Title from "components/Breadcumbs/Title";
import Breadcumbs from "components/Breadcumbs/Breadcumbs";

class ProductConfigure extends React.Component {
  render() {
    return (
      <>
        <Container className="bg-secondary mt--6">
          <Breadcumbs
            name="Product Configure"
            parentName="Products"
            link="/customers/main_products"
          />
          <Title name="Select Truck" />
          <Row>
            <Col>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ProductConfigure;
