import ReactGA from 'react-ga';

const TRACKING_ID = 'G-F58FYEPJ6R';

export const initGA = () => {
  ReactGA.initialize(TRACKING_ID);
};

export const trackingPageGA = (page) => {
  ReactGA.pageview(page);
};

export const eventGA = (categoryName, eventLabel, eventName, eventValue) => {
  ReactGA.event({
    category: categoryName, // Required
    label: eventLabel,
    action: eventName, // Required
    value: eventValue,
    // label: 'labelName',
    // value: 10,
    nonInteraction: false,
  });
};