import React from "react";

import {
  Row,
  Col,
  Container,
  Input,
  Card,
  Table
} from "reactstrap";
import background from "assets/img/document/background.png"
// import Title from "components/Breadcumbs/Title";
import config from "services/config";
import axios from "axios";
class Redeem extends React.Component {
  state = {
    listRedeem: [],
  };
  componentDidMount = async () => {
    this.mounted = true;
    axios.get(`https://prod-08.southeastasia.logic.azure.com:443/workflows/e52584b2d80b41e799c1877563462885/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=65H2dZuLetPrBA__uKBUHW2Nwbb243tWuyR1du94oto`)
    // axios.get(`https://jsonplaceholder.typicode.com/users`)
    .then(res => {
      // console.log(res.data)
      // // const persons = res.data;
      this.setState({ listRedeem: res.data });
    })
  }
  render() {
    return (
      <>
      <section  className="section section-lg  mt--5 mx-8">
        <div className="h1 mb-4" style={{color: "#00aeef"}}>Redeem</div>
        <thead>
                <tr>
                    <th>CustomerId</th>
                    <th>Name</th>
                </tr>
            </thead>
 
            <tbody>
                {this.state.listRedeem.map(customer =>
                    <tr>
                        <td>{customer.st_vendorid}</td>
                        <td>{customer.st_name}</td>
                    </tr>
                )}
            </tbody>
        <Row className="justify-content-center mx-auto rounded border border-dark">
                    <Col lg="12" md="12" xs="12" className="mt-3">
                    <Table
                              className="align-items-center "
                              hover
                              borderless
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col" style={{fontSize: "12px"}}>Redeem Date</th>
                                  <th scope="col" style={{fontSize: "12px"}}>Product</th>
                                  <th scope="col" style={{fontSize: "12px"}}>Program</th>
                                  <th scope="col" style={{fontSize: "12px"}}>Redeem Point</th>
                                  <th scope="col" style={{fontSize: "12px"}}>Reward</th>
                                  <th scope="col" style={{fontSize: "12px"}}>Redeem Code</th>
                                  <th scope="col" style={{fontSize: "12px"}}>Experired Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>03/09/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>Discount</td>
                                  <td>200.000</td>
                                  <td>Discount 10.000.000</td>
                                  <td>RD002</td>
                                  <td>28/12/2022</td>
                                </tr>
                                <tr>
                                  <td>03/09/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>Discount</td>
                                  <td>200.000</td>
                                  <td>Discount 10.000.000</td>
                                  <td>RD002</td>
                                  <td>28/12/2022</td>
                                </tr>
                                <tr>
                                  <td>03/09/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>Discount</td>
                                  <td>200.000</td>
                                  <td>Discount 10.000.000</td>
                                  <td>RD002</td>
                                  <td>28/12/2022</td>
                                </tr>
                                <tr>
                                  <td>03/09/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>Discount</td>
                                  <td>200.000</td>
                                  <td>Discount 10.000.000</td>
                                  <td>RD002</td>
                                  <td>28/12/2022</td>
                                </tr>
                                <tr>
                                  <td>03/09/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>Discount</td>
                                  <td>200.000</td>
                                  <td>Discount 10.000.000</td>
                                  <td>RD002</td>
                                  <td>28/12/2022</td>
                                </tr>
                                <tr>
                                  <td>03/09/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>Discount</td>
                                  <td>200.000</td>
                                  <td>Discount 10.000.000</td>
                                  <td>RD002</td>
                                  <td>28/12/2022</td>
                                </tr>
                                <tr>
                                  <td>03/09/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>Discount</td>
                                  <td>200.000</td>
                                  <td>Discount 10.000.000</td>
                                  <td>RD002</td>
                                  <td>28/12/2022</td>
                                </tr>
                                <tr>
                                  <td>03/09/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>Discount</td>
                                  <td>200.000</td>
                                  <td>Discount 10.000.000</td>
                                  <td>RD002</td>
                                  <td>28/12/2022</td>
                                </tr>
                                <tr>
                                  <td>03/09/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>Discount</td>
                                  <td>200.000</td>
                                  <td>Discount 10.000.000</td>
                                  <td>RD002</td>
                                  <td>28/12/2022</td>
                                </tr>
                                <tr>
                                  <td>03/09/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>Discount</td>
                                  <td>200.000</td>
                                  <td>Discount 10.000.000</td>
                                  <td>RD002</td>
                                  <td>28/12/2022</td>
                                </tr>
                                <tr>
                                  <td>03/09/2022</td>
                                  <td>Water Tank 20KL</td>
                                  <td>Discount</td>
                                  <td>200.000</td>
                                  <td>Discount 10.000.000</td>
                                  <td>RD002</td>
                                  <td>28/12/2022</td>
                                </tr>
                              </tbody>
                    </Table>
                    </Col>
                
                   
        </Row>
      </section>
      </>
    );
  }
}

export default Redeem;
