import React, { PureComponent } from "react";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    CardBody,
    FormGroup,
    Input
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import WaterTruck from "./ProductFamilies/WaterTruck";
import FuelTruck from "./ProductFamilies/FuelTruck";
import WashDownTruck from "./ProductFamilies/WashDownTruck";
import LubeTruck from "./ProductFamilies/LubeTruck";
import OHTBody from "./ProductFamilies/OHTBody";

class CreateTechnicalChecklist extends PureComponent {
    state = {
        data: {
            product: ''
        },
        familiProduct: '',
        isFilter: false,
        isFilterLoading: false,
        isResetLoading: false,
    };

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    };

    keyPressed(event) {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    }

    handleChangeString = (event) => {
        this.setState({
            data: {
                ...this.state.data,
                [event.target.name]: event.target.value,
            },
        });
    };

    handleChangeProduct = (event) => {
        this.setState({
            familiProduct: event.target.value
        });
    };


    render() {
        console.log(this.state.familiProduct)
        return (
            <>
                <StandardHeader
                    name="Create Technical Checklist"
                    parentName="Technical Checklist"
                    link="technical-checklist"
                />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <div className="card-wrapper">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                <h3 className="mb-0">Create Technical Checklist</h3>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                                                <label className="form-control-label">Product</label>
                                                <FormGroup>
                                                    <Input
                                                        id="example-date-input"
                                                        type="select"
                                                        onKeyPress={this.keyPressed}
                                                        name="product"
                                                        placeholder="Product No"
                                                        value={this.state.familiProduct}
                                                        onChange={this.handleChangeProduct}
                                                    >
                                                        <option value={""}>Select</option>
                                                        <option value={"Water Truck"}>Water Truck</option>
                                                        <option value={"Fuel Truck"}>Fuel Truck</option>
                                                        <option value={"Washdown Truck"}>Washdown Truck</option>
                                                        <option value={"Lube Truck"}>Lube Truck</option>
                                                        <option value={"OHT Body"}>OHT Body</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {this.state.familiProduct === 'Water Truck' && <WaterTruck />}
                                        {this.state.familiProduct === 'Fuel Truck' && <FuelTruck />}
                                        {this.state.familiProduct === 'Washdown Truck' && <WashDownTruck />}
                                        {this.state.familiProduct === 'Lube Truck' && <LubeTruck />}
                                        {this.state.familiProduct === 'OHT Body' && <OHTBody />}
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default CreateTechnicalChecklist;
