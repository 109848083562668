
import React, { PureComponent } from "react";
import {
    Row,
    Col,
    FormGroup,
    Input,
    Button
} from "reactstrap";
import { validateOHTBody } from 'services/validate';
import SweetAlert from "react-bootstrap-sweetalert";
class OHTBody extends PureComponent {
    state = {
        data: {
            product: 'OHT Body',
            brand: '',
            type: '',
            wheelbase: '',
            emow: '',
            tgmow: '',
            faxle_e: '',
            raxle_e: '',
            faxle_l: '',
            raxle_l: '',
            payload_type: '',
            payload_density: '',
            t_payload_volume: '',
            t_payload_weight: '',
            body_type: '',
            body_heating: '',
            travelling_distance: '',
            loading_unit: '',
            bucket_capacity: '',
            production_target_year: '',
            qty: '',
            type: '',
            capacity: ''
        },
        dataRemarks: {
            brand_remarks: '',
            type_remarks: '',
            wheelbase_remarks: '',
            emow_remarks: '',
            tgmow_remarks: '',
            faxle_e_remarks: '',
            raxle_e_remarks: '',
            faxle_l_remarks: '',
            raxle_l_remarks: '',
            payload_type_remarks: '',
            payload_density_remarks: '',
            t_payload_volume_remarks: '',
            t_payload_weight_remarks: '',
            body_type_remarks: '',
            body_heating_remarks: '',
            travelling_distance_remarks: '',
            loading_unit_remarks: '',
            bucket_capacity_remarks: '',
            production_target_year_remarks: '',
            qty_remarks: '',
            type_remarks: '',
            capacity_remarks: ''
        },
        optionsType: [],
        isLoading: false,
        alert: null,
        paramError: {}
    };

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleChangeString = (event) => {
        this.setState({
            data: {
                ...this.state.data,
                [event.target.name]: event.target.value,
            },
        });
    };

    handleChangeStringRemarks = (event) => {
        this.setState({
            dataRemarks: {
                ...this.state.dataRemarks,
                [event.target.name]: event.target.value,
            },
        });
    };

    handleChangeTruckBrand = (event) => {
        this.setState({
            data: {
                ...this.state.data,
                [event.target.name]: event.target.value,
            }
        }, () => {
            if (this.state.data.brand === 'CATERPILLAR') {
                this.setState({
                    optionsType: [
                        "CAT 777D",
                        "CAT 777E",
                    ],
                })
            }
            if (this.state.data.brand === 'KOMATSU') {
                this.setState({
                    optionsType: [
                        "HD465-7R",
                        "HD785-7",
                    ],
                })
            }
            if (this.state.data.brand === 'LOAD PRO') {
                this.setState({
                    optionsType: [
                        "PRO 1",
                        "PRO 2",
                    ],
                })
            }

        })
    }

    handleChangeType = (event) => {
        this.setState({
            data: {
                ...this.state.data,
                [event.target.name]: event.target.value,
            }
        }, () => {
            if (this.state.data.type === 'CAT 777D') {
                this.setState({
                    data: {
                        ...this.state.data,
                        wheelbase: '4570',
                        emow: '72960',
                        tgmow: '163360',
                        faxle_e: '34291',
                        raxle_e: '38669',
                        faxle_l: '53909',
                        raxle_l: '109451',
                    }
                })
            }
            if (this.state.data.type === 'CAT 777E') {
                this.setState({
                    data: {
                        ...this.state.data,
                        wheelbase: '4570',
                        emow: '65160',
                        tgmow: '163360',
                        faxle_e: '28670',
                        raxle_e: '36490',
                        faxle_l: '49008',
                        raxle_l: '114352',
                    }
                })
            }
            if (this.state.data.type === 'HD465-7R') {
                this.setState({
                    data: {
                        ...this.state.data,
                        wheelbase: '4300',
                        emow: '43100',
                        tgmow: '99680',
                        faxle_e: '20257',
                        raxle_e: '22843',
                        faxle_l: '31898',
                        raxle_l: '67782',
                    }
                })
            }
            if (this.state.data.type === 'HD785-7') {
                this.setState({
                    data: {
                        ...this.state.data,
                        wheelbase: '4950',
                        emow: '72000',
                        tgmow: '166000',
                        faxle_e: '33840',
                        raxle_e: '38160',
                        faxle_l: '52290',
                        raxle_l: '113710',
                    }
                })
            }

        })
    }

    keyPressed(event) {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    }

    handleBuildValue = () => {
        this.setState({
            isLoading: true
        }, () => {
            const paramError = validateOHTBody(this.state.data);
            this.setState({ paramError });
            if (Object.keys(paramError).length === 0) {
                var data = Object.entries(this.state.data).map(([key, value]) => ({ key, value }));
                var dataRemarks = Object.entries(this.state.dataRemarks).map(([key, value]) => ({ key, value }));

                for (var i = 0; i <= dataRemarks.length - 1; i++) {
                    data[i + 1] = Object.values({ ...data[i + 1], remarks: dataRemarks[i].value })
                }
            } else {
                this.failedAlert("Validation Errors");
                this.setState({
                    isLoading: false,
                })
            }
        })
    }

    failedAlert = (data) => {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    style={{ display: "block" }}
                    title="Failed"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="danger"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    {`${data}`}
                </SweetAlert>
            ),
        });
    };

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    };
    render() {
        return (
            <>
                <Row>
                    <Col>
                        <h6 className="heading-small text-muted">Truck</h6>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Brand</label>
                            <Input
                                type="select"
                                onKeyPress={this.keyPressed}
                                name="brand"
                                placeholder="Brand"
                                value={this.state.data.brand}
                                onChange={this.handleChangeTruckBrand}
                                required
                                className={this.state.paramError.brand ? "is-invalid" : ""}
                            >
                                <option value={""}>Select</option>
                                <option value={"CATERPILLAR"}>CATERPILLAR</option>
                                <option value={"KOMATSU"}>KOMATSU</option>
                                <option value={"LOAD PRO"}>LOAD PRO</option>
                            </Input>
                            <div className="invalid-feedback">
                                {this.state.paramError.brand}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="brand_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.brand_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Type</label>
                            <Input
                                type="select"
                                onKeyPress={this.keyPressed}
                                name="type"
                                placeholder="Brand"
                                value={this.state.data.type}
                                onChange={this.handleChangeType}
                                required
                                className={this.state.paramError.type ? "is-invalid" : ""}
                            >
                                <option value={""}>Select</option>
                                {
                                    this.state.optionsType.map((element, index) => {
                                        return (
                                            <option key={index} value={element}>{element}</option>
                                        )
                                    })
                                }
                            </Input>
                            <div className="invalid-feedback">
                                {this.state.paramError.type}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="type_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.type_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Wheelbase (mm)</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="wheelbase"
                                placeholder="Wheelbase"
                                value={this.state.data.wheelbase}
                                required
                                disabled
                            >
                            </Input>
                            <div className="invalid-feedback">
                                {this.state.paramError.wheelbase}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="wheelbase_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.wheelbase_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Empty Machine Operating Weight</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="emow"
                                placeholder="..."
                                value={this.state.data.emow}
                                required
                                disabled
                            >
                            </Input>
                            <div className="invalid-feedback">
                                {this.state.paramError.emow}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="emow_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.emow_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Target Gross Machine Operating Weight</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="tgmow"
                                placeholder="Wheelbase"
                                value={this.state.data.tgmow}
                                required
                                disabled
                            >
                            </Input>
                            <div className="invalid-feedback">
                                {this.state.paramError.tgmow}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="tgmow_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.tgmow_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Front Axle-Empty, kg</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="faxle_e"
                                placeholder="Wheelbase"
                                value={this.state.data.faxle_e}
                                required
                                disabled
                            >
                            </Input>
                            <div className="invalid-feedback">
                                {this.state.paramError.faxle_e}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="faxle_e_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.faxle_e_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Rear Axle-Empty, kg</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="raxle_e"
                                placeholder="Wheelbase"
                                value={this.state.data.raxle_e}
                                required
                                disabled
                            >
                            </Input>
                            <div className="invalid-feedback">
                                {this.state.paramError.raxle_e}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="raxle_e_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.raxle_e_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Front Axle-Load, kg</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="faxle_l"
                                placeholder="Wheelbase"
                                value={this.state.data.faxle_l}
                                required
                                disabled
                            >
                            </Input>
                            <div className="invalid-feedback">
                                {this.state.paramError.faxle_l}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="faxle_l_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.faxle_l_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Rear Axle-Load, kg</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="raxle_l"
                                placeholder="Wheelbase"
                                value={this.state.data.raxle_l}
                                required
                                disabled
                            >
                            </Input>
                            <div className="invalid-feedback">
                                {this.state.paramError.raxle_l}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="raxle_l_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.raxle_l_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h6 className="heading-small text-muted">Payload Data</h6>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Payload Type</label>
                            <Input
                                type="select"
                                onKeyPress={this.keyPressed}
                                name="payload_type"
                                placeholder="Payload Type"
                                value={this.state.data.payload_type}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.payload_type ? "is-invalid" : ""}
                            >
                                <option value={""}>Select</option>
                                <option value={"Coal"}>Coal</option>
                                <option value={"Overburden"}>Overburden</option>
                                <option value={"Custome"}>Custome</option>
                            </Input>
                            <div className="invalid-feedback">
                                {this.state.paramError.payload_type}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="payload_type_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.payload_type_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Payload Density</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="payload_density"
                                placeholder="Payload Density"
                                value={this.state.data.payload_density}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.payload_density ? "is-invalid" : ""}
                            />
                            <div className="invalid-feedback">
                                {this.state.paramError.payload_density}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="payload_density_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.payload_density_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Taget Payload Volume (SAE 2:1)</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="t_payload_volume"
                                placeholder="Taget Payload Volume"
                                value={this.state.data.t_payload_volume}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.t_payload_volume ? "is-invalid" : ""}
                            />
                            <div className="invalid-feedback">
                                {this.state.paramError.t_payload_volume}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="t_payload_volume_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.t_payload_volume_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Taget Payload Weight</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="t_payload_weight"
                                placeholder="Taget Payload Weight"
                                value={this.state.data.t_payload_weight}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.t_payload_weight ? "is-invalid" : ""}
                            />
                            <div className="invalid-feedback">
                                {this.state.paramError.t_payload_weight}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="t_payload_weight_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.t_payload_weight_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h6 className="heading-small text-muted">Payload Data</h6>
                    </Col>
                </Row>
                <Row>
                <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Body Type</label>
                            <Input
                                type="select"
                                onKeyPress={this.keyPressed}
                                name="body_type"
                                placeholder="Body Type"
                                value={this.state.data.body_type}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.body_type ? "is-invalid" : ""}
                            >
                                <option value={""}>Select</option>
                                <option value={"Standard Body"}>Standard Body</option>
                                <option value={"Light Body"}>Light Body</option>
                            </Input>
                            <div className="invalid-feedback">
                                {this.state.paramError.body_type}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="body_type_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.body_type_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Body Heating</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="body_heating"
                                placeholder="Body Heating"
                                value={this.state.data.body_heating}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.body_heating ? "is-invalid" : ""}
                            />
                            <div className="invalid-feedback">
                                {this.state.paramError.body_heating}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="body_heating_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.body_heating_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h6 className="heading-small text-muted">Application Data</h6>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Travelling Distance</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="travelling_distance"
                                placeholder="Travelling Distance"
                                value={this.state.data.travelling_distance}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.travelling_distance ? "is-invalid" : ""}
                            />
                            <div className="invalid-feedback">
                                {this.state.paramError.travelling_distance}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="travelling_distance_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.travelling_distance_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Loading Unit</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="loading_unit"
                                placeholder="Loading Unit"
                                value={this.state.data.loading_unit}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.loading_unit ? "is-invalid" : ""}
                            />
                            <div className="invalid-feedback">
                                {this.state.paramError.loading_unit}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="loading_unit_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.loading_unit_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Bucket Capacity</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="bucket_capacity"
                                placeholder="Bucket Capacity"
                                value={this.state.data.bucket_capacity}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.bucket_capacity ? "is-invalid" : ""}
                            />
                            <div className="invalid-feedback">
                                {this.state.paramError.bucket_capacity}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="bucket_capacity_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.bucket_capacity_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Production Target per Year</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="production_target_year"
                                placeholder="Production Target per Year"
                                value={this.state.data.production_target_year}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.production_target_year ? "is-invalid" : ""}
                            />
                            <div className="invalid-feedback">
                                {this.state.paramError.production_target_year}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="production_target_year_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.production_target_year_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h6 className="heading-small text-muted">Existing Unit Data</h6>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Qty</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="qty"
                                placeholder="Qty"
                                value={this.state.data.qty}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.qty ? "is-invalid" : ""}
                            />
                            <div className="invalid-feedback">
                                {this.state.paramError.qty}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="qty_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.qty_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Type</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="type"
                                placeholder="Type"
                                value={this.state.data.type}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.type ? "is-invalid" : ""}
                            />
                            <div className="invalid-feedback">
                                {this.state.paramError.type}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="type_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.type_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                            <label className="form-control-label">Capacity</label>
                            <Input
                                type="text"
                                onKeyPress={this.keyPressed}
                                name="capacity"
                                placeholder="Capacity"
                                value={this.state.data.capacity}
                                onChange={this.handleChangeString}
                                required
                                className={this.state.paramError.capacity ? "is-invalid" : ""}
                            />
                            <div className="invalid-feedback">
                                {this.state.paramError.capacity}
                            </div>
                            <Input
                                className="mt-2"
                                type="textarea"
                                name="capacity_remarks"
                                placeholder="Remarks"
                                value={this.state.dataRemarks.capacity_remarks}
                                onChange={this.handleChangeStringRemarks}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-3 col-sm-6"
                        xs="12"
                    >
                        <Button
                            block
                            color="success"
                            className="btn-icon"
                            type="button"
                            disabled={this.state.isLoading ? true : false}
                            onClick={() => this.handleBuildValue()}
                        >
                            {this.state.isLoading ? (
                                <>
                                    <span className="btn-inner--text">
                                        <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Submit
                              </span>
                                </>
                            ) : (
                                <span className="btn-inner--text">Submit</span>
                            )}
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}

export default OHTBody;
