import { 
    GET_DATA_PRODUCTS
 } from "./productActions";

 const initialState = {
    dataProducts: [],
  };

  export default function productReducer(state = initialState, action){
      switch(action.type){
          case GET_DATA_PRODUCTS:
              return {
                  ...state,
                  dataProducts: action.payload.product
              };
        
        default:
            return state;
      }
  }