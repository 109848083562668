import React from "react";
import {
  Badge,
  Card,
  CardFooter,
  Media,
  Alert,
  Form,
  FormGroup,
  FormText,
  Input,
  Table,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
  // UncontrolledPopover,
  // PopoverBody
} from "reactstrap";
import { currencyIDR } from "services/currency";
import Pagination from "react-bootstrap-pagination-logic";
import { getDataCustomerQuotationsDetails } from "./Redux/customerQuotationActions";
import config from "services/config";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import Title from "components/Breadcumbs/Title";
// import Breadcumbs from "components/Breadcumbs/Breadcumbs";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { MyDocument } from "services/PDF/quotation/document";
// import { MyDocumentMultipleProducts } from "services/PDF/quotation/documentMultipleProducts";
// import ListGroupItem from "reactstrap/lib/ListGroupItem";
// import ListGroup from "reactstrap/lib/ListGroup";
class ListCustomerProductQuotations extends React.Component {
  state = {
    actionListQuotations: {
      page: 1,
      items_per_page: 10,
      order_by_field: "created_at",
      order_by_direction: "DESC",
      company_id: "",
      quotations_id: "",
    },
    ready: false,
    listQuotations: [],
    dataCompany: [],
    total_pages: 0,
    total_items: 0,
  };
  componentDidMount() {
    this.mounted = true;
    this.getDataListQuotations();
    // this.getViewCompany();

    // Screen Size
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ hideNav: window.innerWidth <= 575.98 });
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.resize.bind(this));
  }

  toggle() {
    this.setState(
      (prevState) => ({
        ready: false,
      }),
      () => {
        setTimeout(() => {
          this.setState({ ready: true });
        }, 1);
      }
    );
  }

  getDataListQuotations = () => {
    axios
      .post(
        config.API_URL + "/quotations/list_products_quotations_customers",
        {
          ...this.state.actionListQuotations,
          company_id: config.COMPANY_ID,
          quotations_id: this.props.match.params.quotationsId
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listQuotations: res.data.data,
              total_pages: res.data.total_pages,
              total_items: res.data.total_items,
            }, () => {
              this.getViewCompany();
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getViewCompany = () => {
    axios
      .post(
        config.API_URL + "/companies/view_company_customers",
        { company_id: config.COMPANY_ID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              dataCompany: res.data.data[0],
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  }
  handlePagination = (numPage) => {
    this.setState(
      {
        actionListQuotations: {
          ...this.state.actionListQuotations,
          page: numPage,
        },
      },
      () => {
        this.getDataListQuotations();
      }
    );
  };

  handleItemPerpage = (e) => {
    this.setState(
      {
        actionListQuotations: {
          ...this.state.actionListQuotations,
          page: 1,
          items_per_page: parseInt(e.target.value),
        },
      },
      () => {
        this.getDataListQuotations();
      }
    );
  };

  handleDetails = (data) => {
    this.props.dispatch(getDataCustomerQuotationsDetails(data));
    this.props.history.push("/customers/prespec_details/" + data.company_id + '/' + data.quote_id);
  };

  // handleStatus = (data) => {
  //   if (data === "Pending") {
  //     return "bg-primary";
  //   }
  //   if (data === "In Review") {
  //     return "bg-primary";
  //   }
  //   if (data === "Approved") {
  //     return "bg-success";
  //   }
  //   if (data === "Processed") {
  //     return "bg-success";
  //   }
  //   if (data === "Rejected") {
  //     return "bg-danger";
  //   }
  // };
  render() {
    let fromItem = null;
    let toItem = null;

    if (this.state.total_pages === this.state.actionListQuotations.page) {
      toItem = this.state.total_items;
    } else {
      toItem =
        this.state.listQuotations.length * this.state.actionListQuotations.page;
    }
    if (this.state.actionListQuotations.page === 1) {
      fromItem = 1;
    } else if (
      this.state.total_pages === this.state.actionListQuotations.page
    ) {
      fromItem = this.state.total_items - this.state.listQuotations.length + 1;
    } else {
      fromItem =
        this.state.listQuotations.length *
        (this.state.actionListQuotations.page - 1) +
        1;
    }
    const ListDataQuotations = this.state.listQuotations.map((quotations, index) => {
      return (
        <tr
          key={quotations.quote_id}
          style={{ cursor: "pointer" }}
          onClick={() => this.handleDetails(quotations)}
        >
          <td>{quotations.quotations_id}</td>
          <th scope="row">
            <Media className="align-items-center">
              <a
                className="avatar rounded-circle mr-3"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <img
                  alt="..."
                  src={config.BUCKET_URL + quotations.image_1}
                />
              </a>
              <Media>
                <span className="mb-0 text-sm">
                  {quotations.category_name} {quotations.chasis} {quotations.tank_capacity}{" "}
                  {quotations.axle}
                </span>
              </Media>
            </Media>
          </th>
        </tr>
      )
    })
    const availDataQuotations = this.state.listQuotations.map((quotations, index) => {
      return (
        <tr
          key={quotations.quote_id}
          style={{ cursor: "pointer" }}
          onClick={() => this.handleDetails(quotations)}
        >
          {/* <td>{quotations.quotations_id}</td> */}
          {/* <td>
            <Badge color="" className="badge-dot mr-4"
             onClick={() => this.handleDetails(quotations)}
            >
              <i className={this.handleStatus(quotations.status)} />
              {quotations.status}
            </Badge>
          </td> */}
          <th scope="row">
            <Media className="align-items-center">
              <a
                className="avatar rounded-circle mr-3"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <img
                  alt="..."
                  src={config.BUCKET_URL + quotations.image_1}
                />
              </a>
              <Media>
                <span className="mb-0 text-sm">
                  {quotations.category_name} {quotations.chasis} {quotations.tank_capacity}{" "}
                  {quotations.axle}
                </span>
              </Media>
            </Media>
              <div class="text-center">
                    {this.state.ready && (
                      <PDFDownloadLink
                        document={
                          <MyDocument
                            data={{
                              ...this.state.dataPreSpec,
                              ...this.state.dataCompany,
                              company: this.state.dataCompany,
                            }}
                            optional={
                              this.state.listQuotationOptionalComponents
                            }
                            specifications={
                              this.state.listQuotationSpecifications
                            }
                          />
                        }
                        fileName={
                          "Configuration Record-" +
                          quotations.quotations_id +
                          ".pdf"
                        }
                      >
                        {({ blob, url, loading, error }) => loading ? (
                            <>
                              <Button
                                className="text-uppercase"
                                color="primary"
                                size="sm"
                                // onClick={() => this.toggle()}
                              >
                                <span className="btn-inner--text">
                                  <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                  Download your configuration
                                </span>
                              </Button>
                              {/* <Button
                                className="text-uppercase"
                                color="primary"
                                type="button"
                                onClick={() =>
                                  window.open(
                                    config.BUCKET_URL+
                                      this.state.dataPreSpec
                                        .file_specifications,
                                    "_blank"
                                  )
                                }
                              >
                                <span className="btn-inner--text ml-2">Brochure</span>
                              </Button> */}
                            </>
                          ) : (
                            <>
                              <Button
                                className="text-uppercase"
                                color="primary"
                                onClick={() => this.setState({ ready: false })}
                                size="sm"
                              >
                                Download your configuration
                              </Button>
                              {/* <Button
                                className="text-uppercase"
                                color="primary"
                                type="button"
                                onClick={() =>
                                  window.open(
                                    config.BUCKET_URL+
                                      this.state.dataPreSpec
                                        .file_specifications,
                                    "_blank"
                                  )
                                }
                              >
                                <span className="btn-inner--text ml-2">Brochure</span>
                              </Button> */}
                            </>
                          )
                        }
                      </PDFDownloadLink>
                    )}
                    {!this.state.ready && (
                      <>
                        <Button
                          className="text-uppercase"
                          color="primary"
                          // onClick={() => this.toggle()}
                          size="sm"
                        >
                          Download your configuration
                        </Button>
                        {/* <Button
                          className="text-uppercase"
                          color="primary"
                          type="button"
                          onClick={() =>
                            window.open(
                              config.BUCKET_URL+
                                this.state.dataPreSpec
                                  .file_specifications,
                              "_blank"
                            )
                          }
                        >
                          <span className="btn-inner--text ml-2">Brochure</span>
                        </Button> */}
                      </>
                    )}
                  </div>
          </th>
          {/* <td className="text-wrap">{quotations.product_description}
          </td> */}
          <td>{quotations.username}</td>
          <td>{this.state.dataCompany.username}</td>
          <td>{currencyIDR(quotations.product_price).replace(/\.00$/, "")}</td>
          {/* <td>{quotations.tax + " %"}</td>
          <td>{quotations.discount + " %"}</td> */}
          <td>
            {currencyIDR(quotations.total_product_price).replace(/\.00$/, "")}
          </td>
          <td>{quotations.quantity}</td>
          <td>{currencyIDR(quotations.grand_total).replace(/\.00$/, "")}</td>
          {/* <td>{quotations.purchase_timeframe}</td> */}
          <td>{moment(quotations.created_at).format("lll")}</td>
        </tr>
      );
    });
    return (
      <>
        <Container className="bg-secondary mt--6">
          {/* <Breadcumbs
            name="List PreSpec"
            parentName="PreSpec"
            link="/customers/quotations"
          /> */}
          <Title name="CONFIGURATION RECORD" />
          <Row>
            {/* <Col lg="8" xs="8">
              <Title
                name={"PreSpec ID: " + this.props.match.params.quotationsId}
              />
            </Col>
            <Col className="text-right" lg="4" xs="4">
              <div>
                    {this.state.ready && (
                      <PDFDownloadLink
                        document={
                          <MyDocumentMultipleProducts
                            data={{products: this.state.listQuotations, company: this.state.dataCompany}}
                          />
                        }
                        fileName={
                          "PreSpec-" +
                          this.props.match.params.quotationsId +
                          ".pdf"
                        }
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <>
                              <Button
                                className="btn-second text-uppercase"
                                onClick={() => this.toggle()}
                              >
                                 <span className="btn-inner--text">
                                  <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                  Generate PDF
                                </span>
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                className="btn-second text-uppercase"
                                onClick={() => this.setState({ ready: false })}
                              >
                                Download PDF
                              </Button>
                            </>
                          )
                        }
                      </PDFDownloadLink>
                    )}
                    {!this.state.ready && (
                      <>
                        <Button
                          className="btn-second text-uppercase"
                          onClick={() => this.toggle()}
                        >
                          Generate PDF
                        </Button>
                      </>
                    )}
                  </div>
            </Col> */}
          </Row>
          <Row>
            <Col>
              <div className="card-wrapper">
                <Card>
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="6">
                        <h3 className="mb-0">List ID</h3>
                      </Col>
                      <Col className="text-right" xs="6"></Col>
                    </Row>
                  </CardHeader>
                  {this.state?.hideNav ?
                    (
                      <>
                        <div>
                          {this.state.listQuotations.length < 5 ? (
                            <div>
                              {this.state.listQuotations.length === 0 ? (
                                <Alert color="white">
                                  <strong>Data not found!</strong> Please check again!
                                </Alert>
                              ) : (
                                <>
                                  <Table
                                    className="align-items-center table-flush"
                                    hover
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">Product</th>
                                      </tr>
                                    </thead>
                                    <tbody>{ListDataQuotations}</tbody>
                                  </Table>
                                </>
                              )}
                            </div>
                          ) : (
                            <>
                              <Table
                                className="align-items-center table-flush"
                                hover
                                responsive
                              >
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Product</th>
                                  </tr>
                                </thead>
                                <tbody>{ListDataQuotations}</tbody>
                              </Table>
                            </>
                          )}
                        </div>
                        
                        <CardFooter className="py-4">
                          <nav aria-label="...">
                            <Pagination
                              current_page={this.state.actionListQuotations.page}
                              last_page={this.state.total_pages}
                              position="right"
                              handlePageChange={this.handlePagination}
                            />
                          </nav>
                        </CardFooter>
                      </>
                    ) :
                    (
                      <>
                        <div>
                          {this.state.listQuotations.length < 5 ? (
                            <div>
                              {this.state.listQuotations.length === 0 ? (
                                <Alert color="white">
                                  <strong>Data not found!</strong> Please check again!
                                </Alert>
                              ) : (
                                <>
                                  <Table
                                    className="align-items-center table-flush"
                                    hover
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        {/* <th scope="col">ID</th> */}
                                        <th scope="col">Product</th>
                                        {/* <th scope="col">Description</th> */}
                                        <th scope="col">Attention</th>
                                        <th scope="col">Sales</th>
                                        <th scope="col">Price</th>
                                        {/* <th scope="col">Tax</th>
                                      <th scope="col">Discount</th> */}
                                        <th scope="col">Total/Unit</th>
                                        <th scopre="col">Quantity</th>
                                        <th scopre="col">Grand Total</th>
                                        {/* <th scope="col">Purchase Timeframe</th> */}
                                        {/* <th scope="col">Status</th> */}
                                        <th scope="col">Created At</th>
                                      </tr>
                                    </thead>
                                    <tbody>{availDataQuotations}</tbody>
                                  </Table>
                                </>
                              )}
                            </div>
                          ) : (
                            <>
                              <Table
                                className="align-items-center table-flush"
                                hover
                                responsive
                              >
                                <thead className="thead-light">
                                  <tr>
                                    {/* <th scope="col">ID</th> */}
                                    {/* <th scope="col">Status</th> */}
                                    <th scope="col">Product</th>
                                    {/* <th scope="col">Product Description</th> */}
                                    <th scope="col">Attention</th>
                                    <th scope="col">Sales</th>
                                    <th scope="col">Price</th>
                                    {/* <th scope="col">Tax</th>
                                  <th scope="col">Discount</th> */}
                                    <th scope="col">Total/Unit</th>
                                    <th scopre="col">Quantity</th>
                                    <th scopre="col">Grand Total</th>
                                    {/* <th scope="col">Purchase Timeframe</th> */}
                                    <th scope="col">Created At</th>
                                  </tr>
                                </thead>
                                <tbody>{availDataQuotations}</tbody>
                              </Table>
                            </>
                          )}
                        </div>
                        <CardFooter className="py-4">
                          <Row className="align-items-center">
                            <Col xs="8">
                              <Col md="3" sm="6">
                                <Form inline>
                                  <FormGroup>
                                    <FormText className="mr-sm-2">Show</FormText>

                                    <Input
                                      className="form-control-sm mr-sm-2"
                                      type="select"
                                      onChange={this.handleItemPerpage}
                                    >
                                      <option value={10}>10</option>
                                      <option value={25}>25</option>
                                      <option value={50}>50</option>
                                      <option value={100}>100</option>
                                    </Input>
                                    <FormText>
                                      entries. Showing rows {fromItem} to {toItem} of{" "}
                                      {this.state.total_items}
                                    </FormText>
                                  </FormGroup>
                                </Form>
                              </Col>
                            </Col>
                            <Col className="text-right" xs="4">
                              <nav aria-label="...">
                                <Pagination
                                  current_page={this.state.actionListQuotations.page}
                                  last_page={this.state.total_pages}
                                  position="right"
                                  handlePageChange={this.handlePagination}
                                />
                              </nav>
                            </Col>
                          </Row>
                        </CardFooter>
                      </>

                    )
                  }

                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataCustomerProductsQuotationsDetails:
      state.customer_product.dataCustomerProductsQuotationsDetails,
  };
};

export default connect(mapStateToProps)(ListCustomerProductQuotations);