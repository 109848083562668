import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";

import config from "services/config";
import axios from "axios";
import decode from "jwt-decode";
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
// import ReactGA from 'react-ga';

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
// import OtpLayout from "layouts/Otp.js";
import Customers from "layouts/Customers";
import CustomerLoyalti from "layouts/CustomersLoyalti";

import companyReducer from "views/Admin/Companies/Redux/companyReducer";
import categoryReducer from "views/Admin/Categories/Redux/categoryReducer";
import productReducer from "views/Admin/Products/Redux/productReducer";
import customerProductReducer from "views/Customers/Products/Redux/customerProductReducer";
import globalReduxReducer from "services/globalRedux/globalReduxReducer";
import quotationReducer from "views/Admin/Quotations/Redux/quotationReducer";
import customerQuotationReducer from "views/Customers/Quotations/Redux/customerQuotationReducer";
// import CustomersLoyalti from "layouts/CustomersLoyalti";

console.warn = () => {};

const checkAuthAdmin = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }
  try {
    var token_local = decode(token);
    var exp = decode(token_local.token);
    var currentTime = new Date().getTime() / 1000;
    if (exp.exp < currentTime) {
      axios
      .post(config.API_URL + "/auth/logout",{ user_id: token_local.user_id}, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      // localStorage.removeItem("token");
      localStorage.clear();
      window.location.reload(true);
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};

const checkRole  = (role)  =>  {
  try {
    const token = localStorage.getItem("token");
    var token_local = decode(token);
    var token_result = decode(token_local.token);
    if(token_result.role_id === role){
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthAdmin() && checkRole(2||1) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/auth/loginAdmin" }} />
      )
    }
  />
);

const CustomerRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthAdmin() && checkRole(3) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/auth/login" }} />
      )
    }
  />
);

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthAdmin() ? (
        <Redirect to={{ pathname: "/auth/login" }} />
      ) : (
        <Component {...props} />
      )
    }
  />
);
// const OtpRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       checkAuthAdmin() ? (
//         <Redirect to={{ pathname: "/otp/otp" }} />
//       ) : (
//         <Component {...props} />
//       )
//     }
//   />
// );
const CustomerLoyaltiRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthAdmin() ? (
        <Redirect to={{ pathname: "/customerloyalti/customer-loyalti-dashboard" }} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const rootReducer = combineReducers({
  company: companyReducer,
  category: categoryReducer,
  product: productReducer,
  quotation: quotationReducer,
  customer_product: customerProductReducer,
  customer_quotation: customerQuotationReducer,
  global : globalReduxReducer
});

const storeRedux = createStore(rootReducer);

ReactDOM.render(
  <Provider store={storeRedux}>
    <BrowserRouter>
      <Switch>
        {/* {console.log(Customers)} */}
        <AdminRoute path="/admin" component={AdminLayout} />
        <AuthRoute path="/auth" component={AuthLayout} /> 
        <CustomerRoute path="/customers" component={Customers} />
        {/* <CustomerRoute path="/customers" component={Customers} >
        </CustomerRoute> */}
        <CustomerLoyaltiRoute path="/customerloyalti" component={CustomerLoyalti} />
        <Redirect path="/" to="/auth" />
      </Switch>
    </BrowserRouter>
  </Provider>
  ,
  document.getElementById("root")
);
