/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  UncontrolledCarousel,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Alert,
  FormGroup,
  Input,
  Form,
} from "reactstrap";
import BreadcumbsStandardConfigurationDetails from "components/Breadcumbs/BreadcumbsStandardConfigurationDetails";
import config from "services/config";
import { currencyIDR } from "services/currency";
import { connect } from "react-redux";
import axios from "axios";
import { countries } from "services/countries";
import { styleInputSelect } from "services/styles";
import {
  getNumberTabs,
  getNumberCart,
} from "services/globalRedux/globalReduxActions";
import AsyncSelect from "react-select/async";
import { validateQuotations } from "services/validate";
import SweetAlert from "react-bootstrap-sweetalert";
import { getDataCustomerProductsDetails } from "../Redux/customerProductActions";
import CardFooter from "reactstrap/lib/CardFooter";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
class CustomerStandardConfigurationDetails extends React.Component {
  state = {
    listStandardComponents: [],
    listOptionalComponents: [],
    listSpecifications: [],
    listOptionalComponentsAdded: [],
    countPriceComponents: 0,
    grandTotal: 0,
    actionListProducts: {
      page: 1,
      items_per_page: 10,
      order_by_field: "product_id",
      order_by_direction: "DESC",
      category_id: this.props.dataCustomerProductsDetails.category_id,
      product_no: "",
      chasis: "",
      axle: "",
      tank_capacity: "",
      product_type: "",
    },
    data: {
      // purchase_timeframe: "",
      country: "",
      area: "",
      notes: "",
      // shipping_method: "",
      // payment_terms: "",
      // freight_terms: "",
      quantity: 1,
      total_product_price: 0,
    },
    titleCardComparisonProduct: "Comparison Product",
    brochureCardComparisonProduct: "",
    priceCardComparisonProduct: "",
    dataCardComparisonProduct: [],
    listComparisonProduct: [],
    selectedOptionProducts: [],
    isLoading: false,
    isCountryArea: false,
    alert: null,
    paramError: {},
  };
  componentDidMount() {
    this.mounted = true;
    this.additionalComponents = [];
    this.checkAdditionalComponents = [];
    this.PriceComponents = [
      parseInt(this.props.dataCustomerProductsDetails.product_price),
    ];
    this.setState(
      {
        countPriceComponents: parseInt(
          this.props.dataCustomerProductsDetails.product_price
        ),
      },
      () => {
        // var tax = this.props.dataCustomerProductsDetails.tax;
        // var total_tax =
        //   (parseInt(this.props.dataCustomerProductsDetails.product_price) *
        //     tax) /
        //   100;
        // var discount = this.props.dataCustomerProductsDetails.discount;
        // var total_discount =
        //   (parseInt(this.props.dataCustomerProductsDetails.product_price) *
        //     discount) /
        //   100;
        this.setState(
          {
            data: {
              ...this.state.data,
              total_product_price:
                parseInt(this.props.dataCustomerProductsDetails.product_price)
                //  +
                // total_tax -
                // total_discount,
            },
          },
          () => {
            this.setState({
              grandTotal: 1 * this.state.data.total_product_price,
            });
          }
        );
      }
    );
    this.getListStandardComponents();
    this.getListOptionalComponents();
    this.getListSpecifications();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  fetchDataProducts = (inputValue) => {
    let searchTerm = inputValue;

    if (!inputValue || inputValue === " ") {
      searchTerm = "";
    }

    this.setState({
      actionListProducts: {
        ...this.state.actionListProducts,
        chasis: searchTerm,
      },
    });

    const newRequest = axios.post(
      config.API_URL + "/customer_list_products/customer_list_products",
      this.state.actionListProducts,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );

    if (newRequest) {
      return newRequest.then((response) => {
        const compare = response.data.data.filter((i) =>
          i.chasis.toLowerCase().includes(inputValue.toLowerCase())
        );
        return compare.map((data) => ({
          label: data.chasis + " " + data.tank_capacity + " " + data.axle,
          value: data.product_id,
          file: data.file_specifications,
          price: data.product_price,
          data: data,
        }));
      });
    }
  };
  onSearchChangeProducts = (selectedOptionProducts) => {
    this.setState(
      {
        selectedOptionProducts: selectedOptionProducts,
        titleCardComparisonProduct: selectedOptionProducts.label,
        brochureCardComparisonProduct: selectedOptionProducts.file,
        priceCardComparisonProduct: selectedOptionProducts.price,
        dataCardComparisonProduct: selectedOptionProducts.data,
      },
      () => {
        this.getCompareProductSpecifications(
          this.state.selectedOptionProducts.value
        );
      }
    );
  };
  getCompareProductSpecifications = (data) => {
    axios
      .post(
        config.API_URL + "/specifications/specifications_customers",
        { product_id: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listComparisonProduct: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handleComparisonDetails = async (data) => {
    await this.props.dispatch(getDataCustomerProductsDetails(data));
    this.additionalComponents = [];
    this.checkAdditionalComponents = [];
    this.PriceComponents = [
      parseInt(this.props.dataCustomerProductsDetails.product_price),
    ];
    this.setState(
      {
        countPriceComponents: parseInt(
          this.props.dataCustomerProductsDetails.product_price
        ),
        actionListProducts: {
          ...this.state.actionListProducts,
          category_id: this.props.dataCustomerProductsDetails.category_id,
        },
      },
      () => {
        var tax = this.props.dataCustomerProductsDetails.tax;
        var total_tax =
          (parseInt(this.props.dataCustomerProductsDetails.product_price) *
            tax) /
          100;
        var discount = this.props.dataCustomerProductsDetails.discount;
        var total_discount =
          (parseInt(this.props.dataCustomerProductsDetails.product_price) *
            discount) /
          100;
        this.setState(
          {
            data: {
              ...this.state.data,
              total_product_price:
                parseInt(this.props.dataCustomerProductsDetails.product_price) +
                total_tax -
                total_discount,
            },
          },
          () => {
            this.setState({
              grandTotal: 1 * this.state.data.total_product_price,
            });
          }
        );
      }
    );
    await this.getListStandardComponents();
    await this.getListOptionalComponents();
    await this.getListSpecifications();
    this.props.history.push(
      "/customers/customer_standard_configuration_details"
    );
  };
  handleAdditionalComponents = (e, data) => {
    e.preventDefault();
    if (
      this.checkAdditionalComponents.indexOf(data.optional_component_name) == -1
    ) {
      this.checkAdditionalComponents.push(data.optional_component_name);
      this.additionalComponents.push(data);
      this.setState({
        listOptionalComponentsAdded: this.additionalComponents,
      });
      this.PriceComponents.push(parseInt(data.optional_component_price));
      let countPrice = 0;
      for (var i = 0; i < this.PriceComponents.length; i++) {
        countPrice += this.PriceComponents[i];
      }
      this.setState(
        {
          countPriceComponents: countPrice,
        },
        () => {
          var tax = this.props.dataCustomerProductsDetails.tax;
          var total_tax =
            (parseInt(this.state.countPriceComponents) * tax) / 100;
          var discount = this.props.dataCustomerProductsDetails.discount;
          var total_discount =
            (parseInt(this.state.countPriceComponents) * discount) / 100;
          this.setState(
            {
              data: {
                ...this.state.data,
                total_product_price:
                  parseInt(this.state.countPriceComponents) +
                  total_tax -
                  total_discount,
              },
            },
            () => {
              this.setState({
                grandTotal:
                  parseInt(this.state.data.quantity) *
                  this.state.data.total_product_price,
                  
              });
            }
          );
        }
      );
    }
  };
  handleDeleteAdditionalComponents = (data) => {
    this.additionalComponents.splice(data, 1);
    this.checkAdditionalComponents.splice(data, 1);
    this.setState({
      listOptionalComponentsAdded: this.additionalComponents,
    });
    this.PriceComponents.splice(data + 1, 1);
    let countPrice = 0;
    for (var i = 0; i < this.PriceComponents.length; i++) {
      countPrice += this.PriceComponents[i];
    }
    this.setState(
      {
        countPriceComponents: countPrice,
      },
      () => {
        var tax = this.props.dataCustomerProductsDetails.tax;
        var total_tax = (parseInt(this.state.countPriceComponents) * tax) / 100;
        var discount = this.props.dataCustomerProductsDetails.discount;
        var total_discount =
          (parseInt(this.state.countPriceComponents) * discount) / 100;
        this.setState(
          {
            data: {
              ...this.state.data,
              total_product_price:
                parseInt(this.state.countPriceComponents) +
                total_tax -
                total_discount,
            },
          },
          () => {
            this.setState({
              grandTotal:
                parseInt(this.state.data.quantity) *
                this.state.data.total_product_price,
            });
          }
        );
      }
    );
  };
  handleToRequestaQuote = () => {
    var elmnt = document.getElementById("request_quote");
    elmnt.scrollIntoView({ block: "center", behavior: "smooth" });
  };
  getListSpecifications = () => {
    axios
      .post(
        config.API_URL + "/specifications/specifications_customers",
        { product_id: this.props.dataCustomerProductsDetails.product_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listSpecifications: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListStandardComponents = () => {
    axios
      .post(
        config.API_URL + "/standard_components/standard_components_customers",
        { category_id: this.props.dataCustomerProductsDetails.category_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listStandardComponents: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getListOptionalComponents = () => {
    axios
      .post(
        config.API_URL + "/optional_components/optional_components_customers",
        { category_id: this.props.dataCustomerProductsDetails.category_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listOptionalComponents: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handleClear = () => {
    this.setState({
      brochureCardComparisonProduct: "",
      selectedOptionProducts: "",
    });
  };

  handleCancel = () => {
    this.props.history.push('/customers/customer_standard_configuration_products');
  };

  handleSend = () => {
    this.setState(
      {
        data: {
          ...this.state.data,
          product_id: this.props.dataCustomerProductsDetails.product_id,
          category_id: this.props.dataCustomerProductsDetails.category_id,
          company_id: config.COMPANY_ID,
          user_id: config.USER_ID,
          product_no: this.props.dataCustomerProductsDetails.product_no,
          product_description: this.props.dataCustomerProductsDetails
            .product_description,
          chasis: this.props.dataCustomerProductsDetails.chasis,
          axle: this.props.dataCustomerProductsDetails.axle,
          wheel_base: this.props.dataCustomerProductsDetails.wheel_base,
          tank_capacity: this.props.dataCustomerProductsDetails.tank_capacity,
          roh: this.props.dataCustomerProductsDetails.roh,
          product_price: this.state.countPriceComponents,
          grand_total: this.state.grandTotal,
          tax: this.props.dataCustomerProductsDetails.tax,
          lead_time: this.props.dataCustomerProductsDetails.lead_time,
          discount: this.props.dataCustomerProductsDetails.discount,
          image_1: this.props.dataCustomerProductsDetails.image_1,
          image_2: this.props.dataCustomerProductsDetails.image_2,
          image_3: this.props.dataCustomerProductsDetails.image_3,
          image_4: this.props.dataCustomerProductsDetails.image_4,
          file_specifications: this.props.dataCustomerProductsDetails
            .file_specifications,
          quotation_standard_components: [...this.state.listStandardComponents],
          quotation_optional_components: [
            ...this.state.listOptionalComponentsAdded,
          ],
          quotation_specifications: [...this.state.listSpecifications],
        },
      },
      () => {
        const paramError = validateQuotations(this.state.data);
        this.setState({ paramError });
        if (Object.keys(paramError).length === 0) {
          axios
            .post(
              config.API_URL + "/quotations/create_quotation_customers",
              this.state.data,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: config.TOKEN,
                },
              }
            )
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data.message);
                  this.setState({ isLoading: false });
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(err.response.data.message);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };

  handleToQuotations = () => {
    this.props.history.push("/customers/prespec");
    this.props.dispatch(getNumberTabs(4));
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.handleToQuotations()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  confirmAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block" }}
          title="Save Configuration?"
          onConfirm={() => this.handleSend()}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="info"
          confirmBtnText="Save"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        ></SweetAlert>
      ),
    });
  };

  handleChangeShippingMethod = (event) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        if (this.state.data.shipping_method === "Delivery") {
          this.setState({
            isCountryArea: true,
          });
        }
        if (this.state.data.shipping_method === "Ex-work") {
          this.setState({
            isCountryArea: false,
            data: {
              ...this.state.data,
              country: "",
              area: "",
            },
          });
        }
      }
    );
  };
  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  handleChangeQuantity = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: parseInt(event.target.value),
      },
      total_product_price:
        parseInt(event.target.value) * this.state.data.total_product_price,
      grandTotal:
        parseInt(event.target.value) * this.state.data.total_product_price,
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  handleAddToCart = () => {
    const paramError = validateQuotations(this.state.data);
    this.setState({ paramError });
    if (Object.keys(paramError).length === 0) {
      var data_ = {
        ...this.state.data,
        category_id: this.props.dataCustomerProductsDetails.category_id,
        product_id: this.props.dataCustomerProductsDetails.product_id,
        company_id: config.COMPANY_ID,
        user_id: config.USER_ID,
        product_no: this.props.dataCustomerProductsDetails.product_no,
        product_description:
          this.props.dataCustomerProductsDetails.product_description,
        chasis: this.props.dataCustomerProductsDetails.chasis,
        axle: this.props.dataCustomerProductsDetails.axle,
        wheel_base: this.props.dataCustomerProductsDetails.wheel_base,
        tank_capacity: this.props.dataCustomerProductsDetails.tank_capacity,
        roh: this.props.dataCustomerProductsDetails.roh,
        product_price: this.state.countPriceComponents,
        grand_total: this.state.grandTotal,
        tax: this.props.dataCustomerProductsDetails.tax,
        lead_time: this.props.dataCustomerProductsDetails.lead_time,
        discount: this.props.dataCustomerProductsDetails.discount,
        image_1: this.props.dataCustomerProductsDetails.image_1,
        image_2: this.props.dataCustomerProductsDetails.image_2,
        image_3: this.props.dataCustomerProductsDetails.image_3,
        image_4: this.props.dataCustomerProductsDetails.image_4,
        file_specifications:
          this.props.dataCustomerProductsDetails.file_specifications,
        quotation_standard_components: [...this.state.listStandardComponents],
        quotation_optional_components: [
          ...this.state.listOptionalComponentsAdded,
        ],
        quotation_specifications: [...this.state.listSpecifications],
      };
      var data = [];
      data = JSON.parse(localStorage.getItem("cart")) || [];
      data.push({
        ...data_,
      });
      localStorage.setItem("cart", JSON.stringify(data));
      this.props.dispatch(getNumberCart(data.length));
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };
  render() {
    const items = [
      {
        src: config.BUCKET_URL + this.props.dataCustomerProductsDetails.image_1,
        altText: "",
        caption: "",
        header: "",
      },
      {
        src: config.BUCKET_URL + this.props.dataCustomerProductsDetails.image_2,
        altText: "",
        caption: "",
        header: "",
      },
      {
        src: config.BUCKET_URL + this.props.dataCustomerProductsDetails.image_3,
        altText: "",
        caption: "",
        header: "",
      },
      {
        src: config.BUCKET_URL + this.props.dataCustomerProductsDetails.image_4,
        altText: "",
        caption: "",
        header: "",
      },
    ];
    return (
      <>
        <Container className="bg-secondary mt--6">
          <BreadcumbsStandardConfigurationDetails
            name={this.props.dataCustomerProductsDetails.chasis}
            parentName="Products"
            link="/customers/main_products"
            category="All Products"
          />

          <Row className="justify-content-center">
            <Col>
              <Card className="bg-dark text-white border-0">
                <UncontrolledCarousel items={items} />
              </Card>
            </Col>
            <Col className="text-left" lg="4">
              <h2 className="display-3">
                {this.props.dataCustomerProductsDetails.category_name}{" "}
                {this.props.dataCustomerProductsDetails.chasis}{" "}
                {this.props.dataCustomerProductsDetails.tank_capacity}{" "}
                {this.props.dataCustomerProductsDetails.axle}
              </h2>
              <small>Start from</small>
              <h4 className="display-4 d-flex">
                {currencyIDR(this.PriceComponents).replace(/\.00$/, "")}{" "}
                <span className="text-red pe-auto" id="tooltip366258619">
                  *
                </span>
                <UncontrolledTooltip delay={0} target="tooltip366258619">
                  Prices are estimated based on configuration. the actual price
                  may change after the requirement are fixed
                </UncontrolledTooltip>
              </h4>
              <small>
                {this.props.dataCustomerProductsDetails.product_description}
              </small>
              <table>
                <tr>
                  {/* <td>
                    <small>ROH</small>
                  </td>
                  <td className="px-4">
                    <small>Wheel Base</small>
                  </td> */}
                  <td>
                    <small>Lead Time</small>
                  </td>
                </tr>
                <tr>
                  {/* <td>
                    <h3 className="mb-0">
                      {this.props.dataCustomerProductsDetails.roh}
                    </h3>
                  </td>
                  <td className="px-4">
                    {" "}
                    <h3 className="mb-0">
                      {this.props.dataCustomerProductsDetails.wheel_base}
                    </h3>
                  </td> */}
                  <td>
                    {" "}
                    <h3 className="mb-0 d-flex">
                      {this.props.dataCustomerProductsDetails.lead_time}
                      <span className="text-red pe-auto">*</span>
                    </h3>
                  </td>
                </tr>
                <small className="font-italic">
                  Price(s) and leadtime(s) are given as indicative information,
                  subject to further confirmation by SSB Sales Department
                </small>
              </table>
              <Row className="mt-4 mb-4">
                <Col>
                  {/* <Button
                    className="btn-second text-uppercase"
                    type="button"
                    onClick={() => this.handleToRequestaQuote()}
                  >
                    Request a Quote
                  </Button> */}
                  {/* <Button
                    className="text-uppercase"
                    color="primary"
                    type="button"
                    onClick={() =>
                      window.open(
                        config.BUCKET_URL+
                          this.props.dataCustomerProductsDetails
                            .file_specifications,
                        "_blank"
                      )
                    }
                  >
                    <span className="btn-inner--text ml-2">Brochure</span>
                  </Button> */}
                </Col>
              </Row>
            </Col>
          </Row>
          {/* {this.state.listStandardComponents.length > 0 ||
          this.state.listOptionalComponents.length > 0 ? (
            <h1 className="display-4">Components</h1>
          ) : null} */}
          <Row className="mb-6">
            <Col>
              {/* {this.state.listStandardComponents.length > 0 ||
              this.state.listOptionalComponents.length > 0 ? (
                <Row>


 {this.state.listStandardComponents.length > 0 ? (
   <Col className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <Card>
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <div className="col">
                            <h3 className="mb-0">Standard Components</h3>
                          </div>
                        </Row>
                      </CardHeader>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.listStandardComponents.map(
                            (element, index) => {
                              return (
                                <tr key={index}>
                                  <th scope="row">
                                    {element.standard_component_name}
                                  </th>
                                  <td className="text-wrap">
                                    {element.standard_component_description}
                                  </td>
                                </tr>
                              );
                            }
                            )}
                        </tbody>
                      </Table>
                    </Card>
                  </Col>
                  ) : null}
{this.state.listOptionalComponents.length > 0 ? (
                  <Col className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <Card>
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <div className="col">
                            <h3 className="mb-0">Additional Components</h3>
                          </div>
                          <div className="col text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                caret
                                className="text-uppercase"
                                color="primary"
                                size="sm"
                              >
                                Add Components
                              </DropdownToggle>
                              <DropdownMenu>
                                {this.state.listOptionalComponents.length ===
                                0 ? (
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <div className="timeline-block">
                                      <div className="d-flex justify-content-between pt-1">
                                        <div>
                                          <span className="text-sm font-weight-bold">
                                            no components can be added
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </DropdownItem>
                                ) : (
                                  <>
                                    {this.state.listOptionalComponents.map(
                                      (element, index) => {
                                        return (
                                          <DropdownItem
                                            href="#pablo"
                                            onClick={(e) =>
                                              this.handleAdditionalComponents(
                                                e,
                                                element
                                              )
                                            }
                                            key={index}
                                          >
                                            <div className="timeline-block">
                                              <div className="d-flex justify-content-between pt-1">
                                                <div>
                                                  <span className="text-sm font-weight-bold">
                                                    {
                                                      element.optional_component_name
                                                    }
                                                  </span>
                                                </div>
                                                <div className="text-right ml-6">
                                                  <span className="text-sm">
                                                    {currencyIDR(
                                                      element.optional_component_price
                                                    ).replace(/\.00$/, "")}
                                                  </span>
                                                </div>
                                              </div>
                                              <p className="text-sm mt-1 mb-0">
                                                {
                                                  element.optional_component_description
                                                }
                                              </p>
                                            </div>
                                          </DropdownItem>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </Row>
                      </CardHeader>
                      {this.state.listOptionalComponentsAdded.length === 0 ? null : (
                        <>
                          <Table
                            className="align-items-center table-flush"
                            responsive
                          >
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Price</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.listOptionalComponentsAdded.map(
                                (element, index) => {
                                  return (
                                    <tr key={index}>
                                      <th scope="row">
                                        {element.optional_component_name}
                                      </th>
                                      <td>
                                        {currencyIDR(
                                          element.optional_component_price
                                        ).replace(/\.00$/, "")}
                                      </td>
                                      <td>
                                        <a
                                          className="table-action table-action-delete"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.handleDeleteAdditionalComponents(
                                              index
                                            )
                                          }
                                        >
                                          <i className="fas fa-trash" />
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </>
                      )}
                    </Card>
                  </Col>
                  ) : null}
                </Row>
              ) : null} */}
              <Row className="mb-4">
                <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  <h1 className="display-4">Compare</h1>
                </Col>
                <Col className="col-lg-4 col-md-9 col-sm-12" xs="12">
                  <Form role="form">
                    {/* <label className="form-control-label">Compare With</label> */}
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      styles={styleInputSelect}
                      value={this.state.selectedOptionProducts}
                      placeholder={<div>Select</div>}
                      loadOptions={this.fetchDataProducts}
                      onChange={(e) => {
                        this.onSearchChangeProducts(e);
                      }}
                    />
                  </Form>
                </Col>
                <Col className="col-lg-2 col-md-3 col-sm-12 ">
                  <Button
                    block
                    color="dark"
                    className="btn-icon text-uppercase"
                    type="button"
                    disabled={this.state.isResetLoading ? true : false}
                    onClick={() => this.handleClear()}
                  >
                    <span className="btn-inner--text">Clear</span>
                  </Button>
                </Col>
              </Row>
              <Row className="my-1">
                <Col className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <h1 className="display-4">Specification</h1>
                  <Card>
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h3 className="mb-0">
                            {" "}
                            {this.props.dataCustomerProductsDetails.chasis}{" "}
                            {
                              this.props.dataCustomerProductsDetails
                                .tank_capacity
                            }{" "}
                            {this.props.dataCustomerProductsDetails.axle}
                          </h3>
                        </div>
                      </Row>
                    </CardHeader>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listSpecifications.map((element, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">{element.specification_name}</th>
                              <td className="text-wrap">
                                {element.specification_description}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    {this.state.brochureCardComparisonProduct !== "" ? (
                      <CardFooter className="py-4">
                        <div className="pull-right">
                          <th></th>
                          <small>Start from</small>
                          <h4 className="display-4">
                            {currencyIDR(this.state.grandTotal).replace(
                              /\.00$/,
                              ""
                            )}
                          </h4>
                        </div>
                      </CardFooter>
                    ) : null}
                  </Card>
                </Col>
                {this.state.brochureCardComparisonProduct !== "" ? (
                  <Col className="col-lg-6 col-md-12 col-sm-12 col-12">
                         <h1 className="display-4">&nbsp;</h1>
                    <Card>
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <div className="col">
                            <h3
                              className="mb-0"
                              onClick={() =>
                                this.handleComparisonDetails(
                                  this.state.dataCardComparisonProduct
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {" "}
                              {this.state.titleCardComparisonProduct}{" "}
                            </h3>
                          </div>
                          <Col className="text-right" lg="6" xs="5">
                            <Button
                              className="text-uppercase"
                              color="primary"
                              type="button"
                              size="sm"
                              onClick={() =>
                                window.open(
                                  config.BUCKET_URL +
                                    this.state.brochureCardComparisonProduct,
                                  "_blank"
                                )
                              }
                            >
                              <span className="btn-inner--text ml-2">
                                Brochure
                              </span>
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.listComparisonProduct.map(
                            (element, index) => {
                              return (
                                <tr key={index}>
                                  <th scope="row">
                                    {element.specification_name}
                                  </th>
                                  <td className="text-wrap">
                                    {element.specification_description}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                      <CardFooter className="py-4">
                        <div className="pull-right">
                          <small>Start from</small>
                          <h4 className="display-4">
                            {currencyIDR(
                              this.state.priceCardComparisonProduct
                            ).replace(/\.00$/, "")}
                          </h4>
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                ) : (
                  <Col className="col-lg-6 col-md-12 col-sm-12" xs="12">
                    <Row>
                    <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                  {this.state.listStandardComponents.length > 0 ||
                  this.state.listOptionalComponents.length > 0 ? (
                    <h1 className="display-4">Component</h1>
                  ) : null}
                </Col>
                      {/* {this.state.listStandardComponents.length > 0 ? (
                      <Col className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <Card>
                          <CardHeader className="border-0">
                            <Row className="align-items-center">
                              <div className="col">
                                <h3 className="mb-0">Standard Components</h3>
                              </div>
                            </Row>
                          </CardHeader>
                          <Table
                            className="align-items-center table-flush"
                            responsive
                          >
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.listStandardComponents.map(
                                (element, index) => {
                                  return (
                                    <tr key={index}>
                                      <th scope="row">
                                        {element.standard_component_name}
                                      </th>
                                      <td className="text-wrap">
                                        {element.standard_component_description}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </Card>
                      </Col>
                      ) : null} */}
                      {this.state.listOptionalComponents.length > 0 ? (
                        <Col className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <Card>
                            <CardHeader className="border-0">
                              <Row className="align-items-center">
                                <div className="col">
                                  <h3 className="mb-0">
                                    Additional Components
                                  </h3>
                                </div>
                                <div className="col text-right">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      caret
                                      className="text-uppercase"
                                      color="primary"
                                      size="sm"
                                    >
                                      Add Components
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {this.state.listOptionalComponents
                                        .length === 0 ? (
                                        <DropdownItem
                                          href="#pablo"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <div className="timeline-block">
                                            <div className="d-flex justify-content-between pt-1">
                                              <div>
                                                <span className="text-sm font-weight-bold">
                                                  no components can be added 
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </DropdownItem>
                                      ) : (
                                        <>
                                          {this.state.listOptionalComponents.map(
                                            (element, index) => {
                                              return (
                                                <DropdownItem
                                                  href="#pablo"
                                                  onClick={(e) =>
                                                    this.handleAdditionalComponents(
                                                      e,
                                                      element
                                                    )
                                                  }
                                                  key={index}
                                                >
                                                  <div className="timeline-block">
                                                    <div className="d-flex justify-content-between pt-1">
                                                      <div>
                                                        <span className="text-sm font-weight-bold">
                                                          {
                                                            element.optional_component_name
                                                          }
                                                        </span>
                                                      </div>
                                                      <div className="text-right ml-6">
                                                        <span className="text-sm">
                                                          {currencyIDR(
                                                            element.optional_component_price
                                                          ).replace(
                                                            /\.00$/,
                                                            ""
                                                          )}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <p className="text-sm mt-1 mb-0">
                                                      {
                                                        element.optional_component_description
                                                      }
                                                    </p>
                                                  </div>
                                                </DropdownItem>
                                              );
                                            }
                                          )}
                                        </>
                                      )}
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                              </Row>
                            </CardHeader>
                            {this.state.listOptionalComponentsAdded.length ===
                            0 ? null : (
                              <>
                                <Table
                                  className="align-items-center table-flush"
                                  responsive
                                >
                                  <thead className="thead-light">
                                    <tr>
                                      <th scope="col">Name</th>
                                      <th scope="col">Description</th>
                                      <th scope="col">Price</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.listOptionalComponentsAdded.map(
                                      (element, index) => {
                                        return (
                                          <tr key={index}>
                                            <th scope="row">
                                              {element.optional_component_name}
                                            </th>
                                            <td>
                                            {element.optional_component_description}
                                            </td>
                                            <td>
                                              {currencyIDR(
                                                element.optional_component_price
                                              ).replace(/\.00$/, "")}
                                            </td>
                                            <td>
                                              <a
                                                className="table-action table-action-delete"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  this.handleDeleteAdditionalComponents(
                                                    index
                                                  )
                                                }
                                              >
                                                <i className="fas fa-trash" />
                                              </a>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </Table>
                              </>
                            )}
                          </Card>
                        </Col>
                      ) : null}
                      {/* <Col className="col-lg-6 col-md-6 col-sm-12" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Payment Terms
                          </label>
                          <Input
                            id="example-date-input"
                            type="select"
                            name="payment_terms"
                            onKeyPress={this.keyPressed}
                            value={this.state.data.payment_terms}
                            onChange={this.handleChangeString}
                            placeholder="Payment Terms"
                            required
                            className={
                              this.state.paramError.payment_terms
                                ? "is-invalid"
                                : ""
                            }
                          >
                            <option value="">Select</option>
                            <option value="Net 30">Net 30</option>
                            <option value="Net 40">Net 40</option>
                            <option value="Net 45">Net 45</option>
                            <option value="Net 60">Net 60</option>
                            <option value="10% DP - 90% Before Delivery">
                              10% DP - 90% Before Delivery
                            </option>
                            <option value="20% DP - 80% Before Delivery">
                              20% DP - 80% Before Delivery
                            </option>
                            <option value="30% DP - 70% Before Delivery">
                              30% DP - 70% Before Delivery
                            </option>
                          </Input>
                          <div className="invalid-feedback">
                            {this.state.paramError.payment_terms}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-6 col-md-6 col-sm-12" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Purchase Timeframe
                          </label>
                          <Input
                            id="example-date-input"
                            type="select"
                            name="purchase_timeframe"
                            placeholder="Timeframe"
                            onKeyPress={this.keyPressed}
                            value={this.state.data.purchase_timeframe}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.purchase_timeframe
                                ? "is-invalid"
                                : ""
                            }
                          >
                            <option value="">Select</option>
                            <option value="Immediate">Immediate</option>
                            <option value="This Quarter">This Quarter</option>
                            <option value="Next Quarter">Next Quarter</option>
                            <option value="This Year">This Year</option>
                          </Input>
                          <div className="invalid-feedback">
                            {this.state.paramError.purchase_timeframe}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-6 col-md-6 col-sm-12" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Freight Terms
                          </label>
                          <Input
                            id="example-date-input"
                            type="select"
                            name="freight_terms"
                            onKeyPress={this.keyPressed}
                            value={this.state.data.freight_terms}
                            onChange={this.handleChangeString}
                            placeholder="Freight Terms"
                            required
                            className={
                              this.state.paramError.freight_terms
                                ? "is-invalid"
                                : ""
                            }
                          >
                            <option value="">Select</option>
                            <option value="CFR">CFR</option>
                            <option value="CIF">CIF</option>
                            <option value="CIP">CIP</option>
                            <option value="CPT">CPT</option>
                            <option value="FOB">FOB</option>
                            <option value="DAP">DAP</option>
                            <option value="DAT">DAT</option>
                            <option value="DDP">DDP</option>
                            <option value="DPP">DPP</option>
                            <option value="EXW">EXW</option>
                            <option value="FAS">FAS</option>
                            <option value="FCA">FCA</option>
                          </Input>
                          <div className="invalid-feedback">
                            {this.state.paramError.freight_terms}
                          </div>
                        </FormGroup>
                      </Col>

                      <Col className="col-lg-6 col-md-6 col-sm-12" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Shipping Method
                          </label>
                          <Input
                            id="example-date-input"
                            type="select"
                            name="shipping_method"
                            onKeyPress={this.keyPressed}
                            value={this.state.data.shipping_method}
                            onChange={this.handleChangeShippingMethod}
                            placeholder="Shipping Method"
                            required
                            className={
                              this.state.paramError.shipping_method
                                ? "is-invalid"
                                : ""
                            }
                          >
                            <option value="">Select</option>
                            <option value="Delivery">Delivery</option>
                            <option value="Ex-work">Ex-work</option>
                          </Input>
                          <div className="invalid-feedback">
                            {this.state.paramError.shipping_method}
                          </div>
                        </FormGroup>
                      </Col> */}
                      {/* {this.state.isCountryArea ? (
                        <>
                          <Col
                            className="col-lg-12 col-md-12 col-sm-12"
                            xs="12"
                          >
                            <FormGroup>
                              <label className="form-control-label">
                                Country
                              </label>
                              <Input
                                id="example-date-input"
                                type="select"
                                name="country"
                                onKeyPress={this.keyPressed}
                                value={this.state.data.country}
                                onChange={this.handleChangeString}
                                placeholder="Country"
                                required
                                className={
                                  this.state.paramError.country
                                    ? "is-invalid"
                                    : ""
                                }
                              >
                                <option value="">Select</option>
                                {countries.map((element) => {
                                  return (
                                    <option value={element.name}>
                                      {element.name}
                                    </option>
                                  );
                                })}
                              </Input>
                              <div className="invalid-feedback">
                                {this.state.paramError.country}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col
                            className="col-lg-12 col-md-12 col-sm-124"
                            xs="12"
                          >
                            <FormGroup>
                              <label className="form-control-label">
                                City or Area
                              </label>
                              <Input
                                id="example-date-input"
                                type="textarea"
                                name="area"
                                onKeyPress={this.keyPressed}
                                value={this.state.data.area}
                                onChange={this.handleChangeString}
                                placeholder="Demand City or Area"
                                required
                                className={
                                  this.state.paramError.area ? "is-invalid" : ""
                                }
                              />
                              <div className="invalid-feedback">
                                {this.state.paramError.area}
                              </div>
                            </FormGroup>
                          </Col>
                        </>
                      ) : null} */}
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                        <h1 className="display-4">Quantity</h1>
                          <Input
                            id="example-date-input"
                            type="number"
                            name="quantity"
                            min="1"
                            onKeyPress={this.keyPressed}
                            value={this.state.data.quantity}
                            onChange={this.handleChangeQuantity}
                            placeholder="Quantity"
                            required
                            className={
                              this.state.paramError.quantity ? "is-invalid" : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.quantity}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Notes</label>
                          <Input
                            id="example-date-input"
                            type="textarea"
                            name="notes"
                            placeholder="Notes"
                            onKeyPress={this.keyPressed}
                            value={this.state.data.notes}
                            onChange={this.handleChangeString}
                          />
                        </FormGroup>
                      </Col>

                      {/* <Col className="col-lg-4 col-md-4 col-sm-12" xs="12">
                        <div className="text-left">
                          <span className="h6 surtitle text-muted">Price</span>
                          <div className="h3">
                            {currencyIDR(
                              this.state.countPriceComponents
                            ).replace(/\.00$/, "")}
                          </div>
                        </div>
                      </Col>
                      <Col className="col-lg-2 col-md-2 col-sm-12" xs="12">
                        <div className="text-center">
                          <span className="h6 surtitle text-muted">
                            Quantity
                          </span>
                          <div className="h3">{this.state.data.quantity}</div>
                        </div>
                      </Col>
                      <Col className="col-lg-6 col-md-6 col-sm-12" xs="12">
                        <div className="text-right">
                          <span className="h6 surtitle text-muted">
                            Total Price
                          </span>
                          <div className="h3">
                            {currencyIDR(
                              this.state.data.total_product_price
                            ).replace(/\.00$/, "")}
                          </div>
                        </div>
                      </Col> */}
                      {/* {this.state.listOptionalComponentsAdded.map(
                        (element, index) => {
                          return (
                            <div
                              className="col-lg-12 col-md-12 col-sm-12"
                              xs="12"
                              key={index}
                            >
                              <Col
                                className="col-lg-6 col-md-6 col-sm-6 pull-left px-0"
                                xs="12"
                              >
                                <div className="text-left">
                                  <span className="h6 surtitle text-muted">
                                    Additional
                                  </span>
                                  <div className="h3">
                                    {element.optional_component_name}
                                  </div>
                                </div>
                              </Col>
                              <Col
                                className="col-lg-6 col-md-6 col-sm-6 pull-right px-0"
                                xs="12"
                              >
                                <div className="text-right">
                                  <span className="h6 surtitle text-muted">
                                    Component Price
                                  </span>
                                  <div className="h3">
                                    {currencyIDR(
                                      element.optional_component_price
                                    ).replace(/\.00$/, "")}
                                  </div>
                                </div>
                              </Col>
                            </div>
                          );
                        }
                      )} */}

                      {/* <Col className="col-lg-6 col-md-6 col-sm-12" xs="12">
                        <div className="text-left">
                          <span className="h6 surtitle text-muted">Tax</span>
                          <div className="h3">
                            {this.props.dataCustomerProductsDetails.tax + " %"}
                          </div>
                        </div>
                      </Col>
                      <Col className="col-lg-6 col-md-6 col-sm-12" xs="12">
                        <div className="text-right">
                          <span className="h6 surtitle text-muted">
                            Tax Value
                          </span>
                          <div className="h3">
                            {this.props.dataCustomerProductsDetails.tax + " %"}
                          </div>
                        </div>
                      </Col>
                      <Col className="col-lg-6 col-md-6 col-sm-12" xs="12">
                        <div className="text-left">
                          <span className="h6 surtitle text-muted">
                            Discount
                          </span>
                          <div className="h3">
                            {this.props.dataCustomerProductsDetails.discount +
                              " %"}
                          </div>
                        </div>
                      </Col>
                      <Col className="col-lg-6 col-md-6 col-sm-12" xs="12">
                        <div className="text-right">
                          <span className="h6 surtitle text-muted">
                            Discount Value
                          </span>
                          <div className="h3">
                            {this.props.dataCustomerProductsDetails.discount +
                              " %"}
                          </div>
                        </div>
                      </Col> */}

                      <Col
                        className="col-lg-12 col-md-12 col-sm-12 mt-3"
                        xs="12"
                      >
                        <div className="text-right">
                          <span className="h6 surtitle text-muted">
                            Estimate Price 
                          </span>
                          <div className="h1">
                            <b>
                              {currencyIDR(this.state.grandTotal).replace(
                                /\.00$/,
                                ""
                              )}
                            </b>
                          </div>
                        </div>
                      </Col>
                      <Col className="mt-4">
                        <Button
                          block
                          color="danger"
                          className="btn-icon text-uppercase"
                          type="button"
                          onClick={() => this.handleCancel()}
                        >
                            <span className="btn-inner--text">Cancel</span>
                        </Button>
                      </Col>
                      <Col className="mt-4">
                        <Button
                          className="text-uppercase"
                          color="primary"
                          type="button"
                          block
                          onClick={() => this.confirmAlert()}
                        >
                          <span id="request_quote" className="btn-inner--text">
                            Save
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>

              {/* <h1 className="display-4">Request a Quote</h1>
              <h6 className="heading-small text-muted">Amount Information</h6>
              <Row>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <div>
                    <span className="h6 surtitle text-muted">Price</span>
                    <div className="h1">
                      {currencyIDR(this.state.countPriceComponents).replace(
                        /\.00$/,
                        ""
                      )}
                    </div>
                  </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <div>
                    <span className="h6 surtitle text-muted">Tax</span>
                    <div className="h1">
                      {this.props.dataCustomerProductsDetails.tax + " %"}
                    </div>
                  </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <div>
                    <span className="h6 surtitle text-muted">Discount</span>
                    <div className="h1">
                      {this.props.dataCustomerProductsDetails.discount + " %"}
                    </div>
                  </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <div>
                    <span className="h6 surtitle text-muted">Total Price</span>
                    <div className="h1">
                      {currencyIDR(this.state.data.total_product_price).replace(
                        /\.00$/,
                        ""
                      )}
                    </div>
                  </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <div>
                    <span className="h6 surtitle text-muted">Quantity</span>
                    <div className="h1">{this.state.data.quantity}</div>
                  </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <div>
                    <span className="h6 surtitle text-muted">Grand Total</span>
                    <div className="h1">
                      {currencyIDR(this.state.grandTotal).replace(/\.00$/, "")}
                    </div>
                  </div>
                </Col>
              </Row>
              <h6 className="heading-small text-muted">Shipping Information</h6>
              <Row>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Quantity</label>
                    <Input
                      id="example-date-input"
                      type="number"
                      name="quantity"
                      min="1"
                      onKeyPress={this.keyPressed}
                      value={this.state.data.quantity}
                      onChange={this.handleChangeQuantity}
                      placeholder="Quantity"
                      required
                      className={
                        this.state.paramError.quantity ? "is-invalid" : ""
                      }
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.quantity}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">
                      Shipping Method
                    </label>
                    <Input
                      id="example-date-input"
                      type="select"
                      name="shipping_method"
                      onKeyPress={this.keyPressed}
                      value={this.state.data.shipping_method}
                      onChange={this.handleChangeShippingMethod}
                      placeholder="Shipping Method"
                      required
                      className={
                        this.state.paramError.shipping_method
                          ? "is-invalid"
                          : ""
                      }
                    >
                      <option value="">Select</option>
                      <option value="Delivery">Delivery</option>
                      <option value="Ex-work">Ex-work</option>
                    </Input>
                    <div className="invalid-feedback">
                      {this.state.paramError.shipping_method}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Payment Terms</label>
                    <Input
                      id="example-date-input"
                      type="select"
                      name="payment_terms"
                      onKeyPress={this.keyPressed}
                      value={this.state.data.payment_terms}
                      onChange={this.handleChangeString}
                      placeholder="Payment Terms"
                      required
                      className={
                        this.state.paramError.payment_terms ? "is-invalid" : ""
                      }
                    >
                      <option value="">Select</option>
                      <option value="Net 30">Net 30</option>
                      <option value="Net 40">Net 40</option>
                      <option value="Net 45">Net 45</option>
                      <option value="Net 60">Net 60</option>
                      <option value="10% DP - 90% Before Delivery">
                        10% DP - 90% Before Delivery
                      </option>
                      <option value="20% DP - 80% Before Delivery">
                        20% DP - 80% Before Delivery
                      </option>
                      <option value="30% DP - 70% Before Delivery">
                        30% DP - 70% Before Delivery
                      </option>
                    </Input>
                    <div className="invalid-feedback">
                      {this.state.paramError.payment_terms}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">
                      Purchase Timeframe
                    </label>
                    <Input
                      id="example-date-input"
                      type="select"
                      name="purchase_timeframe"
                      placeholder="Timeframe"
                      onKeyPress={this.keyPressed}
                      value={this.state.data.purchase_timeframe}
                      onChange={this.handleChangeString}
                      required
                      className={
                        this.state.paramError.purchase_timeframe
                          ? "is-invalid"
                          : ""
                      }
                    >
                      <option value="">Select</option>
                      <option value="Immediate">Immediate</option>
                      <option value="This Quarter">This Quarter</option>
                      <option value="Next Quarter">Next Quarter</option>
                      <option value="This Year">This Year</option>
                      <option value="Unknown">Unknown</option>
                    </Input>
                    <div className="invalid-feedback">
                      {this.state.paramError.purchase_timeframe}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                  <FormGroup>
                    <label className="form-control-label">Freight Terms</label>
                    <Input
                      id="example-date-input"
                      type="select"
                      name="freight_terms"
                      onKeyPress={this.keyPressed}
                      value={this.state.data.freight_terms}
                      onChange={this.handleChangeString}
                      placeholder="Freight Terms"
                      required
                      className={
                        this.state.paramError.freight_terms ? "is-invalid" : ""
                      }
                    >
                      <option value="">Select</option>
                      <option value="CFR">CFR</option>
                      <option value="CIF">CIF</option>
                      <option value="CIP">CIP</option>
                      <option value="CPT">CPT</option>
                      <option value="FOB">FOB</option>
                      <option value="DAP">DAP</option>
                      <option value="DAT">DAT</option>
                      <option value="DDP">DDP</option>
                      <option value="DPP">DPP</option>
                      <option value="EXW">EXW</option>
                      <option value="FAS">FAS</option>
                      <option value="FCA">FCA</option>
                    </Input>
                    <div className="invalid-feedback">
                      {this.state.paramError.freight_terms}
                    </div>
                  </FormGroup>
                </Col>
                {this.state.isCountryArea ? (
                  <>
                    <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Country</label>
                        <Input
                          id="example-date-input"
                          type="select"
                          name="country"
                          onKeyPress={this.keyPressed}
                          value={this.state.data.country}
                          onChange={this.handleChangeString}
                          placeholder="Country"
                          required
                          className={
                            this.state.paramError.country ? "is-invalid" : ""
                          }
                        >
                          <option value="">Select</option>
                          {countries.map((element) => {
                            return (
                              <option value={element.name}>
                                {element.name}
                              </option>
                            );
                          })}
                        </Input>
                        <div className="invalid-feedback">
                          {this.state.paramError.country}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-12" xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          City or Area
                        </label>
                        <Input
                          id="example-date-input"
                          type="text"
                          name="area"
                          onKeyPress={this.keyPressed}
                          value={this.state.data.area}
                          onChange={this.handleChangeString}
                          placeholder="Demand City or Area"
                          required
                          className={
                            this.state.paramError.area ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.area}
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                ) : null}
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label className="form-control-label">Notes</label>
                    <Input
                      id="example-date-input"
                      type="textarea"
                      name="notes"
                      placeholder="Notes"
                      onKeyPress={this.keyPressed}
                      value={this.state.data.notes}
                      onChange={this.handleChangeString}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-3">
                <Col>
                  <Button
                    className="text-uppercase"
                    color="primary"
                    type="button"
                    onClick={() => this.confirmAlert()}
                  >
                    <span id="request_quote" className="btn-inner--text">
                      Save
                    </span>
                  </Button>
                  <Button
                    className="btn-second text-uppercase"
                    type="button"
                    onClick={() => this.handleAddToCart()}
                  >
                    <span id="request_quote" className="btn-inner--text">
                      Add to Cart
                    </span>
                  </Button>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataCustomerProducts: state.customer_product.dataCustomerProducts,
    dataCustomerProductsDetails:
      state.customer_product.dataCustomerProductsDetails,
  };
};

export default connect(mapStateToProps)(CustomerStandardConfigurationDetails);