import React from "react";

import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
} from "reactstrap";

class SpareParts extends React.Component {

  // render() {
    // return (
    //   <>
    //     <Container className="mt--8">
    //         <div className="header-body text-center mb-4">
    //           <Row className="justify-content-center py-8">
    //             <Col className="px-8" lg="8" md="8" xl="8">
    //                 <i class="fas fa-cog fa-spin fa-8x"></i>
    //                 <h1 className="text-dark">This Section Under Development</h1>
    //                 <p className="h3 text-dark">Coming Soon</p>
    //             </Col>
    //           </Row>
    //         </div>
         
    //     </Container>
    //   </>
    // );
    handletoDetails = () => {
      this.props.history.push("/customers/spare-parts-details");
    };
  
    render() {
      return (
        <>
          <Container className="mt--8">
              {/* <div className="header-body text-center mb-4">
                <Row className="justify-content-center py-8">
                  <Col className="px-8" lg="8" md="8" xl="8">
                      <i class="fas fa-cog fa-spin fa-8x"></i>
                      <h1 className="text-dark">This Section Under Development</h1>
                      <p className="h3 text-dark">Coming Soon</p>
                  </Col>
                </Row>
              </div> */}
               <Row>
            <Col md="4" xs="6">
              <Card className="card-lift--hover shadow border-0">
                <CardImg
                  alt="..."
                  src={require("assets/img/theme/img-1-1000x600.jpg")}
                  top
                />
                <CardBody
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handletoDetails()}
                >
                  <CardTitle className="text-dark">
                      Nuque Porro Quisquan
                  </CardTitle>
                  <CardText className="description text-dark">
                    This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer.
                  </CardText>
                  <div>
                    <Button
                      className="btn-neutral text-uppercase"
                      size="sm"
                      type="button"
                    >
                      Details
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" xs="6">
              <Card className="card-lift--hover shadow border-0">
                <CardImg
                  alt="..."
                  src={require("assets/img/theme/img-1-1000x600.jpg")}
                  top
                />
                <CardBody
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handletoDetails()}
                >
                  <CardTitle className="text-dark">
                      Nuque Porro Quisquan
                  </CardTitle>
                  <CardText className="description text-dark">
                    This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer.
                  </CardText>
                  <div>
                    <Button
                      className="btn-neutral text-uppercase"
                      size="sm"
                      type="button"
                    >
                      Details
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" xs="6">
              <Card className="card-lift--hover shadow border-0">
                <CardImg
                  alt="..."
                  src={require("assets/img/theme/img-1-1000x600.jpg")}
                  top
                />
                <CardBody
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handletoDetails()}
                >
                  <CardTitle className="text-dark">
                      Nuque Porro Quisquan
                  </CardTitle>
                  <CardText className="description text-dark">
                    This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer.
                  </CardText>
                  <div>
                    <Button
                      className="btn-neutral text-uppercase"
                      size="sm"
                      type="button"
                    >
                      Details
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" xs="6">
              <Card className="card-lift--hover shadow border-0">
                <CardImg
                  alt="..."
                  src={require("assets/img/theme/img-1-1000x600.jpg")}
                  top
                />
                <CardBody
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handletoDetails()}
                >
                  <CardTitle className="text-dark">
                      Nuque Porro Quisquan
                  </CardTitle>
                  <CardText className="description text-dark">
                    This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer.
                  </CardText>
                  <div>
                    <Button
                      className="btn-neutral text-uppercase"
                      size="sm"
                      type="button"
                    >
                      Details
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" xs="6">
              <Card className="card-lift--hover shadow border-0">
                <CardImg
                  alt="..."
                  src={require("assets/img/theme/img-1-1000x600.jpg")}
                  top
                />
                <CardBody
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handletoDetails()}
                >
                  <CardTitle className="text-dark">
                      Nuque Porro Quisquan
                  </CardTitle>
                  <CardText className="description text-dark">
                    This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer.
                  </CardText>
                  <div>
                    <Button
                      className="btn-neutral text-uppercase"
                      size="sm"
                      type="button"
                    >
                      Details
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" xs="6">
              <Card className="card-lift--hover shadow border-0">
                <CardImg
                  alt="..."
                  src={require("assets/img/theme/img-1-1000x600.jpg")}
                  top
                />
                <CardBody
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handletoDetails()}
                >
                  <CardTitle className="text-dark">
                      Nuque Porro Quisquan
                  </CardTitle>
                  <CardText className="description text-dark">
                    This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer.
                  </CardText>
                  <div>
                    <Button
                      className="btn-neutral text-uppercase"
                      size="sm"
                      type="button"
                    >
                      Details
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col
              style={{ marginTop: 20 }}
              className="col-6 col-md-3 col-sm-6"
              xs="12"
            >
              <div className="mb-4 text-center">
                <Button
                  block
                  className="btn-neutral text-uppercase"
                  color="default"
                >
                  Load more
                </Button>
              </div>
            </Col>
          </Row>
            <Row>
              <Col md="4" xs="6">
                <Card className="card-lift--hover shadow border-0">
                  <CardImg
                    alt="..."
                    src={require("assets/img/theme/img-1-1000x600.jpg")}
                    top
                  />
                  <CardBody
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handletoDetails()}
                  >
                    <CardTitle className="text-dark">
                        Nuque Porro Quisquan
                    </CardTitle>
                    <CardText className="description text-dark">
                      This is a longer card with supporting text below as a
                      natural lead-in to additional content. This content is a
                      little bit longer.
                    </CardText>
                    <div>
                      <Button
                        className="btn-neutral text-uppercase"
                        size="sm"
                        type="button"
                      >
                        Details
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xs="6">
                <Card className="card-lift--hover shadow border-0">
                  <CardImg
                    alt="..."
                    src={require("assets/img/theme/img-1-1000x600.jpg")}
                    top
                  />
                  <CardBody
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handletoDetails()}
                  >
                    <CardTitle className="text-dark">
                        Nuque Porro Quisquan
                    </CardTitle>
                    <CardText className="description text-dark">
                      This is a longer card with supporting text below as a
                      natural lead-in to additional content. This content is a
                      little bit longer.
                    </CardText>
                    <div>
                      <Button
                        className="btn-neutral text-uppercase"
                        size="sm"
                        type="button"
                      >
                        Details
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xs="6">
                <Card className="card-lift--hover shadow border-0">
                  <CardImg
                    alt="..."
                    src={require("assets/img/theme/img-1-1000x600.jpg")}
                    top
                  />
                  <CardBody
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handletoDetails()}
                  >
                    <CardTitle className="text-dark">
                        Nuque Porro Quisquan
                    </CardTitle>
                    <CardText className="description text-dark">
                      This is a longer card with supporting text below as a
                      natural lead-in to additional content. This content is a
                      little bit longer.
                    </CardText>
                    <div>
                      <Button
                        className="btn-neutral text-uppercase"
                        size="sm"
                        type="button"
                      >
                        Details
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xs="6">
                <Card className="card-lift--hover shadow border-0">
                  <CardImg
                    alt="..."
                    src={require("assets/img/theme/img-1-1000x600.jpg")}
                    top
                  />
                  <CardBody
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handletoDetails()}
                  >
                    <CardTitle className="text-dark">
                        Nuque Porro Quisquan
                    </CardTitle>
                    <CardText className="description text-dark">
                      This is a longer card with supporting text below as a
                      natural lead-in to additional content. This content is a
                      little bit longer.
                    </CardText>
                    <div>
                      <Button
                        className="btn-neutral text-uppercase"
                        size="sm"
                        type="button"
                      >
                        Details
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xs="6">
                <Card className="card-lift--hover shadow border-0">
                  <CardImg
                    alt="..."
                    src={require("assets/img/theme/img-1-1000x600.jpg")}
                    top
                  />
                  <CardBody
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handletoDetails()}
                  >
                    <CardTitle className="text-dark">
                        Nuque Porro Quisquan
                    </CardTitle>
                    <CardText className="description text-dark">
                      This is a longer card with supporting text below as a
                      natural lead-in to additional content. This content is a
                      little bit longer.
                    </CardText>
                    <div>
                      <Button
                        className="btn-neutral text-uppercase"
                        size="sm"
                        type="button"
                      >
                        Details
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" xs="6">
                <Card className="card-lift--hover shadow border-0">
                  <CardImg
                    alt="..."
                    src={require("assets/img/theme/img-1-1000x600.jpg")}
                    top
                  />
                  <CardBody
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handletoDetails()}
                  >
                    <CardTitle className="text-dark">
                        Nuque Porro Quisquan
                    </CardTitle>
                    <CardText className="description text-dark">
                      This is a longer card with supporting text below as a
                      natural lead-in to additional content. This content is a
                      little bit longer.
                    </CardText>
                    <div>
                      <Button
                        className="btn-neutral text-uppercase"
                        size="sm"
                        type="button"
                      >
                        Details
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col
                style={{ marginTop: 20 }}
                className="col-6 col-md-3 col-sm-6"
                xs="12"
              >
                <div className="mb-4 text-center">
                  <Button
                    block
                    className="btn-neutral text-uppercase"
                    color="default"
                  >
                    Load more
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  
}

export default SpareParts;
