import {
  GET_DATA_CUSTOMER_QUOTATIONS_DETAILS
} from "./customerQuotationActions";

const initialState = {
  dataCustomerQuotationsDetails: []
};

export default function customerQuotationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DATA_CUSTOMER_QUOTATIONS_DETAILS:
      return {
        ...state,
        dataCustomerQuotationsDetails: action.payload.customerQuotationDetails,
      };
    default:
      return state;
  }
}
