import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import Breadcumbs from "components/Breadcumbs/Breadcumbs";
import config from "services/config";
import axios from "axios";
class SsbPortofolio extends PureComponent {
  state = {
    listProduct: [],
    total_items: 0,
  };
  componentDidMount() {
    this.mounted = true;
    this.getDataListProducts();
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  handletoDetails = (e) => {
    this.props.history.push("/customers/ssb-portofolio-detail/" + e);
  };
  getDataListProducts = () => {
    axios
      .post(
        config.API_URL + "/portofolio/view_ssb_portofolio_customer",
        this.state.total_items,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listProduct: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  render() {
    const dataProduct = this.state.listProduct.map((products, index) => {
      return (
        <>
          <Card
            className="card-lift--hover rounded shadow mt-4 mx-4"
            key={index}
            style={{ cursor: "pointer", objectFit: "cover" }}
            onClick={() => this.handletoDetails(products.category_name.toUpperCase())}
          >
            <div className="d-flex flex-wrap flex-md-nowrap flex-lg-nowrap flex-xl-nowrap">
              <div className="flex-item justify-content-center">
                <img
                  alt="..."
                  className="rounded"
                  width="320"
                  height="220"
                  style={{ cursor: "pointer", objectFit: "cover" }}
                  src={config.BUCKET_URL + products.image_1}
                />

                <h4 className="text-center text-uppercase">
                  {products.category_name}
                </h4>
              </div>
            </div>
          </Card>
        </>
      );
    });

    return (
      <>
        <Container className="bg-secondary mt--6">
          <Breadcumbs
            name="SSB Portofolio"
            parentName="SSB Portofolio"
            link="/customers/ssb-portofolio"
          />
            {this.state.listProduct.length === 0 ? (
         
                <Card className="card-frame mt-5">
                  <CardBody>
                    <i class="fas fa-sync fa-spin mr-2"></i>
                    <strong>Waiting ssb portofolio ...</strong>
                  </CardBody>
                </Card>
            ) : (
          <Row className="flex-wrap-reverse">
            <Row className=" mx-3 rounded border border-dark mb-4 pt-3">
              {dataProduct}
            </Row>
          </Row>
              )}
        </Container>
      </>
    );
  }
}

export default SsbPortofolio;
